import axios, { AxiosResponse } from "axios";
import { IUserForm } from "../components/UserRegistration";
import { setServerUrl } from "../constants/constants";
import { IUserModel } from "../models/IUserModel";

const UserService = {
  /**
   * get page by id
   * @param id: number|null
   * @returns page
   */

  async createOrUpdate(data: IUserForm) {
    const response = await axios.put(setServerUrl(`/api/user`, `/api/user`), {
      data
    });
    return Promise.resolve(response.data);
  },
  async loginUser(user: any): Promise<any> {
    const response: AxiosResponse = await axios.request({
      url: setServerUrl(`/api/login/`, `/api/login`),
      method: "POST",
      data: user
    });
    return Promise.resolve(response.data);
  },
  async getUser(): Promise<IUserModel> {
    const response: AxiosResponse<IUserModel> = await axios.request<IUserModel>(
      {
        url: setServerUrl(`/api/user`, `/api/user`),
        method: "GET"
      }
    );
    return Promise.resolve(response.data);
  },
  async getUsers() {
    const response = await axios.get(
      setServerUrl("/api/user/all", "/api/user/all")
    );

    return Promise.resolve(response.data.data);
  },
  async deleteUser(userId: string) {
    const response = await axios.delete(
      setServerUrl(`/api/user/${userId}`, `/api/user/${userId}`)
    );
    return Promise.resolve(response.data);
  },
  async updateUser(data: IUserForm) {
    const response = await axios.put(setServerUrl(`/api/user`, `/api/user`), {
      data
    });
    return Promise.resolve(response.data);
  },
  async changeNavPrefs(navPref: string): Promise<IUserModel> {
    const response: AxiosResponse<IUserModel> = await axios.request<IUserModel>(
      {
        url: setServerUrl(
          `/api/user/updateNavPref/${navPref}`,
          `/api/user/updateNavPref/${navPref}`
        ),
        method: "PUT"
      }
    );
    return Promise.resolve(response.data);
  }
};

export default UserService;
