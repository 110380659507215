/* eslint-disable react-hooks/exhaustive-deps */
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import TimerIcon from "@material-ui/icons/Timer";
import { observer } from "mobx-react-lite";
import { FC, MouseEvent, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { competencyTypes } from "../constants/constants";
import { useAsync } from "../hooks/useAsync";
import { ISurveyModel } from "../models/ISurveyModel";
import SurveyService from "../services/SurveyService";
import UserStore from "../stores/UserStore";
import Wrapper from "./containers/Wrapper";
import DataGrid, { IDataGridProps } from "./DataGrid";
import AddSurvey from "./dialogs/AddSurvey";
import { CreateSurvey } from "./dialogs/CreateSurvey";
import Heading from "./Heading";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      maxWidth: "400px",
      marginTop: theme.spacing(3)
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    },
    btnBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    },
    btn: {
      margin: "0.5rem"
    }
  });
});

const Surveys: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyles();
  const history = useHistory();
  const { isAdmin } = useContext(UserStore);

  const { getAllSurveys } = SurveyService;

  const [surveys, setSurveys] = useState<Array<ISurveyModel>>([]);

  const getSurveys = async (): Promise<ISurveyModel[]> => {
    const { data } = await getAllSurveys();
    return data;
  };

  const handleRowClick = (
    _e: MouseEvent<HTMLButtonElement>,
    row: ISurveyModel
  ) => {
    history.push(`/survey/${row.id}`);
  };

  const renderSurveyType = (s: ISurveyModel) => (
    <>{competencyTypes[s.type || "OT"]}</>
  );

  useAsync<ISurveyModel[]>(getSurveys, setSurveys);

  const surveysDataProps: IDataGridProps = {
    pagingFilter: true,
    searchFilter: true,
    tableButtons: [
      {
        tooltip: "Umfrage erstellen",
        icon: () => <AddCircleOutline />,
        isFreeAction: true,
        popup: CreateSurvey,
        disabled: !isAdmin
      },
      {
        tooltip: "Umfrage duplizieren",
        icon: () => <ControlPointDuplicateIcon />,
        isFreeAction: true,
        popup: AddSurvey,
        data: surveys || [],
        disabled: !isAdmin
      }
    ],
    onRowClick: handleRowClick,
    columns: [
      {
        align: "left",
        title: "Umfrage\u00A0ID",
        type: "numeric",
        field: "sid"
      },
      {
        align: "left",
        field: "description",
        title: "Umfrage",
        type: "string"
      },
      {
        field: "type",
        title: "Umfragentyp",
        type: "string",
        render: renderSurveyType
      }
    ],
    data: surveys || []
  };

  return (
    <Wrapper>
      <Heading heading="Umfragen">
        <TimerIcon />
      </Heading>
      <DataGrid dense {...surveysDataProps} />
    </Wrapper>
  );
};

export default observer(Surveys);
