import {
  Avatar,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { FC } from "react";

export interface OverviewDisplay {
  title: string;
  display: JSX.Element;
}

interface DetailsOverviewArgs {
  icon: JSX.Element;
  title: string;
  displays: OverviewDisplay[];
  allowEdits?: boolean;
  editTooltip?: string;
  onEdit?: () => void;
}

export const useDetailStyles = makeStyles((theme: Theme) => ({
  label: {
    minWidth: "250px",
    textAlign: "left",
    margin: "0",
    padding: theme.spacing(0.5)
  },
  chip: {
    width: "fit-content"
  }
}));

const useStyles = makeStyles((theme: Theme) => ({
  details: {
    width: "100%"
  },
  overviewCard: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    alignItems: "flex-start",
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "1px",
    padding: theme.spacing(3)
  },
  editIcon: {
    cursor: "pointer"
  },
  avatar: {
    margin: "3px",
    backgroundColor: theme.palette.secondary.main
  },
  avatarBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(2),
    userSelect: "none"
  },
  display: {
    display: "flex",
    justifyContent: "center"
  },
  title: {
    fontSize: "2rem",
    margin: "0",
    marginLeft: "7px"
  },
  tablerow: {
    display: "flex",
    marginTop: "3px"
  },
  label: {
    minWidth: "250px",
    textAlign: "left",
    margin: "0",
    padding: theme.spacing(0.5)
  }
}));

const DetailsOverview: FC<DetailsOverviewArgs> = ({
  icon,
  title,
  displays,
  allowEdits = false,
  editTooltip,
  onEdit
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.overviewCard}>
        <div className={classes.avatarBox}>
          <div className={classes.display}>
            <Avatar className={classes.avatar}>{icon}</Avatar>
            <Typography className={classes.title}>{title}</Typography>
          </div>
          {allowEdits && (
            <div className={classes.editIcon}>
              <Tooltip title={editTooltip || "Bearbeiten"}>
                <IconButton size="medium" onClick={onEdit}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
        <div className={classes.details}>
          {displays.map((display, i) => (
            <div
              className={classes.tablerow}
              key={`_detailsOverviewDisplay${i}`}
            >
              <Typography className={classes.label}>{display.title}</Typography>
              {display.display}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DetailsOverview;
