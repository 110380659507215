import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { FC, useContext, useState } from "react";
import { IPositionModel } from "../../models/IPositionModel";
import ImportStore, { IImportState } from "../../stores/ImportStore";
import Wrapper from "../containers/Wrapper";

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    maxHeight: "300px",
    boxSizing: "border-box"
  },
  tableCell: {
    maxWidth: "100px",
    minWidth: "100px",
    padding: "2px 0px 2px 4px",
    overflowX: "hidden",
    textOverflow: "ellipsis"
  },
  accordionSummary: {
    backgroundColor: theme.palette.secondary.main
  },
  accordionDetails: {
    padding: "0",
    "@global": {
      ".MuiToolbar-root": {
        minHeight: "10px"
      }
    }
  }
}));

export interface IImportResultsProps {
  rows: IImportState[];
  positions: IPositionModel[];
}

const ImportResults: FC<IImportResultsProps> = ({ positions, rows }) => {
  const importStore = useContext(ImportStore);
  const classes = useStyles();
  const { activeStep, importState } = importStore;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getImportMessage = (type: string, occurence: string) => {
    let message = "";
    if (type === "participant") {
      switch (occurence) {
        case "new":
          message = "Neu angelegt";
          break;
        case "exist":
          message = "Besteht bereits";
          break;
        default:
          break;
      }
    } else if (type === "application") {
      switch (occurence) {
        case "new":
          message = "Neu angelegt";
          break;
        case "exist":
          message = "Besteht bereits";
          break;
        case "extended":
          message = "Teilnahmefrist angepasst";
          break;
        case "rejected":
          message = "Teilnahmefrist konnte nicht angepasst werden";
          break;
        default:
          break;
      }
    }
    return message;
  };

  return (
    <Wrapper>
      <TableContainer className={classes.tableContainer} component={Paper}>
        {importState.fetched &&
          activeStep === 6 &&
          importState.responseData instanceof Array &&
          importState.responseData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((state, i) => {
              return (
                <Accordion key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="secondary" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Table size="small" stickyHeader>
                      <TableBody>
                        <TableRow>
                          <TableCell variant="head">
                            {state.participant.firstName}{" "}
                            {state.participant.lastName}
                          </TableCell>
                          <TableCell variant="head" align="right">
                            {getImportMessage(
                              "participant",
                              state.participant.occurence
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell variant="head">Bewerbung</TableCell>
                          <TableCell variant="head">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state.application.map(
                          (
                            application: {
                              positionId: string;
                              occurence: string;
                            },
                            j: number
                          ) => (
                            <TableRow key={j}>
                              <TableCell>
                                {positions
                                  ?.filter(
                                    (position) =>
                                      position.id === application.positionId
                                  )
                                  ?.map((position) => position.longName)}
                              </TableCell>
                              <TableCell>
                                {getImportMessage(
                                  "application",
                                  application.occurence
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Einträge pro Seite"
          labelDisplayedRows={() => `Seite ${page + 1}`}
          page={page}
          onChangePage={(e, newPage) => setPage(newPage)}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Wrapper>
  );
};

export default ImportResults;
