import { regex, translations } from "../constants/constants";
import { TCustomerItem } from "../models/ICustomerItemModel";
import { ICustomerModel } from "../models/ICustomerModel";
import { IEventModel } from "../models/IEventModel";
import { IOfficeModel } from "../models/IOfficeModel";
import { IProjectModel } from "../models/IProjectModel";
import CustomerService from "../services/CustomerService";
import EventService from "../services/EventService";
import ProjectService from "../services/ProjectService";

const useUpdateCustomerItem = () => {
  const { updateCustomer, deleteCustomer, updateOffice, deleteOffice } =
    CustomerService;
  const { updateProject, deleteProject } = ProjectService;
  const { deleteEvent, updateEvent } = EventService;

  const validate = (data: { [key: string]: string }) => {
    let errors: string[] = [];

    Object.entries(data).forEach(([key, value]) => {
      if (key !== "id" && key !== "customerId") {
        if (
          typeof value !== "boolean" &&
          (!value || (Array.isArray(value) && value.length === 0))
        ) {
          errors.push(
            `${
              translations[key] ? translations[key] : key
            } darf nicht leer sein`
          );
        }
        if (
          key === "color" &&
          typeof value === "string" &&
          !regex.hexCode.test(value)
        ) {
          errors.push(`Farbcode ungültig`);
        }
      }
    });
    return errors;
  };

  const deleteCustomerItem = async (
    type: TCustomerItem,
    data: ICustomerModel & IOfficeModel & IProjectModel & IEventModel
  ) => {
    let success = false;
    let deleteFunction =
      type === "customer"
        ? deleteCustomer
        : type === "office"
        ? deleteOffice
        : type === "project"
        ? deleteProject
        : type === "event"
        ? deleteEvent
        : () => {};

    const res = await deleteFunction(data.id);

    if (res.status_code === 1000) {
      success = true;
    }
    return success;
  };

  const updateCustomerItem = async (
    type: TCustomerItem,
    data: ICustomerModel & IOfficeModel & IProjectModel & IEventModel
  ) => {
    let success = false;
    let updateFunction =
      type === "customer"
        ? updateCustomer
        : type === "office"
        ? updateOffice
        : type === "project"
        ? updateProject
        : type === "event"
        ? updateEvent
        : () => {};

    const res = await updateFunction(data);

    if (res.status_code === 1000) {
      success = true;
    }
    return success;
  };
  return { updateCustomerItem, deleteCustomerItem, validate };
};

export default useUpdateCustomerItem;
