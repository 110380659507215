import { observable } from "mobx";
import { createContext } from "react";

class DrawerStore {
  @observable public drawerOpen: boolean;

  constructor() {
    this.drawerOpen = false;
  }

  public setDrawerOpen = (drawerOpen: boolean) => {
    this.drawerOpen = drawerOpen;
  };
}

export default createContext(new DrawerStore());
