/* eslint-disable react-hooks/exhaustive-deps */
import CategoryIcon from "@material-ui/icons/Category";
import { observer } from "mobx-react-lite";
import { FC, MouseEvent, useContext, useEffect, useState } from "react";
import { ICompetencyModel, emptyCompetency } from "../models/ICompetencyModel";
import CustomerStore from "../stores/CustomerStore";
import DataGrid, { IDataGridProps } from "./DataGrid";
import Heading from "./Heading";
import Wrapper from "./containers/Wrapper";
import EditCompetency from "./dialogs/EditCompetency";

const Competencies: FC = () => {
  const customerStore = useContext(CustomerStore);
  const { getAllCompetencies, fetchedCompetencies } = customerStore;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<ICompetencyModel>(
    emptyCompetency()
  );

  useEffect(() => {
    getAllCompetencies();
  }, []);

  const handleRowClick = (
    _e: MouseEvent<HTMLButtonElement>,
    row: ICompetencyModel
  ) => {
    setCurrentRow(row);
    setDialogOpen(true);
  };

  const competenciesDataProps: IDataGridProps = {
    editCompetencyButton: true,
    onRowClick: handleRowClick,
    pagingFilter: true,
    searchFilter: true,
    columns: [
      {
        title: "Name",
        field: "longName",
        type: "string",
        defaultSort: "asc"
      },
      {
        title: "Abkürzung",
        field: "shortName",
        type: "string"
      },
      {
        title: "Beschreibung",
        field: "description",
        type: "string"
      },
      {
        title: "Typ",
        field: "type",
        type: "string"
      }
    ],
    data:
      fetchedCompetencies.length > 0
        ? JSON.parse(JSON.stringify(fetchedCompetencies))
        : []
  };

  return (
    <Wrapper>
      <Heading heading="Kompetenzen">
        <CategoryIcon />
      </Heading>
      <DataGrid dense {...competenciesDataProps} />
      {currentRow.id && dialogOpen && (
        <EditCompetency
          open={dialogOpen}
          close={() => setDialogOpen(false)}
          data={currentRow}
        />
      )}
    </Wrapper>
  );
};

export default observer(Competencies);
