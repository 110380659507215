import { IGroupModel } from "./IGroupModel";
import { IOfficeModel } from "./IOfficeModel";
import { IProjectModel } from "./IProjectModel";
import { IUserModel } from "./IUserModel";

export interface IEventModel {
  id: string;
  status: EEventStatus;
  date: string;
  location: string;
  note?: string;
  Groups?: IGroupModel[];
  Project?: IProjectModel;
  Office?: IOfficeModel;
  User?: IUserModel;
}

export enum EEventStatus {
  created = "created",
  pending = "pending",
  complete = "complete",
  abort = "abort"
}
export function emptyEvent(): IEventModel {
  return {
    id: "",
    status: EEventStatus.created,
    date: "",
    location: "",
    note: ""
  };
}
