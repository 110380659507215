import {
  Dialog,
  makeStyles,
  Theme,
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormGroup
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { competencyTypes } from "../../constants/constants";
import { SurveyType } from "../../models/ISurveyModel";
import SurveyService from "../../services/SurveyService";
import { DataGridActionButtonProps } from "../DataGrid";
import { isValidSid } from "./EditSurvey";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  title: {
    textAlign: "center",
    boxSizing: "border-box"
  },
  content: {
    width: "400px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2)
  },
  button: {
    marginTop: "15px"
  },
  select: {
    width: "100%"
  },

  formControl: { width: "50%", minWidth: "200px", userSelect: "none" }
}));

export const CreateSurvey: FC<DataGridActionButtonProps> = ({
  open,
  close
}) => {
  const classes = useStyles();
  const { createSurvey } = SurveyService;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [sid, setSid] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [barrier, setBarrier] = useState<boolean>(false);
  const [surveyType, setSurveyType] = useState<SurveyType>();

  const onClose = () => {
    close();
  };

  const handleSubmit = async () => {
    if (!description || !sid || !surveyType) return;

    const res = await createSurvey(description, sid, barrier, [], surveyType);
    if (res.status_code === 1000) {
      history.go(0);
    } else {
      enqueueSnackbar(
        "Umfrage konnte nicht gespeichert werden",
        getNotiOptions("error")
      );
    }
  };

  const disallowedSubmission = () =>
    !description ||
    !description.length ||
    !sid ||
    !isValidSid(sid) ||
    !surveyType;

  const onDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const onSidChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSid(event.target.value);
  };

  const onSurveyTypeChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setSurveyType(event.target.value as SurveyType);
  };

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={onClose}
    >
      <DialogTitle className={classes.title}>Umfrage Erstellen</DialogTitle>
      <Divider />
      <DialogContent>
        <FormControl className={classes.formControl}>
          <InputLabel id="survey-type-select-label">Typ</InputLabel>
          <Select
            labelId="survey-type-select-label"
            value={surveyType || ""}
            onChange={onSurveyTypeChange}
            className={classes.select}
          >
            {/** AC can never be an online test */}
            <MenuItem value="OT">{competencyTypes.OT}</MenuItem>
            <MenuItem value="PT">{competencyTypes.PT}</MenuItem>
          </Select>
          <TextField
            required
            fullWidth
            value={description}
            label="Beschreibung"
            onChange={onDescriptionChange}
          />
          <TextField
            required
            fullWidth
            value={sid}
            label="survey-id"
            onChange={onSidChange}
          />
          <Button
            className={classes.button}
            fullWidth
            size="small"
            variant="contained"
            color="secondary"
            type="submit"
            onClick={handleSubmit}
            disabled={disallowedSubmission()}
          >
            Speichern
          </Button>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};
