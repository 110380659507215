import { INormModel } from "./INormModel";
import { IPosCompModel } from "./IPosCompModel";
import { IProjectModel } from "./IProjectModel";

export interface IPositionModel {
  id: string;
  projectId: string;
  longName: string;
  shortName: string;
  level?: number;
  mJobNormParam?: number;
  PosComps?: IPosCompModel[];
  Project?: IProjectModel;
  Norm?: INormModel;
  ptSurveyId?: string;
}

export function emptyPosition(): IPositionModel {
  return {
    id: "",
    projectId: "",
    longName: "",
    shortName: "",
    level: 0,
    mJobNormParam: 0,
    PosComps: [] as IPosCompModel[],
    Project: {} as IProjectModel,
    Norm: {} as INormModel
  };
}
