/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  GridSize,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import BusinessIcon from "@material-ui/icons/Business";
import PaletteIcon from "@material-ui/icons/Palette";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { CompactPicker } from "react-color";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { colors, translations } from "../../constants/constants";
import { translateStatus } from "../../functions/translateStatus";
import useUpdateCustomerItem from "../../hooks/useUpdateCustomerItem";
import {
  IEditItem,
  ISelectOptions,
  isISelectOptions,
  isITextfieldOptions,
  ITextfieldOptions
} from "../../models/ICustomerItemModel";
import { EEventStatus } from "../../models/IEventModel";
import { EExportType } from "../../models/IProjectModel";
import CustomerStore from "../../stores/CustomerStore";
import { ICustomerItemDialog } from "../DataGrid";
import DatePicker from "../DatePicker";
import DeleteButton from "../DeleteButton";

const str = {
  notification: {
    save: {
      success: "Speichern erfolgreich",
      fail: "Speichern fehlgeschlagen"
    },
    delete: {
      success: "Löschen erfolgreich",
      fail: "Löschen fehlgeschlagen"
    }
  },
  element: {
    button: {
      delete: "Löschen",
      confirm: "Übernehmen"
    },
    auto: {
      noOption: "Keine Auswahlmöglichkeiten"
    },
    text: {
      delete: 'Geben Sie "löschen" ein'
    }
  },
  delete: "löschen",
  redirect: "/customer"
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      gap: theme.spacing(1),
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      maxWidth: "450px"
    },
    title: {
      textAlign: "center",
      boxSizing: "border-box",
      width: "100%"
    },
    content: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
      boxSizing: "border-box"
    },
    border: {
      borderRadius: "5px",
      boxSizing: "border-box"
    },
    marginBot: {
      marginBottom: "20px"
    },
    padding: {
      padding: "10px"
    },
    center: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box"
    },
    details: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2)
    },
    colorPicker: {
      position: "relative",
      zIndex: 100
    },
    closeX: {
      position: "absolute",
      cursor: "pointer",
      userSelect: "none",
      top: "1%",
      right: "1%",
      color: "grey"
    },
    palette: {
      cursor: "pointer"
    },
    actions: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: theme.spacing(2)
    },
    btn: {
      display: "flex",
      alingItems: "center",
      justifyContent: "flex-end"
    },
    autocomplete: {
      width: "100%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    select: {
      width: "100%"
    },
    red: {
      boxSizing: "border-box",
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.primary.light
      }
    },
    green: {
      color: theme.palette.success.main
    }
  })
);

const EditCustomerItem: FC<ICustomerItemDialog> = ({
  customerId,
  items,
  title,
  type,
  open,
  close
}) => {
  const classes = useStyles();
  const customerStore = useContext(CustomerStore);
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const { fetchedOffices, getCustomerOffices } = customerStore;

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState<any>({});

  const { updateCustomerItem, deleteCustomerItem, validate } =
    useUpdateCustomerItem();

  const setInititalData = useCallback(() => {
    let newData = { ...data };
    items.forEach((item: IEditItem) => {
      if (!isISelectOptions(item.options)) {
        newData[item.name] = item.value;
      } else {
        newData[item.name] = item.options.defaultState
          ? item.options.defaultState
          : item.options?.items?.find(
              (it) =>
                it[(item.options as ISelectOptions)!.comparisonKey] ===
                item.value
            );
      }
    });
    if (newData.hasOwnProperty("customerId")) {
      const custId = window.sessionStorage.getItem("custId");
      newData.customerId = customerId ? customerId : custId ? custId : "";
    }
    setData({ ...newData });
  }, []);

  const handleClose = () => {
    close();
    setInititalData();
  };

  useEffect(() => setInititalData(), [setInititalData]);

  useEffect(() => {
    const custId = window.sessionStorage.getItem("custId");
    getCustomerOffices(customerId ? customerId : custId ? custId : "");
  }, [customerId, getCustomerOffices]);

  const getUsedData = (item: IEditItem) => {
    if (!item.options || !isISelectOptions(item.options)) {
      return [];
    }

    return !item.options?.dependencies
      ? item.options?.items
      : item.options.dependencies.handler(
          item,
          item.options.dependencies.states?.map((state) => data[state])
        );
  };

  const extractFormat = (
    format: string,
    obj: any,
    beginToken: string = "{{",
    endToken: string = "}}"
  ) => {
    if (format.length === 0) throw new Error("format string can't be empty!");
    do {
      const bi = format.indexOf(beginToken);
      const ei = format.indexOf(endToken);
      if (bi < 0 || ei < 0)
        throw new Error(
          `expected token not found! begin token index: ${bi}; end token index: ${ei}`
        );
      const key = format.slice(bi + 2, ei);
      format = format.replace(`{{${key}}}`, obj[key]);
    } while (format.indexOf(beginToken) >= 0);
    return format;
  };

  const handleUpdate = async () => {
    const dataToValidate: { [key: string]: string } = {};
    items.forEach((item: IEditItem) => {
      if (!item.options || !item.options?.unvalidated)
        dataToValidate[item.name] = data[item.name];
    });
    const errors = validate(dataToValidate);
    if (errors.length > 0) {
      errors.forEach((error) => {
        enqueueSnackbar(error, getNotiOptions("error"));
      });
    } else {
      const success = await updateCustomerItem(type, data);
      if (success) {
        enqueueSnackbar(
          str.notification.save.success,
          getNotiOptions("success")
        );
      } else {
        enqueueSnackbar(str.notification.save.fail, getNotiOptions("error"));
      }
      history.go(0);
    }
  };

  const handleDelete = async (allowDelete: boolean) => {
    if (allowDelete) {
      const success = await deleteCustomerItem(type, data);
      if (success) {
        enqueueSnackbar(
          str.notification.delete.success,
          getNotiOptions("success")
        );
        history.push(`/customer`);
      } else {
        enqueueSnackbar(str.notification.delete.fail, getNotiOptions("error"));
        history.go(0);
      }
    }
  };

  const handleColorPicker = (e: any) => {
    setAnchorEl(e.currentTarget);
    setShowColorPicker(!showColorPicker);
  };

  const handleDataChange = (
    value: string | boolean | Array<Object> | Date,
    identifier: string
  ) => {
    const resetDependants: any = {};
    items.forEach((item: IEditItem) => {
      if (item.options?.dependencies?.states.includes(identifier)) {
        resetDependants[item.name] = undefined;
      }
    });
    setData({
      ...data,
      [identifier]: value,
      ...resetDependants
    });
  };

  // for choosing a color in the ColorPicker
  const handleColorChange = (color: string) => {
    setData({ ...data, color });
    setShowColorPicker(false);
  };

  const getElement = (item: IEditItem, index: number) => {
    let elem = <></>;
    const getWidth = (
      type: string,
      options?: ISelectOptions | ITextfieldOptions
    ): GridSize => {
      let width: GridSize = 12;
      if (!options || ("fullWidth" in options && !options.fullWidth))
        switch (type) {
          case "date":
          case "color":
          case "default":
            width = 6;
            break;
          default:
            break;
        }
      return width;
    };

    const createElement = (child: ReactElement) => {
      return (
        <Grid item xs={getWidth(item.type, item.options)} key={index}>
          {child}
        </Grid>
      );
    };

    switch (item.type) {
      case "name":
      case "default":
        if (!item.options || isITextfieldOptions(item.options)) {
          elem = createElement(
            <TextField
              multiline={item.options?.multiline}
              required={!item.options?.unvalidated}
              fullWidth
              margin="dense"
              label={translations[item.name]}
              onChange={(e) => {
                handleDataChange(e.target.value, item.name);
              }}
              value={data[item.name]}
            />
          );
        }
        break;
      case "color":
        elem = createElement(
          <>
            <TextField
              fullWidth
              required={!item.options?.unvalidated}
              margin="dense"
              label={translations[item.name]}
              value={data[item.name]}
              onChange={(e) => {
                handleDataChange(e.target.value, item.name);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <PaletteIcon
                      color="secondary"
                      onClick={handleColorPicker}
                      className={classes.palette}
                    />
                  </InputAdornment>
                )
              }}
            />
            <Popover anchorEl={anchorEl} open={showColorPicker}>
              <ClickAwayListener onClickAway={() => setShowColorPicker(false)}>
                <CompactPicker
                  className={classes.colorPicker}
                  onChange={(updatedColor) =>
                    handleColorChange(updatedColor.hex)
                  }
                  colors={colors}
                />
              </ClickAwayListener>
            </Popover>
          </>
        );
        break;
      case "boolean":
        elem = createElement(
          <FormControlLabel
            label={translations[item.name]}
            control={
              <Checkbox
                color="secondary"
                checked={data[item.name]}
                onChange={(e) => {
                  handleDataChange(e.target.checked, item.name);
                }}
              />
            }
          />
        );
        break;
      case "select":
        switch (item.name) {
          case "exportType":
            elem = createElement(
              <FormControl className={classes.select}>
                <InputLabel id={`${item.name}-selectlabel`}>
                  {translations[item.name]}
                </InputLabel>
                <Select
                  labelId={`${item.name}-selectlabel`}
                  id={`${item.name}-select`}
                  defaultValue={item.value || EExportType.grades}
                  onChange={(e) => {
                    handleDataChange(`${e.target.value}`, item.name);
                  }}
                >
                  {Object.values(EExportType).map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {translations[option]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
            break;
          case "status":
            elem = createElement(
              <FormControl className={classes.select}>
                <InputLabel id={`${item.name}-selectlabel`}>
                  {translations[item.name]}
                </InputLabel>
                <Select
                  labelId={`${item.name}-selectlabel`}
                  id={`${item.name}-select`}
                  defaultValue={item.value || EEventStatus.created}
                  onChange={(e) => {
                    handleDataChange(`${e.target.value}`, item.name);
                  }}
                >
                  {Object.values(EEventStatus).map((option, i) => (
                    <MenuItem
                      disabled={
                        option !== EEventStatus.pending ||
                        item.value !== EEventStatus.created
                      }
                      key={i}
                      value={option}
                    >
                      {translateStatus("event", option)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
            break;
          default:
            if (
              isISelectOptions(item.options) &&
              (!item.options.dependencies ||
                item.options.dependencies.states.every((state) => data[state]))
            ) {
              elem = createElement(
                <FormControl className={classes.select}>
                  <InputLabel id={`${item.name}-selectlabel`}>
                    {item.options.title}
                  </InputLabel>
                  <Select
                    labelId={`${item.name}-selectlabel`}
                    id={`${item.name}-select`}
                    defaultValue={item.value}
                    value={data[item.name] ? data[item.name].id : ""}
                    required={!item.options.unvalidated}
                    onChange={(e) => {
                      handleDataChange(
                        getUsedData(item).find(
                          (it: any) =>
                            it[
                              (item.options as ISelectOptions)!.comparisonKey
                            ] === e.target.value
                        ),
                        item.name
                      );
                    }}
                  >
                    {getUsedData(item)?.map((optionItem: any, i: number) => (
                      <MenuItem
                        key={i}
                        value={
                          optionItem[
                            (item.options as ISelectOptions)!.comparisonKey
                          ]
                        }
                      >
                        {extractFormat(
                          (item.options as ISelectOptions)!.format,
                          optionItem
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
            break;
        }
        break;
      case "multiselect":
        const defaultOptions = item.value as any[];
        const multiOptions = item.name === "Offices" ? fetchedOffices : [];
        elem = createElement(
          <Autocomplete
            size="small"
            multiple
            className={classes.autocomplete}
            defaultValue={multiOptions?.filter((option) =>
              defaultOptions?.map((defOp: any) => defOp.id).includes(option.id)
            )}
            options={multiOptions}
            groupBy={(option) =>
              option?.Customer?.longName ? option.Customer.longName : ""
            }
            getOptionLabel={(option) => option.longName || ""}
            filterSelectedOptions
            noOptionsText={str.element.auto.noOption}
            onChange={(e, value) => {
              handleDataChange(value, item.name);
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, i) => (
                <Chip
                  size="small"
                  key={i}
                  {...getTagProps({ index: i })}
                  label={option.longName}
                  color="secondary"
                  icon={<BusinessIcon />}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ required: !item.options?.unvalidated }}
                color="primary"
                label={translations[item.name]}
              />
            )}
          />
        );

        break;
      case "date":
        elem = createElement(
          <span
            style={{
              flexGrow: "1"
            }}
          >
            <DatePicker
              label={translations[item.name]}
              initialDate={data[item.name]}
              processDate={(d) => {
                handleDataChange(new Date(d), item.name);
              }}
            />
          </span>
        );
        break;
      default:
        break;
    }
    return elem;
  };

  const getHeading = () => {
    return (
      <>
        <Grid item xs={11}>
          <Typography variant="h5" id="dialog-title">
            {title}
          </Typography>
        </Grid>
      </>
    );
  };

  const getActions = () => {
    return (
      <>
        <Grid item className={classes.btn}>
          {data.id && (
            <DeleteButton
              compareString={str.delete}
              buttonName={str.element.button.delete}
              description={str.element.text.delete}
              callback={handleDelete}
            />
          )}
        </Grid>
        <Grid item className={classes.btn}>
          <Button
            className={classes.green}
            variant="outlined"
            color="default"
            size="small"
            onClick={handleUpdate}
          >
            {str.element.button.confirm}
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <Dialog transitionDuration={100} open={open} onClose={handleClose}>
      <Grid className={classes.grid}>
        <DialogTitle>
          <Grid className={classes.title}>{getHeading()}</Grid>
        </DialogTitle>
        <Grid>
          <Divider />
        </Grid>
        <DialogContent>
          <Grid container spacing={1} className={classes.content}>
            {items.map((item, i) => getElement(item, i))}
          </Grid>
        </DialogContent>
        <Grid>
          <Divider />
        </Grid>
        <DialogActions>
          <Grid container item xs={12} className={classes.actions}>
            {getActions()}
          </Grid>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default observer(EditCustomerItem);
