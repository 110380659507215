import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { getNotiOptions } from "../constants/configs";
import UserStore from "../stores/UserStore";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function SignIn() {
  const classes = useStyles();
  const { login } = useContext(UserStore);
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({ email: "", password: "" });

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form
          className={classes.form}
          onSubmit={async (e) => {
            e.preventDefault();
            const rsp: boolean = await login(formData);
            if (rsp) {
              enqueueSnackbar("Login Erfolgreich.", getNotiOptions("success"));
            } else {
              enqueueSnackbar(
                "Login Fehlgeschlagen. Ungültige Anmeldedaten.",
                getNotiOptions("error")
              );
            }
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required={true}
            fullWidth
            color="secondary"
            id="email"
            label="E-Mail-Adresse"
            name="email"
            autoComplete="email"
            onChange={(e) => {
              setFormData({ ...formData, email: e.target.value });
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required={true}
            color="secondary"
            fullWidth
            name="password"
            label="Passwort"
            type="password"
            id="password"
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Login
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
    </Container>
  );
}
