import {
  createStyles,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
  Theme,
  useTheme
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import DrawerStore from "../../stores/DrawerStore";
import NavList from "./NavList";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap"
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9)
      }
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "7px 0"
    },
    "@global": {
      "@media (min-width: 768px)": {
        ".makeStyles-drawerClose-43": {
          width: "78px"
        }
      },
      ".MuiListItem-gutters": {
        paddingLeft: "25px"
      }
    }
  })
);

const NavigationDrawer: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const drawerStore = useContext(DrawerStore);
  const { drawerOpen, setDrawerOpen } = drawerStore;

  return (
    <div>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen
          })
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={() => setDrawerOpen(false)}>
            {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
        <Divider />
        <NavList />
      </Drawer>
    </div>
  );
};

export default observer(NavigationDrawer);
