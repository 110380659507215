import { Column } from "material-table";
import { FC } from "react";
import { normNames } from "../../models/INormModel";
import {
  IRawValueModel,
  LocalizedRawValueModel
} from "../../models/IRawValueModel";
import DataGrid, { DataGridActionButton } from "../DataGrid";

interface RawValueGridProps {
  rawValues?: IRawValueModel[];
  tableButtons?: DataGridActionButton[];
  onRowClick?: (
    event: any,
    row: LocalizedRawValueModel,
    togglePanel: any
  ) => void;
  hideLongName?: boolean;
  hideShortName?: boolean;
  hideForm?: boolean;
  hideLevel?: boolean;
  hideEvalType?: boolean;
}

export const RawValueGrid: FC<RawValueGridProps> = ({
  rawValues,
  tableButtons,
  onRowClick,
  hideLongName,
  hideShortName,
  hideForm,
  hideLevel,
  hideEvalType
}) => {
  const getLocalizedEvalType = (evalType: string): string => {
    switch (evalType) {
      case "MC":
        return "Multiple Choice";
      case "MS":
        return "Multiple Select";
      case "FQ":
        return "Fehlerquotient";
      case "PT":
        return "Persönlichkeitsskalen";
      default:
        return `Fehlerhafter Aufgabentyp (${evalType})`;
    }
  };

  const columns: Column<any>[] = [];

  if (!hideLongName)
    columns.push({
      title: "Name",
      field: "longName"
    });
  if (!hideShortName)
    columns.push({
      title: "Abkürzung",
      field: "shortName"
    });
  if (!hideForm)
    columns.push({
      title: "Parallel-Form",
      field: "form"
    });
  if (!hideLevel)
    columns.push({
      title: "Niveau",
      field: "level",
      render: (rv: LocalizedRawValueModel) =>
        `Level ${rv?.level} (z.B. ${normNames[rv?.level]})`
    });
  if (!hideEvalType)
    columns.push({
      title: "Aufgabentyp",
      field: "localizedEvalType"
    });

  return (
    <DataGrid
      dense
      isLoading={!rawValues}
      title="Rohwerte"
      tableButtons={tableButtons || []}
      columns={columns}
      data={
        rawValues
          ? (rawValues as LocalizedRawValueModel[]).map((rv) => ({
              ...rv,
              localizedEvalType: getLocalizedEvalType(rv.evalType)
            }))
          : []
      }
      onRowClick={onRowClick}
      pagingFilter
      pageSize={5}
    />
  );
};
