import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { INormModel } from "../../models/INormModel";

export interface IChangeNorm {
  open: boolean;
  newNorm: INormModel;
  handleClose: () => void;
  handleNormReset: (norm: INormModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "auto",
      height: "auto"
    },
    red: {
      color: theme.palette.error.main
    },
    green: {
      color: theme.palette.success.main
    }
  })
);

const ChangeNorm: FC<IChangeNorm> = ({
  open,
  newNorm,
  handleClose,
  handleNormReset
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} onBackdropClick={handleClose}>
      <DialogTitle>Norm wechseln</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Bei einem Wechsel der Norm geht der aktuelle Fortschritt verloren
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" className={classes.red}>
          Abbrechen
        </Button>
        <Button
          onClick={() => handleNormReset(newNorm)}
          variant="text"
          className={classes.green}
        >
          Norm Wechseln
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default observer(ChangeNorm);
