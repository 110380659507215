import {
    createStyles, LinearProgress, makeStyles,
    Theme
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: "100%",
      height: "100%",
      padding: theme.spacing(2),
      boxSizing: "border-box",
      "& > * + *": {
        marginTop: theme.spacing(2)
      }
    }
  });
});

const LoadingAnimation: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root} data-cy="loading">
      <LinearProgress color="secondary" />
    </div>
  );
};

export default observer(LoadingAnimation);
