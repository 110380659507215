import { createStyles, makeStyles, Tooltip } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      width: "20px",
      height: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      border: "2px solid black"
    },
    inside: {
      width: "100%",
      height: "100%",
      borderRadius: "100%",
      border: "2px solid white",
      boxSizing: "border-box"
    }
  });
});

interface IColorDrop {
  color: string;
}

const ColorDrop: FC<IColorDrop> = ({ color }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tooltip title={color}>
        <div
          className={classes.inside}
          style={{ backgroundColor: color }}
        ></div>
      </Tooltip>
    </div>
  );
};

export default observer(ColorDrop);
