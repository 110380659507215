import {
  Dialog,
  makeStyles,
  Theme,
  TextField,
  Button,
  FormHelperText,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { ICustomerModel } from "../../models/ICustomerModel";
import { ISurveyModel_RawValues } from "../../models/ISurveyModel";
import SurveyService from "../../services/SurveyService";
import CustomerStore from "../../stores/CustomerStore";
import DeleteButton from "../DeleteButton";

export const isValidSid = (sid: string) => {
  return !isNaN(+sid) && +sid >= 111111 && +sid <= 999999;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  title: {
    textAlign: "center",
    boxSizing: "border-box"
  },
  content: {
    width: "400px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2)
  },
  button: {
    marginTop: "15px"
  },
  formControl: { width: "50%", minWidth: "200px", userSelect: "none" }
}));

interface EditCorrectAnswerProps {
  open: boolean;
  close: () => void;
  survey: ISurveyModel_RawValues;
}

export const EditSurvey: FC<EditCorrectAnswerProps> = ({
  open,
  close,
  survey
}) => {
  const classes = useStyles();
  const { updateSurvey, deleteSurvey } = SurveyService;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const customerStore = useContext(CustomerStore);

  // TODO components depending on fetched Customers don't get updated when fetched
  const { fetchedCustomers, getCustomers } = customerStore;

  const [description, setDescription] = useState<string>(survey.description);
  const [sid, setSid] = useState<string>(survey.sid);
  const [barrier, setBarrier] = useState<number>(survey.barrier);
  const [customer, setCustomer] = useState<ICustomerModel | undefined>(
    fetchedCustomers.find((c) => c.id === survey.customerId)
  );
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);

  const onClose = () => {
    close();
  };

  const onDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const onSidChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSid(event.target.value);
  };

  const handleSubmit = async () => {
    if (!survey.id) return;
    const res = await updateSurvey(survey.id, {
      sid,
      barrier,
      description,
      customerId: customer?.id
    });

    if (res) {
      history.go(0);
    } else {
      enqueueSnackbar(
        "Umfrage konnte nicht gespeichert werden",
        getNotiOptions("error")
      );
    }
  };

  const handleDelete = async () => {
    if (!survey.id) {
      enqueueSnackbar("Keine Umfragen-ID vorhanden", getNotiOptions("error")); // This should never happen
      return;
    }

    const res = await deleteSurvey(survey.id);
    if (res.status_code === 1000) {
      history.push("/survey");
    } else {
      enqueueSnackbar(
        "Umfrage konnte nicht gelöscht werden",
        getNotiOptions("error")
      );
    }
  };

  const handleBarrierChange = (b: number) => {
    setBarrier(b);
  };

  const handleCustomerChange = (id: string) => {
    if (!id.length) {
      setCustomer(undefined);
    } else {
      setCustomer(fetchedCustomers.find((c) => c.id === id));
    }
  };

  const isValidSubmission = (): boolean => {
    return description.length > 0 && isValidSid(sid);
  };

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={onClose}
    >
      <DialogTitle className={classes.title}>Umfrage Bearbeiten</DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          required
          fullWidth
          value={description}
          key="title_field"
          label="Beschreibung"
          onChange={onDescriptionChange}
        />
        <TextField
          value={sid}
          required
          fullWidth
          key="solution_field"
          label="Survey-ID"
          error={!isValidSid(sid)}
          onChange={onSidChange}
        />
        <FormControlLabel
          label="Mehr Optionen"
          labelPlacement="end"
          control={
            <Checkbox
              color="secondary"
              onChange={() => {
                setShowAdvanced(!showAdvanced);
              }}
              checked={showAdvanced}
            />
          }
          className={classes.formControl}
        />
        {showAdvanced && (
          <>
            <FormControl fullWidth>
              <InputLabel id="barrier-select-label">Barriere</InputLabel>
              <Select
                labelId="barrier-select-label"
                id="barrier-select"
                value={barrier}
                label="Barriere"
                onChange={(event) => {
                  handleBarrierChange(event.target.value as number);
                }}
              >
                <MenuItem value={1}>Ja</MenuItem>
                <MenuItem value={0}>Nein</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="customer-select-label">Kunde</InputLabel>
              <Select
                labelId="customer-select-label"
                id="customer-select"
                value={customer?.id}
                label="Kunde"
                disabled={fetchedCustomers.length === 0}
                onChange={(event) => {
                  handleCustomerChange(event.target.value as string);
                }}
              >
                <MenuItem key={-1} value="">
                  (Kein Kunde)
                </MenuItem>
                {fetchedCustomers.map((customer, idx) => (
                  <MenuItem key={idx} value={customer.id}>
                    {customer.longName}
                  </MenuItem>
                ))}
              </Select>
              {fetchedCustomers.length === 0 && (
                <FormHelperText>Keine Kunden verfügbar</FormHelperText>
              )}
            </FormControl>
          </>
        )}
        <Button
          className={classes.button}
          fullWidth
          size="small"
          variant="contained"
          color="secondary"
          type="submit"
          onClick={handleSubmit}
          disabled={!isValidSubmission()}
        >
          Absenden
        </Button>
        <div className={classes.button}>
          <DeleteButton
            fullWidth
            compareString="löschen"
            buttonName="Löschen"
            description={'Geben Sie "löschen" ein'}
            callback={handleDelete}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
