import { IOfficeModel } from "./IOfficeModel";
import { IPositionModel } from "./IPositionModel";
import { IResultModel } from "./IResultModel";
import { Model } from "./models";

export interface IReplaceApplicationModel {
  id: string;
  status: string;
  importStatus: string;
  type: string;
  validFrom: Date;
  validUntil: Date;
}
export interface IApplicationModel extends Model {
  id: string;
  status: EApplicationStatus;
  type: string;
  eula: Date;
  barrier: boolean;
  validFrom: string; // TODO Date?
  validUntil: string; // TODO Date?
  lastCorrespondence: Date;
  officeId: string;
  participantId: string;
  archive: boolean | null;
  Position: IPositionModel;
  Results?: IResultModel[];
  Office: IOfficeModel;
}

export enum EApplicationStatus {
  created = "created",
  pending = "pending",
  complete = "complete",
  incomplete = "incomplete",
  abort = "abort"
}

export function emptyApplication(): IApplicationModel {
  return {
    id: "",
    status: EApplicationStatus.created,
    type: "",
    eula: new Date(),
    barrier: false,
    validFrom: "",
    validUntil: "",
    lastCorrespondence: new Date(),
    officeId: "",
    participantId: "",
    archive: false,
    Position: {} as IPositionModel,
    Results: [] as IResultModel[],
    Office: {} as IOfficeModel,
    ...Model()
  };
}
