/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Button,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EventIcon from "@material-ui/icons/Event";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import SettingsIcon from "@material-ui/icons/Settings";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { read, utils } from "xlsx";
import { getNotiOptions, getProcessConfig } from "../../constants/configs";
import { formatDate, parseAndFormat } from "../../functions/date";
import { translateStatus } from "../../functions/translateStatus";
import { IEditItem } from "../../models/ICustomerItemModel";
import { IEventModel } from "../../models/IEventModel";
import { EGroupStatus, IGroupModel } from "../../models/IGroupModel";
import { IOfficeModel } from "../../models/IOfficeModel";
import { IPositionModel } from "../../models/IPositionModel";
import EventService from "../../services/EventService";
import ImportService from "../../services/ImportService";
import UserStore from "../../stores/UserStore";
import Wrapper from "../containers/Wrapper";
import DataGrid, { ICustomerItemDialog, IDataGridProps } from "../DataGrid";
import AcUploadFeedback from "../dialogs/AcUploadFeedback";
import EditCustomerItem from "../dialogs/EditCustomerItem";
import UploadAcResults from "../dialogs/UploadAcResults";
import LoadingAnimation from "../LoadingAnimation";

const str = {
  vCard: {
    location: "Standort:",
    date: "Datum:",
    project: "Projekt:",
    user: "Moderator*in:",
    status: "Status:"
  },
  tool: {
    upload: "Ergebnisse importieren",
    cancel: "Termin stornieren"
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  infoBox: {
    width: "100%",
    height: "auto"
  },
  tablerow: {
    display: "flex",
    marginTop: "3px"
  },
  button: {
    marginTop: "50px"
  },
  avatar: {
    margin: "3px",
    backgroundColor: theme.palette.secondary.main
  },
  avatarBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(2),
    userSelect: "none"
  },
  display: {
    display: "flex",
    justifyContent: "center",
    textAlign: "left",
    margin: "0",
    marginBottom: "10px"
  },
  headline: {
    fontSize: "2rem",
    textAlign: "left"
  },
  vcard: {
    width: "100%",
    minHeight: "35%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    alignItems: "flex-start",
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "1px",
    padding: theme.spacing(3)
  },
  input: {
    minWidth: "250px",
    margin: "0",
    textAlign: "left",
    padding: theme.spacing(0.5)
  },
  label: {
    minWidth: "250px",
    textAlign: "left",
    margin: "0",
    padding: theme.spacing(0.5)
  },
  chip: {
    width: "fit-content"
  },
  icon: {
    cursor: "pointer",
    color: theme.palette.info.main
  },
  disabled: {
    color: theme.palette.info.light
  },
  note: {
    width: "100%",
    margin: "1em",
    padding: "1rem",
    boxSizing: "border-box",
    gap: theme.spacing(1)
  },
  noteFiels: {
    boxSizing: "border-box"
  }
}));

interface IEventDetailParams {
  id: string;
}

interface IFileRow {
  [key: string]: string;
}

interface IOpen {
  settings: boolean;
  upload: boolean;
  feedback: boolean;
}

export interface IUploadResponse {
  error: any[];
  exist: any[];
  success: any[];
}

const EventDetail: FC<RouteComponentProps<IEventDetailParams>> = ({
  match
}) => {
  const eventId = match.params.id;
  const classes = useStyles();
  const history = useHistory();
  const { isAdminSupport, user, fetchedUsers, getUsers } =
    useContext(UserStore);
  const { enqueueSnackbar } = useSnackbar();

  const { getEvent, updateGroupStatus } = EventService;
  const { importAcData } = ImportService;

  const [open, setOpen] = useState<IOpen>({
    settings: false,
    upload: false,
    feedback: false
  });
  const [position, setPosition] = useState<IPositionModel>(
    {} as IPositionModel
  );
  const [activeGroup, setActiveGroup] = useState<IGroupModel>(
    {} as IGroupModel
  );
  const [event, setEvent] = useState<IEventModel>({} as IEventModel);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadResponse, setUploadResponse] = useState<IUploadResponse>(
    {} as IUploadResponse
  );

  const dialogProps: ICustomerItemDialog = {
    customerId: event.id,
    items: [
      {
        type: "id",
        name: "id",
        value: event.id
      },
      {
        type: "select",
        name: "status",
        value: event.status
      },
      {
        type: "default",
        name: "location",
        value: event.location,
        options: {
          fullWidth: true
        }
      },
      {
        type: "default",
        name: "note",
        value: event?.note || "",
        options: {
          unvalidated: true,
          fullWidth: true,
          multiline: true
        }
      },
      {
        type: "date",
        name: "date",
        value: event.date
      },
      {
        type: "select",
        name: "Office",
        value: event.Office ? event.Office.id : "",
        options: {
          format: "{{longName}}",
          // presentationKey: "longName",
          comparisonKey: "id",
          title: "Einheit",
          items: user.Offices ? (user.Offices as any[]) : []
          // defaultIndex: userStore.user.Offices?.findIndex(office => office.id === event.Office?.id),
        }
      },
      {
        type: "select",
        name: "Project",
        value: event.Project ? event.Project.id : "",
        options: {
          format: "{{longName}}",
          comparisonKey: "id",
          title: "Projekt",
          defaultState: event.Project,
          items: user.Offices
            ? user.Offices.map((office) => office.Projects)
                .flat(2)
                .filter(
                  (project, pos, self) =>
                    project &&
                    self.findIndex((pr) => pr?.id === project.id) === pos
                )
            : [],
          dependencies: {
            states: ["Office"],
            handler(self: IEditItem, dependencies: IOfficeModel[]) {
              return dependencies[0].Projects as any;
            }
          }
        }
      },
      {
        type: "select",
        name: "User",
        value: event.User ? event.User.id : "",
        options: {
          format: "{{firstName}} {{lastName}}",
          comparisonKey: "id",
          title: "Moderator*in",
          defaultState: event.User,
          items: fetchedUsers?.filter((user) => Number(user.roleId) === 4)
        }
      }
    ],
    type: "event",
    title: "Termin Bearbeiten",
    open: open.settings,
    close: () => handleOpen("settings", false)
  };

  const handleOpen = (
    element: "settings" | "upload" | "feedback",
    action: boolean
  ) => {
    setOpen({ ...open, [element]: action });
  };

  const handleBack = () => {
    history.push(`/events`);
  };

  const handleUploadClick = (group: IGroupModel) => {
    if (group?.id) {
      setActiveGroup(group);
      setOpen({ ...open, upload: true });
    }
  };

  const handleFeedbackClose = () => {
    handleOpen("feedback", false);
    setUploadResponse({} as IUploadResponse);
    history.go(0);
  };

  const toggleUploadResponse = (response: IUploadResponse) => {
    setUploadResponse(response);
    setOpen({ ...open, upload: false, feedback: true });
  };

  const fetchEvent = async () => {
    const event = await getEvent(eventId);
    if (event) {
      setEvent(event);
    }
  };

  const processFile = (file: File) => {
    if (file) {
      setIsLoading(true);
      const config = getProcessConfig();
      const filters = {
        headers: (item: IFileRow, i: number) => config.rows.headers.includes(i),
        meta: (item: IFileRow, i: number) => config.rows.meta.includes(i),
        participants: (item: IFileRow, i: number) =>
          config.rows.participants.includes(i),
        competencies: (data: IFileRow[]) => {
          const obj = data.filter((item, i) =>
            config.rows.headers.includes(i)
          )[0];
          return Object.entries(obj)
            .filter(
              ([key, val]) =>
                config.columns.competencies.includes(key) &&
                (val as any) !== 0 && // 'val' can be numbers as well
                val !== ""
            )
            .reduce(
              (res: any, [key, val]: any) => ((res[val] = obj[key]), res),
              {}
            );
        }
      };

      const fileReader = new FileReader();
      fileReader.readAsBinaryString(file);
      fileReader.onload = async (e: any) => {
        const bstr = e.target.result;
        const workbook = read(bstr, config.read);
        const worksheet = workbook.Sheets[config.sheetName];
        const rawData = utils.sheet_to_json(worksheet, config.json);
        const data = JSON.parse(JSON.stringify(rawData));

        if (!worksheet) {
          setIsLoading(false);
          enqueueSnackbar(
            `Dokument enthält nicht die Seite "${config.sheetName}"`,
            getNotiOptions("error")
          );
          return;
        }

        try {
          const dataSet = {
            headers: data
              .filter(filters.headers)
              .map((item: IFileRow) => [
                ...config.columns.basic.map((col) => item[col])
              ])
              .flat(1),
            meta: data
              .filter(filters.meta)
              .map((item: IFileRow) => [
                ...config.columns.basic.map((col) => item[col])
              ])
              .flat(1),
            participants: data
              .filter(filters.participants)
              .map((item: IFileRow) => [
                ...config.columns.basic.map((col) => item[col])
              ])
              .filter((item: any[]) => item[0])
          };

          const refinedData = {
            participants: dataSet.participants.map((part: string[]) =>
              part.reduce(
                (obj, item, i) => ({
                  ...obj,
                  [dataSet.headers[i]]: item
                }),
                {}
              )
            ),
            date: new Date(dataSet.meta[0]),
            competencies: filters.competencies(data)
          };
          let errorMessage: string | undefined;
          if (formatDate(refinedData.date) !== parseAndFormat(event.date)) {
            errorMessage = "Termin Datum stimmt nicht überein";
          } else if (
            !refinedData.participants ||
            refinedData.participants.length === 0
          ) {
            errorMessage = "Es konnten keine Teilnehmer ausgelesen werden";
          } else if (
            !refinedData.competencies ||
            Object.keys(refinedData.competencies).length === 0
          ) {
            errorMessage = "Es konntent keine Kompetenzen ausgelesen werden";
          } else if (!refinedData) {
            errorMessage = "Datei konnte nicht ausgelesen werden";
          }
          if (errorMessage) {
            enqueueSnackbar(errorMessage, getNotiOptions("error"));
            setIsLoading(false);
          } else {
            const response = await importAcData(
              position.id,
              event.id,
              activeGroup.id,
              refinedData.date,
              refinedData.participants,
              refinedData.competencies
            );
            if (response && response.status_code === 1000) {
              enqueueSnackbar(
                `Verarbeitung erfolgreich`,
                getNotiOptions("success")
              );
              toggleUploadResponse(response.data);
            } else {
              enqueueSnackbar(
                `Fehler bei der Verarbeitung`,
                getNotiOptions("error")
              );
              setOpen({ ...open, upload: false });
            }
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
          enqueueSnackbar(
            "Fehler bei der Dokumentverarbeitung",
            getNotiOptions("error")
          );
        }
      };
    }
  };

  const fetchUsers = () => {
    getUsers();
  };

  const cancelEvent = async (id: string) => {
    const res = await updateGroupStatus(id, EGroupStatus.abort);
    if (res.status_code === 1000) {
      enqueueSnackbar("Gruppe storniert", getNotiOptions("success"));
    } else {
      enqueueSnackbar(
        "Gruppe konnte nicht storniert werden",
        getNotiOptions("error")
      );
    }
    history.go(0);
  };

  useEffect(() => {
    fetchEvent();
    fetchUsers();
  }, []);

  const groupDataprops: IDataGridProps = {
    title: "Gruppen",
    isLoading,
    searchFilter: false,
    columns: [
      { title: "Gruppe", field: "longName" },
      {
        title: "Status",
        field: "status",
        render: (row: IGroupModel) => (
          <Typography>{translateStatus("group", row.status)}</Typography>
        )
      },
      {
        title: "Ergebnisimport",
        align: "right",
        render: (row: IGroupModel) => (
          <Tooltip key={row.id} title={str.tool.upload}>
            <span /* wrapper element is needed since disabled buttons don't fire events */
            >
              <IconButton
                size="small"
                onClick={() => handleUploadClick(row)}
                disabled={row.status !== EGroupStatus.valid}
                className={
                  row.status !== EGroupStatus.valid
                    ? classes.disabled
                    : classes.icon
                }
              >
                <PublishIcon />
              </IconButton>
            </span>
          </Tooltip>
        )
      },
      {
        title: "Stornierung",
        align: "left",
        render: (row: IGroupModel) => (
          <Tooltip key={row.id} title={str.tool.cancel}>
            <span /* disabled buttons don't fire events which results in no tooltip showing so a wrapper is needed */
            >
              <IconButton
                size="small"
                onClick={() => cancelEvent(row.id)}
                disabled={row.status !== EGroupStatus.valid}
                className={
                  row.status !== EGroupStatus.valid
                    ? classes.disabled
                    : classes.icon
                }
              >
                <HighlightOffIcon />
              </IconButton>
            </span>
          </Tooltip>
        )
      }
    ],
    data: event.id ? JSON.parse(JSON.stringify(event?.Groups)) : []
  };

  return (
    <Wrapper>
      {!event?.id ? (
        <LoadingAnimation />
      ) : (
        <>
          <div className={classes.vcard} data-cy="info-vcard">
            <div className={classes.avatarBox}>
              <div className={classes.display}>
                <Avatar className={classes.avatar} data-cy="info-avatar">
                  <EventIcon />
                </Avatar>
                <Typography className={classes.headline}>
                  {`Einheit: ${event?.Office?.longName}`}
                </Typography>
              </div>
              {isAdminSupport && (
                <div className={classes.icon}>
                  <Tooltip title="Termin editieren">
                    <IconButton onClick={() => handleOpen("settings", true)}>
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                  <EditCustomerItem {...dialogProps} />
                </div>
              )}
            </div>
            <div className={classes.infoBox}>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  {str.vCard.project}
                </Typography>
                <Typography className={classes.input}>
                  {event?.Project?.longName}
                </Typography>
              </div>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  {str.vCard.location}
                </Typography>
                <Typography className={classes.input}>
                  {event?.location}
                </Typography>
              </div>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  {str.vCard.user}
                </Typography>
                <Typography className={classes.input}>
                  {event?.User?.firstName} {event?.User?.lastName}
                </Typography>
              </div>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  {str.vCard.date}
                </Typography>
                <Typography className={classes.input}>
                  {parseAndFormat(event.date)}
                </Typography>
              </div>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  {str.vCard.status}
                </Typography>
                <Typography className={classes.input}>
                  {translateStatus("event", event?.status)}
                </Typography>
              </div>
            </div>
          </div>
          <DataGrid dense {...groupDataprops} />
          <UploadAcResults
            position={position}
            positions={event.Project?.Positions}
            group={activeGroup}
            open={open.upload}
            close={() => handleOpen("upload", false)}
            handlePosition={(position: IPositionModel) => setPosition(position)}
            upload={(file: File) => processFile(file)}
          />
          <AcUploadFeedback
            uploadResponse={uploadResponse}
            close={handleFeedbackClose}
            open={open.feedback}
          />
          {event.note && (
            <Paper className={classes.note}>
              <Typography className={classes.headline}>Anmerkung</Typography>
              <TextField
                multiline
                defaultValue={event.note}
                disabled
                fullWidth
                className={classes.noteFiels}
              />
            </Paper>
          )}
        </>
      )}
      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={handleBack}
        className={classes.button}
      >
        Zurück zu Terminübersicht
      </Button>
    </Wrapper>
  );
};

export default observer(EventDetail);
