import { Button, makeStyles, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import TimerIcon from "@material-ui/icons/Timer";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, MouseEvent, useContext, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { APICodes, getNotiOptions } from "../../constants/configs";
import { useAsync } from "../../hooks/useAsync";
import { IRawValueModel } from "../../models/IRawValueModel";
import {
  CorrectAnswer,
  ISurveyModel_RawValues
} from "../../models/ISurveyModel";
import SurveyService from "../../services/SurveyService";
import UserStore from "../../stores/UserStore";
import { RawValueGrid } from "../containers/RawValueGrid";
import Wrapper from "../containers/Wrapper";
import DataGrid, { IDataGridProps } from "../DataGrid";
import AddCorrectAnswer from "../dialogs/AddCorrectAnswer";
import { CreateRawValueLink } from "../dialogs/CreateRawValueLink";
import { EditCorrectAnswer } from "../dialogs/EditCorrectAnswer";
import { EditSurvey } from "../dialogs/EditSurvey";
import { RemoveRawValueLink } from "../dialogs/RemoveRawValueLink";
import LoadingAnimation from "../LoadingAnimation";
import DetailsOverview, {
  OverviewDisplay,
  useDetailStyles
} from "./shared/DetailsOverview";
import { competencyTypes } from "../../constants/constants";

interface ISurveyDetailProps {
  id: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: "50px"
  }
}));

const SurveyDetails: FC<RouteComponentProps<ISurveyDetailProps>> = ({
  match
}) => {
  const surveyId = match.params.id;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const detailClasses = useDetailStyles();
  const history = useHistory();
  const { isAdmin } = useContext(UserStore);
  const { getSurvey, getCorrectAnswers, removeSurveyLink, createSurveyLinks } =
    SurveyService;

  const [survey, setSurvey] = useState<ISurveyModel_RawValues>();
  const [correctAnswers, setCorrectAnswers] = useState<CorrectAnswer[]>();
  const [editAnswer, setEditAnswer] = useState<CorrectAnswer>();
  const [editSurvey, setEditSurvey] = useState<boolean>(false);
  const [unlinkRawValue, setUnlinkRawValue] = useState<IRawValueModel>();

  useAsync(
    async () => getSurvey(surveyId),
    async (res) => {
      setSurvey(res.data);
      if (res.data) await fetchAnswers(res.data.sid);
    }
  );

  const fetchAnswers = async (sid: string) => {
    const { data } = await getCorrectAnswers(sid);
    setCorrectAnswers(data);
  };

  const handleBack = () => {
    history.push("/survey");
  };

  const handleEdit = () => {
    setEditSurvey(true);
  };

  const handleAnswerEdit = (
    _e: MouseEvent<HTMLButtonElement>,
    row: CorrectAnswer
  ) => {
    if (isAdmin) setEditAnswer(row);
  };

  const handleCreateLink = async (
    rawValueIds: string[],
    deleteRawValueIds: string[]
  ) => {
    const res = await createSurveyLinks(
      surveyId,
      rawValueIds,
      deleteRawValueIds
    );
    console.log(res.data);
    if (res.status_code === APICodes.SUCCESS) {
      history.go(0);
    } else {
      enqueueSnackbar(
        "Verknüpfung konnte nicht erstellt werden",
        getNotiOptions("error")
      );
    }
  };

  const handleUnlink = async () => {
    if (!unlinkRawValue || !survey || !survey.id) return;

    const res = await removeSurveyLink(unlinkRawValue.id, survey.id);
    if (res.status_code === APICodes.SUCCESS) {
      history.go(0);
    } else {
      enqueueSnackbar(
        "Verknüpfung konnte nicht entfernt werden",
        getNotiOptions("error")
      );
    }
  };

  const displays: OverviewDisplay[] = [
    {
      title: "sid",
      display: (
        <Typography className={detailClasses.label}>{survey?.sid}</Typography>
      )
    },
    {
      title: "Barriere",
      display: (
        <Typography className={detailClasses.label}>
          {survey?.barrier ? "Ja" : "Nein"}
        </Typography>
      )
    },
    {
      title: "Kunde",
      display: (
        <Typography className={detailClasses.label}>
          {survey?.Customer?.longName || "—"}
        </Typography>
      )
    },
    {
      title: "Umfragentyp",
      display: (
        <Typography className={detailClasses.label}>
          {competencyTypes[survey?.type || "OT"]}
        </Typography>
      )
    }
  ];

  const correctAnswersGridProps: IDataGridProps = {
    onRowClick: handleAnswerEdit,
    pagingFilter: true,
    pageSize: 5,
    tableButtons: [
      {
        tooltip: "Lösung Hinzufügen",
        icon: () => <ControlPointDuplicateIcon />,
        isFreeAction: true,
        popup: AddCorrectAnswer,
        data: survey,
        disabled: !isAdmin
      }
    ],
    title: "Musterlösungen",
    columns: [
      {
        title: "Titel",
        field: "title"
      },
      {
        title: "Lösung",
        field: "solution"
      }
    ],
    data: correctAnswers
  };

  return (
    <Wrapper>
      {survey && correctAnswers ? (
        <>
          <DetailsOverview
            title={survey.description}
            displays={displays}
            icon={<TimerIcon />}
            allowEdits={isAdmin}
            editTooltip="Umfrage bearbeiten"
            onEdit={handleEdit}
          />
          <>
            {survey.type !== "PT" && (
              <DataGrid dense {...correctAnswersGridProps} />
            )}
            <RawValueGrid
              rawValues={survey.RawValues}
              onRowClick={(event, rawValue) => {
                if (isAdmin) setUnlinkRawValue(rawValue);
              }}
              tableButtons={
                // PT Surveys can only have 1 rawvalue linked
                survey.type !== "PT" ||
                (survey.type === "PT" && survey.RawValues.length === 0)
                  ? [
                      {
                        tooltip: "Rohwerte mit Umfrage verlinken",
                        icon: () => <ControlPointDuplicateIcon />,
                        popup: CreateRawValueLink,
                        data: {
                          handleCreateLink,
                          rvRelevantStart: survey.description.slice(0, 3),
                          irrelevantIds: survey.RawValues.map((rv) => rv.id),
                          rawValues: survey.RawValues
                        },
                        isFreeAction: true,
                        disabled: !isAdmin
                      }
                    ]
                  : []
              }
            />
          </>
        </>
      ) : (
        <LoadingAnimation />
      )}
      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={handleBack}
        className={classes.button}
      >
        Zurück zur Umfragenansicht
      </Button>
      {editAnswer && (
        <EditCorrectAnswer
          open={!!editAnswer}
          answer={editAnswer}
          close={() => setEditAnswer(undefined)}
        />
      )}
      {editSurvey && survey && (
        <EditSurvey
          open={editSurvey}
          survey={survey}
          close={() => setEditSurvey(false)}
        />
      )}
      {unlinkRawValue && survey && (
        <RemoveRawValueLink
          rawValue={unlinkRawValue}
          open={!!unlinkRawValue}
          close={() => {
            setUnlinkRawValue(undefined);
          }}
          handleUnlink={handleUnlink}
        />
      )}
    </Wrapper>
  );
};

export default observer(SurveyDetails);
