import {
  Dialog,
  makeStyles,
  Theme,
  TextField,
  Button,
  FormHelperText,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { ICustomerModel } from "../../models/ICustomerModel";
import { IRvTypeModel } from "../../models/IRvTypeModel";
import { ISurveyModel } from "../../models/ISurveyModel";
import { RvTypeService } from "../../services/RvTypeService";
import SurveyService from "../../services/SurveyService";
import CustomerStore from "../../stores/CustomerStore";
import DeleteButton from "../DeleteButton";

export const isValidSid = (sid: string) => {
  return !isNaN(+sid) && +sid >= 111111 && +sid <= 999999;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  title: {
    textAlign: "center",
    boxSizing: "border-box"
  },
  content: {
    width: "400px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2)
  },
  button: {
    marginTop: "15px"
  },
  formControl: { width: "50%", minWidth: "200px", userSelect: "none" }
}));

interface EditCorrectAnswerProps {
  open: boolean;
  close: () => void;
  rvType: IRvTypeModel;
}

export const EditRvType: FC<EditCorrectAnswerProps> = ({
  open,
  close,
  rvType
}) => {
  const classes = useStyles();
  const { deleteRvType, updateRvType } = RvTypeService;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [longName, setLongName] = useState<string>(rvType.longName);
  const [shortName, setShortName] = useState<string>(rvType.shortName);
  const [description, setDescription] = useState<string>(rvType.description);

  const onClose = () => {
    close();
  };

  const onLongNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLongName(event.target.value);
  };

  const onShortNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setShortName(event.target.value);
  };

  const onDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const handleSubmit = async () => {
    if (!rvType.id || !isValidSubmission()) return;
    const res = await updateRvType({
      id: rvType.id,
      longName,
      shortName,
      description
    });

    if (res) {
      history.go(0);
    } else {
      enqueueSnackbar(
        "Aufgabengruppe konnte nicht gespeichert werden",
        getNotiOptions("error")
      );
    }
  };

  const handleDelete = async () => {
    const res = await deleteRvType(rvType.id);
    if (res) {
      history.push("/rvtypes");
    } else {
      enqueueSnackbar(
        "Aufgabengruppe konnte nicht gelöscht werden",
        getNotiOptions("error")
      );
    }
  };

  const stringInputInvalid = (input: string): boolean => {
    return !input?.length;
  };

  const isValidSubmission = (): boolean => {
    return longName.length + shortName.length + description.length > 0;
  };

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={onClose}
    >
      <DialogTitle className={classes.title}>
        Aufgabengruppe Bearbeiten
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          required
          fullWidth
          value={longName}
          label="Name"
          error={stringInputInvalid(longName)}
          onChange={onLongNameChange}
        />
        <TextField
          required
          fullWidth
          value={shortName}
          label="Abkürzung"
          error={stringInputInvalid(shortName)}
          onChange={onShortNameChange}
        />
        <TextField
          required
          fullWidth
          value={description}
          label="Beschreibung"
          error={stringInputInvalid(description)}
          onChange={onDescriptionChange}
        />
        <Button
          className={classes.button}
          fullWidth
          size="small"
          variant="contained"
          color="secondary"
          type="submit"
          onClick={handleSubmit}
          disabled={!isValidSubmission()}
        >
          Absenden
        </Button>
        <div className={classes.button}>
          <DeleteButton
            fullWidth
            compareString="löschen"
            buttonName="Löschen"
            description={'Geben Sie "löschen" ein'}
            callback={handleDelete}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
