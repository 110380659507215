/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../constants/configs";
import { regex } from "../constants/constants";
import { ICustomerModel } from "../models/ICustomerModel";
import UserService from "../services/UserService";
import CustomerStore from "../stores/CustomerStore";
import DeleteButton from "./DeleteButton";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    maxWidth: "400px",
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  align: {
    textAlign: "left"
  },
  autocomplete: {
    width: "100%"
  },
  pass: {
    cursor: "pointer"
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
}));

export interface IUserForm {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  roleId: string;
  customerId: string;
  officeIds: string[];
}

interface IErrors {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  password: boolean;
  phone: boolean;
  roleId: boolean;
  customerId: boolean;
  officeIds: boolean;
}

interface IUserRegistration {
  user: IUserForm;
}

const UserRegistration: FC<IUserRegistration> = ({ user: passedUser }) => {
  const classes = useStyles();
  const history = useHistory();
  const customerStore = useContext(CustomerStore);
  const { createOrUpdate, deleteUser } = UserService;

  const { enqueueSnackbar } = useSnackbar();
  const { fetchedCustomers, getCustomers, clearOffices, fetchedOffices } =
    customerStore;

  const [user, setUser] = useState<IUserForm>(passedUser);
  const [passVisible, setPassVisible] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<
    ICustomerModel | undefined
  >(fetchedCustomers.find((cust) => cust.id === user.customerId));
  const [errors, setErrors] = useState<IErrors>({
    roleId: false,
    customerId: false,
    officeIds: false,
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    password: false
  });

  const handleRegistration = async (user: IUserForm) => {
    const { data, status_code } = await createOrUpdate(user);
    if (status_code === 1000) {
      switch (data) {
        case "created":
          enqueueSnackbar("Account hinzugefügt", getNotiOptions("success"));
          history.go(0);
          break;
        case "updated":
          enqueueSnackbar("Account angepasst", getNotiOptions("success"));
          history.go(0);
          break;
        case "conflict":
        case "invalid":
          enqueueSnackbar(
            "Account konnte nicht erstellt werden",
            getNotiOptions("error")
          );
          history.go(0);
          break;
        default:
          Object.entries(data).forEach(([key, value]: any) => {
            enqueueSnackbar(value, getNotiOptions("error"));
          });
          break;
      }
    } else {
      enqueueSnackbar(
        "Entschuldigung, da ist etwas schiefgelaufen",
        getNotiOptions("error")
      );
    }
  };

  const handleDeletion = async () => {
    const { status_code } = await deleteUser(user.id);
    if (status_code === 1000) {
      enqueueSnackbar("Account hinzugefügt", getNotiOptions("success"));
      history.go(0);
    } else {
      enqueueSnackbar("Löschen fehlgeschlagen", getNotiOptions("error"));
    }
  };

  const setValidation = () => {
    const newState: IErrors = {
      roleId: !user.roleId,
      customerId: user.roleId === "3" && !user.customerId,
      officeIds: user.roleId === "3" && !user.officeIds.length,
      firstName: !user.firstName || !regex.name.test(user.firstName),
      lastName: !user.lastName || !regex.name.test(user.lastName),
      email: !user.email || !regex.email.test(user.email),
      phone: Boolean(user.phone && !regex.phone.test(user.phone)),
      password: !user.password || !regex.password.test(user.password)
    };
    setErrors(newState);
  };

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        setValidation();
        handleRegistration(user);
      }}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="off"
            name="firstName"
            variant="outlined"
            required={!user.id}
            fullWidth
            color="secondary"
            id="firstName"
            label="Vorname"
            error={errors.firstName}
            value={user.firstName}
            onChange={(e) => {
              setUser({ ...user, firstName: e.target.value });
              setErrors({ ...errors, firstName: !e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="off"
            variant="outlined"
            required={!user.id}
            fullWidth
            color="secondary"
            id="lastName"
            label="Nachname"
            error={errors.lastName}
            value={user.lastName}
            name="Nachname"
            onChange={(e) => {
              setUser({ ...user, lastName: e.target.value });
              setErrors({ ...errors, lastName: !e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            variant="outlined"
            required={!user.id}
            fullWidth
            id="email"
            color="secondary"
            label="E-Mail-Adresse"
            error={errors.email}
            value={user.email}
            name="email"
            onChange={(e) => {
              setUser({ ...user, email: e.target.value });
              setErrors({ ...errors, email: !e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Tooltip
            title="mind: 8 Zeichen | Groß- und Kleinbuchstaben | Zahlen | Sonderzeichen"
            placement="right"
            enterDelay={1000}
          >
            <TextField
              autoComplete="off"
              variant="outlined"
              required={!user.id}
              fullWidth
              color="secondary"
              name="password"
              label="Passwort"
              error={!user.id && errors.password}
              value={user.password}
              type={passVisible ? "text" : "password"}
              id="password"
              onChange={(e) => {
                setUser({ ...user, password: e.target.value });
                setErrors({ ...errors, password: !e.target.value });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => setPassVisible(!passVisible)}
                  >
                    {passVisible ? (
                      <VisibilityIcon
                        color="disabled"
                        className={classes.pass}
                      />
                    ) : (
                      <VisibilityOffIcon
                        color="disabled"
                        className={classes.pass}
                      />
                    )}
                  </InputAdornment>
                )
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            variant="outlined"
            fullWidth
            color="secondary"
            name="phone"
            label="Telefonnummer"
            error={errors.phone}
            value={user.phone}
            type="tel"
            id="phone"
            onChange={(e) => {
              setUser({ ...user, phone: e.target.value });
              setErrors({ ...errors, phone: !e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel id="label">Rolle</InputLabel>
          <Select
            labelId="label"
            fullWidth
            label="Rolle"
            className={classes.align}
            value={user.roleId}
            error={errors.roleId}
            onChange={(e) => {
              setUser({ ...user, roleId: `${e.target.value}` });
              setErrors({ ...errors, roleId: !e.target.value });
            }}
          >
            <MenuItem value="1">Administrator*in</MenuItem>
            <MenuItem value="2">Support</MenuItem>
            <MenuItem value="3">Kunde</MenuItem>
            <MenuItem value="4">Berater*in</MenuItem>
          </Select>
        </Grid>
        {user.roleId === "3" && (
          <Grid item xs={12}>
            <InputLabel id="label">Kund*in</InputLabel>
            <Select
              fullWidth
              labelId="label"
              id="customer"
              label="Kunde"
              value={user.customerId}
              error={errors.customerId}
              onChange={(e: any) => {
                setUser({
                  ...user,
                  customerId: e.target.value
                });
                setSelectedCustomer(
                  fetchedCustomers?.filter(
                    (customer) => customer.id === e.target.value
                  )[0]
                );
                setErrors({ ...errors, customerId: !e.target.value });
              }}
            >
              {fetchedCustomers?.map((e, i) => {
                return (
                  <MenuItem key={i} value={e.id}>
                    {e.longName}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        )}
        {user.roleId === "3" &&
          user.customerId.length > 0 &&
          selectedCustomer?.Offices instanceof Array && (
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                key={user.customerId}
                multiple
                className={classes.autocomplete}
                id="tags-outlined"
                options={selectedCustomer?.Offices}
                value={selectedCustomer?.Offices.filter((office) =>
                  user.officeIds.includes(office.id)
                )}
                getOptionLabel={(option) => option?.longName || ""}
                filterSelectedOptions
                noOptionsText="Keine Einheit"
                onChange={(e, value) => {
                  setUser({
                    ...user,
                    officeIds: value.map((office) => office.id)
                  });
                  setErrors({ ...errors, officeIds: !value });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ required: true }}
                    variant="outlined"
                    color="secondary"
                    label="Einheiten"
                  />
                )}
              />
            </Grid>
          )}
        <Grid container item xs={12} className={classes.btnContainer}>
          {user.id && (
            <Grid item xs={5}>
              <DeleteButton
                fullWidth
                compareString="löschen"
                buttonName="Löschen"
                description="Löschen Sie den Account"
                callback={handleDeletion}
              />
            </Grid>
          )}
          <Grid item xs={user.id ? 5 : 12}>
            <Button
              fullWidth
              size="small"
              variant="contained"
              color="secondary"
              type="submit"
            >
              {user.id ? "Speichern" : "Registrieren"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(UserRegistration);
