import { List } from "@material-ui/core";
import {
  Contacts,
  ImportContactsOutlined,
  Loop,
  PeopleAlt,
  PermContactCalendar,
  PersonAdd,
  PollOutlined
} from "@material-ui/icons";
import CategoryIcon from "@material-ui/icons/Category";
import EventIcon from "@material-ui/icons/Event";
import TimerIcon from "@material-ui/icons/Timer";
import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import UserStore from "../../stores/UserStore";
import NavItem from "./NavItem";

const NavList: FC = () => {
  const { userRole } = useContext(UserStore);

  return (
    <List>
      {userRole < 4 ? (
        <>
          <NavItem
            icon={() => <PeopleAlt />}
            label="Teilnehmer*innen"
            to="/participants"
          />

          {userRole < 3 && (
            <>
              <NavItem
                icon={() => <EventIcon />}
                label="Termine"
                to="/events"
              />
              <NavItem
                icon={() => <PersonAdd />}
                label="TN-Import"
                to="/participantImport"
              />
              <NavItem
                icon={() => <PermContactCalendar />}
                label="Kunden"
                to="/customer"
              />
              <NavItem icon={() => <Contacts />} label="Nutzer" to="/users" />
              <NavItem
                icon={() => <TimerIcon />}
                label="Umfragen"
                to="/survey"
              />
              <NavItem
                icon={() => <CategoryIcon />}
                label="Kompetenzen"
                to="/competency"
              />
              <NavItem
                icon={() => <Loop />}
                label="Konverter"
                to="/converter"
              />
              <NavItem
                icon={() => <PollOutlined />}
                label="Aufgabengruppen"
                to="/rvtypes"
              />
              <NavItem
                icon={() => <ImportContactsOutlined />}
                label="Normen"
                to="/norm"
              />
            </>
          )}
        </>
      ) : (
        <NavItem icon={() => <EventIcon />} label="Termine" to="/events" />
      )}
    </List>
  );
};

export default observer(NavList);
