import axios from "axios";
import { setServerUrl } from "../constants/constants";
import { IFullRvTypeModel, IRvTypeModel } from "../models/IRvTypeModel";
import { APIResponse } from "./SurveyService";

export const RvTypeService = {
  async getAllRvTypes(): Promise<APIResponse<IRvTypeModel[]>> {
    const rsp = await axios.get(setServerUrl("/api/rvtype/get", "/rvtype/get"));
    return Promise.resolve(rsp.data);
  },

  async getRawValueType(id: string): Promise<APIResponse<IFullRvTypeModel>> {
    const rsp = await axios.get(
      setServerUrl(`/api/rvtype/get/${id}`, `/rvtype/get/${id}`)
    );
    console.log(rsp.data);
    return Promise.resolve(rsp.data);
  },

  async createRvType(
    longName: string,
    shortName: string,
    description: string
  ): Promise<APIResponse<IRvTypeModel>> {
    const rsp = await axios.post(
      setServerUrl(`/api/rvtype/new`, "rvtype/new"),
      {
        longName,
        shortName,
        description
      }
    );
    return Promise.resolve(rsp.data);
  },

  async deleteRvType(id: string): Promise<APIResponse<void>> {
    const rsp = await axios.delete(
      setServerUrl(`/api/rvtype/delete/${id}`, `rvtype/delete/${id}`)
    );
    return Promise.resolve(rsp.data);
  },

  async updateRvType(rvType: IRvTypeModel): Promise<APIResponse<IRvTypeModel>> {
    const rsp = await axios.put(
      setServerUrl(`/api/rvtype/update`, `rvtype/update`),
      rvType
    );
    return Promise.resolve(rsp.data);
  }
};
