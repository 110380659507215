import {
  Box,
  Button,
  Chip,
  Divider,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  ExitToApp,
  KeyboardArrowDown,
  KeyboardArrowUp
} from "@material-ui/icons";
import BugReportIcon from "@material-ui/icons/BugReport";
import axios from "axios";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../constants/configs";
import { ICustomerModel } from "../models/ICustomerModel";
import { IOfficeModel } from "../models/IOfficeModel";
import DrawerStore from "../stores/DrawerStore";
import UserStore from "../stores/UserStore";
import Avatar from "./Avatar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      color: "rgba(0, 0, 0, 0.54)",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      cursor: "pointer"
    },
    chipBox: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    chipContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-around"
    },
    chip: {
      width: "fit-content",
      margin: theme.spacing(0.5)
    },
    logo: {
      width: "1em !important",
      height: "1em !important"
    },
    userInfoBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(0.5)
    },
    popover: {
      position: "absolute",
      width: "300px",
      padding: "10px 20px",
      background: theme.palette.primary.light,
      borderTop: "1px solid lightgrey",
      right: "0",
      top: "64px",
      textAlign: "left",
      boxShadow: "-2px 2px 2px 0px rgb(0 0 0 / 30%)",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1)
    },
    loggOut: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      cursor: "pointer"
    },
    toggle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer"
    },
    prefs: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column"
    },
    report: {
      cursor: "pointer"
    },
    gray: {
      color: theme.palette.grey[700]
    },
    save: {
      margin: theme.spacing(2)
    }
  })
);

const LoginInformation: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user, setUser, changeNavPrefs } = useContext(UserStore);
  const { setDrawerOpen } = useContext(DrawerStore);
  const { enqueueSnackbar } = useSnackbar();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [navPref, setNavPref] = useState(user.navPref);

  const changeNavPref = (event: ChangeEvent<any>) => {
    setNavPref(event.target.value);
  };

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    // tslint:disable-next-line:no-string-literal
    delete axios.defaults.headers.common["Authorization"];
    setUser(
      {
        firstName: "",
        lastName: "",
        email: "",
        id: "0",
        roleId: 0,
        Customers: [] as ICustomerModel[],
        Offices: [] as IOfficeModel[],
        showResults: false,
        reports: false,
        revaluation: false,
        navPref: "flexible"
      },
      false
    );
    history.push("/login");
    localStorage.clear();
    enqueueSnackbar("Logout Erfolgreich.", getNotiOptions("success"));
  };

  const saveNavPrefs = () => {
    const windowWidth = window.innerWidth;
    changeNavPrefs(navPref);
    setOpen(false);
    setPopoverOpen(false);
    if (navPref === "folded") {
      setDrawerOpen(false);
    } else if (navPref === "unfolded") {
      setDrawerOpen(true);
    } else if (navPref === "flexible") {
      if (windowWidth >= 1480) {
        setDrawerOpen(true);
      } else {
        setDrawerOpen(false);
      }
    }
  };

  const reportBug = () => {
    const subject = "Fehlerhafte Funktion: KI.PORTAL";
    const body = "Bitte beschreiben Sie das Problem:";
    window.open(
      `mailto:kundensupport@ki-test.com?subject=${subject}&body=${body}`
    );
  };

  return (
    <Box>
      <Avatar user={user} toggle={setPopoverOpen} />
      {popoverOpen && (
        <Box
          className={classes.popover}
          onMouseLeave={() => setPopoverOpen(false)}
        >
          <Box className={classes.userInfoBox}>
            <Box>
              <Typography variant="h5">
                {user.firstName} {user.lastName}
              </Typography>
              <Typography color="secondary">{user.email}</Typography>
            </Box>
            <Tooltip title="Bug melden">
              <BugReportIcon
                className={classes.report}
                color="disabled"
                onClick={reportBug}
              />
            </Tooltip>
          </Box>
          <Box className={classes.chipContainer}>
            <Box className={classes.chipBox}>
              {user.Customers &&
                user.Customers.map((customer) => (
                  <Chip
                    key={customer.id}
                    label={customer.shortName}
                    className={classes.chip}
                    color="secondary"
                    size="small"
                  />
                ))}
            </Box>
            <Box className={classes.chipBox}>
              {user.Offices?.map((office) => (
                <Chip
                  key={office.id}
                  className={classes.chip}
                  label={office.shortName}
                  size="small"
                  variant="outlined"
                />
              ))}
            </Box>
          </Box>
          <Box className={classes.loggOut} onClick={handleLogout}>
            <ExitToApp className={classes.gray} />
            <Typography>Ausloggen</Typography>
          </Box>
          <Divider />
          <Box className={classes.toggle} onClick={() => setOpen(!open)}>
            <Typography>Navigationspräferenzen</Typography>
            {open ? (
              <KeyboardArrowUp className={classes.gray} />
            ) : (
              <KeyboardArrowDown className={classes.gray} />
            )}
          </Box>
          {open && (
            <Box className={classes.prefs}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="navPrefs"
                  name="navPrefs"
                  onChange={changeNavPref}
                  value={navPref}
                >
                  <FormControlLabel
                    value="unfolded"
                    control={<Radio />}
                    label="immer ausgeklappt"
                  />
                  <FormControlLabel
                    value="folded"
                    control={<Radio />}
                    label="immer eingeklappt"
                  />
                  <FormControlLabel
                    value="flexible"
                    control={<Radio />}
                    label="je nach Bildschirmgröße"
                  />
                </RadioGroup>
              </FormControl>
              <Button
                color="secondary"
                variant="contained"
                onClick={saveNavPrefs}
                className={classes.save}
              >
                Speichern
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default observer(LoginInformation);
