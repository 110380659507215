import { IRawValueModel } from "./IRawValueModel";

export interface INormModel {
  id: string;
  longName: string;
  shortName: string;
  description: string;
  RawValues: IRawValueModel[];
}

export function emptyNorm(): INormModel {
  return {
    id: "",
    longName: "",
    shortName: "",
    description: "",
    RawValues: [] as IRawValueModel[]
  };
}

// why do rawvalues not link to a norm directly and instead store an integer as level?
export const normNames: {
  [key: number]: string; // this is bad
} = {
  [1]: "VFA",
  [2]: "RSA",
  [3]: "RIA",
  [4]: "VIA"
} as const;
