import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { IUploadResponse } from "../details/EventDetail";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      margin: theme.spacing(2),
      gap: theme.spacing(3)
    },
    modal: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      position: "absolute",
      top: "40%",
      left: "65%",
      transform: "translate(-50%, -50%)",
      minWidth: "400px",
      backgroundColor: "white",
      padding: theme.spacing(2),
      borderRadius: "5px"
    },
    red: {
      color: theme.palette.error.main
    },
    green: {
      color: theme.palette.success.main
    },
    warning: {
      color: theme.palette.warning.main
    },
    tableContainer: {
      margin: theme.spacing(1)
    },
    tableHeader: {
      fontSize: "1rem",
      fontWeight: "bolder",
      backgroundColor: "whitesmoke"
    }
  })
);

export interface IAcUploadFeedback {
  uploadResponse: IUploadResponse;
  open: boolean;
  close: () => void;
}

const AcUploadFeedback: FC<IAcUploadFeedback> = ({
  uploadResponse,
  close,
  open
}) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={close} onBackdropClick={close}>
      <Box className={classes.modal}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size="small" aria-label="feedback-success">
            <TableHead>
              <TableRow>
                <TableCell
                  className={`${classes.tableHeader} ${classes.green}`}
                >
                  Erfolgreich
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {uploadResponse?.success?.map((item, i) => (
                  <TableCell key={i}>
                    {item.firstName} {item.lastName}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
          <Table size="small" aria-label="Feedback-exist">
            <TableHead>
              <TableRow>
                <TableCell
                  className={`${classes.tableHeader} ${classes.warning}`}
                >
                  Existent
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {uploadResponse?.exist?.map((item, i) => (
                  <TableCell key={i}>
                    {item.firstName} {item.lastName}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
          <Table size="small" aria-label="Feedback-error">
            <TableHead>
              <TableRow>
                <TableCell className={`${classes.tableHeader} ${classes.red}`}>
                  Fehler
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {uploadResponse?.error?.map((item, i) => (
                  <TableCell key={i}>
                    {item.firstName} {item.lastName}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          onClick={close}
          variant="outlined"
          size="small"
          className={classes.red}
        >
          Schließen
        </Button>
      </Box>
    </Modal>
  );
};

export default observer(AcUploadFeedback);
