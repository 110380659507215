export interface Model {
  createdAt: string;
  updatedAt: Date;
}

// TODO proper default values
export const Model = (): Model => ({
  createdAt: "",
  updatedAt: new Date()
});
