import { Button, Grid, Typography } from "@material-ui/core";
import BugReportIcon from "@material-ui/icons/BugReport";
import { FC } from "react";

const ErrorFallback: FC = () => {
  return (
    <div
      style={{
        maxWidth: "800px",
        height: "100vh",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <BugReportIcon style={{ fontSize: "10rem", color: "lightgrey" }} />
      <h1>Oops! ... </h1>
      <h2>Das hätte nicht passieren dürfen</h2>
      <Typography style={{ margin: "2rem" }}>
        Laden Sie die Seite neu. Sollte das Problem weiterhin bestehen, wenden
        Sie sich an den Support
      </Typography>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item>
          <Button variant="outlined" href="/">
            Seite neu laden
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" href="mailto:support@ki-test.com">
            Support
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ErrorFallback;
