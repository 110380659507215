/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import { add, isAfter, startOfTomorrow } from "date-fns";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { IOfficeModel } from "../../models/IOfficeModel";
import { IParticipantDetailModel } from "../../models/IParticipantDetailModel";
import { IPositionModel } from "../../models/IPositionModel";
import { IProjectModel } from "../../models/IProjectModel";
import ImportService from "../../services/ImportService";
import ProjectService from "../../services/ProjectService";
import UserStore from "../../stores/UserStore";
import DatePicker from "../DatePicker";
import LoadingAnimation from "../LoadingAnimation";
import PositionSelector from "../PositionSelector";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    title: {
      textAlign: "center",
      boxSizing: "border-box"
    },
    red: {
      color: theme.palette.error.main
    },
    green: {
      color: theme.palette.success.main
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    content: {
      width: "400px",
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2)
    },
    datePickers: {
      display: "flex",
      flexDirection: "row",
      gap: theme.spacing(4)
    },
    requestButton: {
      width: "fit-content"
    }
  })
);

export interface IAddApplication {
  open: boolean;
  close: () => void;
  data?: IParticipantDetailModel;
}

interface IDates {
  validFrom: Date;
  validUntil: Date;
}

interface ISelection {
  office: IOfficeModel;
  project: IProjectModel;
  position: IPositionModel;
}

const initialSelection: ISelection = {
  office: {} as IOfficeModel,
  project: {} as IProjectModel,
  position: {} as IPositionModel
};

const AddApplication: FC<IAddApplication> = ({ close, open, data }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(UserStore);
  const { enqueueSnackbar } = useSnackbar();
  const { getUserOffices } = ProjectService;

  const [fetchedOffices, setFetchedOffices] = useState<IOfficeModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selection, setSelection] = useState<ISelection>(initialSelection);
  const [dates, setDates] = useState<IDates>({
    validFrom: startOfTomorrow(),
    validUntil: add(new Date(), { weeks: 1 })
  });

  const handleClose = () => {
    setSelection(initialSelection);
    close();
  };

  const sendAddApplicationRequest = async () => {
    if (!Object.values(selection).every((sel) => sel?.id) || !data) return;
    setIsLoading(true);

    const res = await ImportService.addApplication(
      data?.id,
      selection.position.id,
      selection.office.id,
      dates.validFrom,
      dates.validUntil
    );
    if (res.status_code === 1000) {
      enqueueSnackbar("Bewerbung hinzugefügt", getNotiOptions("success"));
      history.go(0);
    } else {
      enqueueSnackbar(
        "Bewerbung konnte nicht hinzugefügt werden",
        getNotiOptions("error")
      );
      handleClose();
    }
    setIsLoading(false);
  };

  const fetchOffices = async () => {
    if (!data) return;
    setIsLoading(true);
    const offices = await getUserOffices(user.id);
    if (offices) {
      setFetchedOffices(offices);
    }
    setIsLoading(false);
  };

  const datesValid = () =>
    isAfter(dates.validUntil, dates.validFrom) &&
    isAfter(dates.validUntil, new Date()) &&
    isAfter(dates.validFrom, new Date());

  const handleDate = (type: "validFrom" | "validUntil", date: Date) => {
    setDates({ ...dates, [type]: date });
    if (!datesValid()) {
      enqueueSnackbar(
        "Das Startdatum muss vor dem Enddatum liegen | Nur zukünftige Daten möglich",
        getNotiOptions("error")
      );
    }
  };

  useEffect(() => {
    //! TODO: better pass offices from the participant detail view to the AddApplication dialog
    fetchOffices();
  }, []);

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.title}>Bewerbung Hinzufügen</DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <Typography>Wählen Sie eine Position</Typography>
        <PositionSelector
          offices={fetchedOffices}
          existingApplications={data?.Applications}
          onChange={(changes) => {
            setSelection({
              office: changes.office?.object
                ? changes.office.object
                : selection.office,
              project: changes.project?.object
                ? changes.project.object
                : selection.project,
              position: changes.position?.object
                ? changes.position.object
                : selection.position
            });
          }}
        />
        <Typography>Wählen Sie einen Zeitraum</Typography>
        <div className={classes.datePickers}>
          <DatePicker
            disablePast
            label="Gültig von"
            minDate={startOfTomorrow()}
            initialDate={dates.validFrom}
            processDate={(date) => handleDate("validFrom", date)}
          />
          <DatePicker
            disablePast
            label="Gültig bis"
            minDate={add(dates.validFrom, { days: 1 })}
            initialDate={dates.validUntil}
            processDate={(date) => handleDate("validUntil", date)}
          />
        </div>
        {isLoading ? (
          <LoadingAnimation />
        ) : (
          <Button
            fullWidth
            size="small"
            variant="contained"
            color="secondary"
            type="submit"
            className={classes.requestButton}
            disabled={
              !Object.values(selection).every((sel) => sel?.id) || !datesValid()
            }
            onClick={sendAddApplicationRequest}
          >
            Bewerbung Hinzufügen
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default observer(AddApplication);
