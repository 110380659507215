import { ICustomerModel } from "./ICustomerModel";
import { IRawValueModel } from "./IRawValueModel";

export type SurveyType = "OT" | "PT";

export interface ISurveyModel {
  id?: string;
  sid: string;
  description: string;
  barrier: number;
  customerId?: string;
  Customer?: ICustomerModel;
  type?: SurveyType;
}

export interface ISurveyModel_RawValues extends ISurveyModel {
  RawValues: IRawValueModel[];
}

export interface CorrectAnswer {
  id: string;
  sid: string;
  title: string;
  solution: string;
}

export function emptySurvey(): ISurveyModel {
  return {
    sid: "",
    description: "",
    barrier: 0
  };
}
