import { IEventModel } from "./IEventModel";

export enum EGroupStatus {
  valid = "valid",
  complete = "complete",
  abort = "abort"
}
export interface IGroupModel {
  id: string;
  status: EGroupStatus;
  completedAt: Date;
  shortName: string;
  longName: string;
  Event?: IEventModel;
}

export function emptyEvent(): IGroupModel {
  return {
    id: "",
    status: EGroupStatus.valid,
    completedAt: new Date(),
    shortName: "",
    longName: ""
  };
}
