/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Button,
  Chip,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import BusinessIcon from "@material-ui/icons/Business";
import SettingsIcon from "@material-ui/icons/Settings";
import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import CustomerStore from "../../stores/CustomerStore";
import UserStore from "../../stores/UserStore";
import ColorDrop from "../ColorDrop";
import Wrapper from "../containers/Wrapper";
import DataGrid, { ICustomerItemDialog, IDataGridProps } from "../DataGrid";
import EditCustomerItem from "../dialogs/EditCustomerItem";
import LoadingAnimation from "../LoadingAnimation";

const str = {
  vCard: {
    abbreviation: "Abkürzung:",
    color: "Farbe:"
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  infoBox: {
    width: "100%"
  },
  tablerow: {
    display: "flex",
    marginTop: "3px"
  },
  button: {
    marginTop: "50px"
  },
  avatar: {
    margin: "3px",
    backgroundColor: theme.palette.secondary.main
  },
  avatarBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(2),
    userSelect: "none"
  },
  display: {
    display: "flex",
    justifyContent: "center"
  },
  customerName: {
    fontSize: "2rem",
    margin: "0",
    marginLeft: "7px"
  },
  vcard: {
    width: "100%",
    height: "35%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    alignItems: "flex-start",
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "1px",
    padding: theme.spacing(3)
  },
  input: {
    minWidth: "250px",
    margin: "0",
    textAlign: "left",
    padding: theme.spacing(0.5)
  },
  editIcon: {
    cursor: "pointer"
  },
  icons: {
    position: "absolute",
    display: "inline",
    bottom: "0px"
  },
  label: {
    minWidth: "250px",
    textAlign: "left",
    margin: "0",
    padding: theme.spacing(0.5)
  },
  customerIcon: {
    position: "relative",
    bottom: "34px",
    left: "3%",
    display: "flex",
    cursor: "pointer"
  },
  chip: {
    width: "fit-content"
  }
}));

interface IDetailParams {
  id: string;
}

const OfficeDetail: FC<RouteComponentProps<IDetailParams>> = ({ match }) => {
  const officeId = match.params.id;
  const classes = useStyles();
  const history = useHistory();
  const { isAdminSupport } = useContext(UserStore);
  const { fetchedOffice, getOfficeById, fetchedCustomer } =
    useContext(CustomerStore);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dialogProps: ICustomerItemDialog = {
    customerId: fetchedCustomer.id,
    items: [
      {
        type: "id",
        name: "id",
        value: fetchedOffice.id
      },
      {
        type: "name",
        name: "longName",
        value: fetchedOffice.longName
      },
      {
        type: "default",
        name: "shortName",
        value: fetchedOffice.shortName
      },
      {
        type: "color",
        name: "color",
        value: fetchedOffice.color
      }
    ],
    type: "office",
    title: "Office editieren",
    open,
    close: handleClose
  };

  const handleBack = () => {
    history.push(
      `/${fetchedCustomer?.id ? `customer/${fetchedCustomer.id}` : ""}`
    );
  };

  useEffect(() => {
    getOfficeById(officeId);
  }, []);

  const projectDataprops: IDataGridProps = {
    title: "Projekte",
    searchFilter: false,
    columns: [
      { title: "Name", field: "longName" },
      { title: "Abkürzung", field: "shortName" },
      { title: "Gültig von", field: "validFrom" },
      { title: "Gültig bis", field: "validUntil" }
    ],
    data: fetchedOffice.id
      ? JSON.parse(JSON.stringify(fetchedOffice?.Projects))
      : []
  };

  const positionDataprops: IDataGridProps = {
    title: "Positionen",
    searchFilter: false,
    columns: [
      { title: "Name", field: "longName" },
      { title: "Abkürzung", field: "shortName" }
    ],
    data: fetchedOffice.id
      ? JSON.parse(JSON.stringify(fetchedOffice?.Positions))
      : []
  };

  return (
    <Wrapper>
      {fetchedOffice?.id ? (
        <>
          <div className={classes.vcard} data-cy="info-vcard">
            <div className={classes.avatarBox}>
              <div className={classes.display}>
                <Avatar className={classes.avatar} data-cy="info-avatar">
                  <BusinessIcon />
                </Avatar>
                <Typography className={classes.customerName}>
                  {fetchedOffice.longName}
                </Typography>
              </div>
              {isAdminSupport && (
                <div className={classes.editIcon}>
                  <Tooltip title="Office editieren">
                    <IconButton onClick={handleOpen}>
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                  <EditCustomerItem {...dialogProps} />
                </div>
              )}
            </div>
            <div className={classes.infoBox}>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  {str.vCard.abbreviation}
                </Typography>
                <Chip
                  className={classes.chip}
                  label={fetchedOffice.shortName}
                  size="small"
                  variant="outlined"
                />
              </div>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  {str.vCard.color}
                </Typography>
                <div className={classes.input}>
                  <Tooltip title={fetchedOffice.color} arrow>
                    <ColorDrop color={fetchedOffice.color} />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <DataGrid dense {...projectDataprops} />
          <DataGrid dense {...positionDataprops} />
        </>
      ) : (
        <LoadingAnimation />
      )}

      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={handleBack}
        className={classes.button}
      >
        Zurück zu Kundendetails
      </Button>
    </Wrapper>
  );
};

export default observer(OfficeDetail);
