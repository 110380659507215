import { IOfficeModel } from "./IOfficeModel";
import { ICustomerModel } from "./ICustomerModel";
import { IRoleModel } from "./IRoleModel";
export interface IUserModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roleId: number;
  userId?: string;
  Customers?: ICustomerModel[];
  Offices?: IOfficeModel[];
  phone?: string;
  Role?: IRoleModel;
  reports: boolean;
  showResults: boolean;
  revaluation: boolean;
  navPref: "flexible" | "unfolded" | "folded";
}
export interface IUserModelReduced {
  id: string;
  firstName: string;
  lastName: string;
}

export function emptyUser(): IUserModel {
  return {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    roleId: 0,
    userId: "",
    Customers: [] as ICustomerModel[],
    Offices: [] as IOfficeModel[],
    Role: {} as IRoleModel,
    phone: "",
    reports: false,
    showResults: false,
    revaluation: false,
    navPref: "flexible"
  };
}
