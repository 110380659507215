import {
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Typography
} from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import { observer } from "mobx-react-lite";
import { FC } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      display: "none"
    },
    btn: {
      whiteSpace: "nowrap"
    }
  })
);

export interface IFileInput {
  buttonText?: string;
  description?: string;
  filetypes: string;
  callback: (file: File) => void;
}

const FileInput: FC<IFileInput> = ({
  buttonText,
  description,
  filetypes,
  callback
}) => {
  const classes = useStyles();
  let fileUpload: any;

  const handleChange = () => {
    callback(fileUpload.files[0]);
  };

  return (
    <div>
      {description && <Typography>{description}</Typography>}
      <input
        accept={filetypes}
        className={classes.input}
        id="raised-button-file"
        type="file"
        ref={(ref) => (fileUpload = ref)}
        onChange={handleChange}
      />
      <label htmlFor="raised-button-file">
        {buttonText ? (
          <Button
            className={classes.btn}
            color="secondary"
            variant="contained"
            component="span"
            size="small"
            startIcon={<PublishIcon />}
          >
            {buttonText}
          </Button>
        ) : (
          <IconButton component="span" size="small">
            <PublishIcon />
          </IconButton>
        )}
      </label>
    </div>
  );
};

export default observer(FileInput);
