import { IProjectModel } from "./IProjectModel";
import { ICustomerModel } from "./ICustomerModel";
import { IApplicationModel } from "./IApplicationModel";
import { IActivityModel } from "./IActivityModel";
import { IPositionModel } from "./IPositionModel";
import { ICustomerItemModel } from "./ICustomerItemModel";
export interface IOfficeModel {
  id: string;
  customerId: string;
  longName: string;
  shortName: string;
  color: string;
  Projects?: IProjectModel[];
  Positions?: IPositionModel[];
  Customer?: ICustomerModel;
  Application?: IApplicationModel;
  Activity?: IActivityModel;
}

export function emptyOffice(): IOfficeModel {
  return {
    id: "",
    customerId: "",
    longName: "",
    shortName: "",
    color: "",
    Projects: [] as IProjectModel[],
    Positions: [] as IPositionModel[],
    Customer: {} as ICustomerModel,
    Application: {} as IApplicationModel,
    Activity: {} as IActivityModel
  };
}

export function newOfficeItem(): ICustomerItemModel {
  return {
    items: [
      {
        type: "id",
        name: "id",
        value: ""
      },
      {
        type: "name",
        name: "longName",
        value: ""
      },
      {
        type: "default",
        name: "shortName",
        value: ""
      },
      {
        type: "color",
        name: "color",
        value: ""
      },
      {
        type: "id",
        name: "customerId",
        value: ""
      }
    ],
    type: "office",
    title: "Einheit erstellen"
  };
}
