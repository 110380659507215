import axios from "axios";
import { setServerUrl } from "../constants/constants";
import IHashModel from "../models/IHashModel";

const UtilityService = {
  /**
   * get page by id
   * @param id: number|null
   * @returns page
   */
  async getAnonId(data: IHashModel) {
    const response = await axios.post(setServerUrl(`/api/hash`, `/api/hash`), {
      ...data
    });
    return Promise.resolve(response.data);
  }
};

export default UtilityService;
