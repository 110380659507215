import {
  Button,
  Chip,
  FormControl,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { isAfter, isBefore, startOfDay } from "date-fns/esm";
import { useSnackbar } from "notistack";
import React, { FC, useContext, useEffect, useState } from "react";
import { getNotiOptions } from "../../constants/configs";
import { regex } from "../../constants/constants";
import { parseDate } from "../../functions/date";
import { IPositionModel } from "../../models/IPositionModel";
import ImportService from "../../services/ImportService";
import ImportStore from "../../stores/ImportStore";
import LoadingAnimation from "./../LoadingAnimation";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: "none",
    maxWidth: "100%"
  },
  btn: {
    margin: "20px",
    boxSizing: "border-box"
  },
  btnBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  duplicate: {
    color: "red"
  },
  form: {
    margin: theme.spacing(1)
  },
  tableContainer: {
    maxHeight: "40vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  tableHeadCell: {
    width: "100px"
  },
  tableCell: {
    maxWidth: "100px",
    minWidth: "100px",
    padding: "2px 0px 2px 4px",
    overflowX: "hidden",
    textOverflow: "ellipsis"
  },
  iconTableCell: {
    overflowX: "hidden",
    userSelect: "none",
    paddingTop: "10px",
    color: "#e39802"
  },
  infoIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  header: {
    fontWeight: 600
  },
  invalid: {
    color: "#b60a1c"
  },
  button: {
    margin: "10px"
  },
  accordionSummary: {
    backgroundColor: theme.palette.secondary.main
  },
  accordion: {
    marginBottom: ".25rem"
  },
  accordionDetails: {
    padding: "0",
    "@global": {
      ".MuiToolbar-root": {
        minHeight: "10px"
      }
    }
  },
  paginationLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space between"
  },
  total: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.light
  },
  conflict: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.primary.light
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.primary.light
  },
  ok: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.primary.light
  },
  chipBox: {
    display: "flex",
    padding: theme.spacing(1),
    gap: theme.spacing(1)
  }
}));

// INTERFACES
export interface IImportDataGridRow {
  [index: number]: Record<string, unknown>;
}
export interface IImportDataGridProps {
  columns: {
    [key: string]: string;
  };
  rows: any[];
  positions: IPositionModel[];
  editable: boolean;
  fileName: string;
  fileHeaders: string[];
  next: () => void;
  reset: () => void;
  storeNewRows: (newRows: any[]) => void;
}
export interface IImportDataGridColumn {
  [key: string]: string;
}

const ImportDataGrid: FC<IImportDataGridProps> = ({
  columns,
  rows,
  positions,
  fileName,
  fileHeaders,
  storeNewRows
}) => {
  const classes = useStyles();
  const importStore = useContext(ImportStore);
  const { enqueueSnackbar } = useSnackbar();
  const { checkConflicts } = ImportService;
  const {
    activeStep,
    setActiveStep,
    mappingState,
    setMappingState,
    validationState,
    setValidationState,
    conflictState,
    setConflictState,
    importState
  } = importStore;

  const [page, setPage] = useState(0);
  const [headers] = useState<IImportDataGridColumn>(columns);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableData, setTableData] = useState(rows);
  const [dataIsModified, setDataIsModified] = useState(false);
  const [mappingArr, setMappingArr] = useState<string[]>(fileHeaders);
  const [mappedHeaders, setMappedHeaders] = useState({});

  const futureDate = (date: string) =>
    isAfter(startOfDay(parseDate(date)), startOfDay(new Date()));

  const untilAfterFrom = (validFrom: string, validUntil: string) =>
    isBefore(parseDate(validFrom), parseDate(validUntil));

  const validateData = (data: Record<string, unknown>[]) => {
    const validatedData = data.map((entry: any) => {
      entry.misc.invalid = [];
      const posShortNames = entry.misc.positions.map(
        (position: any) => position.shortName
      );
      let posCounter = 0;
      for (const key in entry) {
        const value = entry[key];
        const invalids = entry.misc.invalid;
        switch (key) {
          case "lastName":
            !regex.name.test(value) && invalids.push(key);
            break;
          case "firstName":
            !regex.name.test(value) && invalids.push(key);
            break;
          case "email":
            !regex.email.test(value) && invalids.push(key);
            break;
          case "gender":
            !regex.gender.test(value) && invalids.push(key);
            break;
          case "birthday":
            !regex.birthday.test(value) && invalids.push(key);
            break;
          case "validFrom":
            (!regex.date.test(value) || !futureDate(value)) &&
              invalids.push(key);
            break;
          case "validUntil":
            (!regex.date.test(value) ||
              !futureDate(value) ||
              !untilAfterFrom(entry.validFrom, value)) &&
              invalids.push(key);
            break;
          default:
            break;
        }
        if (posShortNames.includes(key) && value === "x") {
          posCounter++;
        }
      }
      if (posCounter === 0) {
        posShortNames.forEach((shortName: string) =>
          entry.misc.invalid.push(shortName)
        );
      }
      return entry;
    });
    if (validatedData[0].misc.invalid.length >= 6) {
      enqueueSnackbar(
        "Enthält die Datei eine Kopfzeile? Bitte import Dateien nur ohne Kopfzeile hochladen.",
        getNotiOptions("warning")
      );
    }

    updateValidationProgress();
    setTableData(validatedData);
    setActiveStep(activeStep);
  };

  const storeHeaders = () => {
    const tableKeys = Object.keys(tableData[0]);
    const mappingObject: any = {};
    tableKeys.forEach((key: string, i: number) => {
      if (key !== "misc") {
        mappingObject[key] = {
          id: mappingArr[i],
          name: columns[mappingArr[i]]
        };
      }
    });
    setMappedHeaders(mappingObject);
  };

  const renameTableKeys = (
    tableData: Record<string, unknown>[],
    mappingObj: { [index: string]: { id: string; name: string } }
  ) => {
    const newTableData = tableData.map((dataSet) => {
      const newDataSet: { [key: string]: any } = {};
      Object.entries(dataSet).forEach(([key, value]) => {
        if (key !== "misc" && key.length) {
          const mappingItem = mappingObj[key];
          newDataSet[mappingItem.id] = value;
        } else if (key === "misc") {
          // TODO: check if this is right
          newDataSet.misc = value;
        }
      });
      return newDataSet;
    });
    newTableData.forEach((dataSet) => {
      const allPos = positions.map((position) => position.shortName);
      Object.entries(dataSet).forEach(([key, value]) => {
        if (allPos.includes(key)) {
          if (value.length > 0) {
            dataSet[key] = "x";
          } else {
            dataSet[key] = "-";
          }
        } else if (key === "gender") {
          const lowVal = value.toLowerCase();
          switch (lowVal) {
            case "m":
              dataSet[key] = "männlich";
              break;
            case "w":
              dataSet[key] = "weiblich";
              break;
            case "d":
              dataSet[key] = "divers";
              break;
            case "männlich":
              dataSet[key] = "männlich";
              break;
            case "weiblich":
              dataSet[key] = "weiblich";
              break;
            case "divers":
              dataSet[key] = "divers";
              break;
            default:
              break;
          }
        }
      });
    });
    setTableData(newTableData);
    setDataIsModified(true);
  };

  const getInfoMessage = (code: string) => {
    // info message according to conflict type
    let message = "";
    switch (code) {
      case "diffMail":
        message = "Teilnehmer*in existiert mit einer anderen E-Mail-Adresse";
        break;
      case "diffFirstName":
        message = "Teilnehmer*in existiert mit einem anderen Vornamen";
        break;
      case "diffLastName":
        message = "Teilnehmer*in existiert mit einem anderen Nachnamen";
        break;
      case "diffBirthday":
        message = "Teilnehmer*in existiert mit einem anderen Geburtsdatum";
        break;
      case "nameSwap":
        message =
          "Teilnehmer*in exisitert bereits mit verdrehtem Vor- und Nachnamen";
        break;
      case "mailExist":
        message = "Die E-Mail-Adresse ist bereits vergeben";
        break;
      case "exist":
        message = "Teilnehmer*in exisiert bereits";
        break;
      case "duplicateMail":
        message =
          "Die E-Mail-Adresse ist innerhalb der Datei für verschiedene Teilnehmer*innen vorhanden";
        break;
      case "diffGender":
        message = "Teilnehmer*in existiert bereits mit anderem Geschlecht";
        break;
      default:
        break;
    }
    return message;
  };

  // MAPPING CONTROL
  const checkMappingDuplicates = () => {
    const duplicates: string[] = mappingArr?.filter(
      (item, i) => mappingArr?.indexOf(item) !== i
    );
    return duplicates.length > 0;
  };

  const checkMappingCompletion = () => {
    const headersCount = Object.keys(columns).length;
    const mappingCount = mappingArr?.filter((item) => item !== "").length;
    return mappingCount === headersCount;
  };

  const updateMappingProgress = () => {
    const complete: boolean = checkMappingCompletion();
    const duplicates: boolean = checkMappingDuplicates();
    setMappingState({
      complete,
      duplicates
    });
  };

  // VALIDATION CONTROL
  const checkValidationCompletion = () => {
    // count validation violoations and set to complete if === 0
    let complete = true;
    let count = 0;
    tableData.forEach((dataSet) => {
      if (dataSet.misc.invalid.length > 0) {
        count++;
        complete = false;
      }
    });
    return { complete, count };
  };

  const updateValidationProgress = () => {
    // update state according to completion-status and count
    const status: { complete: boolean; count: number } =
      checkValidationCompletion();
    setValidationState({
      processing: false,
      complete: status.complete,
      count: status.count
    });
  };

  // CONFLICTS CONTROL
  const checkConflictCompletion = () => {
    // count conflicts and set to complete if === 0
    let complete = true;
    let count = 0;
    tableData.forEach((dataSet) => {
      if (
        dataSet.misc.conflict !== undefined &&
        dataSet.misc.conflict !== "ok" &&
        dataSet.misc.conflict !== ""
      ) {
        count++;
        complete = false;
      }
    });
    return { complete, count };
  };

  const updateConflictProgress = () => {
    // update state according to completion-status and count
    const status: { complete: boolean; count: number } =
      checkConflictCompletion();
    setConflictState({
      fetching: false,
      processing: false,
      complete: status.complete,
      count: status.count
    });
  };

  const checkForConflicts = async (data: Record<string, unknown>[]) => {
    // check conflicts in backend
    validateData(data);
    if (validationState.complete) {
      // if no validation violations
      const response: any = await checkConflicts(data);
      if (response) {
        setTableData(response);
      }
      updateConflictProgress();
    }
  };

  // HANDLES
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeValue = (index: number, key: string, newValue: string) => {
    // save edited datavalue
    if (!isNaN(index) && key && newValue) {
      const newData = tableData;
      newData[index][key] = newValue;
      setTableData(newData);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleMapping = (value: string, index: number) => {
    // save mapped column
    const mappingArray = mappingArr;
    mappingArray[index] = value;
    setMappingArr(mappingArray);
    updateMappingProgress();
  };

  const handleMappingDone = () => {
    // mapping done: overwrite keys in datasets
    const hasHeaders = Object.keys(mappedHeaders).length > 1;
    if (
      mappingState.complete &&
      !mappingState.duplicates &&
      hasHeaders &&
      !dataIsModified
    ) {
      renameTableKeys(tableData, mappedHeaders);
      storeNewRows(tableData);
      setValidationState({
        processing: true,
        complete: validationState.complete,
        count: validationState.count
      });
      setPage(page);
    }
  };

  const handleCsvDownload = () => {
    const conflicts = tableData
      ?.filter((dataset) => dataset.misc.conflict !== "ok")
      ?.map((dataset) => {
        const copy = { ...dataset };
        copy.conflict = getInfoMessage(copy.misc.conflict);
        delete copy.misc;
        return copy;
      });
    const d = new Date();
    const date = `${d.getFullYear().toString().substring(-2)}${(
      "0" +
      (d.getMonth() + 1)
    ).slice(-2)}${("0" + d.getDate()).slice(-2)}`;
    const csvConfig = {
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalSeparator: ".",
      filename: `${date}_Konflikte_${fileName.split(".")[0]}`,
      useTextFile: false
    };
    if (conflicts.length > 0) {
      // TODO: export csv of conflicts
      throw new Error("unavailable");
    } else {
      enqueueSnackbar("Keine Konflikte gefunden.", getNotiOptions("warning"));
    }
  };

  useEffect(() => {
    storeNewRows(tableData);
  }, [storeNewRows, tableData]);

  useEffect(() => {
    // if headers have been mapped, store them.
    const hasHeaders = Object.keys(mappedHeaders).length > 1;
    if (mappingState.complete && !mappingState.duplicates && !hasHeaders) {
      storeHeaders();
    }
  });

  useEffect(() => {
    // complete mapping if headers have been stored
    const hasHeaders = Object.keys(mappedHeaders).length > 1;
    if (
      mappingState.complete &&
      !mappingState.duplicates &&
      hasHeaders &&
      activeStep === 3
    ) {
      handleMappingDone();
    }
  });

  useEffect(() => {
    // check for conflicts if state is set to processing
    if (
      activeStep === 4 &&
      conflictState.processing &&
      !conflictState.fetching
    ) {
      setConflictState({
        fetching: true,
        processing: conflictState.processing,
        complete: conflictState.complete,
        count: conflictState.count
      });
      checkForConflicts(tableData);
    }
  });

  useEffect(() => {
    // validate data if state is set to "processing"
    if ((activeStep === 3 || activeStep === 4) && validationState.processing) {
      validateData(tableData);
    }
  });

  useEffect(() => {
    // go back to step 3 if validation violations detected
    if (activeStep >= 4 && !validationState.complete) {
      setActiveStep(3);
    }
  });

  useEffect(() => {
    // reload if import is done
    if (importState.fetched) {
      setActiveStep(6);
    }
  });

  useEffect(() => {
    // move to next step if no validation violations
    if (
      activeStep === 3 &&
      validationState.complete &&
      validationState.count === 0
    ) {
      setConflictState({
        fetching: conflictState.fetching,
        processing: true,
        complete: conflictState.complete,
        count: conflictState.count
      });
      setActiveStep(4);
    }
  }, [
    activeStep,
    conflictState,
    setActiveStep,
    setConflictState,
    validationState
  ]);

  useEffect(() => {
    //  go back to step 4 if there are no participants without conflict left
    if (
      activeStep === 5 &&
      tableData?.filter((dataset) => dataset?.misc?.conflict === "ok")
        .length === 0
    ) {
      setActiveStep(4);
    }
  }, [activeStep, setActiveStep, tableData]);

  // RENDER
  return (
    <div className={classes.root}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {activeStep === 2 ? (
                Object.keys(columns).map((column, i) => {
                  return (
                    <TableCell
                      key={i}
                      className={`${classes.tableHeadCell} ${classes.tableCell}`}
                    >
                      <FormControl
                        color="secondary"
                        fullWidth
                        className={classes.form}
                      >
                        <Select
                          defaultValue={""}
                          autoWidth
                          value={mappingArr[i]}
                          onChange={(e) => {
                            if (typeof e.target.value === "string") {
                              handleMapping(e.target.value, i);
                            }
                          }}
                        >
                          <MenuItem value={""}>-</MenuItem>
                          {Object.entries(headers)
                            ?.filter(([key]) => mappingArr.indexOf(key) === i)
                            ?.map(([key, value], i) => {
                              return (
                                <MenuItem key={i} value={key || ""}>
                                  {value || ""}
                                </MenuItem>
                              );
                            })}
                          {Object.entries(headers)
                            ?.filter(([key]) => !mappingArr.includes(key))
                            ?.map(([key, value], i) => {
                              return (
                                <MenuItem key={i} value={key || ""}>
                                  {value || ""}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </TableCell>
                  );
                })
              ) : (
                <>
                  {(activeStep === 3 || activeStep === 4 || activeStep === 5) &&
                  mappingState.complete &&
                  mappedHeaders ? (
                    Object.entries(mappedHeaders).map(
                      ([key, value]: any, i) => {
                        return (
                          <TableCell
                            key={i}
                            variant={"head"}
                            className={classes.tableCell}
                          >
                            <>{value.name}</>
                          </TableCell>
                        );
                      }
                    )
                  ) : (
                    <></>
                  )}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {activeStep === 2 ? (
              tableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((dataset, i) => {
                  return (
                    <TableRow key={i}>
                      {Object.entries(dataset)
                        ?.filter(([key]) => key !== "misc")
                        ?.map(([key], i) => {
                          return (
                            <TableCell key={i} className={classes.tableCell}>
                              <>{dataset[key]}</>
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  );
                })
            ) : activeStep === 3 ? (
              tableData
                ?.filter((dataset) => dataset.misc?.invalid?.length > 0)
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((dataset) => {
                  return (
                    <TableRow key={dataset.misc?.id}>
                      {Object.entries(dataset).map(([key], i) => {
                        if (key !== "misc") {
                          return (
                            <TableCell key={i} className={classes.tableCell}>
                              <TextField
                                variant="outlined"
                                margin="dense"
                                defaultValue={tableData[dataset.misc?.id][key]}
                                label={
                                  dataset.misc?.invalid?.includes(key)
                                    ? "Ungültig"
                                    : ""
                                }
                                error={dataset.misc?.invalid?.includes(key)}
                                onChange={(e) => {
                                  handleChangeValue(
                                    dataset.misc?.id,
                                    key,
                                    e.target.value
                                  );
                                }}
                              />
                            </TableCell>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </TableRow>
                  );
                })
            ) : activeStep === 4 &&
              !conflictState.processing &&
              !conflictState.fetching &&
              tableData.length > 0 ? (
              tableData
                ?.filter((dataset) => dataset?.misc?.conflict !== "ok")
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((dataset) => {
                  return (
                    <TableRow key={dataset.misc.id}>
                      {Object.entries(dataset).map(([key], i) => {
                        if (key !== "misc") {
                          return (
                            <TableCell key={i} className={classes.tableCell}>
                              <TextField
                                variant="outlined"
                                margin="dense"
                                defaultValue={tableData[dataset.misc.id][key]}
                                onChange={(e) => {
                                  handleChangeValue(
                                    dataset.misc?.id,
                                    key,
                                    e.target.value
                                  );
                                }}
                              />
                            </TableCell>
                          );
                        } else if (key === "misc") {
                          return (
                            <TableCell
                              key={i}
                              className={classes.iconTableCell}
                            >
                              <Tooltip
                                title={getInfoMessage(dataset[key].conflict)}
                                placement="left"
                              >
                                <ErrorOutlineIcon
                                  className={classes.infoIcon}
                                  fontSize="large"
                                />
                              </Tooltip>
                            </TableCell>
                          );
                        } else {
                          return <LoadingAnimation />;
                        }
                      })}
                    </TableRow>
                  );
                })
            ) : activeStep === 5 && tableData.length > 0 ? (
              tableData
                ?.filter((dataset) => dataset.misc.conflict === "ok")
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((dataset, i) => {
                  return (
                    <TableRow key={i}>
                      {Object.entries(dataset)
                        ?.filter(([key]) => key !== "misc")
                        ?.map(([key, value], i) => {
                          return (
                            <TableCell key={i} className={classes.tableCell}>
                              {value === "x" ? (
                                <Radio checked />
                              ) : value === "-" ? (
                                <Radio checked={false} disabled />
                              ) : (
                                <>{dataset[key]}</>
                              )}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  );
                })
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        {activeStep === 4 && (
          <Button
            color="secondary"
            className={classes.button}
            variant="outlined"
            onClick={handleCsvDownload}
          >
            Download .CSV
          </Button>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage="Einträge pro Seite"
        labelDisplayedRows={() => (
          <div className={classes.paginationLabel}>
            <div className={classes.chipBox}>
              {(activeStep === 3 || activeStep === 4) && (
                <Chip
                  label={`${
                    activeStep === 3
                      ? tableData.filter(
                          (dataset) => dataset.misc?.invalid?.length > 0
                        ).length
                      : activeStep === 4
                      ? tableData?.filter(
                          (dataset) => dataset?.misc?.conflict !== "ok"
                        ).length
                      : rows.length
                  } ${
                    activeStep === 3
                      ? "Fehler"
                      : activeStep === 4
                      ? "Konflikte"
                      : ""
                  }`}
                  size="small"
                  className={`${classes.total} ${
                    activeStep === 3
                      ? classes.error
                      : activeStep === 4
                      ? classes.conflict
                      : ""
                  }`}
                />
              )}
              {activeStep > 4 && activeStep < 6 && (
                <Chip
                  label={`${
                    tableData?.filter(
                      (dataset) =>
                        dataset?.misc?.invalid?.length === 0 &&
                        dataset?.misc?.conflict === "ok"
                    ).length
                  } OK`}
                  size="small"
                  className={classes.ok}
                />
              )}
              <Chip
                label={`${tableData.length} Gesamt`}
                size="small"
                className={classes.total}
              />
            </div>
            <Typography>Seite {page + 1}</Typography>
          </div>
        )}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default ImportDataGrid;
