import axios, { AxiosResponse } from "axios";
import { IPositionData, IDetails } from "../components/dialogs/EditPosition";
import { setServerUrl } from "../constants/constants";
import { IPositionModel } from "../models/IPositionModel";
import { IProjectModel } from "../models/IProjectModel";

export interface IPositionImport {
  ot?: {
    positionData: IPositionData[];
    normId: string;
  };
  pt?: {
    surveyId?: string;
  };
}

const ProjectService = {
  async getProjects() {
    const rsp: AxiosResponse<IProjectModel[]> = await axios.get<
      IProjectModel[]
    >(setServerUrl(`/api/project`, `/api/project`));
    return Promise.resolve(rsp.data);
  },
  async getProjectPositions(projectId: string) {
    const rsp: AxiosResponse<IPositionModel[]> = await axios.get<
      IPositionModel[]
    >(
      setServerUrl(
        `/api/project/position/${projectId}`,
        `/api/project/position/${projectId}`
      )
    );
    return Promise.resolve(rsp.data);
  },
  async createProjectPosition(
    projectId: string,
    data: IPositionImport,
    details: IDetails
  ) {
    const rsp = await axios.post(
      setServerUrl(`/api/position/${projectId}`, `/api/position/${projectId}`),
      { data, details }
    );
    return Promise.resolve(rsp.data);
  },
  async deleteProjectPosition(positionId: string) {
    const rsp = await axios.delete(
      setServerUrl(`/api/position/${positionId}`, `/api/position/${positionId}`)
    );
    return Promise.resolve(rsp.data);
  },
  async getUserOffices(userId: string) {
    const rsp: AxiosResponse = await axios.get(
      setServerUrl(`/api/user/offices/${userId}`, `/api/user/offices/${userId}`)
    );
    return Promise.resolve(rsp.data.data);
  },
  async deleteProject(projectId: string) {
    const rsp = await axios.delete(
      setServerUrl(`/api/project/${projectId}`, `/api/project/${projectId}`)
    );
    return Promise.resolve(rsp.data);
  },
  async getProjectById(projectId: string): Promise<any> {
    const rsp = await axios.get(
      setServerUrl(`/api/project/${projectId}`, `/api/project/${projectId}`)
    );
    return Promise.resolve(rsp.data.data);
  },
  async updateProject({
    id,
    longName,
    shortName,
    validFrom,
    validUntil,
    Offices,
    eulaSurvey,
    exportType,
    branding,
    dataSharing,
    customerId
  }: IProjectModel) {
    const rsp = await axios.put(setServerUrl(`/api/project`, `/api/project`), {
      id,
      longName,
      shortName,
      validFrom,
      validUntil,
      Offices,
      exportType,
      eulaSurvey,
      branding,
      dataSharing,
      customerId
    });
    return Promise.resolve(rsp.data);
  },
  async archiveProject(projectId: string) {
    const rsp = await axios.delete(
      setServerUrl(
        `/api/project/archive/${projectId}`,
        `/api/project/archive/${projectId}`
      )
    );
    return Promise.resolve(rsp.data);
  },
  async recalculateProject(projectId: string) {
    const rsp = await axios.put(
      setServerUrl(`/api/project/recalculate`, `/api/project/recalculate`),
      { projectId }
    );
    return Promise.resolve(rsp.data);
  }
};

export default ProjectService;
