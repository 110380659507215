import axios, { AxiosResponse } from "axios";
import { setServerUrl } from "../constants/constants";
import { ICompetencyModel } from "../models/ICompetencyModel";
import { ICustomerModel } from "../models/ICustomerModel";
import { IOfficeModel } from "../models/IOfficeModel";
import { IUserModel } from "../models/IUserModel";

const CustomerService = {
  // get all customers
  async getCustomers(): Promise<ICustomerModel[]> {
    const rsp = await axios.get(
      setServerUrl("/api/customer/all", "/api/customer/all")
    );
    return Promise.resolve(rsp.data.data);
  },
  // get a customer by Id
  async getCustomerById(customerId: string) {
    const rsp = await axios.get(
      setServerUrl(`/api/customer/${customerId}`, `/api/customer/${customerId}`)
    );
    return Promise.resolve(rsp.data.data);
  },
  // update a customer
  async updateCustomer({
    id,
    longName,
    shortName,
    color,
    showResults,
    revaluation,
    reports
  }: ICustomerModel) {
    const rsp = await axios.put(
      setServerUrl(`/api/customer`, `/api/customer`),
      { id, longName, shortName, color, showResults, revaluation, reports }
    );
    return Promise.resolve(rsp.data);
  },
  // create a customer
  async createCustomer(data: ICustomerModel) {
    const rsp = await axios.post(
      setServerUrl(`/api/customer`, `/api/customer`),
      { data }
    );
    return Promise.resolve(rsp.data);
  },
  // delete a customer
  async deleteCustomer(customerId: string) {
    const rsp = await axios.delete(
      setServerUrl(`/api/customer/${customerId}`, `/api/customer/${customerId}`)
    );
    return Promise.resolve(rsp.data);
  },
  // get all offices
  async getAllOffices(): Promise<IOfficeModel[]> {
    const rsp = await axios.get(
      setServerUrl("/api/office/all", "/api/office/all")
    );
    return Promise.resolve(rsp.data.data);
  },
  // get all offices
  async getCustomerOffices(customerId: string): Promise<IOfficeModel[]> {
    const rsp = await axios.get(
      setServerUrl(
        `/api/office/customer/${customerId}`,
        `/api/office/customer/${customerId}`
      )
    );
    return Promise.resolve(rsp.data.data);
  },
  // get a office by Id
  async getOfficeById(officeId: string) {
    const rsp = await axios.get(
      setServerUrl(`/api/office/${officeId}`, `/api/office/${officeId}`)
    );
    return Promise.resolve(rsp.data.data);
  },
  // create an office
  async createOffice(data: IOfficeModel) {
    const rsp = await axios.put(setServerUrl(`/api/office`, `/api/office`), {
      data
    });
    return Promise.resolve(rsp.data);
  },
  // delete an office
  async deleteOffice(officeId: string) {
    const rsp = await axios.delete(
      setServerUrl(`/api/office/${officeId}`, `/api/office/${officeId}`)
    );
    return Promise.resolve(rsp.data);
  },
  // update an office
  async updateOffice({
    id,
    longName,
    shortName,
    color,
    customerId
  }: IOfficeModel) {
    const rsp = await axios.put(setServerUrl(`/api/office`, `/api/office`), {
      id,
      longName,
      shortName,
      color,
      customerId
    });
    return Promise.resolve(rsp.data);
  },
  // get all users
  async getUsers(customerId: string): Promise<IUserModel[]> {
    const rsp: AxiosResponse<IUserModel[]> = await axios.get<IUserModel[]>(
      setServerUrl(
        `/api/customer/${customerId}/user`,
        `/api/customer/${customerId}/user`
      )
    );
    return Promise.resolve(rsp.data);
  },
  // update a user
  async updateUser(
    customerId: string,
    userId: string | number,
    data: IUserModel
  ): Promise<IUserModel[]> {
    const rsp: AxiosResponse<IUserModel[]> = await axios.post<IUserModel[]>(
      setServerUrl(
        `/api/customer/${customerId}/user/${userId}`,
        `/api/customer/${customerId}/user/${userId}`
      ),
      {
        data
      }
    );
    return Promise.resolve(rsp.data);
  },
  // delete a user
  async deleteUser(
    customerId: string,
    userId: string | number
  ): Promise<IUserModel[]> {
    const rsp: AxiosResponse<IUserModel[]> = await axios.delete<IUserModel[]>(
      setServerUrl(
        `/api/customer/${customerId}/user/${userId}`,
        `/api/customer/${customerId}/user/${userId}`
      )
    );
    return Promise.resolve(rsp.data);
  },
  // get all rvTypes
  async getAllRvTypes() {
    const rsp = await axios.get(
      setServerUrl(`/api/position/rvtype`, `/api/position/rvtype`)
    );
    return Promise.resolve(rsp.data.data);
  },
  // get all competencies
  async getAllCompetencies() {
    const rsp = await axios.get(
      setServerUrl(`/api/competency/all`, `/api/competency/all`)
    );
    return Promise.resolve(rsp.data.data);
  },
  // create a competency
  async createOrUpdateCompetency({
    id,
    shortName,
    longName,
    description,
    type
  }: ICompetencyModel) {
    const rsp = await axios.put(
      setServerUrl(`/api/competency`, `/api/competency`),
      {
        id,
        shortName,
        longName,
        description,
        type
      }
    );
    return Promise.resolve(rsp.data);
  },
  // delete a competency
  async deleteCompetency(competencyId: string) {
    const rsp = await axios.delete(
      setServerUrl(
        `/api/competency/${competencyId}`,
        `/api/competency/${competencyId}`
      )
    );
    return Promise.resolve(rsp.data);
  }
};

export default CustomerService;
