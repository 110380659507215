/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Replay } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import WarningIcon from "@material-ui/icons/Warning";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { competencyTypes, regex } from "../../constants/constants";
import { useAsync } from "../../hooks/useAsync";
import { INormModel } from "../../models/INormModel";
import { IPosCompRvModel } from "../../models/IPosCompRvModel";
import { IPositionModel } from "../../models/IPositionModel";
import { IRawValueModel } from "../../models/IRawValueModel";
import { IRvTypeModel } from "../../models/IRvTypeModel";
import { ISurveyModel } from "../../models/ISurveyModel";
import ProjectService, { IPositionImport } from "../../services/ProjectService";
import SurveyService from "../../services/SurveyService";
import CustomerStore from "../../stores/CustomerStore";
import TabWrapper from "../containers/TabWrapper";
import { IAddDialog } from "../DataGrid";
import DeleteButton from "../DeleteButton";
import ChangeNorm from "./ChangeNorm";

interface IStrings {
  [key: string]: {
    [key: string]: string;
  };
}

enum ESteps {
  initial = 0,
  continue
}

interface INumberInput {
  [key: string]: {
    type: "number";
    step: number;
    max: number;
    min: number;
  };
}

const dialogStr: IStrings = {
  initial: {
    title: "Position erstellen",
    description: "Definieren Sie eine neue Position."
  },
  continue: {
    title: "Daten überprüfen",
    description: "Sind Sie sicher, dass Sie die Position erstellen wollen?"
  },
  delete: {
    compare: "löschen",
    description: 'Geben Sie "löschen" ein'
  }
};

const elemStr: IStrings = {
  header: {
    competency: "Kompetenz",
    weight: "Gewichtung\u00a0(%)"
  },
  select: {
    empty: "- Bitte wählen -"
  },
  button: {
    reset: "Reset",
    continue: "Weiter",
    back: "Zurück",
    confirm: "Bestätigen",
    abort: "Abbrechen",
    delete: "Löschen"
  },
  input: {
    longName: "Name",
    shortName: "Abkürzung"
  }
};

const notiStr: IStrings = {
  validate: {
    competency: "Bitte wählen Sie eine Kompetenz",
    rvType: "Bitte wählen Sie eine Aufgabengruppe",
    compSum: "Die Summe der Kompetenz-Gewichtungen muss 100% ergeben",
    rvTypeSum: "Bitte ordnen Sie jeder Übung mindestens eine Kompetenz zu",
    rvTypeSumRev: "Bitte ordnen Sie jeder Kompetenz mindestens eine Übung zu",
    shortName: "Abkürzung ungültig",
    longName: "Name ungültig"
  },
  delete: {
    success: "Löschen erfolgreich",
    fail: "Löschen fehlgeschlagen",
    conflict: "Es existieren Bewerbungen auf diese Position"
  },
  create: {
    success: "Speichern erfolgreich",
    fail: "Speichern fehlgeschlagen"
  }
};

const numberInput: INumberInput = {
  competency: {
    type: "number",
    step: 1,
    max: 100.0,
    min: 1.0
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "auto",
      height: "auto",
      minWidth: "550px",
      minHeight: "600px",
      maxHeight: "90vh"
    },
    // super hacky
    floatingButton: {
      position: "absolute",
      bottom: 60,
      right: 25,
      backgroundColor: "rgba(0,0,0,0)"
    },
    centered: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(2),
      width: "20%"
    },
    autocomplete: {
      width: "100%"
    },
    actions: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end"
    },
    title: {
      boxSizing: "border-box",
      textAlign: "center"
    },
    description: {
      boxSizing: "border-box",
      margin: "0px",
      padding: theme.spacing(2),
      textAlign: "center"
    },
    content: {
      padding: theme.spacing(2),
      boxSizing: "border-box",
      width: "100%"
    },
    divider: {
      width: "100%"
    },
    detailBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      caretColor: theme.palette.common.white
    },
    details: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: theme.spacing(2),
      gap: theme.spacing(4),
      boxSizing: "border-box"
    },
    detail: {
      minWidth: "150px"
    },
    select: {
      width: "120px",
      minWidth: "100px",
      fontSize: "0.8rem",
      paddingBottom: "15px"
    },
    selectEmpty: {
      fontStyle: "italic"
    },
    table: {
      boxShadow: theme.shadows[1],
      width: "fit-content"
    },
    header: {
      width: "fit-content",
      maxWidth: "120px",
      color: theme.palette.primary.light,
      fontSize: "0.85rem"
    },
    confirmBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    confirm: {
      marginTop: theme.spacing(2),
      minWidth: "200px"
    },
    btnBox: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      caretColor: theme.palette.common.white
    },
    tableBox: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%"
    },
    tableCell: {
      fontSize: "0.85rem",
      padding: theme.spacing(0.5)
    },
    input: {
      caretColor: theme.palette.common.black
    },
    sum: {
      fontWeight: "bold"
    },
    red: {
      color: theme.palette.error.main
    },
    yellow: {
      color: theme.palette.warning.main
    },
    lightYellow: {
      color: theme.palette.warning.light
    },
    green: {
      color: theme.palette.success.main
    }
  })
);

export interface IPositionData {
  competencyId: string;
  weight: number;
  posCompRvs: {
    rvTypeId: string;
    selected: number;
  }[];
}

export interface INormData {
  current: INormModel;
  new: INormModel;
}

export interface IDetails {
  shortName: string;
  longName: string;
}

interface IMapping {
  rows: {
    competencyId: string;
  }[];
  columns: {
    rvTypeId: string;
  }[];
}

interface ISums {
  comp: number;
  rvTypes: number[];
}

const emptyRow: { competencyId: string } = {
  competencyId: ""
};

const emptyColumn: { rvTypeId: string } = {
  rvTypeId: ""
};

const emptyMappingItem: IMapping = {
  rows: [emptyRow],
  columns: [emptyColumn]
};

const emptyDataItem: IPositionData = {
  competencyId: "",
  weight: 1.0,
  posCompRvs: [
    {
      rvTypeId: "",
      selected: 0.0
    }
  ]
};

const emptyDetails: IDetails = {
  shortName: "",
  longName: ""
};

const emptySums: ISums = {
  comp: 0.0,
  rvTypes: [0.0]
};

const emptyNormData: INormData = {
  current: {} as INormModel,
  new: {} as INormModel
};

const EditPosition: FC<IAddDialog> = ({ open, close, data }) => {
  const classes = useStyles();
  const history = useHistory();
  const customerStore = useContext(CustomerStore);

  const {
    getAllCompetencies,
    getAllRvTypes,
    getAllNorms,
    fetchedCompetencies,
    fetchedProject,
    fetchedNorms
  } = customerStore;
  const { createProjectPosition, deleteProjectPosition } = ProjectService;
  const { enqueueSnackbar } = useSnackbar();

  const [title, setTitle] = useState<string>(
    dialogStr.initial.title.toUpperCase()
  );
  const [description, setDescription] = useState<string>(
    dialogStr.initial.description
  );
  const [changeNormOpen, setChangeNormOpen] = useState<boolean>(false);
  const [step, setStep] = useState<number>(ESteps.initial);
  const [mapping, setMapping] = useState<IMapping>(emptyMappingItem);
  const [sums, setSums] = useState<ISums>(emptySums);
  const [positionData, setPositionData] = useState<IPositionData[]>([
    emptyDataItem
  ]);
  const [rvTypes, setRvTypes] = useState<IRvTypeModel[]>([]);
  const [normData, setNormData] = useState<INormData>({
    current: {} as INormModel,
    new: {} as INormModel
  });
  const [details, setDetails] = useState<IDetails>(emptyDetails);
  const [inactive] = useState<boolean>(data ? true : false);

  const [surveys, setSurveys] = useState<ISurveyModel[]>([]);
  const [ptSurvey, setPtSurvey] = useState<ISurveyModel>();

  const { getAllSurveys } = SurveyService;

  const getSurveys = async () => {
    const { data } = await getAllSurveys();
    return data;
  };

  useAsync<ISurveyModel[]>(getSurveys, (srvs) => {
    // make this extra type safe because getAllSurveys isn't typed properly (yet)
    setSurveys(srvs || []);
    if (srvs && data) {
      const survey = srvs.find((survey) => survey.id === data?.ptSurveyId);
      setPtSurvey(survey);
    }
  });

  // ==== STATE ====
  const resetShared = () => {
    setStep(ESteps.initial);
    // setDetails(emptyDetails);
  };

  const handleOnlineTestReset = () => {
    resetShared();

    setPositionData([emptyDataItem]);
    setMapping(emptyMappingItem);
    setSums(emptySums);
    setNormData(emptyNormData);
    setRvTypes([]);
  };

  const handlePersonalityTestReset = () => {
    resetShared();

    setPtSurvey(undefined);
  };

  const handleNormReset = (norm: INormModel) => {
    setStep(ESteps.initial);
    setPositionData([emptyDataItem]);
    setMapping(emptyMappingItem);
    setSums(emptySums);
    setNormData({
      current: norm,
      new: {} as INormModel
    });
    setRvTypes([
      ...new Map(
        norm.RawValues.map((rv: IRawValueModel) => [
          rv.RvTypes[0].id,
          rv.RvTypes[0]
        ])
      ).values()
    ]);
    setChangeNormOpen(false);
  };

  const handleClose = () => {
    close();
    setStep(ESteps.initial);
    setTitle(dialogStr.initial.title);
    setDescription(dialogStr.initial.description);
  };

  const handleInitialPositionData = (initialData: IPositionModel) => {
    const { shortName, longName, PosComps, Norm } = initialData;

    setDetails({
      shortName,
      longName
    });

    if (PosComps && PosComps.length > 0) {
      const allRvTypeIds: string[] = [];
      PosComps.forEach((posComp) =>
        posComp.PosCompRvs.forEach((posCompRv) => {
          if (!allRvTypeIds.includes(posCompRv.rvTypeId)) {
            allRvTypeIds.push(posCompRv.rvTypeId);
          }
        })
      );

      const initialPosComps = PosComps.map((posComp: any) => ({
        competencyId: posComp.competencyId,
        weight: Math.floor(posComp.weight * 100),
        posCompRvs: allRvTypeIds.map((rvTypeId) => ({
          rvTypeId,
          selected: posComp.PosCompRvs.map(
            (posCompRv: IPosCompRvModel) => posCompRv.rvTypeId
          ).includes(rvTypeId)
            ? 1
            : 0
        }))
      }));
      setPositionData(initialPosComps);
      setMapping({
        columns: allRvTypeIds.map((rvTypeId) => ({ rvTypeId })),
        rows: PosComps.map((posComp) => ({
          competencyId: posComp.competencyId
        }))
      });
    }
    if (Norm) {
      setNormData({ ...normData, current: Norm });

      const fetchedNormData = fetchedNorms.find(
        (norm) => norm.id === data?.Norm?.id
      );

      // This sucks but because fetchedNormData is an observable types do not match
      if (fetchedNormData) {
        setRvTypes([
          ...new Map(
            fetchedNormData.RawValues.map((rv: IRawValueModel) => [
              rv.RvTypes[0].id,
              rv.RvTypes[0]
            ])
          ).values()
        ]);
      }
    }
  };

  // ==== UNTILITY ====
  const handleDelete = async (allowDelete: boolean) => {
    if (allowDelete) {
      const res = await deleteProjectPosition(data.id);
      const statusCode = res.status_code;
      if (statusCode === 1000) {
        enqueueSnackbar(notiStr.delete.success, getNotiOptions("success"));
        history.go(0);
      } else if (statusCode === 1009) {
        enqueueSnackbar(
          `${notiStr.delete.fail} ${notiStr.delete.conflict}`,
          getNotiOptions("error")
        );
      } else {
        enqueueSnackbar(notiStr.delete.fail, getNotiOptions("error"));
      }
    }
  };

  const handleCreatePosition = async () => {
    const importPositionData: IPositionImport = {};

    if (isNormDataUnitialized()) {
      importPositionData.ot = {
        positionData,
        normId: normData.current.id
      };
    }

    if (ptSurvey) {
      importPositionData.pt = {
        surveyId: ptSurvey.id
      };
    }

    const res = await createProjectPosition(
      fetchedProject.id,
      importPositionData,
      details
    );
    if (res.status_code === 1000) {
      enqueueSnackbar(notiStr.create.success, getNotiOptions("success"));
      history.go(0);
    } else {
      enqueueSnackbar(notiStr.create.fail, getNotiOptions("error"));
    }
  };

  const handleSums = () => {
    const newRvTypeSum = mapping.columns
      .map((col, i) => {
        let sum = positionData
          .map((item) =>
            item.posCompRvs[i]?.selected ? item.posCompRvs[i]?.selected : 0
          )
          .reduce((a, b) => a + b);
        return sum === 0 ? 0 : Number((1 / sum).toFixed(2));
      })
      .flat(1);
    const newCompetencySum = Number(
      positionData
        .map((item) => item.weight)
        .reduce((a, b) => a + b)
        .toFixed(2)
    );
    setSums({
      rvTypes: newRvTypeSum,
      comp: newCompetencySum
    });
  };

  const isNormDataUnitialized = () => {
    return Object.keys(normData.current).length > 0;
  };

  const validate = () => {
    let valid: boolean = true;
    let errors: string[] = [];
    positionData.forEach((item) => {
      let rowSum = 0;
      if (!item.competencyId) {
        valid = false;
        errors.push(notiStr.validate.competency);
      }
      item.posCompRvs.forEach((posCompRv) => {
        rowSum += posCompRv.selected;
        if (!posCompRv.rvTypeId) {
          valid = false;
          errors.push(notiStr.validate.rvType);
        }
      });
      if (rowSum === 0) {
        valid = false;
        errors.push(notiStr.validate.rvTypeSumRev);
      }
    });
    if (sums.comp !== 100) {
      valid = false;
      errors.push(notiStr.validate.compSum);
    }
    sums.rvTypes.forEach((sum) => {
      if (sum === 0) {
        valid = false;
        errors.push(notiStr.validate.rvTypeSum);
      }
    });

    if (!valid) {
      errors.forEach((error) => {
        enqueueSnackbar(error, getNotiOptions("error"));
      });
    }

    return { valid, errors: [...new Set(errors)] };
  };

  const handlePropagation = (direction: "next" | "back") => {
    let validSubmission = false;

    console.log(normData);

    if (ptSurvey) {
      // todo: any other verification needed?
      validSubmission = true;
    }

    // only validate online-test inputs if any are given
    // - if omitted, only a personality survey should be created
    if (Object.keys(normData.current).length || !ptSurvey) {
      const validation = validate();
      validSubmission = validation.valid;
    }

    if (validSubmission) {
      const newStep = direction === "next" ? step + 1 : step - 1;
      if (ESteps[newStep]) {
        setStep(newStep);
        setTitle(dialogStr[ESteps[newStep]].title);
        setDescription(dialogStr[ESteps[newStep]].description);
      }
    }
  };

  const handleCompetency = (event: any, index: number) => {
    const competencyId = event.target.value;
    let newRows = [...mapping.rows];
    let newData = [...positionData];
    // set mapping
    newRows[index] = { ...newRows[index], competencyId };
    setMapping({
      ...mapping,
      rows: newRows
    });
    // set positionData
    newData[index] = { ...newData[index], competencyId };
    setPositionData(newData);
  };

  const handleRvType = (event: any, columnIndex: number) => {
    const rvTypeId = event.target.value;
    let newColumns = [...mapping.columns];
    let newData = [...positionData];
    // set mapping
    newColumns[columnIndex] = { ...newColumns[columnIndex], rvTypeId };
    setMapping({
      ...mapping,
      columns: newColumns
    });
    // set positionData
    newData.forEach((dataItem) => {
      const newPosCompRvs = dataItem.posCompRvs;
      newPosCompRvs[columnIndex] = { ...newPosCompRvs[columnIndex], rvTypeId };
    });
    setPositionData(newData);
  };

  const handleNorm = (e: any) => {
    const norm = fetchedNorms.find((norm) => norm.id === e.target.value);
    if (norm) {
      if (Object.keys(normData.current).length === 0) {
        handleNormReset(norm);
      } else {
        setNormData({ ...normData, new: norm });
        setChangeNormOpen(true);
      }
    }
  };

  const handleWeight = (
    value: number,
    type: "competency" | "posComp",
    rowIndex: number,
    columnIndex: number | null
  ) => {
    const competencyId = mapping.rows[rowIndex].competencyId;
    switch (type) {
      case "competency":
        // set positionData
        let newData = [...positionData];
        newData[rowIndex] = {
          ...newData[rowIndex],
          weight: value > 0 ? value : 1,
          competencyId
        };
        setPositionData(newData);
        break;
      case "posComp":
        if (columnIndex !== null) {
          // set positionData
          let newData = [...positionData];
          let newElem = { ...newData[rowIndex] };
          let newPosCompRvs = [...newElem.posCompRvs];
          let newPosCompRv = { ...newPosCompRvs[columnIndex] };
          newPosCompRv.selected = value;
          newPosCompRvs[columnIndex] = newPosCompRv;
          newElem.posCompRvs = newPosCompRvs;
          newData[rowIndex] = newElem;
          setPositionData(newData);
        }
        break;
      default:
        break;
    }
  };

  const handleAdd = (type: string) => {
    let newRows = [...mapping.rows];
    let newColumns = [...mapping.columns];
    let newData = [...positionData];
    let newRvTypeSum = [...sums.rvTypes];

    if (mapping.rows.map((row) => row.competencyId).includes("")) {
      enqueueSnackbar(notiStr.validate.competency, getNotiOptions("warning"));
    } else if (mapping.columns.map((col) => col.rvTypeId).includes("")) {
      enqueueSnackbar(notiStr.validate.rvType, getNotiOptions("warning"));
    } else {
      switch (type) {
        case "row":
          if (
            mapping.rows.length <
            fetchedCompetencies.filter((comp) => comp.type === "OT").length
          ) {
            // set mapping
            newRows.push(emptyRow);
            setMapping({ ...mapping, rows: newRows });
            // set positionData
            let newDataItem = emptyDataItem;
            let newPosCompRvs = mapping.columns.map((col) => ({
              rvTypeId: col.rvTypeId,
              selected: 0
            }));
            newDataItem.posCompRvs = [...newPosCompRvs];
            setPositionData([...newData, { ...newDataItem }]);
          }
          break;
        case "column":
          if (mapping.columns.length < rvTypes.length) {
            // set mapping
            newColumns.push(emptyColumn);
            setMapping({ ...mapping, columns: newColumns });
            // set sum
            newRvTypeSum.push(0.0);
            setSums({ ...sums, rvTypes: newRvTypeSum });
            // set positionData
            newData.forEach((item) => {
              let newPosCompRvs = [
                ...item.posCompRvs,
                { selected: 0, rvTypeId: "" }
              ];
              item.posCompRvs = newPosCompRvs;
            });
            setPositionData([...newData]);
          }
          break;
        default:
          break;
      }
    }
  };

  // ==== USE-EFFECTS ====
  useEffect(() => {
    getAllCompetencies();
  }, [getAllCompetencies]);

  useEffect(() => {
    getAllRvTypes();
  }, [getAllRvTypes]);

  useEffect(() => {
    getAllNorms();
  }, [getAllNorms]);

  useEffect(() => {
    handleSums();
  }, [positionData]);

  useEffect(() => {
    if (data) {
      handleInitialPositionData(data);
    }
  }, []);

  // ==== CONTENT ====
  const getHeaders = () => {
    return (
      <TableRow>
        <TableCell className={classes.tableCell} size="small">
          <Typography className={classes.header}>
            {elemStr.header.weight}
          </Typography>
        </TableCell>
        <TableCell size="small" className={classes.tableCell}>
          <Typography className={classes.header}>
            {elemStr.header.competency}
          </Typography>
        </TableCell>
        {mapping.columns.map((column, i) => {
          return (
            <TableCell className={classes.tableCell} size="small">
              {/* <p>{rvTypes.length}</p> */}
              <Select
                disabled={
                  inactive ||
                  !mapping.rows.some((row) => row.competencyId.length > 0) ||
                  step > 0 ||
                  !normData?.current?.id
                }
                className={`${classes.select} ${classes.header}`}
                fullWidth
                value={
                  mapping.columns[i].rvTypeId
                    ? mapping.columns[i].rvTypeId
                    : "-"
                }
                onChange={(e) => handleRvType(e, i)}
              >
                {!mapping.columns[i].rvTypeId && (
                  <MenuItem value="-">
                    <span className={classes.selectEmpty}>
                      {elemStr.select.empty}
                    </span>
                  </MenuItem>
                )}
                {rvTypes
                  ?.filter(
                    (rvType) =>
                      column.rvTypeId === rvType.id ||
                      !mapping.columns
                        .map((c) => c.rvTypeId)
                        .includes(rvType.id)
                  )
                  ?.map((rvType, index) => {
                    return (
                      <MenuItem key={index} value={`${rvType.id}`}>
                        {rvType.longName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const getRows = () => {
    return (
      <>
        {mapping.rows.map((row, index) => (
          <TableRow key={index}>
            <TableCell className={classes.tableCell} size="small">
              <TextField
                className={classes.input}
                disabled={
                  inactive || !mapping.rows[index].competencyId || step > 0
                }
                onChange={(e) =>
                  handleWeight(
                    Number(e.currentTarget.value),
                    "competency",
                    index,
                    null
                  )
                }
                value={positionData[index].weight}
                size="small"
                inputProps={numberInput.competency}
              />
            </TableCell>
            <TableCell className={classes.tableCell} size="small">
              <Select
                disabled={
                  inactive || step > 0 || !Object.keys(normData?.current).length
                }
                fullWidth
                value={
                  mapping.rows[index].competencyId
                    ? mapping.rows[index].competencyId
                    : "-"
                }
                onChange={(e) => handleCompetency(e, index)}
                className={classes.select}
              >
                {!mapping.rows[index].competencyId && (
                  <MenuItem value="-">
                    <span className={classes.selectEmpty}>
                      {elemStr.select.empty}
                    </span>
                  </MenuItem>
                )}
                {fetchedCompetencies
                  ?.filter(
                    (comp) =>
                      comp.type === "OT" &&
                      (row.competencyId === comp.id ||
                        !mapping.rows
                          .map((r) => r.competencyId)
                          .includes(comp.id))
                  )
                  ?.map((comp, index) => (
                    <MenuItem key={index} value={`${comp.id}`}>
                      {comp.longName}
                    </MenuItem>
                  ))}
              </Select>
            </TableCell>
            {mapping.columns.map((column, i) => {
              return (
                <TableCell
                  className={classes.tableCell}
                  padding="checkbox"
                  size="small"
                  align="center"
                  key={i}
                >
                  <Checkbox
                    size="small"
                    disabled={
                      inactive ||
                      !column.rvTypeId ||
                      !mapping.rows[index].competencyId ||
                      step > 0
                    }
                    checked={positionData[index]?.posCompRvs[i]?.selected === 1}
                    onChange={(e) =>
                      handleWeight(
                        e.target.checked ? 1 : 0,
                        "posComp",
                        index,
                        i
                      )
                    }
                  />
                </TableCell>
              );
            })}
          </TableRow>
        ))}
        <TableRow>
          <TableCell
            size="small"
            className={`${classes.tableCell} ${classes.sum} ${
              sums.comp === 100
                ? classes.green
                : sums.comp < 100
                ? classes.yellow
                : classes.red
            }`}
          >
            {sums.comp.toFixed(1).toString().replace(".", ",")} %
          </TableCell>
        </TableRow>
      </>
    );
  };

  const detailsContent = () => {
    return (
      <div className={classes.detailBox}>
        <div className={classes.details}>
          <TextField
            className={`${classes.input} ${classes.detail}`}
            onChange={(e) =>
              setDetails({ ...details, longName: e.currentTarget.value })
            }
            label={elemStr.input.longName}
            value={details.longName}
            disabled={inactive || step > 0}
          />
          <TextField
            className={`${classes.input} ${classes.detail}`}
            onChange={(e) =>
              setDetails({ ...details, shortName: e.currentTarget.value })
            }
            label={elemStr.input.shortName}
            value={details.shortName}
            disabled={inactive || step > 0}
          />
        </div>
      </div>
    );
  };

  const gridContent = () => {
    const tabs: {
      headers: string[];
      components: JSX.Element[];
    } = {
      headers: [],
      components: []
    };

    if (data) {
      if (data.Norm) {
        tabs.headers.push(competencyTypes.OT);
        tabs.components.push(onlineTestGrid());
      }

      if (data.ptSurveyId) {
        tabs.headers.push(competencyTypes.PT);
        tabs.components.push(personalityTestGrid());
      }
    } else {
      tabs.headers = [competencyTypes.OT, competencyTypes.PT];
      tabs.components = [onlineTestGrid(), personalityTestGrid()];
    }

    return <TabWrapper headers={tabs.headers}>{tabs.components}</TabWrapper>;
  };

  const personalityTestGrid = () => (
    <>
      <div className={classes.tableBox}>
        <FormControl className={classes.select}>
          <InputLabel id="survey-select-label">Umfrage</InputLabel>
          <Select
            disabled={inactive || step > 0}
            labelId="survey-select-label"
            value={ptSurvey?.id || ""}
            onChange={(e) => {
              const ps = surveys.find((s) => s.id === e.target.value);
              setPtSurvey(ps);
            }}
          >
            <MenuItem value="">-</MenuItem>
            {surveys &&
              surveys
                .filter((survey) => survey.type === "PT")
                .map((survey) => (
                  <MenuItem value={survey.id}>{survey.description}</MenuItem>
                ))}
          </Select>
        </FormControl>
      </div>
      {!inactive && step === 0 && (
        <Fab
          className={classes.floatingButton}
          color="default"
          size="small"
          onClick={handlePersonalityTestReset}
        >
          <Replay />
        </Fab>
      )}
    </>
  );

  const onlineTestGrid = () => {
    return (
      <>
        <div className={classes.tableBox}>
          {/**
           * TODO the norm select needs to be styled better
           *
           * do not forget
           */}
          <FormControl className={classes.select}>
            <InputLabel id="norm-select-label">Norm</InputLabel>
            <Select
              labelId="norm-select-label"
              value={normData?.current?.id || "-"}
              autoWidth
              onChange={(e) => handleNorm(e)}
              disabled={inactive}
            >
              <MenuItem value="-">-</MenuItem>
              {fetchedNorms?.map((norm) => (
                <MenuItem value={norm.id} key={norm.id}>
                  <Tooltip title={norm.description}>
                    <span>{norm.longName}</span>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.btnBox}>
            <Table className={classes.table} size="small">
              <TableHead>{getHeaders()}</TableHead>
              <TableBody>{getRows()}</TableBody>
            </Table>
            {!inactive && step <= 0 && (
              <IconButton
                size="small"
                color="secondary"
                onClick={() => handleAdd("column")}
              >
                <AddIcon />
              </IconButton>
            )}
          </div>
          {!inactive && step <= 0 && (
            <IconButton
              size="small"
              color="secondary"
              onClick={() => handleAdd("row")}
            >
              <AddIcon />
            </IconButton>
          )}
        </div>
        {!inactive && step === 0 && (
          <Fab
            className={classes.floatingButton}
            color="default"
            size="small"
            onClick={handleOnlineTestReset}
          >
            <Replay />
          </Fab>
        )}
      </>
    );
  };

  const actionsContent = () => {
    const finalStep = Object.keys(ESteps).length / 2 - 1;
    return (
      <>
        {!inactive && (
          <>
            {step > 0 && (
              <Button
                className={classes.yellow}
                onClick={() => handlePropagation("back")}
                color="default"
                variant="text"
                size="small"
              >
                {elemStr.button.back}
              </Button>
            )}
            {step < finalStep && (
              <Button
                className={classes.green}
                onClick={() => handlePropagation("next")}
                color="default"
                variant="text"
                size="small"
              >
                {elemStr.button.continue}
              </Button>
            )}
          </>
        )}
        {inactive && (
          <DeleteButton
            compareString={dialogStr.delete.compare}
            buttonName={elemStr.button.delete}
            description={dialogStr.delete.description}
            callback={handleDelete}
          />
        )}
        <Button
          className={classes.red}
          onClick={handleClose}
          color="default"
          variant="text"
          size="small"
        >
          {elemStr.button.abort}
        </Button>
      </>
    );
  };

  const summaryContent = () => {
    return (
      <div className={classes.confirmBox}>
        <Button
          className={classes.confirm}
          onClick={handleCreatePosition}
          color="secondary"
          variant="contained"
          size="small"
          startIcon={<WarningIcon className={classes.lightYellow} />}
        >
          {elemStr.button.confirm}
        </Button>
      </div>
    );
  };

  // ==== MAIN ====
  return (
    <>
      <Dialog
        fullScreen
        transitionDuration={100}
        classes={{ paper: classes.root }}
        open={open}
        onClose={handleClose}
      >
        {!inactive && (
          <>
            <DialogTitle className={classes.title}>{title}</DialogTitle>
            <Divider className={classes.divider} component="span" />
            <Typography className={classes.description}>
              {description}
            </Typography>
            <Divider className={classes.divider} component="span" />
          </>
        )}
        <DialogContent className={classes.content}>
          {detailsContent()}
          {gridContent()}
          {step === 1 && summaryContent()}
        </DialogContent>
        <Divider className={classes.divider} component="span" />
        <DialogActions className={classes.actions}>
          {actionsContent()}
        </DialogActions>
      </Dialog>
      <ChangeNorm
        open={changeNormOpen}
        newNorm={normData.new}
        handleClose={() => setChangeNormOpen(false)}
        handleNormReset={(norm) => handleNormReset(norm)}
      />
    </>
  );
};

export default observer(EditPosition);

/*
  // step 1: remove Berufs-Parameter & Level
  //   * inputs
  //   * strings
  //   * state
  // step 2: add request to get all Norms
  //   * frontend
  //   * backend
  // step 3: dropdown to select a Norm (display: longName, tooltip: description)
    // * enable matrix when selected  
    // * reset everything if different norm has been selected
  // step 4: only display rvTypes linked to Norm
  //   * get rvTypes via relation to fetched norms
  // step 5: disable everything until norm has been selected
  step 6: add reset / switching norm confimration
*/
