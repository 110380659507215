import {
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Tooltip
} from "@material-ui/core";
import DateRange from "@material-ui/icons/DateRange";
import FunctionsIcon from "@material-ui/icons/Functions";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import StorageIcon from "@material-ui/icons/Storage";
import { useSnackbar } from "notistack";
import { FC, MouseEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { IApplicationModel } from "../../models/IApplicationModel";
import ParticipantService from "../../services/ParticipantService";
import ArchiveApplication from "./ArchiveApplication";
import EditApplication from "./EditApplication";
import RecalculateApplication from "./RecalculateApplication";

interface IApplicationMenu {
  participantId: string;
  application: IApplicationModel;
}

const str = {
  tool: {
    menu: "Menü",
    edit: {
      application: "Bewerbungsfrist anpassen"
    },
    archive: {
      application: {
        inactive: "Freigabe aufheben",
        active: "Zur archivierung freigeben"
      }
    },
    recalculate: {
      application: "Ergebnisse neu berechnen"
    }
  },
  noti: {
    archive: {
      active: {
        success: "Bewerbung zur Archivierung freigegeben",
        fail: "Archivierung fehlgeschlagen"
      },
      inactive: {
        success: "Freigabe aufgehoben",
        fail: "Aufheben fehlgeschlagen"
      }
    }
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  menuItem: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(3)
  },
  red: {
    color: theme.palette.error.main
  },
  yellow: {
    color: theme.palette.warning.main
  },
  grey: {
    color: theme.palette.info.main
  }
}));

const allClosed = {
  root: false,
  edit: false,
  archive: false,
  recalculate: false
};

const ApplicationMenu: FC<IApplicationMenu> = ({
  participantId,
  application
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { archiveApplication } = ParticipantService;
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLElement>();
  const [open, setOpen] = useState(allClosed);

  const handleArchiveApplication = async () => {
    const response = await archiveApplication(application?.id);
    if (response.status_code === 1000) {
      enqueueSnackbar(
        str.noti.archive[application?.archive ? "inactive" : "active"].success,
        getNotiOptions("success")
      );
      history.go(0);
    } else {
      enqueueSnackbar(
        str.noti.archive[application?.archive ? "active" : "inactive"].success,
        getNotiOptions("error")
      );
      setOpen(allClosed);
    }
  };

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setOpen({ ...open, root: !open.root });
  };

  const handleDialog = (
    type: "edit" | "archive" | "recalculate" | "root",
    state: boolean,
    e?: MouseEvent<HTMLElement>
  ) => {
    if (e) e.stopPropagation();
    setOpen({ ...open, [type]: state });
    setAnchorEl(undefined);
  };

  return (
    <>
      <Tooltip title={str.tool.menu} enterDelay={1000}>
        <IconButton size="small" onClick={handleClick}>
          <MoreHorizIcon className={classes.grey} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(e: MouseEvent<HTMLElement>) => handleDialog("root", false, e)}
      >
        <Tooltip title={str.tool.edit.application} enterDelay={1000}>
          <MenuItem
            onClick={(e) => handleDialog("edit", true, e)}
            className={classes.menuItem}
          >
            <DateRange className={classes.grey} />
            Gültigkeit
          </MenuItem>
        </Tooltip>
        <Tooltip
          enterDelay={1000}
          title={
            application.archive
              ? str.tool.archive.application.inactive
              : str.tool.archive.application.active
          }
        >
          <MenuItem
            className={classes.menuItem}
            onClick={(e) => handleDialog("archive", true, e)}
          >
            <StorageIcon
              className={application.archive ? classes.yellow : classes.grey}
            />
            Archivierung
          </MenuItem>
        </Tooltip>
        <Tooltip title={str.tool.recalculate.application} enterDelay={1000}>
          <MenuItem
            onClick={(e) => handleDialog("recalculate", true, e)}
            className={classes.menuItem}
          >
            <FunctionsIcon className={classes.grey} />
            Berechnung
          </MenuItem>
        </Tooltip>
      </Menu>
      {open.edit && (
        <EditApplication
          participantId={participantId ? participantId : ""}
          application={application}
          open={open.edit}
          toggleDialog={() => handleDialog("edit", false)}
        />
      )}
      {open.archive && (
        <ArchiveApplication
          active={application.archive}
          open={open.archive}
          close={() => handleDialog("archive", false)}
          archive={handleArchiveApplication}
        />
      )}
      {open.recalculate && (
        <RecalculateApplication
          open={open.recalculate}
          close={() => handleDialog("recalculate", false)}
          application={application}
        />
      )}
    </>
  );
};

export default ApplicationMenu;
