import {
  ControlPointDuplicateOutlined,
  PollOutlined
} from "@material-ui/icons";
import { FC, MouseEvent, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAsync } from "../hooks/useAsync";
import { IRvTypeModel } from "../models/IRvTypeModel";
import { RvTypeService } from "../services/RvTypeService";
import UserStore from "../stores/UserStore";
import DataGrid, { IDataGridProps } from "./DataGrid";
import Heading from "./Heading";
import Wrapper from "./containers/Wrapper";
import { CreateRvType } from "./dialogs/CreateRvType";

export const RvTypes: FC = () => {
  const history = useHistory();
  const { isAdmin } = useContext(UserStore);
  const { getAllRvTypes } = RvTypeService;

  const [rvTypes, setRvTypes] = useState<IRvTypeModel[]>([]);

  useAsync(async () => {
    const { data } = await getAllRvTypes();
    return data || [];
  }, setRvTypes);

  const handleRowClick = (
    _e: MouseEvent<HTMLButtonElement>,
    row: IRvTypeModel
  ) => {
    history.push(`/rvtype/${row.id}`);
  };

  const rvTypesDataGridProps: IDataGridProps = {
    pagingFilter: true,
    searchFilter: true,
    onRowClick: handleRowClick,
    tableButtons: [
      {
        tooltip: "Aufgabengruppe Erstellen",
        icon: () => <ControlPointDuplicateOutlined />,
        popup: CreateRvType,
        isFreeAction: true,
        disabled: !isAdmin
      }
    ],
    columns: [
      { align: "left", title: "Name", type: "string", field: "longName" },
      {
        align: "left",
        title: "Beschreibung",
        type: "string",
        field: "description"
      }
    ],
    data: rvTypes
  };

  return (
    <Wrapper>
      <Heading heading="Aufgabengruppen">
        <PollOutlined />
      </Heading>
      <DataGrid dense {...rvTypesDataGridProps} />
    </Wrapper>
  );
};
