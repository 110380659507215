import {
  Box,
  Button,
  createStyles,
  FormControl,
  makeStyles,
  Modal,
  Theme,
  Typography
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { IApplicationModel } from "../../models/IApplicationModel";
import ParticipantService from "../../services/ParticipantService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      gap: theme.spacing(4)
    },
    modal: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "75%",
      transform: "translate(-50%, -50%)",
      width: "fit-content",
      backgroundColor: "white",
      padding: theme.spacing(2),
      borderRadius: "5px"
    },
    btn: {
      color: theme.palette.primary.light
    },
    btnBox: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between"
    }
  })
);

export interface IRecalculateApplication {
  open: boolean;
  close: () => void;
  application: IApplicationModel;
}

const RecalculateApplicationDialog: FC<IRecalculateApplication> = ({
  open,
  close,
  application
}) => {
  const classes = useStyles();
  const { recalculateApplication } = ParticipantService;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  return (
    <Modal open={open} onClose={close} onBackdropClick={close}>
      <Box className={classes.modal}>
        <Typography variant="h6">Werte Neu Berechnen</Typography>
        <FormControl className={classes.formControl}>
          <Box className={classes.btnBox}>
            <Button
              onClick={async () => {
                const res = await recalculateApplication(application.id);
                if (res) {
                  history.go(0);
                  enqueueSnackbar(
                    "Z-Werte wurden erneut berechnet",
                    getNotiOptions("success")
                  );
                } else {
                  enqueueSnackbar(
                    "Fehler bei der Neuberechnung",
                    getNotiOptions("error")
                  );
                }
                close();
              }}
              variant="contained"
              color="secondary"
              className={classes.btn}
            >
              Bestätigen
            </Button>
          </Box>
        </FormControl>
      </Box>
    </Modal>
  );
};

export default observer(RecalculateApplicationDialog);
