import { Button, makeStyles, Theme, Typography } from "@material-ui/core";
import {
  ControlPointDuplicateOutlined,
  PollOutlined
} from "@material-ui/icons";
import { FC, useContext, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useAsync } from "../../hooks/useAsync";
import { IRawValueModel } from "../../models/IRawValueModel";
import { IFullRvTypeModel } from "../../models/IRvTypeModel";
import { RvTypeService } from "../../services/RvTypeService";
import UserStore from "../../stores/UserStore";
import { RawValueGrid } from "../containers/RawValueGrid";
import Wrapper from "../containers/Wrapper";
import { EditOrCreateRawValue } from "../dialogs/EditOrCreateRawValue";
import { EditRvType } from "../dialogs/EditRvType";
import LoadingAnimation from "../LoadingAnimation";
import DetailsOverview from "./shared/DetailsOverview";

interface IRawValueDetailProps {
  id: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: "50px"
  }
}));

export const RvTypeDetail: FC<RouteComponentProps<IRawValueDetailProps>> = ({
  match: {
    params: { id }
  }
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { isAdmin } = useContext(UserStore);

  const { getRawValueType } = RvTypeService;

  const [rvType, setRawValue] = useState<IFullRvTypeModel>();
  const [editRvType, setEditRvType] = useState<boolean>(false);
  const [editRawValue, setEditRawValue] = useState<IRawValueModel>();

  useAsync<IFullRvTypeModel | undefined>(async () => {
    const { data } = await getRawValueType(id);
    return data;
  }, setRawValue);

  const handleBack = () => {
    history.push("/rvtypes");
  };

  const handleEdit = () => {
    setEditRvType(!editRvType);
  };

  return (
    <Wrapper>
      {rvType ? (
        <>
          <DetailsOverview
            icon={<PollOutlined />}
            displays={[
              {
                title: "Abkürzung",
                display: <Typography>{rvType.shortName}</Typography>
              },
              {
                title: "Beschreibung",
                display: <Typography>{rvType.description}</Typography>
              }
            ]}
            title={rvType.longName}
            allowEdits={isAdmin}
            editTooltip="Aufgabengruppe Bearbeiten"
            onEdit={handleEdit}
          />
          <RawValueGrid
            rawValues={rvType.RawValues}
            tableButtons={[
              {
                tooltip: "Rohwert erstellen",
                icon: () => <ControlPointDuplicateOutlined />,
                popup: EditOrCreateRawValue,
                isFreeAction: true,
                data: { rvTypeId: rvType.id },
                disabled: !isAdmin
              }
            ]}
            onRowClick={(_, row: IRawValueModel) => {
              isAdmin && setEditRawValue(row);
            }}
          />
          {editRvType && (
            <EditRvType
              open={editRvType}
              rvType={rvType}
              close={() => setEditRvType(false)}
            />
          )}
          {editRawValue && (
            <EditOrCreateRawValue
              close={() => {
                setEditRawValue(undefined);
              }}
              open={!!editRawValue}
              data={{ rawValue: editRawValue, rvTypeId: rvType.id }}
            />
          )}
        </>
      ) : (
        <LoadingAnimation />
      )}
      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={handleBack}
        className={classes.button}
      >
        Zurück zur Aufgabengruppenansicht
      </Button>
    </Wrapper>
  );
};
