import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Chip,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import PostAddIcon from "@material-ui/icons/PostAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { parseAndFormat } from "../../functions/date";
import { ICustomerModel } from "../../models/ICustomerModel";
import { IOfficeModel } from "../../models/IOfficeModel";
import { IProjectModel } from "../../models/IProjectModel";
import CustomerStore from "../../stores/CustomerStore";
import UserStore from "../../stores/UserStore";
import ColorDrop from "../ColorDrop";
import Wrapper from "../containers/Wrapper";
import DataGrid, { ICustomerItemDialog, IDataGridProps } from "../DataGrid";
import EditCustomerItem from "../dialogs/EditCustomerItem";
import IconDrop from "../IconDrop";
import LoadingAnimation from "../LoadingAnimation";
// import useUpdateCustomerItem from "../utils/useUpdateCustomerItem";

const str = {
  vCard: {
    abbreviation: "Abkürzung:",
    color: "Farbe:",
    settings: {
      root: "Einstellungen:",
      results: "Ergebnisse anzeigen",
      revaluation: "Umrechnung",
      reports: "Bericht"
    }
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  details: {
    width: "100%"
  },
  settings: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1)
  },
  tablerow: {
    display: "flex",
    marginTop: "3px"
  },
  button: {
    marginTop: "50px"
  },
  avatar: {
    margin: "3px",
    backgroundColor: theme.palette.secondary.main
  },
  avatarBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(2),
    userSelect: "none"
  },
  display: {
    display: "flex",
    justifyContent: "center"
  },
  customerName: {
    fontSize: "2rem",
    margin: "0",
    marginLeft: "7px"
  },
  vcard: {
    width: "100%",
    height: "35%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    alignItems: "flex-start",
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "1px",
    padding: theme.spacing(3)
  },
  editIcon: {
    cursor: "pointer"
  },
  icons: {
    position: "absolute",
    display: "inline",
    bottom: "0px"
  },
  label: {
    minWidth: "250px",
    textAlign: "left",
    margin: "0",
    padding: theme.spacing(0.5)
  },
  customerIcon: {
    position: "relative",
    bottom: "34px",
    left: "3%",
    display: "flex",
    cursor: "pointer"
  },
  green: {
    color: theme.palette.success.main
  },
  fromUntil: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  chip: {
    width: "fit-content"
  },
  offices: {
    maxWidth: "150px"
  },
  officesHeading: {
    display: "flex",
    justifyContent: "space-between"
  },
  officesContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    overflow: "visible"
  },
  dots: {
    padding: "0 5px"
  }
}));
interface IDetailParams {
  id: string;
}

const CustomerDetail: FC<RouteComponentProps<IDetailParams>> = ({ match }) => {
  const customerId = match.params.id;
  const classes = useStyles();
  const history = useHistory();
  const { isAdminSupport } = useContext(UserStore);
  const { fetchedCustomer, isFetchingCustomerData, getCustomerById } =
    useContext(CustomerStore);

  const [open, setOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleBack = () => {
    history.push(`/customer`);
  };

  const handleOfficeClick = (e: any, row: IOfficeModel) => {
    history.push(`/office/${row.id}`);
  };

  const handleProjectClick = (e: any, row: IProjectModel) => {
    history.push(`/project/${row.id}`);
  };

  const handleExpansion = (e: any) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    getCustomerById(customerId);
    window.sessionStorage.setItem("custId", customerId);
  }, [getCustomerById, customerId]);

  const dialogProps: ICustomerItemDialog = {
    customerId,
    items: [
      {
        type: "id",
        name: "id",
        value: fetchedCustomer.id
      },
      {
        type: "name",
        name: "longName",
        value: fetchedCustomer.longName
      },
      {
        type: "default",
        name: "shortName",
        value: fetchedCustomer.shortName
      },
      {
        type: "color",
        name: "color",
        value: fetchedCustomer.color
      },
      {
        type: "boolean",
        name: "showResults",
        value: fetchedCustomer.showResults
      },
      {
        type: "boolean",
        name: "revaluation",
        value: fetchedCustomer.revaluation
      },
      {
        type: "boolean",
        name: "reports",
        value: fetchedCustomer.reports
      }
    ],
    type: "customer",
    title: "Kunde editieren",
    open,
    close: handleClose
  };

  const officeDataprops: IDataGridProps = {
    addOfficeButton: true,
    title: "Offices",
    searchFilter: false,
    onRowClick: handleOfficeClick,
    columns: [
      { title: "Name", field: "longName" },
      { title: "Abkürzung", field: "shortName" },
      {
        title: "Farbe",
        field: "color",
        render: (row: ICustomerModel) => <ColorDrop color={row.color} />
      }
    ],
    data: fetchedCustomer.id
      ? JSON.parse(JSON.stringify(fetchedCustomer?.Offices))
      : []
  };

  const projectDataprops: IDataGridProps = {
    title: "Projekte",
    searchFilter: false,
    addProjectButton: true,
    onRowClick: handleProjectClick,
    columns: [
      { title: "Name", field: "longName" },
      { title: "Abkürzung", field: "shortName" },
      {
        title: "Gültigkeit",
        field: "validFrom",
        render: (row) => (
          <>
            <div className={classes.fromUntil}>
              <Typography>{parseAndFormat(row["validFrom"])}</Typography>
              <Typography>{"-"}</Typography>
            </div>
            <div className={classes.fromUntil}>
              <Typography>{parseAndFormat(row["validUntil"])}</Typography>
            </div>
          </>
        )
      },
      { title: "Eula-Umfrage", field: "eulaSurvey" },
      {
        title: "Branding",
        field: "branding",
        type: "boolean",
        render: (row) => (
          <IconDrop
            dense
            title={"branding"}
            enabled={row.branding}
            icon={<WhatshotIcon />}
          />
        )
      },
      {
        title: "Daten teilen",
        field: "dataSharing",
        type: "boolean",
        render: (row) => (
          <IconDrop
            dense
            title={"dataSharing"}
            enabled={row.dataSharing}
            icon={<SyncAltIcon />}
          />
        )
      },
      {
        title: "Offices",
        field: "Offices",
        render: (row) => (
          <Accordion
            className={classes.offices}
            expanded={isExpanded}
            onClick={handleExpansion}
            TransitionProps={{ timeout: 2 }}
          >
            <AccordionSummary
              className={classes.officesHeading}
              expandIcon={<ExpandMoreIcon />}
            >
              <Chip
                key={row.Offices[0].id}
                className={classes.chip}
                label={row.Offices[0].shortName}
                size="small"
                variant="outlined"
              />
              {row.Offices.length > 1 && (
                <Typography className={classes.dots}>
                  +{row.Offices.length - 1}
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails className={classes.officesContent}>
              {row?.Offices?.filter(
                (office: IOfficeModel, i: number) => i !== 0
              ).map((office: IOfficeModel, i: number) => (
                <Chip
                  key={i}
                  className={classes.chip}
                  label={office.shortName}
                  size="small"
                  variant="outlined"
                />
              ))}
            </AccordionDetails>
          </Accordion>
        )
      }
    ],
    data: fetchedCustomer.id
      ? JSON.parse(JSON.stringify(fetchedCustomer?.Projects))
      : []
  };

  return (
    <Wrapper>
      {!isFetchingCustomerData && fetchedCustomer?.id ? (
        <>
          <div className={classes.vcard} data-cy="info-vcard">
            <div className={classes.avatarBox}>
              <div className={classes.display}>
                <Avatar className={classes.avatar} data-cy="info-avatar">
                  <PermContactCalendar />
                </Avatar>
                <Typography className={classes.customerName}>
                  {fetchedCustomer.longName}
                </Typography>
              </div>
              {isAdminSupport && (
                <div className={classes.editIcon}>
                  <Tooltip title="Kunden editieren">
                    <IconButton size="medium" onClick={handleOpen}>
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                  <EditCustomerItem {...dialogProps} />
                </div>
              )}
            </div>
            <div className={classes.details}>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  {str.vCard.abbreviation}
                </Typography>
                <Chip
                  color="secondary"
                  className={classes.chip}
                  label={fetchedCustomer.shortName}
                  size="small"
                />
              </div>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  {str.vCard.color}
                </Typography>
                <div>
                  <Tooltip title={fetchedCustomer.color} arrow>
                    <ColorDrop color={fetchedCustomer.color} />
                  </Tooltip>
                </div>
              </div>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  {str.vCard.settings.root}
                </Typography>
                <div className={classes.settings}>
                  <IconDrop
                    title={str.vCard.settings.results}
                    icon={<FormatListBulletedIcon />}
                    enabled={fetchedCustomer?.showResults}
                  />
                  <IconDrop
                    title={str.vCard.settings.revaluation}
                    icon={<PostAddIcon />}
                    enabled={fetchedCustomer?.revaluation}
                  />
                  <IconDrop
                    title={str.vCard.settings.reports}
                    icon={<AssessmentIcon />}
                    enabled={fetchedCustomer?.reports}
                  />
                </div>
              </div>
            </div>
          </div>
          <DataGrid dense {...officeDataprops} />
          <DataGrid dense {...projectDataprops} />
        </>
      ) : (
        <LoadingAnimation />
      )}

      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={handleBack}
        className={classes.button}
      >
        Zurück zur Kundenansicht
      </Button>
    </Wrapper>
  );
};

export default observer(CustomerDetail);
