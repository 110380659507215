import { OptionsObject } from "notistack";
import { ParsingOptions, Sheet2JSONOpts } from "xlsx";

export type TNotiVariant = undefined | "info" | "success" | "error" | "warning";

export enum APICodes {
  SUCCESS = 1000,
  FAIL = 5000,
  EXISTS = 1009,
  NOTFOUND = 4000
}

interface IProcessConfig {
  read: ParsingOptions;
  json: Sheet2JSONOpts;
  sheetName: string;
  columns: {
    basic: string[];
    meta: string[];
    competencies: string[];
  };
  rows: {
    headers: number[];
    meta: number[];
    participants: number[];
  };
}

const getNotiOptions = (variant: TNotiVariant = undefined): OptionsObject => {
  return {
    ...(variant && { variant }),
    preventDuplicate: true,
    anchorOrigin: {
      horizontal: "right",
      vertical: "bottom"
    }
  };
};

const getProcessConfig = (): IProcessConfig => ({
  read: {
    type: "binary",
    cellDates: true
  },
  json: {
    skipHidden: true,
    header: "A",
    blankrows: false,
    dateNF: "dd/mm/yyyy"
  },
  sheetName: "Gesamtübersicht",
  columns: {
    basic: [
      "B", // lastName
      "C", // firstName
      "D", // gender
      "E", // birthday
      "I", // finalResult
      "J", // finalResult(%)
      "L", // results -->
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z" // <-- results
    ],
    meta: ["B"],
    competencies: [
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z"
    ]
  },
  rows: {
    headers: [23],
    meta: [20, 21, 22], // date, project, customer,
    participants: Array.from(Array(18 + 1).keys()).slice(1) // row 2 - 19
  }
});

export { getNotiOptions, getProcessConfig };
