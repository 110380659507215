import {
  Box,
  Button,
  ButtonGroup,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DvrIcon from "@material-ui/icons/Dvr";
import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import SaveIcon from "@material-ui/icons/Save";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { getNotiOptions, TNotiVariant } from "../../constants/configs";
import { ResultType } from "../../constants/constants";
import { transform, TResultFormat } from "../../functions/transform";
import { IApplicationModel } from "../../models/IApplicationModel";
import { IResultModel } from "../../models/IResultModel";
import ParticipantService from "../../services/ParticipantService";
import UserStore from "../../stores/UserStore";
import AcResults from "../AcResults";
import TabWrapper from "../containers/TabWrapper";
import SaveDialog from "../dialogs/SaveDialog";
import LoadingAnimation from "../LoadingAnimation";
import { PtResults } from "../PtResults";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {},
    mainTable: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxSizing: "border-box"
    },
    table: {
      width: "50%"
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between"
    },
    tableCell: {
      border: "none"
    },
    tableHeader: {
      color: "white",
      height: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    total: {
      width: "50%",
      fontWeight: 600,
      fontSize: "1rem",
      borderTop: "1px solid lightgrey"
    },
    btnGroup: {
      marginTop: theme.spacing(1)
    },
    downloadContainer: {
      minWidth: "200px",
      minHeight: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(3)
    },
    btn: {},
    input: {
      padding: "none",
      paddingLeft: theme.spacing(1)
    },
    diaBtnBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around"
    },
    white: {
      color: theme.palette.primary.light
    }
  });
});

export interface IApplicationDetail {
  application: IApplicationModel;
  participantId: string;
  firstName: string;
  lastName: string;
  showAc: boolean;
}

export interface IEditingRw {
  rwId: string;
  value: string;
}

const ApplicationDetail: FC<IApplicationDetail> = ({
  application,
  participantId,
  firstName,
  lastName,
  showAc
}) => {
  const classes = useStyles();
  const { isAdminSupport } = useContext(UserStore);
  const { enqueueSnackbar } = useSnackbar();
  const { updateRawValue } = ParticipantService;

  const [fetching, setFetching] = useState<boolean>(false);
  const [resultFormat, setResultFormat] = useState<TResultFormat>(
    application.Position.Project?.exportType
      ? application.Position.Project?.exportType
      : "grades"
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editingRw, setEditingRw] = useState<IEditingRw>({
    rwId: "",
    value: ""
  });
  const [open, setOpen] = useState({
    csv: false,
    rv: false
  });

  const otResults = application.Results?.filter(
    (result) => result.type === ResultType.RawApplication
  );

  const acResults = application.Results?.filter(
    (result) => result.type === ResultType.CompetencyAC
  );

  const ptResults = application.Results?.filter(
    (result) => result.type === ResultType.PT
  );

  const toggle = (type: "csv" | "rv", toggleOpen: boolean = false) => {
    setOpen({ ...open, [type]: toggleOpen });
  };

  const handleCancel = (type: "csv" | "rv") => {
    if (type === "rv") {
      setEditMode(false);
      setEditingRw({ rwId: "", value: "" });
    }
    toggle(type);
  };

  const handleUpdateRawValue = async (
    applicationId: string,
    editingRw: IEditingRw
  ) => {
    if (editingRw.value) {
      const rsp = await updateRawValue(applicationId, editingRw);
      if (rsp) {
        enqueueSnackbar("Rohwert angepasst", getNotiOptions("success"));
        window.location.href = `/participant/${participantId}`;
      } else {
        enqueueSnackbar(
          "Fehler beim anpassen des Rohwertes",
          getNotiOptions("error")
        );
      }
    } else {
      enqueueSnackbar("Kein Rohwert verändert", getNotiOptions("info"));
    }
    handleCancel("rv");
  };

  const handleRawValueChange = (
    rawValue: IResultModel,
    value: number,
    min: number,
    max: number
  ) => {
    setEditingRw({
      rwId: rawValue?.id,
      value: String(value > max ? max : value < min ? min : value)
    });
  };

  const handleReportDownload = async () => {
    setFetching(true);
    const rsp = await ParticipantService.getReport(
      application.id,
      firstName,
      lastName
    );
    if (rsp.status_code !== 1000) {
      enqueueSnackbar(`Downloadfehler`, getNotiOptions("error"));
    }
    setFetching(false);
  };

  const handleExport = async () => {
    setFetching(true);
    if (!application?.Position?.Project?.id) return;
    const rsp = await ParticipantService.exportParticipants(
      application.Position.Project.id,
      [participantId],
      application.Position.Project.longName
    );
    let title: string = "Es sind keine Ergebnisse verfügbar";
    let type: TNotiVariant = "warning";
    if (rsp) {
      if (rsp === 4000) {
        title = "Keine Bewerbungen zum exportieren vorhanden";
      } else {
        title = "Export erfolgreich";
        type = "success";
      }
    }
    enqueueSnackbar(title, getNotiOptions(type));
    setFetching(false);
  };

  const OtResults: FC = () => (
    <TableContainer component={Paper} className={classes.mainTable}>
      {isAdminSupport && (
        <ButtonGroup
          size="small"
          variant="outlined"
          aria-label="outlined button group"
          className={classes.btnGroup}
        >
          <Button
            color={resultFormat === "grades" ? "secondary" : "default"}
            onClick={() => setResultFormat("grades")}
          >
            Noten
          </Button>
          <Button
            color={resultFormat === "points" ? "secondary" : "default"}
            onClick={() => setResultFormat("points")}
          >
            Punkte
          </Button>
          <Button
            color={resultFormat === "percentiles" ? "secondary" : "default"}
            onClick={() => setResultFormat("percentiles")}
          >
            Prozentränge
          </Button>
          <Button
            color={resultFormat === "raw" ? "secondary" : "default"}
            onClick={() => setResultFormat("raw")}
          >
            z-Werte
          </Button>
        </ButtonGroup>
      )}
      <TableContainer
        style={{ display: "flex", padding: "1rem", boxSizing: "border-box" }}
      >
        {isAdminSupport && (
          <Table
            className={classes.table}
            size="small"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>
                  <Typography>Rohdaten</Typography>
                  {editMode ? (
                    <div>
                      <Tooltip title="Änderungen speichern">
                        <IconButton onClick={() => toggle("rv", true)}>
                          <SaveIcon className={classes.white} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Abbrechen">
                        <IconButton onClick={() => handleCancel("rv")}>
                          <CloseIcon className={classes.white} />
                        </IconButton>
                      </Tooltip>
                      <SaveDialog
                        open={open.rv}
                        close={() => toggle("rv")}
                        title="Änderungen speichern?"
                        save={() =>
                          handleUpdateRawValue(application.id, editingRw)
                        }
                      />
                    </div>
                  ) : (
                    <Tooltip title="Rohwerte bearbeiten">
                      <IconButton onClick={() => setEditMode(!editMode)}>
                        <EditIcon className={classes.white} />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {application?.Results?.map((result, i) => (
                <TableRow key={i} className={classes.tableRow}>
                  {result.type === ResultType.RawApplication && (
                    <>
                      <Tooltip
                        title={`Range= ${result?.typeObject?.rngMin}-${result?.typeObject?.rngMax} | MW=${result?.typeObject?.popM} | SD=${result?.typeObject?.popSd}`}
                      >
                        <TableCell className={classes.tableCell}>
                          {result?.typeObject?.RvTypes[0]?.description}
                        </TableCell>
                      </Tooltip>
                      {editMode &&
                      result.valid &&
                      typeof result.value === "number" ? (
                        <TextField
                          disabled={
                            editingRw.rwId && editingRw.rwId !== result.id
                              ? true
                              : false
                          }
                          size="small"
                          type="number"
                          onChange={(e) =>
                            handleRawValueChange(
                              result,
                              Number(e.currentTarget.value),
                              Number(result?.typeObject?.rngMin),
                              Number(result?.typeObject?.rngMax)
                            )
                          }
                          InputProps={{
                            inputProps: {
                              min: result?.typeObject?.rngMin,
                              max: result?.typeObject?.rngMax
                            }
                          }}
                          className={classes.input}
                          defaultValue={Math.round(result.value)}
                        />
                      ) : (
                        <TableCell className={classes.tableCell}>
                          {result.valid && result.value !== null
                            ? transform.round(result.value)
                            : "-"}
                        </TableCell>
                      )}
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <Table
          className={classes.table}
          size="small"
          aria-label="simple table"
          style={{ width: isAdminSupport ? "50%" : "100%" }}
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Fähigkeiten</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {application?.Results?.map((result) => (
              <TableRow key={result.id} className={classes.tableRow}>
                {result.type === "competency_z" && (
                  <>
                    <TableCell className={classes.tableCell}>
                      {result?.typeObject?.longName}:
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {transform.result(result.value, resultFormat, true) ||
                        "-"}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer
        style={{ display: "flex", padding: "1rem", boxSizing: "border-box" }}
      >
        <Table
          className={classes.table}
          size="small"
          aria-label="simple table"
          style={{ width: "100%" }}
        >
          <TableBody>
            {application?.Results?.map((result) => (
              <TableRow key={result.id}>
                {result.type === "position_z" && (
                  <>
                    <TableCell
                      className={`${classes.tableCell} ${classes.total}`}
                    >
                      Gesamtergebnis:
                    </TableCell>
                    <TableCell
                      className={`${classes.tableCell} ${classes.total}`}
                    >
                      {transform.result(result.value, resultFormat, true) ||
                        "-"}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.downloadContainer}>
        {fetching ? (
          <LoadingAnimation />
        ) : (
          <ButtonGroup
            className={classes.btnGroup}
            variant="contained"
            size="small"
          >
            <Button
              disabled={
                !isAdminSupport && !application.Office.Customer?.reports
              }
              color="secondary"
              className={classes.btn}
              onClick={handleReportDownload}
              startIcon={<PictureAsPdfIcon />}
            >
              Einzelreport
            </Button>
            <Button
              disabled={
                !isAdminSupport && !application.Office.Customer?.reports
              }
              color="secondary"
              className={classes.btn}
              onClick={handleExport}
              endIcon={<DvrIcon />}
            >
              CSV-Export
            </Button>
          </ButtonGroup>
        )}
      </Box>
    </TableContainer>
  );

  const tabs: {
    headers: string[];
    components: JSX.Element[];
  } = {
    headers: [],
    components: []
  };

  if (otResults?.length) {
    tabs.headers.push("Online-Test");
    tabs.components.push(<OtResults />);
  }

  if (acResults?.length) {
    tabs.headers.push("Mündliches Auswahlverfahren");
    tabs.components.push(
      <AcResults
        acResults={acResults}
        acFinalResult={application.Results?.find(
          (result) => result.type === ResultType.PositionAC
        )}
      />
    );
  }
  if (ptResults?.length) {
    tabs.headers.push("Persönlichkeits-Test");
    tabs.components.push(<PtResults ptResults={ptResults} />);
  }

  return <TabWrapper headers={tabs.headers}>{tabs.components}</TabWrapper>;
};

export default observer(ApplicationDetail);
