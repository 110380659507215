import {
  Button,
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Theme
} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { FC, useState } from "react";
import { getNotiOptions } from "../constants/configs";
import UtilityService from "../services/UtilityService";
import DatePicker from "./DatePicker";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      maxWidth: "400px"
    },
    heading: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: theme.spacing(3)
    },
    pageHeader: {
      fontFamily: "Open Sans",
      lineHeight: "1.334",
      fontWeight: "normal",
      margin: "0"
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      maxWidth: "300px",
      marginTop: theme.spacing(3)
    },
    btnBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    },
    btn: {
      margin: "0.5rem"
    },
    copy: {
      cursor: "pointer"
    }
  });
});

const HashConverter: FC = () => {
  const classes = useStyles();
  const { getAnonId } = UtilityService;
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    birthday: new Date()
  });

  const [anonId, setAnonId] = useState("");

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    identifier: "firstName" | "lastName" | "birthday"
  ) => {
    e.preventDefault();
    setData({ ...data, [identifier]: e.target.value });
  };

  const handleDate = (date: Date) => {
    setData({ ...data, birthday: date });
  };

  const handleConvert = async () => {
    const response = await getAnonId(data);
    if (response) {
      setAnonId(response.data);
    }
  };

  const handleCopy = () => {
    if (anonId) {
      navigator.clipboard.writeText(anonId);
      enqueueSnackbar(`Hash copied`, getNotiOptions());
    } else {
      enqueueSnackbar(`Convert first`, getNotiOptions());
    }
  };

  const handleReset = () => {
    setData({ firstName: "", lastName: "", birthday: new Date() });
    setAnonId("");
  };

  return (
    <form className={classes.form}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="firstName"
            color="secondary"
            placeholder="Firstname"
            name="firstName"
            value={data.firstName}
            onChange={(e) => handleChange(e, "firstName")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="lastName"
            color="secondary"
            placeholder="Lastname"
            name="lastName"
            value={data.lastName}
            onChange={(e) => handleChange(e, "lastName")}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            label="Birthday"
            processDate={(date) => handleDate(date)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            fullWidth
            size="small"
            id="hash"
            color="secondary"
            placeholder="Hash"
            name="hash"
            value={anonId}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FileCopyOutlinedIcon
                    className={classes.copy}
                    onClick={handleCopy}
                  />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.btnBox}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            className={classes.btn}
            onClick={handleReset}
          >
            Clear
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.btn}
            onClick={handleConvert}
          >
            Convert
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(HashConverter);
