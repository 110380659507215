import { isValid, parse, subDays } from "date-fns";
import {
  dateFormats,
  locale,
  localeOptions,
  localeOptionsTime
} from "../constants/constants";

const parseDate = (date: string) => {
  // string -> date
  if (isValid(parse(date, dateFormats.default, new Date()))) {
    return parse(date, dateFormats.default, new Date());
  }
  if (isValid(parse(date, dateFormats.variant, new Date()))) {
    return parse(date, dateFormats.variant, new Date());
  }
  return new Date(date);
};

const formatDate = (date: Date, withTime: boolean = false) => {
  // date -> string
  return date.toLocaleDateString(
    locale,
    withTime ? localeOptionsTime : localeOptions
  );
};

const parseAndFormat = (date: string, withTime: boolean = false) => {
  let str = "";
  try {
    const parsedDate = parseDate(date);
    if (isValid(parsedDate)) {
      str = formatDate(parsedDate, withTime);
    } else {
      throw new Error("Invalid Date");
    }
  } catch (e) {
    console.log(e);
    str = date;
  }
  return str;
};

const toValidUntilString = (validUntil: string) => {
  let str = "";
  if (typeof validUntil !== "string") throw new Error("Date is not a string");
  const parsed = parseDate(validUntil);
  try {
    if (isValid(parsed)) {
      str = `${formatDate(subDays(parsed, 1))} 23:59:59 Uhr`;
    } else {
      throw new Error("Invalid Date");
    }
  } catch (e) {
    console.log(e);
  }
  return str;
};

export { toValidUntilString, formatDate, parseAndFormat, parseDate };
