import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

// keine ahnung warum aber die signature von render wurde irgendwann geändert und <BrowserRouter /> scheint nur ein Element zu sein und kein JSX.Element ?
// casting "fix" ist jetzt auch egal weil ReactDOM.render sowieso deprecated ist und irgendwann rausgeschmissen wird (hoffentlich)
ReactDOM.render(
  (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  ) as any,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
