import {
  Button,
  ClickAwayListener,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Popover,
  Select,
  TextField,
  Theme,
  Typography
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { regex, translations } from "../../constants/constants";
import {
  emptyCompetency,
  ICompetencyModel
} from "../../models/ICompetencyModel";
import CustomerService from "../../services/CustomerService";
import { IAddDialog } from "../DataGrid";

interface IStrings {
  [key: string]: {
    [key: string]: string;
  };
}

enum ESteps {
  initial = 0,
  continue
}

const dialogStr: IStrings = {
  initial: {
    title: "Kompetenz erstellen",
    description: "Definieren Sie eine neue Kompetenz"
  },
  edit: {
    title: "Kompetenz anpassen",
    description: "Passen Sie Attribute der Kompetenz an"
  },
  continue: {
    title: "Daten Überprüfen",
    description: "Sind Sie sicher, dass Sie die Kompetenz speichern wollen?"
  }
};

const notiStr: IStrings = {
  validate: {
    fail: "Die Daten sind nicht gültig"
  },
  save: {
    success: "Speichern erfolgreich",
    fail: "Kompetenz konnte nicht gespeichert werden"
  },
  delete: {
    success: "Löschen erfolgreich",
    fail: "Löschen fehlgeschlagen"
  }
};

const elemStr: IStrings = {
  button: {
    continue: "Weiter",
    back: "Zurück",
    confirm: "Bestätigen",
    abort: "Abbrechen",
    delete: "Löschen"
  },
  text: {
    delete: 'Schreiben Sie "Löschen"'
  },
  compare: {
    delete: "löschen"
  },
  competencyTypes: {
    ot: "Online-Test",
    ac: "Mündliches Verfahren",
    pt: "Persönlichkeit"
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    title: {
      textAlign: "center",
      boxSizing: "border-box"
    },
    description: {
      textAlign: "center",
      boxSizing: "border-box",
      padding: theme.spacing(2),
      margin: "0px"
    },
    content: {
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    elementContainer: {
      width: "20vw",
      paddingBottom: theme.spacing(3),
      boxSizing: "border-box"
    },
    btn: {
      minWidth: "200px"
    },
    red: {
      color: theme.palette.error.main
    },
    yellow: {
      color: theme.palette.warning.main
    },
    lightYellow: {
      color: theme.palette.warning.light
    },
    green: {
      color: theme.palette.success.main
    },
    delete: {
      boxSizing: "border-box",
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.primary.light
      }
    }
  })
);

const EditCompetency: FC<IAddDialog> = ({ open, close, data }) => {
  const classes = useStyles();
  const history = useHistory();

  const { createOrUpdateCompetency, deleteCompetency } = CustomerService;
  const { enqueueSnackbar } = useSnackbar();

  const [openDelete, setOpenDelete] = useState(false);
  const [deleteStr, setDeleteStr] = useState("");
  const [deletable, setDeletable] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [step, setStep] = useState<number>(ESteps.initial);
  const [title, setTitle] = useState<string>(
    (data?.id ? dialogStr.edit.title : dialogStr.initial.title).toUpperCase()
  );
  const [description, setDescription] = useState<string>(
    data?.id ? dialogStr.edit.description : dialogStr.initial.description
  );
  const [details, setDetails] = useState<ICompetencyModel>({ ...data });

  const handleClose = () => {
    close();
    setStep(0);
    setTitle(dialogStr.initial.title.toUpperCase());
    setDescription(dialogStr.initial.description);
    setDetails(emptyCompetency());
  };

  const handleCreateOrUpdateCompetency = async () => {
    const rsp = await createOrUpdateCompetency(details);
    if (rsp.status_code === 1000) {
      enqueueSnackbar(notiStr.save.success, getNotiOptions("success"));
      history.go(0);
    } else {
      enqueueSnackbar(notiStr.save.fail, getNotiOptions("error"));
      handleClose();
    }
  };

  const handleDelete = async (e: any) => {
    setAnchorEl(e.currentTarget);
    if (!deletable) {
      setOpenDelete(true);
    } else {
      if (deleteStr.toLowerCase() === elemStr.compare.delete) {
        const success = await deleteCompetency(details.id);
        if (success) {
          enqueueSnackbar(notiStr.delete.success, getNotiOptions("success"));
          history.go(0);
        } else {
          enqueueSnackbar(notiStr.delete.fail, getNotiOptions("error"));
          history.go(0);
        }
      }
    }
  };

  const handleDeleteStr = (value: string) => {
    setDeleteStr(value);
    if (value.toLowerCase() === elemStr.compare.delete) {
      setDeletable(true);
      setOpenDelete(false);
    }
  };

  const handlePropagation = (direction: "next" | "back") => {
    const newStep = direction === "next" ? step + 1 : step - 1;
    if (ESteps[newStep]) {
      setStep(newStep);
      setTitle(dialogStr[ESteps[newStep]].title);
      setDescription(dialogStr[ESteps[newStep]].description);
    }
  };

  const handleDataChange = (
    value: string | boolean | Array<Object> | Date,
    identifier: string
  ) => {
    setDetails({
      ...details,
      [identifier]: value
    });
  };

  const handleContinue = () => {
    if (
      regex.name.test(details.shortName) &&
      regex.name.test(details.longName) &&
      regex.description.test(details.description)
    ) {
      handlePropagation("next");
    } else {
      enqueueSnackbar(notiStr.validate.fail, getNotiOptions("error"));
    }
  };

  const detailsContent = () => {
    return (
      <Grid container spacing={1} className={classes.elementContainer}>
        <Grid item xs={6}>
          <TextField
            disabled={step > 0}
            label={translations["longName"]}
            onChange={(e) => {
              handleDataChange(e.target.value, "longName");
            }}
            value={details.longName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={step > 0}
            label={translations["shortName"]}
            onChange={(e) => {
              handleDataChange(e.target.value, "shortName");
            }}
            value={details.shortName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={step > 0}
            label={translations["description"]}
            onChange={(e) => {
              handleDataChange(e.target.value, "description");
            }}
            value={details.description}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel id="competency-typw-select-label">
            {translations["type"]}
          </InputLabel>
          <Select
            labelId="competency-typw-select-label"
            fullWidth
            disabled={step > 0}
            value={details.type}
            onChange={(e) => {
              handleDataChange(e.target.value as string, "type");
            }}
          >
            <MenuItem value="OT">{elemStr.competencyTypes.ot}</MenuItem>
            <MenuItem value="AC">{elemStr.competencyTypes.ac}</MenuItem>
            <MenuItem value="PT">{elemStr.competencyTypes.pt}</MenuItem>
          </Select>
          {/* <TextField
            disabled // disabled as long as there is no selection needed (only one type atm)
            label={translations["type"]}
            onChange={(e) => {
              handleDataChange(e.target.value, "type");
            }}
            value={details.type}
          /> */}
        </Grid>
      </Grid>
    );
  };

  const actionsContent = () => {
    const finalStep = Object.keys(ESteps).length / 2 - 1;
    return (
      <>
        {step > 0 && (
          <Button
            className={classes.yellow}
            onClick={() => handlePropagation("back")}
            color="default"
            variant="text"
            size="small"
          >
            {elemStr.button.back}
          </Button>
        )}

        {step === 0 && (
          <>
            {data.id && (
              <Button
                variant="outlined"
                onClick={handleDelete}
                className={`${deletable && classes.delete}`}
              >
                {elemStr.button.delete}
              </Button>
            )}
            <Popover anchorEl={anchorEl} open={openDelete}>
              <ClickAwayListener onClickAway={() => setOpenDelete(false)}>
                <TextField
                  size="small"
                  value={deleteStr}
                  variant="filled"
                  label={elemStr.text.delete}
                  onChange={(e) => handleDeleteStr(e.currentTarget.value)}
                />
              </ClickAwayListener>
            </Popover>
          </>
        )}
        {step < finalStep && !deletable && (
          <Button
            className={classes.green}
            onClick={() => {
              step === 0 ? handlePropagation("next") : handleContinue();
            }}
            color="default"
            variant="text"
            size="small"
          >
            {elemStr.button.continue}
          </Button>
        )}
        <Button
          className={classes.red}
          onClick={handleClose}
          color="default"
          variant="text"
          size="small"
        >
          {elemStr.button.abort}
        </Button>
      </>
    );
  };

  const summaryContent = () => {
    return (
      <>
        <Button
          className={classes.btn}
          onClick={() => {
            handleCreateOrUpdateCompetency();
          }}
          color="secondary"
          variant="contained"
          size="small"
          startIcon={<WarningIcon className={classes.lightYellow} />}
        >
          {elemStr.button.confirm}
        </Button>
      </>
    );
  };

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <Divider component="span" />
      <Typography className={classes.description}>{description}</Typography>
      <Divider component="span" />
      <DialogContent className={classes.content}>
        {detailsContent()}
        {step === 1 && summaryContent()}
      </DialogContent>
      <Divider component="span" />
      <DialogActions className={classes.actions}>
        {actionsContent()}
      </DialogActions>
    </Dialog>
  );
};

export default observer(EditCompetency);
