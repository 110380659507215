/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Link,
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Theme,
  Typography,
  withStyles
} from "@material-ui/core";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { PersonAdd } from "@material-ui/icons";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import BusinessIcon from "@material-ui/icons/Business";
import Check from "@material-ui/icons/Check";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { read, utils } from "xlsx";
import { getNotiOptions } from "../../constants/configs";
import { parseAndFormat } from "../../functions/date";
import { emptyOffice, IOfficeModel } from "../../models/IOfficeModel";
import { emptyProject, IProjectModel } from "../../models/IProjectModel";
import ImportService from "../../services/ImportService";
import ProjectService from "../../services/ProjectService";
import ImportStore from "../../stores/ImportStore";
import UserStore from "../../stores/UserStore";
import Wrapper from "../containers/Wrapper";
import FileInput, { IFileInput } from "../FileInput";
import Heading from "../Heading";
import LoadingAnimation from "../LoadingAnimation";
import PositionSelector from "../PositionSelector";
import ImportDataGrid, {
  IImportDataGridProps,
  IImportDataGridRow
} from "./ImportDataGrid";
import ImportResults from "./ImportResults";

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    color: theme.palette.warning.main
  },
  success: {
    color: theme.palette.success.main
  },
  results: {
    fontSize: "1.5rem",
    fontFamily: "Open Sans",
    lineHeight: "1.334",
    fontWeight: "normal"
  },
  box: {
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  workbench: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    margin: theme.spacing(2, 0)
  },
  infoBox: {
    maxWidth: "400px",
    padding: "5px",
    margin: "10px",
    color: "grey"
  },
  tableBox: {
    height: "100%",
    width: "100%",
    margin: theme.spacing(2, 0)
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    alingItems: "center",
    justifyContent: "center",
    gap: "20px"
  },
  selectBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    width: "170px"
  },
  instructionBox: {
    borderTop: `1px solid ${theme.palette.secondary.dark}`,
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 1)
  },
  stepper: {
    width: "50vw",
    minWidth: "600px",
    background: "none"
  },
  infoItem: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "20px"
  },
  instructions: {
    fontWeight: 600,
    color: theme.palette.secondary.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  align: {
    textAlign: "left",
    fontWeight: "bold",
    margin: "5px 0",
    minWidth: 200
  },
  resetBtn: {
    color: "grey"
  }
}));

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  active: {
    "& $line": {
      borderColor: "#008DCF"
    }
  },
  completed: {
    "& $line": {
      borderColor: "#309134"
    }
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center"
  },
  active: {
    color: "#e8ac34"
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor"
  },
  completed: {
    color: "#309134",
    border: "2px solid #309134",
    borderRadius: "50%",
    zIndex: 1,
    fontSize: 18
  }
});

function QontoStepIcon({ active, completed }: StepIconProps) {
  const classes = useQontoStepIconStyles();
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const allSteps = [
  "Projekt wählen",
  "Datei wählen",
  "Daten zuordnen",
  "Fehler",
  "Konflikte",
  "Import",
  "Zusammenfassung"
];

const stepContents = [
  "Wählen Sie eine Einheit und ein Projekt.",
  "Wählen Sie eine Datei mit den Daten der Teilnehmer*innen aus.",
  "Ordnen Sie den Daten die Entsprechenden Spalten zu.",
  "Korrigieren Sie die fehlerhaften Einträge um fortzufahren.",
  "Beheben Sie die Konflikte oder fahren Sie ohne die Konflikte fort.",
  "Daten importieren",
  "Zusammenfassung der importierten Teilnehmer*innen"
];

let requiredKeyTranslations = new Map<string, string[]>([
  ["firstName", ["vorname", "firstname"]],
  ["lastName", ["nachname", "lastname"]],
  ["birthday", ["geburtstag", "birthday", "geburtsdatum", "geburts-datum"]],
  ["gender", ["geschlecht", "gender", "anrede"]],
  [
    "validFrom",
    ["gültig von", "gueltig von", "valid from", "validfrom", "einladungsdatum"]
  ],
  [
    "validUntil",
    ["gültig bis", "gueltig bis", "valid until", "validuntil", "teilnahmefrist"]
  ],
  [
    "email",
    [
      "e-mail",
      "email",
      "e mail",
      "mail",
      "e-mail-adresse",
      "email-adresse",
      "emailadresse"
    ]
  ]
]);

interface LooseObject {
  [key: string]: string;
}

const ParticipantImport: FC = () => {
  const classes = useStyles();
  const { user } = useContext(UserStore);
  const {
    activeStep,
    setActiveStep,
    mappingState,
    setMappingState,
    validationState,
    setValidationState,
    conflictState,
    setConflictState,
    selectState,
    setSelectState,
    setImportState,
    importState
  } = useContext(ImportStore);
  const { enqueueSnackbar } = useSnackbar();

  const { getUserOffices } = ProjectService;

  const [steps, setSteps] = useState<string[]>(allSteps);
  const [fileLoaded, setFileLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [columns, setColumns] = useState<LooseObject>({});
  const [rows, setRows] = useState<IImportDataGridRow[]>([]);
  const [fetchedOffices, setFetchedOffices] = useState<IOfficeModel[]>([]);
  const [selectedOffice, setSelectedOffice] = useState<IOfficeModel>(
    emptyOffice()
  );
  const [selectedProject, setSelectedProject] = useState<IProjectModel>(
    emptyProject()
  );
  const [statefulHeaders, setStatefulHeaders] = useState<string[]>([]);

  selectedProject.Positions?.forEach((p) => {
    requiredKeyTranslations.set(p.shortName, [p.shortName, p.longName]);
  });

  const handleReset = () => {
    setFetchedOffices([]);
    setSelectedOffice(emptyOffice());
    setSelectedProject(emptyProject());
    setSteps(allSteps);
    setActiveStep(0);
    setFileLoaded(false);
    setIsLoading(false);
    setIsEditable(false);
    setFileName("");
    setColumns({});
    setRows([]);

    setMappingState({
      complete: false,
      duplicates: false
    });
    setValidationState({
      processing: false,
      complete: false,
      count: 0
    });
    setConflictState({
      fetching: false,
      processing: false,
      complete: false,
      count: 0
    });
    setSelectState({
      fetched: false,
      officeSelected: false,
      projectSelected: false
    });
    setImportState({
      responseData: [
        {
          participant: {
            id: "",
            firstName: "",
            lastName: "",
            occurence: ""
          },
          application: [
            {
              positionId: "",
              officeId: "",
              occurence: "",
              status: "",
              action: ""
            }
          ]
        }
      ],
      fetched: false,
      status: ""
    });
  };

  // PROPS
  const inputProps: IFileInput = {
    buttonText: "Datei wählen",
    filetypes: ".csv, .xlsx, .xlsm, .xls",
    callback: (file) => {
      if (file) processFile(file);
    }
  };

  const dataGridProps: IImportDataGridProps = {
    columns: columns,
    rows: rows,
    positions: selectedProject?.Positions || [],
    editable: isEditable,
    fileName: fileName,
    fileHeaders: statefulHeaders,
    next: () => {
      setActiveStep(activeStep + 1);
    },
    reset: () => {
      handleReset();
    },
    storeNewRows: (newRows: any[]) => {
      if (newRows) setRows(newRows);
    }
  };

  const resultsDataProps: any = {
    rows: importState.responseData,
    positions: selectedProject?.Positions || []
  };

  // FUNCTIONS
  const processFile = (file: File) => {
    setIsLoading(true);

    const getPositionColumns = () => {
      const positionColumns: LooseObject = {};
      if (selectedProject.Positions) {
        selectedProject.Positions.forEach((position) => {
          positionColumns[position.shortName] = position.longName;
        });
      }
      return positionColumns;
    };

    const getAllColumns = () => ({
      firstName: "Vorname",
      lastName: "Nachname",
      birthday: "Geboren",
      email: "E\u2011Mail",
      gender: "Geschlecht",
      ...getPositionColumns(),
      validFrom: "Teststart",
      validUntil: "Testende"
    });

    const convertDates = (data: any[]) => {
      for (const entry of data) {
        for (const key in entry) {
          const val = entry[key];
          if (val.length > 1) {
            entry[key] = parseAndFormat(val);
          }
        }
      }
    };

    if (file) {
      setFileName(file.name);
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(file);
      fileReader.onload = async (e) => {
        const bstr = e?.target?.result;
        const workbook = read(bstr, {
          type: "binary",
          raw: true,
          cellDates: true
        });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rawData: any = utils.sheet_to_json(worksheet, {
          defval: "",
          header: "A",
          blankrows: false,
          dateNF: "dd/mm/yyyy"
        });
        const parsedFileData = JSON.parse(JSON.stringify(rawData));

        let hasHeader: boolean = false;
        let rawHeaders = Object.values(parsedFileData[0]);
        rawHeaders = rawHeaders.map((header) => (header as string).trim());
        const allColumns = getAllColumns();
        const allColumnKeys = Object.keys(allColumns);
        let sendIncorrectWarning = true;

        if (rawHeaders.length > allColumnKeys.length) {
          enqueueSnackbar(
            "Die Datei enthält zu viele Spalten/Elemente in der Kopfzeile",
            getNotiOptions("error")
          );
          handleReset();
          return;
        }
        if (rawHeaders.includes("")) {
          // empty header fields are filled with empty strings
          enqueueSnackbar(
            "Die Datei enthält zu wenige Spalten/Elemente in der Kopfzeile. Bitte weisen Sie die fehlenden Spalten zu.",
            getNotiOptions("warning")
          );
          sendIncorrectWarning = false; // silence any following incorrect header warnings
        }

        for (const fValue of rawHeaders) {
          if (hasHeader) break;

          for (const value of requiredKeyTranslations.values()) {
            if (
              value.find(
                (el) => (fValue as string).toLowerCase() === el.toLowerCase()
              )
            ) {
              hasHeader = true; // if any of the required headers is found in the first row, this file has a header
              break;
            }
          }
        }

        let completelyMapped: boolean = hasHeader;
        if (hasHeader) {
          const tempStatelessHeaders: string[] = [];
          for (const fileValue of rawHeaders) {
            let val: string = "";

            for (const [key, value] of requiredKeyTranslations) {
              if (
                // if any of the possible aliases for the header key is found
                value.find(
                  (el) =>
                    (fileValue as string).toLowerCase() === el.toLowerCase()
                )
              ) {
                val = key;
                break;
              }
            }

            if (val === "") {
              // if no valid alias was used
              completelyMapped = false;
              if (sendIncorrectWarning) {
                // if any headers are missing, this case is expected and no warnings should be shown
                sendIncorrectWarning = false;
                enqueueSnackbar(
                  "Die Datei enthält Spalten/Elemente in der Kopfzeile die nicht zugewiesen werden konnten. Bitte weisen Sie die fehlenden Spalten zu.",
                  getNotiOptions("warning")
                );
              }
            }
            tempStatelessHeaders.push(val);
          }
          setStatefulHeaders(tempStatelessHeaders); // update the headers used in the data grid
          parsedFileData.shift(); // remove the header line from the import data
        }

        if (
          Object.keys(allColumns).length !==
          Object.keys(parsedFileData[0]).length
        ) {
          enqueueSnackbar(
            "Die Datei ist nicht mit dem Projekt kompatibel.",
            getNotiOptions("error")
          );
          handleReset();
          return;
        }
        for (const key in parsedFileData) {
          parsedFileData[key].misc = {
            id: key,
            projectId: selectedProject?.id,
            officeId: selectedOffice?.id,
            positions: selectedProject?.Positions
          };
        }
        convertDates(parsedFileData);
        setFileLoaded(true);
        setIsLoading(false);
        setColumns(allColumns);
        setRows(parsedFileData);
        setActiveStep(activeStep + 1);
        setMappingState({
          ...mappingState,
          complete: completelyMapped
        });
      };
    }
  };

  const fetchOffices = async () => {
    setIsLoading(true);
    const offices = await getUserOffices(user.id.toString());
    if (offices) {
      setFetchedOffices(offices);
      setSelectState({
        fetched: true,
        officeSelected: selectState.officeSelected,
        projectSelected: selectState.projectSelected
      });
    }
    setIsLoading(false);
  };

  // HANDLES

  const handleImport = async () => {
    setIsLoading(true);
    const res = await ImportService.importParticipants(rows, fileName);
    if (res && res.status_code === 1000) {
      setActiveStep(6);
      setImportState({
        responseData: res.data,
        fetched: true,
        status: "ok"
      });
      setIsLoading(false);
    } else {
      setImportState({
        ...importState,
        fetched: true,
        status: "error"
      });
      enqueueSnackbar(
        "Fehler beim Importieren der Teilnehmer*innen",
        getNotiOptions("error")
      );
      setActiveStep(activeStep + 1);
      setIsLoading(false);
    }
  };

  const handleContinue = () => {
    !mappingState.complete
      ? enqueueSnackbar(
          "Bitte ordnen Sie jeder Spalte einen Namen zu.",
          getNotiOptions("error")
        )
      : mappingState.duplicates
      ? enqueueSnackbar(
          "Jeder Spaltenname kann nur einmal zugeordnet werden.",
          getNotiOptions("error")
        )
      : setActiveStep(activeStep + 1);
  };

  const handleOfficeAndProjectSelected = (
    office: IOfficeModel | undefined = selectedOffice,
    project?: IProjectModel
  ) => {
    if (office) {
      setSelectedOffice(office);
      if (project) {
        setSelectedProject(project);
      }
    }
    setSelectState({
      fetched: selectState.fetched,
      officeSelected: !!office,
      projectSelected: !!project
    });
  };

  // USE-EFFECTS
  useEffect(() => {
    if (!fetchedOffices.length) fetchOffices();
  }, [fetchedOffices]);

  // RENDER
  return (
    <Wrapper>
      <Heading heading="TN-Import">
        <PersonAdd />
      </Heading>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
        className={classes.stepper}
      >
        {steps.map((label: any) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={`${classes.instructionBox} ${classes.box}`}>
        <Typography className={classes.instructions}>
          {stepContents[activeStep]}
        </Typography>
      </div>
      <div className={classes.workbench}>
        {activeStep > 0 && (
          <div className={`${classes.infoBox} ${classes.box}`}>
            {selectedProject?.longName ? (
              <div className={classes.infoItem}>
                <AccountTreeIcon color="secondary" />
                <Typography>{selectedProject.longName}</Typography>
              </div>
            ) : (
              <></>
            )}
            {selectedOffice?.longName && activeStep > 0 && activeStep < 6 && (
              <div className={classes.infoItem}>
                <BusinessIcon color="secondary" />
                <Typography>{selectedOffice.longName}</Typography>
              </div>
            )}
            {fileName.length > 0 && (
              <div className={classes.infoItem}>
                <FileCopyIcon color="secondary" />
                <Typography>{fileName}</Typography>
              </div>
            )}
          </div>
        )}

        {activeStep >= 2 && (
          <div className={`${classes.tableBox} ${classes.box}`}>
            {isLoading ? (
              <LoadingAnimation />
            ) : fileLoaded && !importState.fetched ? (
              <ImportDataGrid {...dataGridProps} editable={isEditable} />
            ) : activeStep >= 6 &&
              importState.fetched &&
              importState.status === "ok" ? (
              <ImportResults {...resultsDataProps} />
            ) : (
              activeStep >= 6 &&
              importState.fetched &&
              importState.status !== "ok" && (
                <>
                  <Typography className={classes.warning}>
                    Das hat leider nicht geklappt! Bitte versuchen Sie es
                    erneut.
                    <br />
                    Wenn der Fehler weiterhin besteht, wenden Sie sich an
                    unseren Support.
                  </Typography>
                  <Link color="secondary" href="https://support.ki-test.com">
                    support.ki-test.com
                  </Link>
                </>
              )
            )}
          </div>
        )}
        {activeStep === 0 && (
          <div className={classes.selectBox}>
            {selectState.fetched && fetchedOffices.length > 0 && (
              <PositionSelector
                offices={fetchedOffices}
                onChange={(currents) => {
                  handleOfficeAndProjectSelected(
                    currents.office?.object,
                    currents.project?.object
                  );
                  if (
                    !currents.project?.reset &&
                    currents.project?.object?.id
                  ) {
                    setActiveStep(activeStep + 1);
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className={classes.buttonBox}>
        {activeStep >= 1 && (
          <Button
            className={classes.resetBtn}
            onClick={handleReset}
            disabled={isLoading}
            size="small"
          >
            Zurücksetzen
          </Button>
        )}
        {activeStep === 1 && <FileInput {...inputProps} />}
        {activeStep === 2 && (
          <Button
            color="secondary"
            variant="contained"
            size="small"
            onClick={handleContinue}
            disabled={isLoading}
          >
            weiter
          </Button>
        )}
        {activeStep === 3 && (
          <Button
            color="secondary"
            variant="contained"
            size="small"
            onClick={() => {
              setValidationState({
                processing: true,
                complete: validationState.complete,
                count: validationState.count
              });
            }}
            disabled={isLoading}
          >
            Erneut überprüfen
          </Button>
        )}
        {activeStep === 4 && (
          <>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => {
                setConflictState({
                  fetching: conflictState.fetching,
                  processing: true,
                  complete: conflictState.complete,
                  count: conflictState.count
                });
              }}
              disabled={isLoading}
            >
              Erneut überprüfen
            </Button>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => setActiveStep(5)}
              disabled={isLoading}
            >
              Weiter
            </Button>
          </>
        )}
        {activeStep === 5 && (
          <Button
            color="secondary"
            variant="contained"
            size="small"
            onClick={handleImport}
            disabled={isLoading}
          >
            Teilnehmer*innen importieren
          </Button>
        )}
      </div>
    </Wrapper>
  );
};
export default observer(ParticipantImport);
