import axios, { AxiosResponse } from "axios";
import { setServerUrl } from "../constants/constants";
import { IEventModel } from "../models/IEventModel";
import { EGroupStatus } from "../models/IGroupModel";

interface IUpdateEvent extends IEventModel {
  groupNames?: string[];
}

const EventService = {
  async getAllEvents(): Promise<any> {
    const rsp: AxiosResponse = await axios.get(
      setServerUrl(`/api/event/all`, `/api/event/all`)
    );
    if (rsp.data.status_code === 1000) {
      return Promise.resolve(rsp.data);
    } else {
      return Promise.reject("error");
    }
  },
  async getEvent(eventId: string): Promise<any> {
    const rsp: AxiosResponse = await axios.get(
      setServerUrl(`/api/event/${eventId}`, `/api/event/${eventId}`)
    );
    if (rsp.data.status_code === 1000) {
      return Promise.resolve(rsp.data.data);
    } else {
      return Promise.reject("error");
    }
  },
  async deleteEvent(eventId: string): Promise<any> {
    const rsp: AxiosResponse = await axios.delete(
      setServerUrl(`/api/event/${eventId}`, `/api/event/${eventId}`)
    );
    if (rsp.data.status_code === 1000) {
      return Promise.resolve(rsp.data);
    } else {
      return Promise.reject("error");
    }
  },
  async updateEvent(data: IUpdateEvent) {
    const rsp = await axios.put(setServerUrl(`/api/event`, `/api/event`), {
      eventId: data.id,
      projectId: data.Project?.id,
      officeId: data.Office?.id,
      userId: data.User?.id,
      note: data.note,
      date: data.date,
      location: data.location,
      status: data.status,
      groupNames: data.groupNames
    });
    return Promise.resolve(rsp.data);
  },
  async updateGroupStatus(id: string, status: EGroupStatus) {
    const rsp = await axios.put(
      setServerUrl(`/api/event/${id}`, `/api/event/${id}`),
      {
        status
      }
    );
    return Promise.resolve(rsp.data);
  }
};

export default EventService;
