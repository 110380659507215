import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { useEffect, useState } from "react";
import LoadingAnimation from "../components/LoadingAnimation";
import { toValidUntilString } from "../functions/date";
import { translateStatus } from "../functions/translateStatus";
import { IRouterResponseModel } from "../models/IRouterResponseModel";
import RoutingService from "../services/RoutingService";

export default function SurveyRouter() {
  const { getSurvey } = RoutingService;
  const [participantToken, setParticipantToken] = useState("");
  const [projectToken, setProjectToken] = useState("");
  const [isError, setIsError] = useState(false);
  const [isFreeze, setIsFreeze] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isAbort, setIsAbort] = useState(false);
  const [applications, setApplications] = useState([]);

  const strings = {
    table: {
      office: "Auftraggeber",
      position: "Zielposition",
      status: "Bewerbungsstatus",
      validity: "Teilnahmefrist"
    }
  };

  const handleQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const part = urlParams.get("part");
    const proj = urlParams.get("proj");
    if (part) {
      window.sessionStorage.setItem("part", part);
      setParticipantToken(part);
    } else {
      setIsError(true);
    }
    if (proj) {
      window.sessionStorage.setItem("proj", proj);
      setProjectToken(proj);
    } else {
      setIsError(true);
    }
  };

  const handleRouting = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const res: IRouterResponseModel = await getSurvey(
      participantToken,
      projectToken
    );
    const status = res?.status_code;
    const data = res?.data;

    if (status === 1000) {
      switch (data?.progress) {
        case "done":
          setIsDone(true);
          setApplications(data?.applications);
          break;
        case "continue":
          const dev = urlParams.get("dev");
          const link = `${data.link}${dev ? `&dev=${dev}` : ""}`;
          window.location.href = link;
          break;
        case "freeze":
          setIsFreeze(true);
          break;
        case "abort":
          setIsAbort(true);
          break;
        default:
          break;
      }
    } else {
      setIsError(true);
    }
  };

  // USE_EFFECTS
  useEffect(() => {
    handleQueryParams();
  }, []);

  useEffect(() => {
    if (participantToken && projectToken && !isDone) {
      handleRouting();
    }
  });

  return (
    <div
      style={{
        width: "100%",
        height: "60vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box"
      }}
    >
      {isError ? (
        <>
          <ErrorOutline
            style={{ fontSize: "6rem", margin: "2rem", color: "lightgrey" }}
          />
          <Typography>
            Hoppla! Wir konnten die aufgerufene Seite nicht finden.
          </Typography>
          <Typography style={{ textAlign: "center", maxWidth: "600px" }}>
            Bitte überprüfen Sie den Link und versuchen es noch einmal. Sollten
            Sie diese Seite weiterhin sehen, kontaktieren Sie bitte unseren
            Support. Wir kümmern uns schnellstmöglich um Ihr Anliegen.
          </Typography>
          <Button
            style={{ margin: "1rem" }}
            variant="outlined"
            color="secondary"
            href="https://support.ki-test.com/"
          >
            Zum Support
          </Button>
          <Typography>Sie können das Browser-Fenster nun schließen.</Typography>
        </>
      ) : isFreeze ? (
        <>
          <Typography style={{ textAlign: "center", maxWidth: "600px" }}>
            Sie haben angegeben ein technisches Problem im Rahmen des
            Online-Tests gehabt zu haben. Diese Anfrage befindet sich derzeit in
            Prüfung. Bitte haben Sie Verständnis, dass Sie den Test bis dahin
            nicht fortsetzen können. Bei Fragen wenden Sie sich gerne an unser
            Support-Team.
          </Typography>
          <Button
            style={{ margin: "1rem" }}
            variant="outlined"
            color="secondary"
            href="https://support.ki-test.com/"
          >
            Zum Support
          </Button>
        </>
      ) : isAbort ? (
        <>
          <Typography style={{ textAlign: "center", maxWidth: "600px" }}>
            Wir haben Ihren Antrag auf Datenlöschung erhalten.
          </Typography>
          <Typography>
            Bitte beachten Sie, dass Sie für einen Rückzug Ihrer Bewerbung
            zusätzlich die einstellende Behörde/Firma informieren müssen.
          </Typography>
          <Button
            style={{ margin: "1rem" }}
            variant="outlined"
            color="secondary"
            href="https://support.ki-test.com/"
          >
            Zum Support
          </Button>
        </>
      ) : isDone ? (
        <>
          <Typography
            style={{ textAlign: "center", maxWidth: "600px", margin: "3rem" }}
          >
            Zur Zeit liegen keine weiteren Tests für Sie vor. Nachfolgend finden
            Sie Ihre aktuellen Bewerbungen in der Übersicht:
          </Typography>
          <TableContainer component={Paper} style={{ maxWidth: "1000px" }}>
            <Table className="table">
              <TableHead>
                <TableRow>
                  <TableCell scope="col">{strings.table.office}</TableCell>
                  <TableCell scope="col">{strings.table.position}</TableCell>
                  <TableCell scope="col">{strings.table.status}</TableCell>
                  <TableCell scope="col">{strings.table.validity}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applications.map((application: any, i) => {
                  return (
                    <tr key={i}>
                      <TableCell>{application.Office.longName}</TableCell>
                      <TableCell>{application.Position.longName}</TableCell>
                      <TableCell>
                        {translateStatus("application", application.status)}
                      </TableCell>
                      <TableCell>
                        {toValidUntilString(application.validUntil)}
                      </TableCell>
                    </tr>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <LoadingAnimation />
          <Typography style={{ textAlign: "center", maxWidth: "600px" }}>
            Einen Augenblick bitte
          </Typography>
        </>
      )}
    </div>
  );
}
