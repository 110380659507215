import { ISurveyModel } from "./ISurveyModel";
export interface IRunModel {
  id: string;
  token: string;
  status: ERunStatus;
  barrier: boolean;
  supportStatus: string;
  accessible: string;
  dataSharing: boolean;
  invitedAt: Date;
  completedAt: Date;
  lastRemindedAt: Date;
  valid: number;
  validFrom: Date;
  validUntil: Date;
  participantId: string;
  systemUsed: string;
  browserUsed: string;
  surveyId: string;
  Survey?: ISurveyModel;
}

export enum ERunStatus {
  created = "created",
  valid = "valid",
  started = "started",
  complete = "complete",
  incomplete = "incomplete",
  expired = "expired"
}

export function emptyRun(): IRunModel {
  return {
    id: "",
    token: "",
    status: ERunStatus.created,
    barrier: false,
    supportStatus: "",
    accessible: "",
    dataSharing: false,
    invitedAt: new Date(),
    completedAt: new Date(),
    lastRemindedAt: new Date(),
    valid: 1,
    validFrom: new Date(),
    validUntil: new Date(),
    participantId: "",
    systemUsed: "",
    browserUsed: "",
    surveyId: ""
  };
}
