import DateFnsUtils from "@date-io/date-fns";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  DatePicker as Picker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import deDE from "date-fns/locale/de";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { dateFormats } from "../constants/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "250px"
    },
    red: {
      color: theme.palette.error.main
    },
    green: {
      color: theme.palette.success.main
    }
  })
);

export interface IDatePickerProps {
  allowKeyboardControl?: boolean;
  autoOk?: boolean;
  cancelBtnText?: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  initialDate?: Date;
  label: string;
  maxDate?: Date;
  maxDateMessage?: string;
  minDate?: Date;
  minDateMessage?: string;
  okBtnText?: string;
  openTo?: "date" | "month" | "year";
  processDate: (date: Date) => void;
}

const DatePicker: FC<IDatePickerProps> = ({
  allowKeyboardControl = true,
  autoOk = true,
  cancelBtnText = "Abbrechen",
  disablePast = false,
  disableFuture = false,
  initialDate = new Date(),
  label,
  maxDate = new Date("2100-01-01"),
  maxDateMessage = "Maximaldatum ungültig",
  minDate = new Date("1900-01-01"),
  minDateMessage = "Minimaldatum ungültig",
  okBtnText = "OK",
  openTo = "date",
  processDate
}) => {
  const classes = useStyles();
  const [date, setDate] = useState<Date>(initialDate);

  const selectDate = (date: any) => {
    setDate(date);
    processDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deDE}>
      <Picker
        fullWidth
        className={classes.root}
        allowKeyboardControl={allowKeyboardControl}
        openTo={openTo}
        autoOk={autoOk}
        cancelLabel={<span className={classes.red}>{cancelBtnText}</span>}
        disablePast={disablePast}
        disableFuture={disableFuture}
        format={dateFormats.default}
        initialFocusedDate={initialDate}
        label={label}
        maxDate={maxDate}
        maxDateMessage={maxDateMessage}
        minDate={minDate}
        minDateMessage={minDateMessage}
        okLabel={<span className={classes.green}>{okBtnText}</span>}
        onChange={(d: any) => selectDate(d)}
        value={date}
        variant="dialog"
      />
    </MuiPickersUtilsProvider>
  );
};

export default observer(DatePicker);
