import { Avatar, makeStyles, Theme } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { FC, ReactNode } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(3)
  },
  pageHeader: {
    fontSize: "1.5rem",
    fontFamily: "Open Sans",
    lineHeight: "1.334",
    fontWeight: "normal",
    margin: "0"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  }
}));

interface HeadingProps {
  heading: string;
  children: ReactNode;
}

const Heading: FC<HeadingProps> = ({ heading, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar className={classes.avatar}>{children}</Avatar>
      <h1 className={classes.pageHeader}>{heading}</h1>
    </div>
  );
};

export default observer(Heading);
