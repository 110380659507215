import { createStyles, makeStyles, Paper, Theme } from "@material-ui/core";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import MaterialTable, { Column } from "material-table";
import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";
import { IApplicationModel } from "../models/IApplicationModel";
import { emptyCompetency } from "../models/ICompetencyModel";
import { ICustomerItemModel } from "../models/ICustomerItemModel";
import { newCustomerItem } from "../models/ICustomerModel";
import { newOfficeItem } from "../models/IOfficeModel";
import { newProjectItem } from "../models/IProjectModel";
import { IUserModel } from "../models/IUserModel";
import AddApplication from "./dialogs/AddApplication";
import AddEvent from "./dialogs/AddEvent";
import AddSurvey from "./dialogs/AddSurvey";
import EditCompetency from "./dialogs/EditCompetency";
import EditCustomerItem from "./dialogs/EditCustomerItem";
import AddPosition from "./dialogs/EditPosition";
import EditUser from "./dialogs/EditUser";
import ExportProject, { IExportProject } from "./dialogs/ExportProject";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingTop: theme.spacing(3),
      boxSizing: "border-box",
      caretColor: theme.palette.primary.main
    },
    "@global": {
      ".MuiTableCell-head": {
        backgroundColor: `${theme.palette.secondary.main}`
      },
      ".MuiTableCell-head .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#FFF"
      }
    }
  })
);

export interface DataGridActionButtonProps {
  open: boolean;
  close: () => void;
  data?: any; // TODO: typing
}

export interface IAction {
  tooltip: string;
  icon: () => JSX.Element;
  isFreeAction?: boolean;
  onClick?: () => void;
}

export interface IReplaceSelectedRowModel {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  birthday: Date;
  importStatus: string;
  createdAt: Date;
  updatedAt: Date;
  batchId: string;
  Application: {
    status: string;
    importStatus: string;
    type: string;
    validFrom: Date;
    validUntil: Date;
    createdAt: Date;
    updatedAt: Date;
    officeId: string;
    participantId: string;
    batchId: string;
    positionId: string;
    Office: {
      longName: string;
      shortName: string;
      color: string;
      createdAt: Date;
      updatedAt: Date;
      customerId: string;
    };
  };
}
export interface ISelectedRowModel {
  applications: IApplicationModel;
  birthday: Date | string;
  firstName: string;
  lastName: string;
  status: string;
  tabledata: {
    id: number;
  };
}

export interface IAddDialog {
  open: boolean;
  close: () => void;
  data?: any;
}

export interface IAddEventDialog extends IAddDialog {
  minGroups?: number;
  maxGroups?: number;
  users: IUserModel[];
}

export interface ICustomerItemDialog extends ICustomerItemModel, IAddDialog {}

export interface DataGridActionButton {
  tooltip: string;
  icon: string | (() => JSX.Element);
  isFreeAction?: boolean;
  popup: FC<DataGridActionButtonProps>;
  data?: any;
  disabled?: boolean;
}

export interface IDataGridProps {
  tableButtons?: DataGridActionButton[];
  addApplicationButton?: boolean;
  addCustomerButton?: boolean;
  addEventButton?: boolean;
  addOfficeButton?: boolean;
  addPositionButton?: boolean;
  addProjectButton?: boolean;
  addSurveyButton?: boolean;
  addSurveyFunction?: () => void;
  addUserButton?: boolean;
  columnFilter?: boolean;
  columns: Column<any>[];
  data: IReplaceSelectedRowModel[] | any; // TODO: set proper interface
  dense?: boolean;
  detailPanel?: (row: any) => JSX.Element;
  dialogData?: any;
  editCompetencyButton?: boolean;
  exportCsv?: (projectId: string) => Promise<any>;
  fontColor?: string;
  fontSize?: string;
  headerColor?: string;
  headerFontColor?: string;
  hideContainer?: boolean;
  isDeletable?: (rows: any) => any;
  isEditable?: (rows: any) => any;
  isLoading?: boolean;
  onRowAdd?: (data: any) => Promise<any>;
  onRowClick?: (evt: any, selectedRow: any, togglePanel?: any) => void;
  onRowEditFunction?: (evt: any, selectedRow: any, togglePanel?: any) => void;
  onRowsDelete?: (oldData: any) => Promise<any>;
  onRowUpdate?: (data: any) => Promise<any>;
  pageSize?: number;
  pagingFilter?: boolean;
  searchFilter?: boolean;
  selectable?: boolean;
  selection?: boolean;
  singleCollapse?: boolean;
  title?: string;
  userList?: IUserModel[];
}

const DataGrid: FC<IDataGridProps> = ({
  tableButtons,
  addApplicationButton,
  addCustomerButton,
  addEventButton,
  addOfficeButton,
  addPositionButton,
  addProjectButton,
  addSurveyButton,
  addSurveyFunction,
  addUserButton,
  columnFilter,
  columns,
  data,
  dense,
  detailPanel,
  dialogData,
  editCompetencyButton,
  exportCsv,
  fontColor,
  fontSize,
  headerColor,
  headerFontColor,
  hideContainer,
  isDeletable,
  isEditable,
  isLoading,
  onRowAdd,
  onRowClick,
  onRowsDelete,
  onRowUpdate,
  pageSize,
  pagingFilter,
  searchFilter,
  selectable,
  selection,
  singleCollapse,
  title,
  userList
}) => {
  const classes = useStyles();
  const selectedRows = React.useRef(data);
  const [open, setOpen] = useState<boolean>(false);

  const [tableButtonOpenStates, setTableButtonOpenStates] = useState<boolean[]>(
    Array(tableButtons?.length)
  );

  const handleRowSelection = (rows: any) => {
    selectedRows.current = rows;
  };

  const renderColumnStyle = (): any => {
    if (fontSize) {
      columns.forEach((column) => {
        column.cellStyle = {
          ...column.cellStyle,
          fontSize: "0.8rem"
        };
      });
    }
    if (fontColor) {
      columns.forEach((column) => {
        column.cellStyle = {
          ...column.cellStyle,
          color: fontColor
        };
      });
    }
    return columns;
  };

  const ExportProjectDialogProps: IExportProject = {
    open,
    close: () => setOpen(false)
  };

  const addSurveyDialogProps: IAddDialog = {
    open,
    close: () => setOpen(false),
    data
  };

  const addCompetencyDialogProps: IAddDialog = {
    open,
    close: () => setOpen(false),
    data: emptyCompetency()
  };

  const addPositionDialogProps: IAddDialog = {
    open,
    close: () => setOpen(false)
  };

  const addCustomerDialogProps: ICustomerItemDialog = {
    ...newCustomerItem(),
    open,
    close: () => setOpen(false)
  };

  const addProjectDialogProps: ICustomerItemDialog = {
    ...newProjectItem(),
    open,
    close: () => setOpen(false)
  };

  const addOfficeDialogProps: ICustomerItemDialog = {
    ...newOfficeItem(),
    open,
    close: () => setOpen(false)
  };

  const addUserDialogProps: IAddDialog = {
    open,
    close: () => setOpen(false),
    data
  };

  const addApplicationDialogProps: IAddDialog = {
    open,
    close: () => setOpen(false),
    data: dialogData
  };

  const addEventDialogProps: IAddEventDialog = {
    open,
    close: () => setOpen(false),
    data: dialogData,
    users: userList ? userList : []
  };

  const getAction = (tooltip: string): IAction => {
    return {
      tooltip,
      icon: () => <ControlPointDuplicateIcon />,
      isFreeAction: true,
      onClick: () => setOpen(true)
    };
  };

  let actions: any[] = [
    editCompetencyButton && getAction("Kompetenz erstellen"),
    addCustomerButton && getAction("Kunde erstellen"),
    addOfficeButton && getAction("Einheit erstellen"),
    addPositionButton && getAction("Position erstellen"),
    addProjectButton && getAction("Projekt erstellen"),
    addSurveyButton && getAction("Bestehende Umfrage duplizieren"),
    addUserButton && getAction("Benutzer*in hinzufügen"),
    addApplicationButton && getAction("Bewerbung hinzufügen"),
    addEventButton && getAction("Event erstellen")
  ];

  if (tableButtons) {
    actions = actions?.concat(
      tableButtons.map((tableButton, i) => ({
        ...tableButton,
        onClick: () => {
          // !TODO: clean this up
          const item = Array(tableButtons.length);
          item[i] = true;
          setTableButtonOpenStates(item);
        }
      }))
    );
  }

  return (
    <div className={classes.root}>
      <MaterialTable
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} von {count}",
            labelRowsSelect: "Einträge",
            firstTooltip: "Erste Seite",
            previousTooltip: "Vorherige Seite",
            nextTooltip: "Nächste Seite",
            lastTooltip: "Letzte Seite"
          },
          toolbar: {
            searchPlaceholder: "Suche",
            searchTooltip: "Suche",
            nRowsSelected: (rowCount) => {
              return rowCount === data.length
                ? `Alle Teilnehmer*innen ausgewählt (${rowCount})`
                : `${rowCount} Teilnehmer*innen ausgewählt`;
            }
          },
          header: {
            actions: "Aktion"
          },
          body: {
            emptyDataSourceMessage: "Keine Elemente gefunden",
            filterRow: {
              filterTooltip: "Filter"
            },
            editRow: {
              deleteText: "Element löschen?",
              cancelTooltip: "Abbrechen",
              saveTooltip: "Speichern"
            },
            deleteTooltip: "Löschen",
            editTooltip: "Bearbeiten"
          }
        }}
        title={title}
        columns={renderColumnStyle()}
        data={data}
        isLoading={isLoading}
        onRowClick={onRowClick}
        detailPanel={detailPanel}
        editable={{
          isDeletable,
          isEditable,
          onRowDelete: onRowsDelete,
          onRowUpdate,
          onRowAdd
        }}
        onSelectionChange={(rows) => {
          handleRowSelection(rows);
        }}
        options={{
          actionsColumnIndex: -1,
          detailPanelType: singleCollapse ? "single" : "multiple",
          emptyRowsWhenPaging: false,
          filtering: columnFilter,
          headerStyle: {
            backgroundColor: headerColor ? headerColor : "#008dcf",
            color: headerFontColor ? headerFontColor : "#fff"
          },
          padding: dense ? "dense" : "default",
          pageSize: pageSize ? pageSize : 5,
          pageSizeOptions: [5, 10, 20, 50, 100],
          paginationType: "stepped",
          paging: pagingFilter,
          search: searchFilter,
          selection: selectable,
          showTitle: typeof title === "string" && title.length > 0,
          tableLayout: "auto"
        }}
        components={{
          Container: (props: any) => {
            return hideContainer ? (
              <div {...props}></div>
            ) : (
              <Paper {...props} />
            );
          }
        }}
        actions={actions}
      />
      {editCompetencyButton && <EditCompetency {...addCompetencyDialogProps} />}
      {addCustomerButton && <EditCustomerItem {...addCustomerDialogProps} />}
      {addOfficeButton && <EditCustomerItem {...addOfficeDialogProps} />}
      {addPositionButton && <AddPosition {...addPositionDialogProps} />}
      {addProjectButton && <EditCustomerItem {...addProjectDialogProps} />}
      {addSurveyButton && <AddSurvey {...addSurveyDialogProps} />}
      {addUserButton && <EditUser {...addUserDialogProps} />}
      {addApplicationButton && (
        <AddApplication {...addApplicationDialogProps} />
      )}
      {addEventButton && <AddEvent {...addEventDialogProps} />}
      {tableButtons &&
        tableButtons.map((tb, idx) => {
          // console.log(idx);
          return (
            <tb.popup
              open={tableButtonOpenStates[idx]}
              close={() => {
                const nt = Array(tableButtons.length);
                nt[idx] = false;
                setTableButtonOpenStates(
                  // tableButtonOpenStates.splice(idx, 1, false)
                  // [false, false]
                  nt
                );
              }}
              data={tb.data}
            />
          );
        })}
    </div>
  );
};

export default observer(DataGrid);
