import axios from "axios";
import jwt_decode from "jwt-decode";
import { action, observable, runInAction } from "mobx";
import { createContext } from "react";
import { IUserForm } from "../components/UserRegistration";
import { IUserModel } from "../models/IUserModel";
import UserService from "../services/UserService";

class UserStore {
  @observable public fetchedUsers: IUserModel[];
  @observable public isfetchingData: boolean;
  @observable public loggedIn: boolean;
  @observable public isAdmin: boolean;
  @observable public isSupport: boolean;
  @observable public isAdminSupport: boolean;
  @observable public isCustomer: boolean;
  @observable public user: IUserModel;
  @observable public userRole: number;

  constructor() {
    this.fetchedUsers = [] as IUserModel[];
    this.isfetchingData = false;
    this.loggedIn = false;
    this.isAdmin = false;
    this.isSupport = false;
    this.isAdminSupport = false;
    this.isCustomer = false;
    this.user = {} as IUserModel;
    this.userRole = 0;
  }

  @action("set User")
  public setUser = (user: IUserModel, loggedIn = true) => {
    this.user = user;
    this.loggedIn = loggedIn;
    this.userRole = Number(user.roleId);
    this.isAdmin = Number(user.roleId) === 1;
    this.isSupport = Number(user.roleId) === 2;
    this.isAdminSupport =
      Number(user.roleId) === 1 || Number(user.roleId) === 2;
    this.isCustomer = Number(user.roleId) === 3;
  };

  @action("map user role")
  public getRoleString = (user: IUserModel): string => {
    const mapping: any = {
      1: "admin",
      2: "support",
      3: "customer",
      4: "assistant"
    };
    return mapping[user.roleId];
  };

  @action("get current user")
  public getUser = () => {
    runInAction("Get current user", async () => {
      const user = await UserService.getUser();
      this.setUser(user);
    });
  };

  @action("get all users")
  public getUsers = () => {
    this.isfetchingData = true;
    runInAction("Get all users", async () => {
      this.fetchedUsers = await UserService.getUsers();
      this.isfetchingData = false;
    });
  };

  @action("login User")
  public login = async (user: any) => {
    const res = await UserService.loginUser(user);
    // no check for token if user does not exist
    const { token } = res;
    if (token) {
      // Apply authorization token to every request if logged in
      localStorage.setItem("jwtToken", token);
      // tslint:disable-next-line:no-string-literal
      axios.defaults.headers.common["Authorization"] = token;
      const decoded: IUserModel = jwt_decode(token);
      this.setUser({
        id: decoded.id,
        firstName: decoded.firstName,
        lastName: decoded.lastName,
        email: decoded.email,
        roleId: decoded.roleId,
        reports: decoded.reports,
        revaluation: decoded.revaluation,
        showResults: decoded.showResults,
        navPref: decoded.navPref
      });
      return await Promise.resolve(true);
    } else {
      // Delete auth header
      // tslint:disable-next-line:no-string-literal
      delete axios.defaults.headers.common["Authorization"];
      return await Promise.resolve(false);
    }
  };

  @action("delete user")
  public deleteUser = (userId: string) => {
    this.isfetchingData = true;
    runInAction("Delete User", async () => {
      this.fetchedUsers = await UserService.deleteUser(userId);
      this.isfetchingData = false;
    });
  };

  @action("update user")
  public updateUser = (data: IUserForm) => {
    this.isfetchingData = true;
    runInAction("Update User", async () => {
      this.fetchedUsers = await UserService.updateUser(data);
      this.isfetchingData = false;
    });
  };

  @action("change navigation preferences")
  public changeNavPrefs = (navPref: string) => {
    runInAction("Change navigation preferences", async () => {
      const user = await UserService.changeNavPrefs(navPref);
      this.setUser({ ...this.user, navPref: user.navPref }, true);
    });
  };
}
export default createContext(new UserStore());
