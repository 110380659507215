import {
  createMuiTheme,
  createStyles,
  Grid,
  makeStyles,
  MuiThemeProvider,
  Theme
} from "@material-ui/core";
import axios from "axios";
import deDE from "date-fns/locale/de";
import jwt_decode from "jwt-decode";
import { observer } from "mobx-react-lite";
import { SnackbarProvider } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Redirect, Route, useHistory } from "react-router-dom";
import "./App.css";
import Competencies from "./components/Competencies";
import Converter from "./components/Converter";
import Customer from "./components/Customers";
import CustomerDetail from "./components/details/CustomerDetail";
import EventDetail from "./components/details/EventDetail";
import { NormDetail } from "./components/details/NormDetail";
import OfficeDetail from "./components/details/OfficeDetail";
import Participant from "./components/details/ParticipantDetail";
import ProjectDetail from "./components/details/ProjectDetail";
import { RvTypeDetail } from "./components/details/RvTypeDetail";
import SurveyDetails from "./components/details/SurveyDetail";
import ErrorFallback from "./components/ErrorFallback";
import Events from "./components/Events";
import ParticipantImport from "./components/importer/ParticipantImport";
import NavBar from "./components/NavBar";
import NavigationDrawer from "./components/navigation/NavigationDrawer";
import { Norms } from "./components/Norms";
import Participants from "./components/Participants";
import { RvTypes } from "./components/RvTypes";
import Surveys from "./components/Surveys";
import Users from "./components/Users";
import LoginPage from "./pages/LoginPage";
import SurveyRouter from "./pages/SurveyRouter";
import DrawerStore from "./stores/DrawerStore";
import UserStore from "./stores/UserStore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(12),
      fontFamily: '"Open Sans", "Cambria"'
    },
    "@media (max-width: 812px)": {
      root: {
        padding: theme.spacing(2)
      }
    },
    "@global .App": {
      display: "flex"
    }
  })
);

const App: FC = () => {
  const theme = createMuiTheme(
    {
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 800,
          lg: 900,
          xl: 1200
        }
      },
      palette: {
        type: "light",
        primary: { light: "#ffffff", main: "#404040", dark: "#000000" },
        secondary: { light: "#1998d3", main: "#008dcf", dark: "#015f8ad8" },
        info: { light: "#999999", main: "#666666", dark: "#323232" },
        warning: { light: "#f1cb80", main: "#e39802", dark: "#b57901" },
        success: { light: "#59a75c", main: "#309134", dark: "#1c551e" },
        error: { light: "#c43a49", main: "#b60a1c", dark: "#910816" }
      },
      typography: {
        fontFamily: "Open Sans"
      }
    },
    deDE
  );

  const history = useHistory();
  const { setUser, loggedIn, user, getUser, isAdminSupport, isCustomer } =
    useContext(UserStore);
  const drawerStore = useContext(DrawerStore);
  const { setDrawerOpen } = drawerStore;

  const [exclLoc] = useState<Array<string>>(["/", "/login", "/router"]);
  const [storedLocation] = useState<string | null>(
    localStorage.getItem("path")
  );
  const [currentLocation] = useState<string>(history.location.pathname);
  const [windowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    // Check for jwt to keep user logged in
    if (localStorage.jwtToken) {
      // Set auth token header auth
      const token = localStorage.jwtToken;
      if (token) {
        // Apply authorization token to every request if logged in
        // tslint:disable-next-line:no-string-literal
        axios.defaults.headers.common["Authorization"] = token;
      } else {
        // Delete auth header
        // tslint:disable-next-line:no-string-literal
        delete axios.defaults.headers.common["Authorization"];
      }
      getUser();
      // Decode token and get user info and exp
      const decoded: any = jwt_decode(token);
      // Check for expired token
      const currentTime = Date.now() / 1000; // to get in milliseconds
      if (decoded.exp < currentTime) {
        history.push("/login");
      }
    } else if (currentLocation !== "/router") {
      history.push("/login");
    }
  }, [setUser, history, getUser, loggedIn, currentLocation]);

  useEffect(() => {
    if (user.navPref === "folded") {
      setDrawerOpen(false);
    } else if (user.navPref === "unfolded") {
      setDrawerOpen(true);
    } else if (user.navPref === "flexible") {
      if (windowWidth >= 1480) {
        setDrawerOpen(true);
      } else {
        setDrawerOpen(false);
      }
    }
  }, [windowWidth, setDrawerOpen, user.navPref]);

  useEffect(() => {
    axios.defaults.timeout = 120000; // 2 minutes
  }, []);

  // redirect to last page if stored in local storage
  if (loggedIn && storedLocation && !exclLoc.includes(storedLocation)) {
    history.push(storedLocation);
  }

  const classes = useStyles();
  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={6}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <div id="app" className="App">
            <Route // only render navbar if path does not include "/router"
              path="/"
              render={({ location }) =>
                ["/router"].includes(location.pathname) ? null : <NavBar />
              }
            />
            {loggedIn && (
              <>
                <Route path="/" component={NavigationDrawer} />
              </>
            )}
            <Grid container={true} justify="center" className={classes.root}>
              {loggedIn && (
                <>
                  {isAdminSupport && (
                    <>
                      <Route
                        exact
                        path="/participantImport"
                        component={ParticipantImport}
                      />
                      {/* <Route
                        exact
                        path="/register"
                        component={UserRegistration}
                      /> */}
                      <Route exact path="/users" component={Users} />
                      <Route exact path="/customer" component={Customer} />
                      <Route
                        exact
                        path="/customer/:id"
                        component={CustomerDetail}
                      />
                      <Route
                        exact
                        path="/project/:id"
                        component={ProjectDetail}
                      />
                      <Route
                        exact
                        path="/office/:id"
                        component={OfficeDetail}
                      />
                      <Route exact path="/converter" component={Converter} />
                      <Route exact path="/survey" component={Surveys} />
                      <Route
                        exact
                        path="/survey/:id"
                        component={SurveyDetails}
                      />
                      <Route
                        exact
                        path="/competency"
                        component={Competencies}
                      />
                      <Route exact path="/rvtypes" component={RvTypes} />
                      <Route
                        exact
                        path="/rvtype/:id"
                        component={RvTypeDetail}
                      />
                      <Route exact path="/norm" component={Norms} />
                      <Route exact path="/norm/:id" component={NormDetail} />
                    </>
                  )}
                  {(isAdminSupport || isCustomer) && (
                    <>
                      <Route
                        exact
                        path="/participants"
                        component={Participants}
                      />
                      <Route
                        exact
                        path="/participant/:id"
                        component={Participant}
                      />
                      <Redirect exact from="/" to="/participants" />
                    </>
                  )}
                  <Route exact path="/events" component={Events} />
                  <Route exact path="/event/:id" component={EventDetail} />
                </>
              )}
              <Route exact path="/router" component={SurveyRouter} />
              <Route exact path="/login" component={LoginPage} />
            </Grid>
          </div>
        </ErrorBoundary>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

export default observer(App);
