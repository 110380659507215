import { createStyles, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { FC, ReactElement } from "react";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: "20px",
      height: "20px",
      display: "flex",
      padding: "3px",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      borderColor: "black"
    },
    green: {
      color: theme.palette.success.main,
      borderColor: theme.palette.success.dark
    },
    border: {
      border: "2px solid",
      backgroundColor: "ghost-white"
    }
  });
});

interface IIconDrop {
  title?: string;
  icon: ReactElement;
  enabled: boolean;
  dense?: boolean;
}

const IconDrop: FC<IIconDrop> = ({
  title = "",
  icon,
  enabled,
  dense
}) => {
  const classes = useStyles();

  return (
    <Tooltip title={title}>
      <div
        className={`${!dense && classes.border} ${classes.root} ${
          enabled && classes.green
        } `}
      >
        {icon}
      </div>
    </Tooltip>
  );
};

export default observer(IconDrop);
