/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Avatar,
  Button,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { Person } from "@material-ui/icons";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import LinkIcon from "@material-ui/icons/Link";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import SettingsIcon from "@material-ui/icons/Settings";
import copy from "clipboard-copy";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import short from "short-uuid";
import { getNotiOptions } from "../../constants/configs";
import { getPortalUrl, locale, ResultType } from "../../constants/constants";
import { formatDate, parseAndFormat } from "../../functions/date";
import { translateStatus } from "../../functions/translateStatus";
import { IApplicationModel } from "../../models/IApplicationModel";
import {
  IParticipantDetailModel,
  TParticipantSupportstatus
} from "../../models/IParticipantDetailModel";
import { IRunModel } from "../../models/IRunModel";
import ParticipantService from "../../services/ParticipantService";
import ParticipantsStore from "../../stores/ParticipantsStore";
import UserStore from "../../stores/UserStore";
import ActivityStreamNew from "../ActivityStream";
import Wrapper from "../containers/Wrapper";
import DataGrid, { IDataGridProps } from "../DataGrid";
import ApplicationMenu from "../dialogs/ApplicationMenu";
import EditParticipant from "../dialogs/EditParticipant";
import EditRun from "../dialogs/EditRun";
import LoadingAnimation from "../LoadingAnimation";
import ApplicationDetail from "./ApplicationDetail";

const convert = short();

const str = {
  tool: {
    copy: {
      sid: "Umfrage-ID kopieren",
      desc: "Umfragename kopieren",
      link: "Umfragelink kopieren",
      email: "E-Mail-Adresse kopieren",
      gender: "Geschlecht kopieren",
      birthday: "Geburtstag kopieren"
    },
    edit: {
      application: "Bewerbung editieren",
      run: "Run editieren"
    },
    archive: {
      application: {
        inactive: "Freigabe aufheben",
        active: "Zur archivierung freigeben"
      }
    },
    recalculate: {
      application: "Werte neu berechnen"
    }
  },
  noti: {
    copy: {
      token: "Token kopiert",
      sid: "Umfrage-ID kopiert",
      desc: "Umfragename kopiert",
      link: "Umfragelink kopiert",
      email: "E-Mail-Adresse kopiert",
      gender: "Geschlecht kopiert",
      birthday: "Geburtstag kopiert"
    }
  },
  misc: {
    supp: {
      minor: "Minor Support",
      major: "Major Support"
    },
    vCard: {
      birthday: "Geburtstag:",
      gender: "Geschlecht:",
      email: "E-Mail-Adresse:",
      supp: "Supportstatus:"
    },
    showResults: {
      pending: "Es sind noch keine Ergebnisse verfügbar",
      support:
        "Es liegt ein Supportfall vor, die Ergebnisse können nicht angezeigt werden"
    }
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1)
  },
  chip: {
    backgroundColor: "limegreen",
    color: "white"
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Open Sans",
    justifyContent: "flex-start",
    maxWidth: "1180px"
  },
  rawdata: {
    display: "flex",
    justifyContent: "center"
  },
  rawdataText: {
    margin: "0",
    paddingRight: "10px"
  },
  vcard: {
    alignItems: "flex-start",
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "1px",
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    width: "100%"
  },
  tablerow: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  participantName: {
    fontSize: "2rem",
    margin: "0"
  },
  participantDetails: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start"
  },
  avatarBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(2),
    userSelect: "none",
    width: "100%"
  },
  infoBox: {
    width: "100%"
  },
  label: {
    margin: "0",
    minWidth: "250px",
    padding: theme.spacing(0.5),
    textAlign: "left"
  },
  input: {
    margin: "0",
    minWidth: "250px",
    padding: theme.spacing(0.5),
    textAlign: "left"
  },
  buttons: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: "50px",
    width: "100%"
  },
  gridContainer: {
    boxSizing: "border-box",
    padding: "1rem",
    width: "100%",
    "@global": {
      ".MuiToolbar-root": {
        display: "none"
      },
      ".MuiMenu-paper": {
        marginTop: "45px"
      }
    }
  },
  comment: {
    marginTop: "10px",
    textAlign: "left",
    width: "100%"
  },
  actions: {
    alignItems: "center",
    display: "flex"
  },
  deleteButton: {
    cursor: "pointer",
    padding: "0 10px"
  },
  button: {
    margin: theme.spacing(3),
    width: "fit-content"
  },
  results: {
    display: "flex",
    justifyContent: "center",
    fontStyle: "italic"
  },
  dropdown: {
    display: "block",
    maxWidth: "40%",
    margin: "10px 0"
  },
  fromUntil: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  red: {
    color: theme.palette.error.main
  },
  yellow: {
    color: theme.palette.warning.main
  },
  grey: {
    color: theme.palette.info.main
  }
}));

const basicRunCellStyle = {
  maxWidth: `${1000 / 7}px`,
  overflow: "hidden",
  textOverflow: "ellipsis"
};

interface IDetailParams {
  id: string;
}

interface IDialogStatus {
  applicationEdit: boolean;
  applicationArchive: boolean;
  run: boolean;
  participant: boolean;
  recalulate: boolean;
}

const Participant: FC<RouteComponentProps<IDetailParams>> = ({ match }) => {
  const participantId = match.params.id;
  const portalUrl = getPortalUrl();

  const { fetchedParticipant, isfetchingData, getParticipantById } =
    useContext(ParticipantsStore);
  const { isAdminSupport } = useContext(UserStore);
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { updateSupportStatus } = ParticipantService;

  const [editingRun, setEditingRun] = useState<IRunModel>({} as IRunModel);
  const [dialogOpen, setDialogOpen] = useState<IDialogStatus>({
    applicationEdit: false,
    applicationArchive: false,
    run: false,
    participant: false,
    recalulate: false
  });

  const handlePageBack = () => {
    history.push(`/participants`);
  };

  const handleCopy = (identifier: "token" | "sid" | "desc", value: string) => {
    copy(value);
    enqueueSnackbar(str.noti.copy[identifier], getNotiOptions());
  };

  const copySurveylink = (e: any, application: IApplicationModel) => {
    const link = `${portalUrl}/router?part=${convert.fromUUID(
      participantId
    )}&proj=${convert.fromUUID(application.Position.projectId)}`;

    e.stopPropagation();
    copy(link);
    enqueueSnackbar(str.noti.copy.link, getNotiOptions());
  };

  const toggleSupport = async (
    instance: IRunModel | IParticipantDetailModel,
    severity: TParticipantSupportstatus,
    level: "run" | "participant"
  ) => {
    instance.supportStatus === severity ? null : severity;
    const rsp = await updateSupportStatus(instance.id, severity, level);
    if (rsp.status_code === 1000) {
      enqueueSnackbar(
        `Supportfall: "${
          instance.supportStatus === severity ? "aufgelöst" : severity
        }"`,
        getNotiOptions("success")
      );
    } else {
      enqueueSnackbar(`Aktualisierung fehlgeschlagen`, getNotiOptions("error"));
    }
    history.go(0);
  };

  const toggleRunEditDialog = (run: IRunModel) => {
    setEditingRun(run);
    setDialogOpen({ ...dialogOpen, run: true });
  };

  const toggleParticipantEditDialog = () => {
    setDialogOpen({ ...dialogOpen, participant: true });
  };

  useEffect(() => {
    getParticipantById(participantId);
  }, [getParticipantById, participantId]);

  const applicationDataprops: IDataGridProps = {
    searchFilter: false,
    title: "Bewerbungen",
    addApplicationButton: isAdminSupport,
    dialogData: fetchedParticipant,
    detailPanel: (row: IApplicationModel) => {
      if (
        row.status === "complete" &&
        !fetchedParticipant.supportStatus &&
        (isAdminSupport ||
          (!isAdminSupport && row.Office.Customer?.showResults))
      ) {
        return (
          <ApplicationDetail
            application={row}
            participantId={participantId}
            firstName={fetchedParticipant.firstName}
            lastName={fetchedParticipant.lastName}
            showAc={false}
          />
        );
      } else {
        return (
          <div className={classes.results}>
            <Typography>
              {"- "}
              {fetchedParticipant.supportStatus
                ? str.misc.showResults.support
                : str.misc.showResults.pending}
              {" -"}
            </Typography>
          </div>
        );
      }
    },
    onRowClick: (event, row, togglePanel) => togglePanel(),
    columns: [
      { title: "Position", field: "Position.longName" },
      {
        dateSetting: { locale },
        field: "createdAt",
        defaultSort: "asc",
        title: "Erstellt",
        sorting: true,
        type: "date",
        render: (row: IApplicationModel) => (
          <span>{parseAndFormat(row.createdAt)}</span>
        )
      },
      {
        field: "status",
        title: "Status",
        render: (row: IApplicationModel) => (
          <div
            style={{
              whiteSpace: "nowrap",
              display: "inline"
            }}
          >
            <span
              style={{
                paddingRight: "10px"
              }}
            >
              {translateStatus(
                "application",
                row.status,
                fetchedParticipant.supportStatus
              )}
            </span>
            <span>
              {row.Results?.filter(
                (result: any) =>
                  result.type === ResultType.CompetencyAC ||
                  result.type === ResultType.PositionAC
              ).length ? (
                <Chip size="small" className={classes.chip} label="AC" />
              ) : (
                <></>
              )}
            </span>
          </div>
        )
      },

      {
        title: "Gültigkeit",
        render: (row: IApplicationModel) => (
          <div>
            <div className={classes.fromUntil}>
              <Typography>{parseAndFormat(row["validFrom"])}</Typography>
              <Typography>{"-"}</Typography>
            </div>
            <div className={classes.fromUntil}>
              <Typography>{parseAndFormat(row["validUntil"])}</Typography>
            </div>
          </div>
        )
      },
      {
        title: "Einheit",
        field: "Office.longName"
      },
      {
        render: (row: IApplicationModel) =>
          isAdminSupport && (
            <>
              <Tooltip title={str.tool.copy.link}>
                <IconButton
                  size="small"
                  onClick={(e) => copySurveylink(e, row)}
                >
                  <LinkIcon key={row.id} className={classes.grey} />
                </IconButton>
              </Tooltip>
              <ApplicationMenu
                participantId={participantId}
                application={row}
              />
            </>
          )
      }
    ],
    data: fetchedParticipant?.id
      ? JSON.parse(JSON.stringify(fetchedParticipant?.Applications))
      : []
  };

  const runDataprops: IDataGridProps = {
    title: "Testdurchläufe",
    searchFilter: false,
    columns: [
      {
        align: "left",
        cellStyle: { ...basicRunCellStyle, cursor: "pointer" },
        defaultSort: "asc",
        field: "Survey.description",
        sorting: true,
        title: "Umfrage",
        type: "string",
        render: (row) => (
          <Tooltip title={str.tool.copy.desc}>
            <span onClick={() => handleCopy("desc", row.Survey?.description)}>
              {row.Survey?.description}
            </span>
          </Tooltip>
        )
      },
      {
        align: "left",
        cellStyle: { ...basicRunCellStyle, cursor: "pointer" },
        field: "Survey.sid",
        title: "Umfrage\u00A0ID",
        type: "numeric",
        render: (row) => (
          <Tooltip title={str.tool.copy.sid}>
            <span onClick={() => handleCopy("sid", row.Survey?.sid)}>
              {row.Survey?.sid}
            </span>
          </Tooltip>
        )
      },
      {
        align: "left",
        cellStyle: basicRunCellStyle,
        field: "status",
        title: "Status",
        render: (row) => (
          <Tooltip title={translateStatus("run", row.status)}>
            <span>{translateStatus("run", row.status)}</span>
          </Tooltip>
        )
      },
      {
        align: "left",
        cellStyle: { ...basicRunCellStyle, cursor: "pointer" },
        field: "token",
        title: "Token",
        render: (row) => (
          <Tooltip title={row.token}>
            <span onClick={() => handleCopy("token", row.token)}>
              {row.token}
            </span>
          </Tooltip>
        )
      },
      {
        align: "left",
        cellStyle: basicRunCellStyle,
        dateSetting: { locale },
        field: "validFrom",
        title: "Gültig\u00A0von",
        type: "date",
        render: (row) => (
          <Tooltip title={parseAndFormat(row["validFrom"])}>
            <span>{parseAndFormat(row["validFrom"])}</span>
          </Tooltip>
        )
      },
      {
        align: "left",
        cellStyle: basicRunCellStyle,
        dateSetting: { locale },
        field: "validUntil",
        title: "Gültig\u00A0bis",
        type: "date",
        render: (row) => (
          <Tooltip title={parseAndFormat(row["validUntil"])}>
            <span>{parseAndFormat(row["validUntil"])}</span>
          </Tooltip>
        )
      },
      {
        title: "Support",
        cellStyle: basicRunCellStyle,
        render: (row) =>
          row.valid ? (
            <div>
              <Tooltip title={str.misc.supp.minor} arrow>
                <IconButton
                  key={row.supportStatus}
                  className={`${
                    row.supportStatus === "minor"
                      ? classes.yellow
                      : classes.grey
                  }`}
                  size="small"
                  onClick={() => toggleSupport(row, "minor", "run")}
                >
                  <ErrorOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={str.misc.supp.major} arrow>
                <IconButton
                  key={row.supportStatus}
                  className={`${
                    row.supportStatus === "major" ? classes.red : classes.grey
                  }`}
                  size="small"
                  onClick={() => toggleSupport(row, "major", "run")}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={str.tool.edit.run} arrow>
                <IconButton
                  key={row.valid}
                  size="small"
                  onClick={() => toggleRunEditDialog(row)}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <Typography className={classes.red}>ungültig</Typography>
          )
      }
    ],
    data: fetchedParticipant?.Runs
      ? JSON.parse(JSON.stringify(fetchedParticipant?.Runs))
      : []
  };

  const getVCardContent = () => {
    return (
      <div className={classes.vcard} data-cy="info-vcard">
        <div className={classes.avatarBox}>
          <div className={classes.participantDetails}>
            <Avatar className={classes.avatar} data-cy="info-avatar">
              <Person />
            </Avatar>
            <Typography className={classes.participantName}>
              {fetchedParticipant.firstName} {fetchedParticipant.lastName}
            </Typography>
          </div>
          {isAdminSupport && (
            <IconButton size="small" onClick={toggleParticipantEditDialog}>
              <SettingsIcon />
            </IconButton>
          )}
        </div>
        <div className={classes.infoBox}>
          <div className={classes.tablerow}>
            <Typography className={classes.label}>
              {str.misc.vCard.birthday}
            </Typography>
            <Tooltip title={str.tool.copy.birthday}>
              <Typography
                className={classes.input}
                style={{ cursor: "pointer", userSelect: "none" }}
                onClick={() => {
                  copy(formatDate(new Date(fetchedParticipant.birthday)));
                  enqueueSnackbar(str.noti.copy.birthday, getNotiOptions());
                }}
              >
                {formatDate(new Date(fetchedParticipant.birthday))}
              </Typography>
            </Tooltip>
          </div>
          <div className={classes.tablerow}>
            <Typography className={classes.label}>
              {str.misc.vCard.gender}
            </Typography>
            <Tooltip title={str.tool.copy.gender}>
              <Typography
                className={classes.input}
                style={{ cursor: "pointer", userSelect: "none" }}
                onClick={() => {
                  copy(fetchedParticipant.gender);
                  enqueueSnackbar(str.noti.copy.gender, getNotiOptions());
                }}
              >
                {fetchedParticipant.gender}
              </Typography>
            </Tooltip>
          </div>
          <div className={classes.tablerow}>
            <Typography className={classes.label}>
              {str.misc.vCard.email}
            </Typography>
            <Tooltip title={str.tool.copy.email}>
              <Typography
                className={classes.input}
                style={{ cursor: "pointer", userSelect: "none" }}
                onClick={() => {
                  copy(fetchedParticipant.email);
                  enqueueSnackbar(str.noti.copy.email, getNotiOptions());
                }}
              >
                {fetchedParticipant.email}
              </Typography>
            </Tooltip>
          </div>
          {isAdminSupport && (
            <div className={classes.tablerow}>
              <Typography className={classes.label}>
                {str.misc.vCard.supp}
              </Typography>
              <Tooltip title={str.misc.supp.minor} arrow>
                <IconButton
                  key={fetchedParticipant.supportStatus}
                  className={`${
                    fetchedParticipant.supportStatus === "minor"
                      ? classes.yellow
                      : classes.grey
                  }`}
                  size="small"
                  onClick={() =>
                    toggleSupport(fetchedParticipant, "minor", "participant")
                  }
                >
                  <ErrorOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={str.misc.supp.major} arrow>
                <IconButton
                  key={fetchedParticipant.supportStatus}
                  className={`${
                    fetchedParticipant.supportStatus === "major"
                      ? classes.red
                      : classes.grey
                  }`}
                  size="small"
                  onClick={() =>
                    toggleSupport(fetchedParticipant, "major", "participant")
                  }
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Wrapper>
      {!isfetchingData && fetchedParticipant?.id ? (
        <>
          {getVCardContent()}
          <DataGrid dense {...applicationDataprops} />
          {!isfetchingData && isAdminSupport && (
            <DataGrid dense {...runDataprops} />
          )}
          {!isfetchingData && <ActivityStreamNew />}
        </>
      ) : (
        <LoadingAnimation />
      )}
      <Button
        color="secondary"
        variant="contained"
        component="span"
        data-cy="overviewBtn"
        onClick={handlePageBack}
        className={classes.button}
      >
        Zurück zur Übersicht
      </Button>
      {dialogOpen.run && editingRun && (
        <EditRun
          participantId={participantId ? participantId : ""}
          run={editingRun && editingRun}
          open={dialogOpen.run}
          toggleDialog={() => setDialogOpen({ ...dialogOpen, run: false })}
        />
      )}
      {dialogOpen.participant && fetchedParticipant && (
        <EditParticipant
          open={dialogOpen.participant}
          toggleDialog={() =>
            setDialogOpen({ ...dialogOpen, participant: false })
          }
          participant={fetchedParticipant}
        />
      )}
    </Wrapper>
  );
};

export default observer(Participant);
