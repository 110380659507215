import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Theme,
  makeStyles
} from "@material-ui/core";
import { FC, useState } from "react";
import { useAsync } from "../../hooks/useAsync";
import { IRawValueModel } from "../../models/IRawValueModel";
import { RawValueService } from "../../services/RawValueService";
import { DataGridActionButtonProps } from "../DataGrid";
import LoadingAnimation from "../LoadingAnimation";
import { TransferList } from "../TransferList";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  title: {
    textAlign: "center",
    boxSizing: "border-box"
  },
  content: {
    width: "400px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2)
  },
  button: {
    marginTop: "15px"
  }
}));

export const CreateRawValueLink: FC<DataGridActionButtonProps> = ({
  open,
  close,
  data
}) => {
  const { handleCreateLink, rvRelevantStart, irrelevantIds, rawValues } =
    data as {
      handleCreateLink: (newIds: string[], deleteIds: string[]) => void;
      rvRelevantStart?: string;
      irrelevantIds: string[];
      rawValues: IRawValueModel[];
    };
  const classes = useStyles();
  const { getAllRawValues } = RawValueService;
  const [allRawValues, setAllRawValues] = useState<readonly IRawValueModel[]>(); // contains all fetched raw values that aren't in irrelevantIds
  const [chosenRawValues, setChosenRawValues] = useState<
    readonly IRawValueModel[]
  >([...rawValues]);

  useAsync(
    async () => {
      const { data } = await getAllRawValues();
      return data;
    },
    (rvs) => {
      if (rvs) {
        const relevant = rvs.filter(
          (rv) => irrelevantIds.find((irv) => irv === rv.id) === undefined
        );
        setAllRawValues(relevant);
      }
    }
  );

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={() => {
        close();
      }}
    >
      <DialogTitle className={classes.title}>
        Verknüpfungen Bearbeiten
      </DialogTitle>
      <Divider />
      <DialogContent>
        {allRawValues ? (
          <>
            <TransferList
              titleKey="longName"
              chosenTitle="Aktuell Verlinkt"
              choicesTitle="Verfügbare Rohwerte"
              chosen={rawValues}
              choices={allRawValues}
              initialSearch={rvRelevantStart}
              setChosen={setChosenRawValues}
            />
            <Button
              color="secondary"
              variant="contained"
              size="medium"
              fullWidth
              className={classes.button}
              onClick={() => {
                handleCreateLink(
                  chosenRawValues.map((rv) => rv.id),
                  rawValues
                    .filter(
                      (rv) => !chosenRawValues.find((crv) => crv.id === rv.id)
                    )
                    .map((rv) => rv.id)
                );
              }}
            >
              Verknüpfen
            </Button>
          </>
        ) : (
          <LoadingAnimation />
        )}
      </DialogContent>
    </Dialog>
  );
};
