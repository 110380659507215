import { action, observable, runInAction } from "mobx";
import { createContext } from "react";
import { IActivityModel } from "../models/IActivityModel";
import { ICommentModel, ICommentUpdateModel } from "../models/ICommentModel";
import {
  IParticipantDetailModel,
  IReplaceParticipantDetailModel
} from "../models/IParticipantDetailModel";
import IParticipantModel from "../models/IParticipantModel";
import ParticipantService from "../services/ParticipantService";
class ParticipantsStore {
  @observable public fetchedActivities: IActivityModel[];
  @observable public fetchedAddParticipants: IParticipantModel[];
  @observable public fetchedParticipant: IParticipantDetailModel;
  @observable public fetchedParticipants: IReplaceParticipantDetailModel[];
  @observable public isDoneFetchingActivities: boolean;
  @observable public isfetchingData: boolean;

  constructor() {
    this.fetchedActivities = [] as IActivityModel[];
    this.fetchedAddParticipants = [] as IParticipantModel[];
    this.fetchedParticipant = {} as IParticipantDetailModel;
    this.fetchedParticipants = [] as IReplaceParticipantDetailModel[];
    this.isDoneFetchingActivities = false;
    this.isfetchingData = false;
  }

  @action("add participants")
  public addParticipants = (formData: FormData) => {
    this.isfetchingData = true;
    runInAction("Add participants to System", async () => {
      this.fetchedAddParticipants = await ParticipantService.addParticipants(
        formData
      );
      this.isfetchingData = false;
    });
  };

  @action("get all participants")
  public getParticipants = () => {
    this.isfetchingData = true;
    runInAction("Get all participants from System", async () => {
      this.fetchedParticipants = await ParticipantService.getParticpants();
      this.isfetchingData = false;
    });
  };

  @action("get participant by id")
  public getParticipantById = (participantId: string) => {
    this.isfetchingData = true;
    runInAction("Get single participant from System", async () => {
      this.fetchedParticipant = await ParticipantService.getParticpantById(
        participantId
      );
      this.isfetchingData = false;
    });
  };

  @action("get Activities")
  public getActivities = (participantId: string) => {
    runInAction("Get participant activities", async () => {
      this.fetchedActivities = await ParticipantService.getActivities(
        participantId
      );
    });
  };

  @action("add Comment")
  public addComment = (participantId: string, data: ICommentModel) => {
    this.isfetchingData = true;
    runInAction("Add comment to participant", async () => {
      const response: any = await ParticipantService.addComment(
        participantId,
        data
      );
      this.isfetchingData = false;
      return response;
    });
  };

  @action("delete Comment")
  public updateComment = (
    participantId: string,
    activityId: string,
    data: ICommentUpdateModel
  ) => {
    this.isfetchingData = true;
    runInAction("Delete participant comment", async () => {
      const response = await ParticipantService.updateComment(
        participantId,
        activityId,
        data
      );
      this.isfetchingData = false;
      return response;
    });
  };

  @action("Update Supportstatus")
  public updateSupportStatus = (
    id: string,
    severity: "major" | "minor",
    level: "run" | "participant"
  ) => {
    this.isfetchingData = true;
    runInAction("Update run supportStatus", async () => {
      const response: any = await ParticipantService.updateSupportStatus(
        id,
        severity,
        level
      );
      this.isfetchingData = false;
      return response;
    });
  };
}
export default createContext(new ParticipantsStore());
