import {
  Button,
  ClickAwayListener,
  createStyles,
  makeStyles,
  Popover,
  TextField,
  Theme
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      color: theme.palette.error.main
    },
    red: {
      boxSizing: "border-box",
      backgroundColor: theme.palette.primary.light,
      border: `1px solid ${theme.palette.error.main}`,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.primary.light
      }
    }
  });
});

interface IDeleteButton {
  fullWidth?: boolean;
  noVerify?: boolean | null;
  compareString: string;
  buttonName: string;
  description: string;
  callback: (allow: boolean) => void;
}

const DeleteButton: FC<IDeleteButton> = ({
  fullWidth = false,
  noVerify = false,
  compareString,
  buttonName,
  description,
  callback
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [input, setInput] = useState(noVerify ? compareString : "");
  const [deletable, setDeletable] = useState(noVerify);

  const handleClick = async (e: any) => {
    setAnchorEl(e.currentTarget);
    if (!deletable) {
      setOpenDelete(true);
    } else {
      if (input.toLowerCase() === compareString) {
        callback(true);
      }
    }
  };

  const handleDeleteStr = (value: string) => {
    setInput(value);
    if (value.toLowerCase() === compareString) {
      setDeletable(true);
      setOpenDelete(false);
    }
  };

  return (
    <>
      <Button
        fullWidth={fullWidth}
        variant="outlined"
        size="small"
        onClick={handleClick}
        className={`${deletable && classes.red} ${classes.root}`}
      >
        {buttonName}
      </Button>
      {
        <Popover anchorEl={anchorEl} open={openDelete}>
          <ClickAwayListener onClickAway={() => setOpenDelete(false)}>
            <TextField
              style={{ width: `${description.length * 9}px` }}
              size="small"
              value={input}
              variant="filled"
              label={description}
              onChange={(e) => handleDeleteStr(e.currentTarget.value)}
            />
          </ClickAwayListener>
        </Popover>
      }
    </>
  );
};

export default observer(DeleteButton);
