import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { IRawValueModel } from "../../models/IRawValueModel";
import {
  ISurveyModel,
  ISurveyModel_RawValues
} from "../../models/ISurveyModel";
import { RawValueService } from "../../services/RawValueService";
import { DataGridActionButtonProps } from "../DataGrid";
import DeleteButton from "../DeleteButton";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  title: {
    textAlign: "center",
    boxSizing: "border-box"
  },
  content: {
    width: "400px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2)
  },
  button: {
    marginTop: "15px"
  }
}));

interface RemoveRawValueSurveyLinkProps {
  open: boolean;
  close: () => void;
  handleUnlink: (rawValueId: string) => void;
  rawValue: IRawValueModel;
}

export const RemoveRawValueLink: FC<RemoveRawValueSurveyLinkProps> = ({
  open,
  close,
  handleUnlink,
  rawValue
}) => {
  const classes = useStyles();

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={() => {
        close();
      }}
    >
      <DialogTitle className={classes.title}>Verknüpfung Entfernen</DialogTitle>
      <Divider />
      <DialogContent>
        <DeleteButton
          buttonName="Entfernen"
          callback={() => handleUnlink(rawValue.id)}
          compareString="entfernen"
          description="Geben Sie 'entfernen' ein"
        />
      </DialogContent>
    </Dialog>
  );
};
