import { Button, ButtonGroup } from "@material-ui/core";
import { Loop } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";
import Wrapper from "./containers/Wrapper";
import HashConverter from "./HashConverter";
import Heading from "./Heading";
import UuidConverter from "./UuidConverter";

const Converter: FC = () => {
  const [active, setActive] = useState("uuid");

  return (
    <Wrapper>
      <Heading heading="Konverter">
        <Loop />
      </Heading>
      <ButtonGroup
        size="small"
        color="primary"
        aria-label="outlined primary button group"
      >
        <Button
          color={active === "uuid" ? "secondary" : "primary"}
          variant={active === "uuid" ? "contained" : "outlined"}
          onClick={() => setActive("uuid")}
        >
          UUID - Token
        </Button>
        <Button
          color={active === "hash" ? "secondary" : "primary"}
          variant={active === "hash" ? "contained" : "outlined"}
          onClick={() => setActive("hash")}
        >
          Data - Hash
        </Button>
      </ButtonGroup>
      {active === "uuid" && <UuidConverter />}
      {active === "hash" && <HashConverter />}
    </Wrapper>
  );
};

export default observer(Converter);
