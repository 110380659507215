import {
  ControlPointDuplicateOutlined,
  ImportContactsOutlined
} from "@material-ui/icons";
import { FC, MouseEvent, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAsync } from "../hooks/useAsync";
import { INormModel } from "../models/INormModel";
import { NormService } from "../services/NormService";
import UserStore from "../stores/UserStore";
import DataGrid, { IDataGridProps } from "./DataGrid";
import Heading from "./Heading";
import Wrapper from "./containers/Wrapper";
import { EditOrCreateNorm } from "./dialogs/EditOrCreateNorm";

export const Norms: FC = () => {
  const history = useHistory();
  const { isAdmin } = useContext(UserStore);
  const { getAllNorms } = NormService;

  const [norms, setNorms] = useState<INormModel[]>([]);

  useAsync(getAllNorms, (data) => {
    setNorms(data.data || []);
  });

  const normTableProps: IDataGridProps = {
    pagingFilter: true,
    pageSize: 5,
    tableButtons: [
      {
        tooltip: "Norm Erstellen",
        icon: () => <ControlPointDuplicateOutlined />,
        popup: EditOrCreateNorm,
        isFreeAction: true,
        disabled: !isAdmin
      }
    ],
    onRowClick: (_e: MouseEvent<HTMLButtonElement>, row: INormModel) => {
      history.push(`/norm/${row?.id}`);
    },
    columns: [
      { align: "left", title: "Name", type: "string", field: "longName" },
      { align: "left", title: "Abkürzung", type: "string", field: "shortName" },
      {
        align: "left",
        title: "Beschreibung",
        type: "string",
        field: "description"
      }
    ],
    data: norms
  };

  return (
    <Wrapper>
      <Heading heading="Normen">
        <ImportContactsOutlined />
      </Heading>
      <DataGrid dense {...normTableProps} />
    </Wrapper>
  );
};
