export function downloadCSV(rows: string[][], filename: string) {
  const csvContent = `data:text/csv;charset=utf-8,\uFEFF${rows
    .map((e) => e.join(";"))
    .join("\n")}`;

  const link = document.createElement("a");
  link.download = filename;
  link.href = encodeURI(csvContent);
  link.click();
  link.remove();
}
