import { Button, makeStyles, Theme, Typography } from "@material-ui/core";
import { PollOutlined } from "@material-ui/icons";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { APICodes, getNotiOptions } from "../../constants/configs";
import { useAsync } from "../../hooks/useAsync";
import { INormModel } from "../../models/INormModel";
import { IRawValueModel } from "../../models/IRawValueModel";
import { NormService } from "../../services/NormService";
import UserStore from "../../stores/UserStore";
import { RawValueGrid } from "../containers/RawValueGrid";
import Wrapper from "../containers/Wrapper";
import { CreateRawValueLink } from "../dialogs/CreateRawValueLink";
import { EditOrCreateNorm } from "../dialogs/EditOrCreateNorm";
import { RemoveRawValueLink } from "../dialogs/RemoveRawValueLink";
import LoadingAnimation from "../LoadingAnimation";
import DetailsOverview from "./shared/DetailsOverview";

interface INormDetailProps {
  id: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: "50px"
  }
}));

export const NormDetail: FC<RouteComponentProps<INormDetailProps>> = ({
  match: {
    params: { id }
  }
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { isAdmin } = useContext(UserStore);

  const { getNorm, createNormLinks, removeNormLink } = NormService;
  const { enqueueSnackbar } = useSnackbar();

  const [norm, setNorm] = useState<INormModel>();
  const [editNorm, setEditNorm] = useState<boolean>(false);
  const [unlinkRawValue, setUnlinkRawValue] = useState<IRawValueModel>();

  useAsync(
    async () => {
      return await getNorm(id);
    },
    (res) => {
      setNorm(res?.data);
    }
  );

  const handleBack = () => {
    history.push("/norm");
  };

  const handleEdit = () => {
    setEditNorm(!editNorm);
  };

  const handleUnlink = async (rawValueId: string) => {
    if (!norm) return;
    const res = await removeNormLink(rawValueId, norm.id);
    if (res.status_code === APICodes.SUCCESS) {
      history.go(0);
    } else {
      enqueueSnackbar(
        "Verknüpfung konnte nicht entfernt werden",
        getNotiOptions("error")
      );
    }
  };

  const handleCreateLink = async (
    rawValueIds: string[],
    deleteRawValueIds: string[]
  ) => {
    if (!norm) return;
    const res = await createNormLinks(norm.id, rawValueIds, deleteRawValueIds);
    if (res.status_code === APICodes.SUCCESS) {
      history.go(0);
    } else {
      enqueueSnackbar(
        "Verknüpfung konnte nicht erstellt werden",
        getNotiOptions("error")
      );
    }
  };

  return (
    <Wrapper>
      {norm ? (
        <>
          <DetailsOverview
            icon={<PollOutlined />}
            displays={[
              {
                title: "Abkürzung",
                display: <Typography>{norm.shortName}</Typography>
              },
              {
                title: "Beschreibung",
                display: <Typography>{norm.description}</Typography>
              }
            ]}
            title={norm.longName}
            allowEdits={isAdmin}
            editTooltip="Norm Bearbeiten"
            onEdit={handleEdit}
          />
          <RawValueGrid
            rawValues={norm.RawValues}
            tableButtons={[
              {
                tooltip: "Rohwert mit Norm verlinken",
                icon: () => <ControlPointDuplicateIcon />,
                popup: CreateRawValueLink,
                data: {
                  handleCreateLink,
                  irrelevantIds: norm.RawValues.map((rv) => rv.id),
                  rawValues: norm.RawValues
                },
                isFreeAction: true,
                disabled: !isAdmin
              }
            ]}
            onRowClick={(_, row) => {
              if (isAdmin) setUnlinkRawValue(row);
            }}
          />
          <EditOrCreateNorm
            close={() => {
              setEditNorm(false);
            }}
            open={editNorm}
            data={norm}
          />
        </>
      ) : (
        <LoadingAnimation />
      )}
      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={handleBack}
        className={classes.button}
      >
        Zurück zur Normübersicht
      </Button>
      {unlinkRawValue && (
        <RemoveRawValueLink
          rawValue={unlinkRawValue}
          close={() => setUnlinkRawValue(undefined)}
          open={Boolean(unlinkRawValue)}
          handleUnlink={handleUnlink}
        />
      )}
    </Wrapper>
  );
};
