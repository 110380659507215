import React, { ElementType, FC } from "react";
import {
  createStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Tooltip
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.info.main
    },
    selected: {
      color: theme.palette.secondary.main,
      "& svg": {
        color: theme.palette.secondary.main
      }
    }
  })
);

interface INavItemProps {
  icon: ElementType<any>;
  label: string;
  to: string;
}

const NavItem: FC<INavItemProps> = ({ icon: Icon, label, to }) => {
  const classes = useStyles();

  return (
    <ListItem
      component={(props: any) => (
        <NavLink
          to={to}
          activeClassName={`${classes.link} ${classes.selected}`}
          {...props}
        />
      )}
    >
      <Tooltip title={label}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary={label} />
    </ListItem>
  );
};

export default observer(NavItem);
