import { makeStyles, Theme } from "@material-ui/core";
import { Contacts } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { sort } from "../functions/filter";
import { transform } from "../functions/transform";
import { emptyUser, IUserModel } from "../models/IUserModel";
import CustomerStore from "../stores/CustomerStore";
import UserStore from "../stores/UserStore";
import Wrapper from "./containers/Wrapper";
import DataGrid, { IDataGridProps } from "./DataGrid";
import EditUser from "./dialogs/EditUser";
import Heading from "./Heading";
import LoadingAnimation from "./LoadingAnimation";

const useStyles = makeStyles((theme: Theme) => ({
  addLink: {
    padding: theme.spacing(2)
  },
  addCircle: {
    width: "50px",
    height: "50px",
    background: "#fff",
    cursor: "pointer",
    borderRadius: "50%"
  }
}));

const basicCellStyle = {
  maxWidth: `${1000 / 6}px`,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whitespace: "nowrap"
};

const Users: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyles();
  const { fetchedUsers, isfetchingData, getUsers } = useContext(UserStore);
  const { getCustomers, getAllOffices } = useContext(CustomerStore);

  const [open, setOpen] = useState(false);
  const [editingUser, setEditingUser] = useState<IUserModel>(emptyUser());

  const toggleEdit = (user: IUserModel) => {
    setEditingUser(user);
    setOpen(true);
  };

  useEffect(() => {
    getUsers();
    getCustomers();
    getAllOffices();
  }, [getUsers, getCustomers, getAllOffices]);

  const dataprops: IDataGridProps = {
    addUserButton: true,
    onRowClick: (e, row) => toggleEdit(row),
    dense: true,
    searchFilter: true,
    pagingFilter: true,
    columns: [
      { title: "Vorname", field: "firstName" },
      { title: "Nachname", field: "lastName" },
      {
        title: "E-Mail",
        field: "email",
        cellStyle: basicCellStyle,
        render: (row) => <span>{transform.noBreak(row?.email)}</span>
      },
      {
        title: "Rolle",
        render: (row) => <span>{row?.Role?.longName}</span>,
        customSort: (rowA: IUserModel, rowB: IUserModel) =>
          sort.alphabetically(rowA.Role?.longName, rowB.Role?.longName)
      }
    ],
    data: JSON.parse(JSON.stringify(fetchedUsers))
  };

  return (
    <Wrapper>
      <Heading heading="Nutzer">
        <Contacts />
      </Heading>
      {(!isfetchingData && <DataGrid {...dataprops} />) || <LoadingAnimation />}
      {open && (
        <EditUser open={open} close={() => setOpen(false)} user={editingUser} />
      )}
    </Wrapper>
  );
};

export default observer(Users);
