import axios, { AxiosResponse } from "axios";
import { setServerUrl } from "../constants/constants";
import { IRouterResponseModel } from "../models/IRouterResponseModel";

const RoutingService = {
  async getSurvey(
    participantToken: string,
    projectToken: string
  ): Promise<IRouterResponseModel> {
    const response: AxiosResponse = await axios.request({
      url: setServerUrl(
        `/api/router/${participantToken}/${projectToken}`,
        `/api/router/${participantToken}/${projectToken}`
      ),
      method: "GET"
    });
    return await Promise.resolve(response.data);
  }
};

export default RoutingService;
