import {
    Button,
    createStyles,
    Grid,
    makeStyles,
    TextField,
    Theme
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";
import short from "short-uuid";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      maxWidth: "400px"
    },
    heading: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: theme.spacing(3)
    },
    pageHeader: {
      fontFamily: "Open Sans",
      lineHeight: "1.334",
      fontWeight: "normal",
      margin: "0"
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      maxWidth: "300px",
      marginTop: theme.spacing(3)
    },
    btnBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    },
    btn: {
      margin: "0.5rem"
    }
  });
});

const UuidConverter: FC = () => {
  const convert = short();
  const classes = useStyles();

  const [data, setData] = useState({
    token: "",
    uuid: ""
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    identifier: "token" | "uuid"
  ) => {
    e.preventDefault();
    setData({ ...data, [identifier]: e.target.value });
  };

  const handleConvert = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (data.token.length > 0 && data.uuid.length === 0) {
      setData({ ...data, uuid: convert.toUUID(data.token) });
    } else if (data.uuid.length > 0 && data.token.length === 0) {
      setData({ ...data, token: convert.fromUUID(data.uuid) });
    }
  };

  const handleReset = () => {
    setData({ uuid: "", token: "" });
  };

  return (
    <form className={classes.form}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="token"
            color="secondary"
            placeholder="Token"
            name="token"
            value={data.token}
            onChange={(e) => handleChange(e, "token")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="uuid"
            color="secondary"
            placeholder="UUID"
            name="uuid"
            value={data.uuid}
            onChange={(e) => handleChange(e, "uuid")}
          />
        </Grid>
        <Grid item xs={12} className={classes.btnBox}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            className={classes.btn}
            onClick={handleReset}
          >
            Clear
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.btn}
            onClick={handleConvert}
          >
            Convert
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(UuidConverter);
