import { ICustomerItemModel } from "./ICustomerItemModel";
import { IOfficeModel, emptyOffice } from "./IOfficeModel";
import { IPositionModel, emptyPosition } from "./IPositionModel";

export enum EExportType {
  grades = "grades",
  points = "points",
  percentiles = "percentiles"
}

export interface IProjectModel {
  id: string;
  customerId: string;
  longName: string;
  shortName: string;
  validFrom: string;
  validUntil: string;
  eulaSurvey: string;
  branding: boolean;
  dataSharing: boolean;
  exportType: EExportType;
  Offices: IOfficeModel[];
  Positions?: IPositionModel[];
}

export function emptyProject(): IProjectModel {
  return {
    id: "",
    customerId: "",
    longName: "",
    shortName: "",
    validFrom: "",
    validUntil: "",
    eulaSurvey: "",
    branding: false,
    dataSharing: false,
    exportType: EExportType.grades,
    Offices: [emptyOffice()],
    Positions: [emptyPosition()]
  };
}

export function newProjectItem(): ICustomerItemModel {
  return {
    items: [
      {
        type: "id",
        name: "id",
        value: ""
      },
      {
        type: "name",
        name: "longName",
        value: ""
      },
      {
        type: "default",
        name: "shortName",
        value: ""
      },
      {
        type: "default",
        name: "eulaSurvey",
        value: ""
      },
      {
        type: "date",
        name: "validFrom",
        value: new Date()
      },
      {
        type: "date",
        name: "validUntil",
        value: new Date()
      },
      {
        type: "multiselect",
        name: "Offices",
        value: []
      },
      {
        type: "select",
        name: "exportType",
        value: "grades"
      },
      {
        type: "boolean",
        name: "branding",
        value: false
      },
      {
        type: "boolean",
        name: "dataSharing",
        value: false
      },
      {
        type: "id",
        name: "customerId",
        value: ""
      }
    ],
    type: "project",
    title: "Projekt erstellen"
  };
}
