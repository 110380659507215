/* eslint-disable no-nested-ternary */
export type TResultFormat = "raw" | "grades" | "points" | "percentiles";

export const transform = {
  zToGrade(value: number) {
    if (value === null) return null;
    const grade = 3.5 - value;
    const rounded = Math.round((grade + Number.EPSILON) * 100) / 100;
    return rounded;
  },
  zToPoint(value: number) {
    if (value === null) return null;
    const val1 = 1.1499999999999999;
    const val2 = 2.093;
    const val3 = 13;
    const val4 = 63.348999999999997;
    const point = val1 * value ** 3 - val2 * value ** 2 + val3 * value + val4;
    const rounded = Math.round((point + Number.EPSILON) * 100) / 100;
    return rounded > 100 ? 100 : rounded < 0 ? 0 : rounded;
  },
  zToPercentile(value: number) {
    if (value === null) return null;
    const val1 = 0.90000000000000002;
    const val2 = 0.00034400000000000001;
    const val3 = 0.019526999999999999;
    const val4 = 0.198654;
    const val5 = 0.115194;
    let percentile = 0;

    if (value < 0) {
      percentile =
        100 *
        (0.5 /
          (1 +
            val4 * (0 - value / val1) +
            val5 * (0 - value / val1) ** 2 +
            val2 * (0 - value / val1) ** 3 +
            val3 * (0 - value / val1) ** 4) **
            4);
    } else {
      percentile =
        100 *
        (1 -
          0.5 /
            (1 +
              val4 * (value / val1) +
              val5 * (value / val1) ** 2 +
              val2 * (value / val1) ** 3 +
              val3 * (value / val1) ** 4) **
              4);
    }
    return Math.round(percentile);
  },
  result(value: number, resultFormat: TResultFormat, fixed: boolean) {
    if (value === null) return null;
    let transformed;
    switch (resultFormat) {
      case "grades":
        transformed = this.zToGrade(value);
        break;
      case "points":
        transformed = this.zToPoint(value);
        break;
      case "percentiles":
        transformed = this.zToPercentile(value);
        break;
      case "raw":
        transformed = value;
        break;
      default:
        break;
    }
    return fixed ? transformed?.toFixed(2) : transformed;
  },
  noBreak(str: string) {
    return str.replace("-", "\u2011").replace(" ", "\u00A0");
  },
  round(value: number) {
    if (value === null) return null;
    return Math.round(value);
  }
};
