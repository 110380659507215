import {
  Avatar,
  Button,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import FunctionsIcon from "@material-ui/icons/Functions";
import SettingsIcon from "@material-ui/icons/Settings";
import StorageIcon from "@material-ui/icons/Storage";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { translations } from "../../constants/constants";
import { parseAndFormat } from "../../functions/date";
import { IPositionModel } from "../../models/IPositionModel";
import ProjectService from "../../services/ProjectService";
import CustomerStore from "../../stores/CustomerStore";
import UserStore from "../../stores/UserStore";
import Wrapper from "../containers/Wrapper";
import DataGrid, { ICustomerItemDialog, IDataGridProps } from "../DataGrid";
import ArchiveProject from "../dialogs/ArchiveProject";
import EditCustomerItem from "../dialogs/EditCustomerItem";
import EditPosition from "../dialogs/EditPosition";
import RecalculateProject from "../dialogs/RecalculateProject";
import IconDrop from "../IconDrop";
import LoadingAnimation from "../LoadingAnimation";

const str = {
  label: {
    abbreviation: "Abkürzung:",
    validity: "Gültigkeit:",
    eulaSurvey: "EULA-Umfrage:",
    exportType: "Export-Typ:"
  },
  dash: " - "
};

const notiStr = {
  archive: {
    success: "Archivierung erfolgreich",
    error: "Archivierung fehlgeschlagen"
  },
  recalculate: {
    success: "Neuberechnug erfolgreich",
    error: "Neuberechnung fehlgeschlagen"
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  infoBox: {
    width: "100%"
  },
  settings: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1)
  },
  tablerow: {
    display: "flex",
    marginTop: "3px"
  },
  button: {
    marginTop: "50px"
  },
  avatar: {
    margin: "3px",
    backgroundColor: theme.palette.secondary.main
  },
  avatarBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(2),
    userSelect: "none"
  },
  display: {
    display: "flex",
    justifyContent: "center"
  },
  customerName: {
    fontSize: "2rem",
    margin: "0",
    marginLeft: "7px"
  },
  vcard: {
    width: "100%",
    minHeight: "35%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    alignItems: "flex-start",
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "1px",
    padding: theme.spacing(3)
  },
  input: {
    minWidth: "250px",
    margin: "0",
    textAlign: "left",
    padding: theme.spacing(0.5)
  },
  editIcon: {
    cursor: "pointer"
  },
  icons: {
    position: "absolute",
    display: "inline",
    bottom: "0px"
  },
  label: {
    minWidth: "250px",
    textAlign: "left",
    margin: "0",
    padding: theme.spacing(0.5)
  },
  customerIcon: {
    position: "relative",
    bottom: "34px",
    left: "3%",
    display: "flex",
    cursor: "pointer"
  }
}));
interface IDetailParams {
  id: string;
}

interface IOpenstate {
  recalculate: boolean;
  edit: boolean;
  archive: boolean;
  position: boolean;
}

const ProjectDetail: FC<RouteComponentProps<IDetailParams>> = ({ match }) => {
  const projectId = match.params.id;
  const classes = useStyles();
  const history = useHistory();
  const { isAdminSupport } = useContext(UserStore);
  const {
    fetchedCustomer,
    fetchedProject,
    isFetchingProjectData,
    getProjectById,
    getCustomerOffices
  } = useContext(CustomerStore);
  const { enqueueSnackbar } = useSnackbar();

  const { archiveProject, recalculateProject } = ProjectService;

  const [open, setOpen] = useState<IOpenstate>({
    recalculate: false,
    edit: false,
    archive: false,
    position: false
  });
  const [editingPosition, setEditingPosition] = useState<IPositionModel>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleClose = () => {
    setOpen({
      recalculate: false,
      edit: false,
      archive: false,
      position: false
    });
  };

  const handleBack = () => {
    if (fetchedCustomer?.id) {
      history.push(
        `/${fetchedCustomer?.id ? `customer/${fetchedCustomer.id}` : ""}`
      );
    }
  };

  const handleRowClick = (e: any, row: IPositionModel) => {
    setEditingPosition(row);
    setOpen({ ...open, position: true });
  };

  const handleArchive = async () => {
    setIsProcessing(true);
    const response = await archiveProject(fetchedProject.id);
    if (response.status_code === 1000) {
      enqueueSnackbar(notiStr.archive.success, getNotiOptions("success"));
      history.go(0);
    } else {
      enqueueSnackbar(notiStr.archive.error, getNotiOptions("error"));
    }
    setOpen({ ...open, archive: false });
    setIsProcessing(false);
  };

  const handleRecalculation = async () => {
    setIsProcessing(true);
    const response = await recalculateProject(fetchedProject.id);
    if (response.status_code === 1000) {
      enqueueSnackbar(notiStr.recalculate.success, getNotiOptions("success"));
      history.go(0);
    } else {
      enqueueSnackbar(notiStr.recalculate.error, getNotiOptions("error"));
    }
    setOpen({ ...open, recalculate: false });
    setIsProcessing(false);
  };

  useEffect(() => {
    getProjectById(projectId);
  }, [getProjectById, projectId]);

  useEffect(() => {
    const custId = window.sessionStorage.getItem("custId");
    getCustomerOffices(
      fetchedCustomer.id ? fetchedCustomer.id : custId ? custId : ""
    );
  }, [getCustomerOffices, fetchedCustomer.id]);

  const dialogProps: ICustomerItemDialog = {
    customerId: fetchedCustomer.id,
    items: [
      {
        type: "id",
        name: "id",
        value: fetchedProject.id
      },
      {
        type: "name",
        name: "longName",
        value: fetchedProject.longName
      },
      {
        type: "default",
        name: "shortName",
        value: fetchedProject.shortName
      },
      {
        type: "default",
        name: "eulaSurvey",
        value: fetchedProject.eulaSurvey
      },
      {
        type: "date",
        name: "validFrom",
        value: fetchedProject.validFrom
      },
      {
        type: "date",
        name: "validUntil",
        value: fetchedProject.validUntil
      },
      {
        type: "multiselect",
        name: "Offices",
        value: fetchedProject.Offices
      },
      {
        type: "select",
        name: "exportType",
        value: fetchedProject.exportType
      },
      {
        type: "boolean",
        name: "branding",
        value: fetchedProject.branding
      },
      {
        type: "boolean",
        name: "dataSharing",
        value: fetchedProject.dataSharing
      }
    ],
    type: "project",
    title: "Projekt editieren",
    open: open.edit,
    close: handleClose
  };

  const positionDataprops: IDataGridProps = {
    addPositionButton: true,
    onRowClick: handleRowClick,
    title: "Positionen",
    searchFilter: false,
    columns: [
      { title: "Name", field: "longName" },
      { title: "Abkürzung", field: "shortName" }
    ],
    data: fetchedProject?.id
      ? JSON.parse(JSON.stringify(fetchedProject?.Positions)) // ?
      : []
  };

  return (
    <Wrapper>
      {!isFetchingProjectData && fetchedProject?.id ? (
        <>
          <div className={classes.vcard} data-cy="info-vcard">
            <div className={classes.avatarBox}>
              <div className={classes.display}>
                <Avatar className={classes.avatar} data-cy="info-avatar">
                  <AccountTreeIcon />
                </Avatar>
                <Typography className={classes.customerName}>
                  {fetchedProject.longName}
                </Typography>
              </div>
              {isAdminSupport && (
                <div className={classes.editIcon}>
                  <Tooltip title="Projektneuberechnung">
                    <IconButton
                      onClick={() => setOpen({ ...open, recalculate: true })}
                    >
                      <FunctionsIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Projektdaten archivieren">
                    <IconButton
                      onClick={() => setOpen({ ...open, archive: true })}
                    >
                      <StorageIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Office editieren">
                    <IconButton
                      onClick={() => setOpen({ ...open, edit: true })}
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                  <EditCustomerItem {...dialogProps} />
                </div>
              )}
            </div>
            <div className={classes.infoBox}>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  {str.label.abbreviation}
                </Typography>
                <Typography className={classes.input}>
                  {fetchedProject.shortName}
                </Typography>
              </div>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  {str.label.validity}
                </Typography>
                <Typography className={classes.input}>
                  {parseAndFormat(fetchedProject.validFrom)}
                  {str.dash}
                  {parseAndFormat(fetchedProject.validUntil)}
                </Typography>
              </div>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  {str.label.eulaSurvey}
                </Typography>
                <Typography className={classes.input}>
                  {fetchedProject.eulaSurvey}
                </Typography>
              </div>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  {str.label.exportType}
                </Typography>
                <Typography className={classes.input}>
                  {translations[fetchedProject.exportType]}
                </Typography>
              </div>
              <div className={classes.tablerow}>
                <Typography className={classes.label}>
                  Einstellungen:
                </Typography>
                <div className={classes.settings}>
                  <IconDrop
                    title="Branding"
                    icon={<WhatshotIcon />}
                    enabled={fetchedProject.branding}
                  />
                  <IconDrop
                    title="Daten teilen"
                    icon={<SyncAltIcon />}
                    enabled={fetchedProject.dataSharing}
                  />
                </div>
              </div>
            </div>
          </div>
          <DataGrid dense {...positionDataprops} />
          {}
        </>
      ) : (
        <LoadingAnimation />
      )}

      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={handleBack}
        className={classes.button}
      >
        Zurück zu Kundendetails
      </Button>
      {editingPosition && open.position && (
        <EditPosition
          open={open.position}
          close={handleClose}
          data={editingPosition}
        />
      )}
      {open.archive && (
        <ArchiveProject
          disabled={isProcessing}
          open={open.archive}
          close={handleClose}
          archive={handleArchive}
        />
      )}
      {open.recalculate && (
        <RecalculateProject
          disabled={isProcessing}
          open={open.recalculate}
          close={handleClose}
          recalculate={handleRecalculation}
        />
      )}
    </Wrapper>
  );
};

export default observer(ProjectDetail);
