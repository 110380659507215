import {
  Box,
  Button,
  createStyles,
  FormControl,
  makeStyles,
  Modal,
  Theme,
  Typography
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import DeleteButton from "../DeleteButton";
import LoadingAnimation from "../LoadingAnimation";

const str = {
  delete: {
    compare: "berechnen",
    description: 'Geben Sie "berechnen" ein',
    buttonName: "Neuberechnen"
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      margin: theme.spacing(1),
      gap: theme.spacing(4)
    },
    modal: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      position: "absolute",
      top: "25%",
      left: "75%",
      transform: "translate(-50%, -50%)",
      width: "fit-content",
      backgroundColor: "white",
      padding: theme.spacing(2),
      borderRadius: "5px"
    },
    btn: {
      color: theme.palette.primary.main,
      minWidth: "fit-content"
    },
    btnBox: {
      width: "100%",
      display: "flex",
      justifyContent: "space-around"
    }
  })
);

export interface IRecalculateProject {
  disabled?: boolean;
  open: boolean;
  close: () => void;
  recalculate: () => void;
}

const RecalculateProject: FC<IRecalculateProject> = ({
  disabled = false,
  open,
  close,
  recalculate
}) => {
  const classes = useStyles();

  const handleRecalculation = (allow: boolean) => {
    if (allow) recalculate();
  };

  return (
    <Modal open={open} onClose={close} onBackdropClick={close}>
      <Box className={classes.modal}>
        <Typography variant="h6">Projektergebnisse neuberechnen?</Typography>
        <FormControl className={classes.formControl}>
          <Box className={classes.btnBox}>
            <Button
              disabled={disabled}
              onClick={close}
              size="small"
              color="secondary"
              variant="outlined"
              className={classes.btn}
            >
              Abbrechen
            </Button>
            {disabled ? (
              <LoadingAnimation />
            ) : (
              <DeleteButton
                compareString={str.delete.compare}
                buttonName={str.delete.buttonName}
                description={str.delete.description}
                callback={handleRecalculation}
              />
            )}
          </Box>
        </FormControl>
      </Box>
    </Modal>
  );
};

export default observer(RecalculateProject);
