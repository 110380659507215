import {
  Dialog,
  makeStyles,
  Theme,
  TextField,
  Button,
  Select,
  InputLabel,
  Grid
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import { isInteger } from "lodash";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { IRawValueModel } from "../../models/IRawValueModel";
import { RawValueService } from "../../services/RawValueService";
import CustomerStore from "../../stores/CustomerStore";
import { DataGridActionButtonProps } from "../DataGrid";
import DatePicker from "../DatePicker";
import DeleteButton from "../DeleteButton";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  title: {
    textAlign: "center",
    boxSizing: "border-box"
  },
  content: {
    width: "400px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2)
  },
  button: {
    marginTop: "15px"
  }
}));

interface InputErrors {
  longName?: string;
  shortName?: string;
  description?: string;
  taskType?: string;
  evalType?: string;
  form?: string;
  popM?: string;
  popSd?: string;
  rtt?: string;
  rngMin?: string;
  rngMax?: string;
  sampleSize?: string;
}

export const EditOrCreateRawValue: FC<DataGridActionButtonProps> = ({
  open,
  close,
  data
}) => {
  const { rawValue, rvTypeId } = (data || {}) as {
    rawValue?: IRawValueModel;
    rvTypeId?: string;
  };
  const classes = useStyles();
  const { createOrUpdateRawValue, deleteRawValue } = RawValueService;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [errors, setErrors] = useState<InputErrors>({});

  const [longName, setLongName] = useState(rawValue?.longName);
  const [shortName, setShortName] = useState(rawValue?.shortName);
  const [description, setDescription] = useState(rawValue?.description);
  const [taskType, setTaskType] = useState(rawValue?.taskType);
  const [evalType, setEvalType] = useState(rawValue?.evalType);
  const [form, setForm] = useState(rawValue?.form);
  // const [polarity, setPolarity] = useState(rawValue?.polarity); // determined by code
  const [popM, setPopM] = useState(rawValue?.popM.toString());
  const [popSd, setPopSd] = useState(rawValue?.popSd.toString());
  const [mJobSd, setMJobSd] = useState(rawValue?.mJobSd || undefined); // mJobSd is null by default which is not allowed for inputs
  const [rtt, setRtt] = useState(rawValue?.rtt.toString());
  const [rngMin, setRngMin] = useState(rawValue?.rngMin);
  const [rngMax, setRngMax] = useState(rawValue?.rngMax);
  const [level, setLevel] = useState(rawValue?.level);
  const [date, setDate] = useState(rawValue?.date || new Date());
  const [sampleSize, setSampleSize] = useState(rawValue?.sampleSize);

  const customerStore = useContext(CustomerStore);
  const { getAllNorms, fetchedNorms } = customerStore;

  useEffect(() => {
    getAllNorms();
  }, [getAllNorms]);

  const setError = (key: string, error?: string) => {
    setErrors({ ...errors, [key]: error });
  };

  const getDecimalCount = (s: string): number => {
    return s.slice(s.indexOf(".") + 1).length;
  };

  const onClose = () => {
    close();
  };

  const onLongNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLongName(event.target.value);

    setError(
      "longName",
      event.target.value.length < 1
        ? "Bezeichnung darf nicht leer sein"
        : undefined
    );
  };

  const onShortNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setShortName(event.target.value);

    setError(
      "shortName",
      event.target.value.length < 1
        ? "Abkürzung darf nicht leer sein"
        : undefined
    );
  };

  const onDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setDescription(event.target.value);

    setError(
      "description",
      event.target.value.length < 1
        ? "Beschreibung darf nicht leer sein"
        : undefined
    );
  };

  const handleFormChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setForm(event.target.value);

    setError(
      "form",
      event.target.value.length < 1
        ? "Aufgabengruppe darf nicht leer sein"
        : undefined
    );
  };

  const handleTaskTypeChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTaskType(event.target.value);

    setError(
      "taskType",
      event.target.value.length < 3
        ? "Aufgabengruppe muss mindestens 3 Zeichen lang sein"
        : undefined
    );
  };

  const handleEvalTypeChange = (type: string) => {
    setEvalType(type);

    setError(
      "evalType",
      type.length < 1 ? "Aufgabentyp darf nicht leer sein" : undefined
    );
  };

  const handlePopMChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newVal: number = parseFloat(event.target.value);

    setPopM(event.target.value);

    setError(
      "popM",
      !isInteger(newVal)
        ? getDecimalCount(event.target.value) !== 4
          ? "Mittelwert muss genau 4 Nachkommastellen haben"
          : newVal <= 0
          ? "Mittelwert muss größer als 0 sein"
          : undefined
        : "Mittelwert darf keine ganze Zahl sein"
    );
  };

  const handlePopSdChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newVal: number = parseFloat(event.target.value);

    setPopSd(event.target.value);

    setError(
      "popSd",
      !isInteger(newVal)
        ? getDecimalCount(event.target.value) !== 4
          ? "Standardabweichung muss genau 4 Nachkommastellen haben"
          : newVal <= 0
          ? "Standardabweichung muss größer als 0 sein"
          : undefined
        : "Standardabweichung darf keine ganze Zahl sein"
    );
  };

  const handleRttChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newVal: number = parseFloat(event.target.value);

    setRtt(event.target.value);
    setError(
      "rtt",
      !isInteger(newVal)
        ? getDecimalCount(event.target.value) !== 2
          ? "Retest-Reliabilität muss genau 2 Nachkommastellen haben"
          : newVal < 0 || newVal > 1
          ? "Retest-Reliabilität muss zwischen 0 und 1 liegen"
          : undefined
        : "Retest-Reliabilität muss eine Zahl mit Nachkommastellen sein"
    );
  };

  const handleRngMinChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newVal: number = parseFloat(event.target.value);

    setRngMin(newVal);

    setError(
      "rngMin",
      isInteger(newVal)
        ? newVal >= 0
          ? undefined
          : "Untergrenze muss größer als 0 sein"
        : "Untergrenze muss eine ganze Zahl sein"
    );
  };

  const handleRngMaxChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newVal: number = parseFloat(event.target.value);

    setRngMax(newVal);

    setError(
      "rngMax",
      isInteger(newVal)
        ? newVal > 0
          ? undefined
          : "Untergrenze muss größer als 0 sein"
        : "Untergrenze muss eine ganze Zahl sein"
    );
  };

  const handleSampleSizeChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newVal: number = parseInt(event.target.value);

    setSampleSize(newVal);

    setError(
      "sampleSize",
      isInteger(newVal)
        ? newVal > 0
          ? undefined
          : "Stichprobengröße muss größer als 0 sein"
        : event.target.value.length > 0
        ? "Stichprobengröße muss eine ganze Zahl sein"
        : undefined
    );
  };

  const handleDelete = async () => {
    if (rawValue?.id) {
      const res = await deleteRawValue(rawValue.id);

      if (res.status_code === 1000) {
        history.go(0);
      } else {
        enqueueSnackbar(
          "Rohwert konnte nicht gelöscht werden",
          getNotiOptions("error")
        );
      }
    }
  };

  const handleSubmit = async () => {
    if (
      (!isValidSubmission() && typeof rvTypeId !== "string") ||
      !popM ||
      !popSd ||
      !rtt
    )
      return;

    const res = await createOrUpdateRawValue(
      {
        id: rawValue?.id,
        longName: longName,
        shortName: shortName,
        description: description,
        taskType: taskType,
        polarity: evalType === "FQ" ? -1 : 1,
        popM: parseFloat(popM),
        popSd: parseFloat(popSd),
        mJobSd,
        rtt: parseFloat(rtt),
        rngMin: rngMin,
        rngMax: rngMax,
        evalType: evalType,
        level: level,
        form: form,
        date: date,
        RvTypes: [], // this is ignored by the update backend
        sampleSize
      },
      rvTypeId as string
    );
    if (res.status_code === 1000) {
      history.go(0);
    } else {
      enqueueSnackbar(
        "Rohwert konnte nicht gespeichert werden",
        getNotiOptions("error")
      );
    }
  };

  const isValidSubmission = (): boolean => {
    for (const error of Object.values(errors)) {
      if (error) {
        return false;
      }
    }

    return (
      typeof rvTypeId === "string" &&
      longName !== undefined &&
      shortName !== undefined &&
      description !== undefined &&
      form !== undefined &&
      popM !== undefined &&
      popSd !== undefined &&
      rtt !== undefined &&
      rngMin !== undefined &&
      rngMax !== undefined &&
      level !== undefined &&
      date !== undefined &&
      !isNaN(parseFloat(popSd)) &&
      !isNaN(parseFloat(popM)) &&
      !isNaN(parseFloat(rtt))
    );
  };

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={onClose}
    >
      <DialogTitle className={classes.title}>
        {`Rohwert ${rawValue ? "Bearbeiten" : "Erstellen"}`}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Bezeichnung"
              value={longName}
              onChange={onLongNameChange}
              error={!!errors.longName}
              helperText={errors.longName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Abkürzung"
              value={shortName}
              onChange={onShortNameChange}
              error={!!errors.shortName}
              helperText={errors.shortName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Beschreibung"
              value={description}
              onChange={onDescriptionChange}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Aufgabengruppe"
              value={taskType}
              onChange={handleTaskTypeChange}
              error={!!errors.taskType}
              helperText={errors.taskType}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Parallel-Form"
              value={form}
              onChange={handleFormChange}
              error={!!errors.form}
              helperText={errors.form}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="evalTypeLabel">Aufgabentyp</InputLabel>
            <Select
              labelId="evalTypeLabel"
              fullWidth
              value={evalType || ""}
              onChange={(e) => {
                handleEvalTypeChange(e.target.value as string);
              }}
            >
              <MenuItem value="MC">Multiple Choice</MenuItem>
              <MenuItem value="MS">Multiple Select</MenuItem>
              <MenuItem value="FQ">Fehlerquotient</MenuItem>
              <MenuItem value="PT">Persönlichkeitsskalen</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="levelLabel">Niveau</InputLabel>
            <Select
              labelId="levelLabel"
              fullWidth
              label="Niveau"
              value={level || ""}
              onChange={(e) => {
                setLevel(e.target.value as number);
              }}
            >
              {fetchedNorms.map((norm, i) => (
                <MenuItem value={i + 1}>{norm.longName}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Mittelwert"
              value={popM}
              onChange={handlePopMChange}
              error={!!errors.popM}
              helperText={errors.popM}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Standardabweichung"
              value={popSd}
              onChange={handlePopSdChange}
              error={!!errors.popSd}
              helperText={errors.popSd}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Retest-Reliabilität"
              value={rtt}
              onChange={handleRttChange}
              error={!!errors.rtt}
              helperText={errors.rtt}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Untergrenze"
              value={rngMin}
              type="number"
              onChange={handleRngMinChange}
              error={!!errors.rngMin}
              helperText={errors.rngMin}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Obergrenze"
              value={rngMax}
              type="number"
              onChange={handleRngMaxChange}
              error={!!errors.rngMax}
              helperText={errors.rngMax}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Stichprobengröße"
              value={sampleSize}
              type="number"
              onChange={handleSampleSizeChange}
              error={!!errors.sampleSize}
              helperText={errors.sampleSize}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="Datum"
              initialDate={date}
              processDate={(date) => {
                setDate(date);
              }}
            />
          </Grid>
        </Grid>
        <Button
          className={classes.button}
          fullWidth
          size="small"
          variant="contained"
          color="secondary"
          type="submit"
          onClick={handleSubmit}
          disabled={!isValidSubmission()}
        >
          Speichern
        </Button>
        {rawValue && (
          <DeleteButton
            fullWidth
            compareString="löschen"
            buttonName="Löschen"
            description="Geben Sie 'löschen' ein"
            callback={handleDelete}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
