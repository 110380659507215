import {
  Box,
  Button,
  createStyles,
  FormControl,
  makeStyles,
  Modal,
  Theme,
  Typography
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import DeleteButton from "../DeleteButton";
import LoadingAnimation from "../LoadingAnimation";

const str = {
  archive: {
    compare: "archiv",
    description: 'Geben Sie "archiv" ein',
    buttonName: { active: "Aufheben", inactive: "Archivieren" }
  },
  button: {
    abort: "Abbrechen"
  },
  description: {
    archive: {
      inactive: "Bewerbung archivieren?",
      active: "Freigabe aufheben?"
    }
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      margin: theme.spacing(1),
      gap: theme.spacing(4)
    },
    modal: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      position: "absolute",
      top: "50%",
      left: "75%",
      transform: "translate(-50%, -50%)",
      width: "fit-content",
      backgroundColor: "white",
      padding: theme.spacing(2),
      borderRadius: "5px"
    },
    btn: {
      color: theme.palette.primary.light
    },
    btnBox: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between"
    }
  })
);

export interface IArchiveApplication {
  active: boolean | null;
  disabled?: boolean;
  open: boolean;
  close: () => void;
  archive: () => void;
}

const ArchiveApplication: FC<IArchiveApplication> = ({
  active,
  disabled = false,
  open,
  close,
  archive
}) => {
  const classes = useStyles();

  const handleArchive = (allow: boolean) => {
    if (allow) archive();
  };

  return (
    <Modal open={open} onClose={close} onBackdropClick={close}>
      <Box className={classes.modal}>
        <Typography variant="h6">
          {active
            ? str.description.archive.active
            : str.description.archive.inactive}
        </Typography>
        <FormControl className={classes.formControl}>
          <Box className={classes.btnBox}>
            <Button
              disabled={disabled}
              onClick={close}
              variant="contained"
              color="secondary"
              className={classes.btn}
            >
              {str.button.abort}
            </Button>
            {disabled ? (
              <LoadingAnimation />
            ) : (
              <DeleteButton
                noVerify={active}
                compareString={str.archive.compare}
                buttonName={
                  active
                    ? str.archive.buttonName.active
                    : str.archive.buttonName.inactive
                }
                description={str.archive.description}
                callback={handleArchive}
              />
            )}
          </Box>
        </FormControl>
      </Box>
    </Modal>
  );
};

export default observer(ArchiveApplication);
