import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  Theme
} from "@material-ui/core";
import { FC } from "react";

interface ISave {
  open: boolean;
  title: string;
  close: () => void;
  save: () => void;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {},
    btnBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around"
    }
  });
});

const Save: FC<ISave> = ({ open, title = "", close, save }) => {
  const classes = useStyles();
  return (
    <Dialog transitionDuration={100} open={open} onClose={close}>
      <DialogTitle>{title}</DialogTitle>
      <DialogActions className={classes.btnBox}>
        <Button
          onClick={close}
          color="secondary"
          variant="outlined"
          size="small"
        >
          Abbrechen
        </Button>
        <Button
          onClick={save}
          color="secondary"
          variant="contained"
          size="small"
        >
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Save;
