import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { getNotiOptions } from "../../constants/configs";
import { validate } from "../../constants/constants";
import { formatDate } from "../../functions/date";
import IParticipantModel from "../../models/IParticipantModel";
import ParticipantService from "../../services/ParticipantService";
import DatePicker from "../DatePicker";

interface IDialogStr {
  [key: string]: {
    [key: string]: string;
  };
}

interface IDetailsStr {
  [key: string]: string;
}

enum ESteps {
  initial = 0,
  continue
}

const dialogStr: IDialogStr = {
  initial: {
    title: "Stammdaten ändern",
    description: "Passen Sie die Stammdaten an"
  },
  continue: {
    title: "Stammdaten Speichern",
    description:
      "Sind Sie sicher, dass Sie die angepassten Stammdaten Speichern wollen?"
  }
};

const detailsStr: IDetailsStr = {
  birthday: "Geburtsdatum",
  email: "E-Mail-Adresse",
  firstName: "Vorname",
  gender: "Geschlecht",
  lastName: "Nachname"
};

const genders = {
  divers: "divers",
  female: "weiblich",
  male: "männlich"
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    form: {
      width: "80%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(1)
    },
    formControl: { width: "100%", userSelect: "none" },
    actions: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    title: {
      textAlign: "center",
      boxSizing: "border-box"
    },
    description: {
      textAlign: "center",
      boxSizing: "border-box",
      padding: theme.spacing(2),
      margin: "0px"
    },
    surveyDetails: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(1),
      textAlign: "center",
      boxSizing: "border-box",
      margin: "0px"
    },
    content: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2)
    },
    table: {
      width: "80%"
    },
    row: {},
    rowName: {
      fontWeight: "bold"
    },
    cell: { width: "50%" },
    lastRow: {
      border: "none"
    },
    btn: {
      minWidth: "200px"
    },
    red: {
      color: theme.palette.error.main
    },
    yellow: {
      color: theme.palette.warning.main
    },
    lightYellow: {
      color: theme.palette.warning.light
    },
    green: {
      color: theme.palette.success.main
    },
    grey: {
      color: "grey"
    }
  })
);

export interface IEditParticipant {
  open: boolean;
  toggleDialog: () => void;
  participant: IParticipantModel;
}

const EditParticipant: FC<IEditParticipant> = ({
  open,
  toggleDialog,
  participant
}) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const { updateParticipant } = ParticipantService;

  const [step, setStep] = useState<number>(ESteps.initial);
  const [title, setTitle] = useState<string>(
    dialogStr.initial.title.toUpperCase()
  );
  const [description, setDescription] = useState<string>(
    dialogStr.initial.description
  );
  const [details, setDetails] = useState<IParticipantModel>({
    id: participant.id,
    firstName: participant.firstName,
    lastName: participant.lastName,
    birthday: new Date(participant.birthday),
    gender: participant.gender,
    email: participant.email,
    supportStatus: participant.supportStatus
  });

  const handleClose = () => {
    toggleDialog();
    setStep(0);
    setTitle(dialogStr.initial.title);
    setDescription(dialogStr.initial.description);
  };

  const handlePropagation = (direction: "next" | "back") => {
    const newStep = direction === "next" ? step + 1 : step - 1;
    if (ESteps[newStep]) {
      setStep(newStep);
      setTitle(dialogStr[ESteps[newStep]].title);
      setDescription(dialogStr[ESteps[newStep]].description);
    }
  };

  const validateDetails = () => {
    let valid = false;
    if (
      validate.birthday(formatDate(details.birthday)) &&
      validate.name(details.firstName) &&
      validate.name(details.lastName) &&
      validate.email(details.email) &&
      validate.gender(details.gender)
    ) {
      valid = true;
    }
    return valid;
  };

  const handleContinue = async () => {
    const valid = validateDetails();
    if (valid) {
      const rsp = await updateParticipant(details);
      if (rsp.status_code === 1000) {
        enqueueSnackbar(
          `Stammdaten erfolgreich geändert`,
          getNotiOptions("success")
        );
        window.location.href = `/participant/${participant.id}`;
      } else {
        enqueueSnackbar(
          `Stammdaten konnten nicht geändert werden`,
          getNotiOptions("error")
        );
        handleClose();
      }
    } else {
      enqueueSnackbar(`Stammdaten sind nicht gültig`, getNotiOptions("error"));
    }
  };

  const actionsContent = () => {
    const finalStep = Object.keys(ESteps).length / 2 - 1;
    return (
      <>
        {step > 0 && (
          <Button
            className={classes.yellow}
            onClick={() => handlePropagation("back")}
            color="default"
            variant="text"
            size="small"
          >
            Zurück
          </Button>
        )}
        {step < finalStep && (
          <Button
            className={classes.green}
            onClick={() => handlePropagation("next")}
            color="default"
            variant="text"
            size="small"
          >
            Weiter
          </Button>
        )}
        <Button
          className={classes.red}
          onClick={handleClose}
          color="default"
          variant="text"
          size="small"
        >
          Abbrechen
        </Button>
      </>
    );
  };

  const getEditContent = () => {
    return (
      <FormGroup className={classes.form} row>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                color="secondary"
                label="Vorname"
                value={details.firstName}
                onChange={(e) =>
                  setDetails({ ...details, firstName: `${e.target.value}` })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                color="secondary"
                label="Nachname"
                value={details.lastName}
                onChange={(e) =>
                  setDetails({ ...details, lastName: `${e.target.value}` })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <DatePicker
                processDate={(d) => setDetails({ ...details, birthday: d })}
                label="Geburtsdatum"
                openTo="year"
                disableFuture={true}
                initialDate={details.birthday}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              color="secondary"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="gender_label">Geschlecht</InputLabel>
              <Select
                labelId="gender_label"
                autoWidth
                value={details.gender}
                onChange={(e) =>
                  setDetails({ ...details, gender: `${e.target.value}` })
                }
              >
                {Object.entries(genders).map(([key, value], i) => (
                  <MenuItem key={i} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                color="secondary"
                label="E-Mail-Adresse"
                value={details.email}
                onChange={(e) =>
                  setDetails({ ...details, email: `${e.target.value}` })
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </FormGroup>
    );
  };

  const getSummaryContent = () => {
    return (
      <>
        <Table size="small" className={classes.table}>
          <TableBody>
            {Object.entries(details).map(([key, value], i) => {
              if (key !== "id") {
                return (
                  <TableRow className={classes.row} key={i}>
                    <TableCell align="left" className={classes.rowName}>
                      {`${detailsStr[key]}:`}
                    </TableCell>
                    <TableCell align="right">
                      {key === "birthday" ? formatDate(value) : value}
                    </TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
        <Button
          className={classes.btn}
          onClick={() => {
            handleContinue();
          }}
          color="secondary"
          variant="contained"
          size="small"
          startIcon={<WarningIcon className={classes.lightYellow} />}
        >
          Bestätigen
        </Button>
      </>
    );
  };

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <Divider component="span" />
      <Typography className={classes.description}>{description}</Typography>
      <Divider component="span" />
      <DialogContent className={classes.content}>
        {step === 0 && getEditContent()}
        {step === 1 && getSummaryContent()}
      </DialogContent>
      <Divider component="span" />
      <DialogActions className={classes.actions}>
        {actionsContent()}
      </DialogActions>
    </Dialog>
  );
};

export default observer(EditParticipant);
