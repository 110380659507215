import {
  Dialog,
  makeStyles,
  Theme,
  TextField,
  Button
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { ISurveyModel } from "../../models/ISurveyModel";
import SurveyService from "../../services/SurveyService";
import { DataGridActionButtonProps } from "../DataGrid";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  title: {
    textAlign: "center",
    boxSizing: "border-box"
  },
  content: {
    width: "400px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2)
  },
  button: {
    marginTop: "15px"
  }
}));

const AddCorrectAnswer: FC<DataGridActionButtonProps> = ({
  open,
  close,
  data
}) => {
  const survey = data as ISurveyModel;
  const classes = useStyles();
  const { createCorrectAnswer } = SurveyService;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [answerTitle, setAnswerTitle] = useState<string>("");
  const [answerSolution, setAnswerSolution] = useState<string>("");

  const onClose = () => {
    close();
  };

  const onTitleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setAnswerTitle(event.target.value);
  };

  const onSolutionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setAnswerSolution(event.target.value);
  };

  const handleSubmit = async () => {
    const res = await createCorrectAnswer(
      survey.sid,
      answerTitle,
      answerSolution
    );
    if (res.data) {
      history.go(0);
    } else {
      enqueueSnackbar(
        "Lösung konnte nicht gespeichert werden",
        getNotiOptions("error")
      );
    }
  };

  const disallowedSubmission = () => {
    return answerSolution.length === 0 || answerTitle.length === 0;
  };

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={onClose}
    >
      <DialogTitle className={classes.title}>
        Musterlösung Hinzufügen
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Geben Sie den Titel der Aufgabe und die Lösung an
        </Typography>
        <TextField
          required
          fullWidth
          key="title_field"
          label="Titel"
          onChange={onTitleChange}
        />
        <TextField
          required
          fullWidth
          key="solution_field"
          label="Lösung"
          onChange={onSolutionChange}
        />
        <Button
          className={classes.button}
          fullWidth
          size="small"
          variant="contained"
          color="secondary"
          type="submit"
          onClick={handleSubmit}
          disabled={disallowedSubmission()}
        >
          Speichern
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default AddCorrectAnswer;
