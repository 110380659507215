import { Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react-lite";
import React, { Children, FC, ReactNode } from "react";

const TabPanel = (props: {
  children: ReactNode;
  value: number;
  index: number;
}) => {
  const { children, value, index } = props;

  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export interface ITabWrapper {
  headers: string[];
  children: React.ReactNode;
}

const TabWrapper: FC<ITabWrapper> = ({ headers, children }) => {
  const [value, setValue] = React.useState(0);

  Children.count(children);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: React.SetStateAction<number>
  ) => {
    setValue(newValue);
  };

  if (Children.count(children) < headers.length) {
    throw new Error("TabWrapper got less children than headers");
  }

  const getTabs = () => {
    const childrenArray = Children.toArray(children);
    return headers.map((header, index) => (
      <TabPanel value={value} index={index}>
        {childrenArray[index]}
      </TabPanel>
    ));
  };

  return (
    <>
      <Paper square>
        <Tabs value={value} onChange={handleChange} centered>
          {headers.map((header) => (
            <Tab label={header} />
          ))}
        </Tabs>
      </Paper>
      {getTabs()}
    </>
  );
};

export default observer(TabWrapper);
