/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { parseAndFormat } from "../functions/date";
import { filterStatus, searchUser } from "../functions/filter";
import { translateStatus } from "../functions/translateStatus";
import { IEventModel } from "../models/IEventModel";
import { IUserModel } from "../models/IUserModel";
import EventService from "../services/EventService";
import UserService from "../services/UserService";
import UserStore from "../stores/UserStore";
import DataGrid, { IDataGridProps } from "./DataGrid";
import Heading from "./Heading";
import Wrapper from "./containers/Wrapper";

const Events: FC = () => {
  const history = useHistory();
  const { isAdminSupport } = useContext(UserStore);
  const { getAllEvents } = EventService;

  const [events, setEvents] = useState<Array<IEventModel>>([]);
  const [users, setUsers] = useState<IUserModel[]>([]);

  const getEvents = async () => {
    const { data } = await getAllEvents();
    if (data) {
      setEvents(data);
    }
  };

  const getUsers = async () => {
    const fetchedUsers = await UserService.getUsers();
    if (fetchedUsers) {
      setUsers(
        fetchedUsers.filter((user: IUserModel) => Number(user.roleId) === 4)
      );
    }
  };

  const handleRowClick = (e: any, row: IEventModel) => {
    history.push(`/event/${row.id}`);
  };

  useEffect(() => {
    getEvents();
    getUsers();
  }, []);

  const eventsDataProps: IDataGridProps = {
    pagingFilter: true,
    pageSize: 100,
    searchFilter: true,
    addEventButton: isAdminSupport,
    userList: users,
    onRowClick: handleRowClick,
    columns: [
      {
        align: "left",
        title: "Datum",
        type: "date",
        field: "date",
        sorting: true,
        defaultSort: "asc",
        render: (row: IEventModel) => (
          <Typography>{parseAndFormat(row.date)}</Typography>
        ),
        customFilterAndSearch: (input, row) =>
          parseAndFormat(row.date).includes(input)
      },
      {
        align: "left",
        field: "Office.longName",
        type: "string",
        title: "Einheit"
      },
      {
        align: "left",
        field: "Project.longName",
        type: "string",
        title: "Projekt"
      },
      {
        align: "left",
        title: "Standort",
        type: "string",
        field: "location"
      },
      {
        align: "left",
        title: "Status",
        type: "string",
        field: "status",
        render: (row: IEventModel) => (
          <Typography>{translateStatus("event", row.status)}</Typography>
        ),
        customFilterAndSearch: (input, row) =>
          filterStatus.application(input, row)
      },
      ...(isAdminSupport
        ? [
            {
              title: "Moderator*in",
              field: "User",
              customFilterAndSearch: (input: string, row: IEventModel) =>
                searchUser.byFullName(input, row?.User),
              render: (row: IEventModel) => (
                <Typography>
                  {row.User?.firstName} {row.User?.lastName}
                </Typography>
              )
            }
          ]
        : [])
    ],
    data: events.length > 0 ? JSON.parse(JSON.stringify(events)) : []
  };

  return (
    <Wrapper>
      <Heading heading="Termine">
        <EventIcon />
      </Heading>
      <DataGrid dense {...eventsDataProps} />
    </Wrapper>
  );
};

export default observer(Events);
