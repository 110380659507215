import { makeStyles, Theme } from "@material-ui/core";
import { useContext } from "react";
import UserStore from "../../stores/UserStore";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "1100px",
    width: "100%",
    fontFamily: "Open Sans",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start"
  }
}));

export default function Wrapper(props: any) {
  const { loggedIn } = useContext(UserStore);

  const current = window.location.pathname;
  if (loggedIn && current !== "/" && current !== "/router") {
    window.localStorage.setItem("path", current);
  }

  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
}
