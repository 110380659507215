import axios from "axios";
import { setServerUrl } from "../constants/constants";
import { INormModel } from "../models/INormModel";
import { APIResponse, LinksUpdate } from "./SurveyService";

export const NormService = {
  // get all norms
  async getAllNorms(): Promise<APIResponse<INormModel[]>> {
    const rsp = await axios.get(setServerUrl(`/api/norm/all`, `/api/norm/all`));
    return Promise.resolve(rsp.data);
  },
  // get norm
  async getNorm(id: string): Promise<APIResponse<INormModel>> {
    const rsp = await axios.get(
      setServerUrl(`/api/norm/${id}`, `/api/norm/${id}`)
    );
    return Promise.resolve(rsp.data);
  },
  // create raw value to norm link
  // async createNormLink(
  //   rawValueId: string,
  //   normId: string
  // ): Promise<APIResponse<void>> {
  //   const rsp = await axios.post(
  //     setServerUrl("/api/norm/link", "/api/norm/link"),
  //     {
  //       rawValueId,
  //       normId
  //     }
  //   );
  //   return Promise.resolve(rsp.data);
  // },
  // remove raw value to norm link
  async removeNormLink(
    rawValueId: string,
    normId: string
  ): Promise<APIResponse<void>> {
    const rsp = await axios.delete(
      setServerUrl(
        `/api/norm/unlink/${rawValueId}/${normId}`,
        `/api/norm/unlink/${rawValueId}/${normId}`
      )
    );
    return Promise.resolve(rsp.data);
  },
  // create a new norm
  async createNorm(
    longName: string,
    shortName: string,
    description: string
  ): Promise<APIResponse<INormModel>> {
    const rsp = await axios.post(setServerUrl("/api/norm/new", "/norm/new"), {
      longName,
      shortName,
      description
    });
    return Promise.resolve(rsp.data);
  },
  // delete an existing norm
  async deleteNorm(normId: string): Promise<APIResponse<void>> {
    const rsp = await axios.delete(
      setServerUrl(`/api/norm/delete/${normId}`, `/norm/delete/${normId}`)
    );
    return Promise.resolve(rsp.data);
  },
  // update an existing norm
  async updateNorm(
    normId: string,
    longName: string,
    shortName: string,
    description: string
  ): Promise<APIResponse<boolean>> {
    const rsp = await axios.put(
      setServerUrl("/api/norm/update", "/norm/update"),
      {
        normId,
        longName,
        shortName,
        description
      }
    );
    return Promise.resolve(rsp.data);
  },
  // create & delete norm to rawValue links
  async createNormLinks(
    normId: string,
    rawValueIds: string[],
    deleteRawValueIds: string[]
  ): Promise<APIResponse<LinksUpdate>> {
    const rsp = await axios.post(setServerUrl("/api/norm/link", "/norm/link"), {
      normId,
      rawValueIds,
      deleteRawValueIds
    });
    return Promise.resolve(rsp.data);
  }
};
