import axios, { AxiosResponse } from "axios";
import { setServerUrl } from "../constants/constants";
import IParticipantModel from "../models/IParticipantModel";

const ImportService = {
  async checkConflicts(participants: any): Promise<any> {
    const response: AxiosResponse = await axios.request({
      url: setServerUrl(`/api/import/conflicts`, `/conflicts`),
      method: "POST",
      data: participants
    });
    return await Promise.resolve(response.data.data);
  },
  async importParticipants(formData: any[], fileName: string): Promise<any> {
    const rsp: AxiosResponse = await axios.put<IParticipantModel[]>(
      setServerUrl("/api/import/new", "/api/import/new"),
      { participants: formData, file: fileName },
      {}
    );
    return Promise.resolve(rsp.data);
  },
  async addApplication(
    participantId: string,
    positionId: string,
    officeId: string,
    validFrom: Date,
    validTo: Date
  ): Promise<any> {
    const rsp: AxiosResponse = await axios.put<any>( // überlegen was ich zurückgebe
      setServerUrl("/api/import/ui", "/api/import/ui"),
      {
        participantId,
        positionId,
        officeId,
        validFrom,
        validTo
      }
    );
    return Promise.resolve(rsp.data);
  },
  async importAcData(
    positionId: string,
    eventId: string,
    groupId: string,
    date: Date,
    participants: any,
    competencies: Object
  ): Promise<any> {
    const rsp: AxiosResponse = await axios.post<any>(
      setServerUrl("/api/import/ac", "/api/import/ac"),
      {
        positionId,
        eventId,
        groupId,
        date,
        participants,
        competencies
      }
    );
    return Promise.resolve(rsp.data);
  }
};

export default ImportService;
