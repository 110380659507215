import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip
} from "@material-ui/core";
import { FC } from "react";
import { transform } from "../functions/transform";
import { IResultModel } from "../models/IResultModel";
import { IAcResults } from "./AcResults";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {},
    mainTable: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxSizing: "border-box"
    },
    table: {},
    tableRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between"
    },
    tableCell: {
      border: "none"
    },
    tableHeader: {
      color: "white",
      height: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    totalContainer: {
      display: "flex",
      paddingTop: "1rem",
      boxSizing: "border-box"
    },
    total: {
      fontWeight: 600,
      fontSize: "1rem",
      borderTop: "1px solid lightgrey"
    },
    totalResult: {
      textAlign: "end"
    },
    btnGroup: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
      padding: theme.spacing(1)
    },
    downloadContainer: {
      width: "200px",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    btn: {
      margin: theme.spacing(1)
    },
    input: {
      padding: "none",
      paddingLeft: theme.spacing(1)
    },
    diaBtnBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around"
    },
    white: {
      color: theme.palette.primary.light
    },
    red: {
      color: theme.palette.error.main
    },
    date: {
      textAlign: "center",
      paddingBottom: theme.spacing(2)
    }
  });
});

export interface IPtResults {
  ptResults: IResultModel[];
}

export const PtResults: FC<IPtResults> = ({ ptResults }) => {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>
              Persönlichkeits-Test Ergebnisse
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ptResults.map((result, i) => (
            <TableRow key={i} className={classes.tableRow}>
              <Tooltip title={result.value}>
                <TableCell className={classes.tableCell}>
                  {result.typeObject?.longName}
                </TableCell>
              </Tooltip>
              <TableCell className={classes.tableCell}>
                {transform.zToPercentile(result.value)?.toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
