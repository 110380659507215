import { action, observable, runInAction } from "mobx";
import { createContext } from "react";
import { ICustomerModel } from "../models/ICustomerModel";
import { IOfficeModel } from "../models/IOfficeModel";
import { IPositionModel } from "../models/IPositionModel";
import { IProjectModel } from "../models/IProjectModel";
import { IUserModel } from "../models/IUserModel";
import { IRvTypeModel } from "../models/IRvTypeModel";
import { ICompetencyModel } from "../models/ICompetencyModel";
import { INormModel } from "../models/INormModel";
import CustomerService from "../services/CustomerService";
import ProjectService from "../services/ProjectService";
import { NormService } from "../services/NormService";
class CustomerStore {
  @observable public fetchedCustomer: ICustomerModel;
  @observable public fetchedCustomers: ICustomerModel[];
  @observable public fetchedOffice: IOfficeModel;
  @observable public fetchedOffices: IOfficeModel[];
  @observable public fetchedPositions: IPositionModel[];
  @observable public fetchedProject: IProjectModel;
  @observable public fetchedProjects: IProjectModel[];
  @observable public fetchedUsers: IUserModel[];
  @observable public fetchedRvTypes: IRvTypeModel[];
  @observable public fetchedCompetencies: ICompetencyModel[];
  @observable public fetchedNorms: INormModel[];
  @observable public fetchedNormRvTypes: IRvTypeModel[];
  @observable public isFetchingCustomerData: boolean;
  @observable public isFetchingOfficeData: boolean;
  @observable public isFetchingProjectData: boolean;
  @observable public isFetchingCompetencyData: boolean;
  @observable public isFetchingRvTypeData: boolean;
  @observable public isFetchingNormData: boolean;

  constructor() {
    this.fetchedCustomer = {} as ICustomerModel;
    this.fetchedCustomers = [] as ICustomerModel[];
    this.fetchedOffice = {} as IOfficeModel;
    this.fetchedOffices = [] as IOfficeModel[];
    this.fetchedPositions = [] as IPositionModel[];
    this.fetchedProject = {} as IProjectModel;
    this.fetchedProjects = [] as IProjectModel[];
    this.fetchedUsers = [] as IUserModel[];
    this.fetchedRvTypes = [] as IRvTypeModel[];
    this.fetchedCompetencies = [] as ICompetencyModel[];
    this.fetchedNorms = [] as INormModel[];
    this.fetchedNormRvTypes = [] as IRvTypeModel[];
    this.isFetchingCustomerData = false;
    this.isFetchingOfficeData = false;
    this.isFetchingProjectData = false;
    this.isFetchingCompetencyData = false;
    this.isFetchingRvTypeData = false;
    this.isFetchingNormData = false;
  }

  // get all customers
  @action("get all customers")
  public getCustomers = () => {
    this.isFetchingCustomerData = true;
    runInAction("Get all customers", async () => {
      this.fetchedCustomers = await CustomerService.getCustomers();
      this.isFetchingCustomerData = false;
    });
  };

  // get customer by Id
  @action("get customer by id")
  public getCustomerById = (customerId: string) => {
    this.isFetchingCustomerData = true;
    runInAction("get customer by id", async () => {
      this.fetchedCustomer = await CustomerService.getCustomerById(customerId);
      this.isFetchingCustomerData = false;
    });
    return this.fetchedCustomer;
  };

  // create customer
  @action("create customer")
  public createCustomer = (data: ICustomerModel) => {
    this.isFetchingCustomerData = true;
    runInAction("Create customer", async () => {
      this.fetchedCustomers = await CustomerService.createCustomer(data);
      this.isFetchingCustomerData = false;
    });
    return this.fetchedCustomers;
  };

  // delete customer
  @action("delete customer")
  public deleteCustomer = (customerId: string) => {
    this.isFetchingCustomerData = true;
    runInAction("Delete customer by id", async () => {
      this.fetchedCustomers = await CustomerService.deleteCustomer(customerId);
      this.isFetchingCustomerData = false;
    });
    return this.fetchedCustomers;
  };

  // get all offices
  @action("get all offices")
  public getAllOffices = () => {
    this.isFetchingOfficeData = true;
    runInAction("get all offices", async () => {
      this.fetchedOffices = await CustomerService.getAllOffices();
      this.isFetchingOfficeData = false;
    });
  };

  // get customer offices
  @action("get customer offices")
  public getCustomerOffices = (customerId: string) => {
    this.isFetchingOfficeData = true;
    runInAction("get customer offices", async () => {
      this.fetchedOffices = await CustomerService.getCustomerOffices(
        customerId
      );
      this.isFetchingOfficeData = false;
    });
  };

  // get office by id
  @action("get office by id")
  public getOfficeById = (officeId: string) => {
    this.isFetchingOfficeData = true;
    runInAction("get office by id", async () => {
      this.fetchedOffice = await CustomerService.getOfficeById(officeId);
      this.isFetchingOfficeData = false;
    });
  };

  // create office
  @action("create office")
  public createOffice = (data: any) => {
    this.isFetchingOfficeData = true;
    runInAction("create office", async () => {
      await CustomerService.createOffice(data);
      this.isFetchingOfficeData = false;
    });
  };

  // clear all offices
  @action("clear offices")
  public clearOffices = () => {
    this.isFetchingOfficeData = true;
    runInAction("clear Offices", async () => {
      this.fetchedOffices = [];
      this.isFetchingOfficeData = false;
    });
  };

  // delete office
  @action("delete office")
  public deleteOffice = (officeId: string) => {
    this.isFetchingCustomerData = true;
    runInAction("delete office", async () => {
      await CustomerService.deleteOffice(officeId);
      this.isFetchingCustomerData = false;
    });
  };

  // get users of customer
  @action("get users of customer")
  public getUsers = (customerId: string) => {
    this.isFetchingCustomerData = true;
    runInAction("Get Users of customer", async () => {
      this.fetchedUsers = await CustomerService.getUsers(customerId);
      this.isFetchingCustomerData = false;
    });
  };

  // update user
  @action("update user")
  public updateUser = (
    customerId: string,
    userId: string | number,
    data: IUserModel
  ) => {
    this.isFetchingCustomerData = true;
    runInAction("Update User", async () => {
      this.fetchedUsers = await CustomerService.updateUser(
        customerId,
        userId,
        data
      );
      this.isFetchingCustomerData = false;
    });
  };

  // delete user
  @action("delete user")
  public deleteUser = (customerId: string, userId: string | number) => {
    this.isFetchingCustomerData = true;
    runInAction("Delete User", async () => {
      this.fetchedUsers = await CustomerService.deleteUser(customerId, userId);
      this.isFetchingCustomerData = false;
    });
  };

  // get project by id
  @action("get project by id")
  public getProjectById = (projectId: string) => {
    this.isFetchingProjectData = true;
    runInAction("Get project by id", async () => {
      this.fetchedProject = await ProjectService.getProjectById(projectId);
      this.isFetchingProjectData = false;
    });
  };

  // get all rvTypes
  @action("get all rvTypes")
  public getAllRvTypes = () => {
    this.isFetchingRvTypeData = true;
    runInAction("get all rvTypes", async () => {
      this.fetchedRvTypes = await CustomerService.getAllRvTypes();
      this.isFetchingRvTypeData = false;
    });
  };

  // get all norms
  @action("get all norms")
  public getAllNorms = () => {
    this.isFetchingNormData = true;
    runInAction("get all norms", async () => {
      const response = await NormService.getAllNorms();
      if (response.status_code === 1000 && response.data) {
        this.fetchedNorms = response.data;
        this.fetchedNormRvTypes = this.fetchedNorms
          .map((norm) => norm?.RawValues?.map((rv) => rv?.RvTypes[0]))
          .flat();
      }
      this.isFetchingNormData = false;
    });
  };

  // get all competencies
  @action("get all competencies")
  public getAllCompetencies = () => {
    this.isFetchingCompetencyData = true;
    runInAction("get all competencies", async () => {
      this.fetchedCompetencies = await CustomerService.getAllCompetencies();
      this.isFetchingCompetencyData = false;
    });
  };
}

export default createContext(new CustomerStore());
