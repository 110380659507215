import {
  AppBar,
  Box,
  createStyles,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/ki-test-logo.svg";
import DrawerStore from "../stores/DrawerStore";
import UserStore from "../stores/UserStore";
import LoginInformation from "./LoginInformation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: "25px",
      cursor: "pointer"
    },
    appBar: {
      zIndex: 1300,
      boxShadow: theme.shadows[2]
    },
    logoWrapper: {
      display: "flex",
      alignItems: "center"
    },
    logo: {
      width: "160px",
      height: "auto"
    },
    userInfo: {
      display: "flex",
      alignItems: "center",
      justifyConten: "space-around",
      gap: theme.spacing(5)
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between"
    },
    leftHeader: {
      display: "flex",
      alignItems: "center"
    }
  })
);

const NavBar: FC = () => {
  const classes = useStyles();
  const { setDrawerOpen, drawerOpen } = useContext(DrawerStore);
  const { loggedIn } = useContext(UserStore);

  return (
    <AppBar className={classes.appBar} position="fixed" color="inherit">
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftHeader}>
          {loggedIn && (
            <MenuIcon
              className={classes.menuButton}
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            />
          )}
          <Box className={classes.logoWrapper}>
            <Link to={loggedIn ? "/participants" : "/login"} data-cy="logo">
              <Logo className={classes.logo} />
              {/* <img alt="logo" className={classes.logo} src={logo} /> */}
            </Link>
          </Box>
        </div>
        {loggedIn && (
          <Box className={classes.userInfo}>
            <LoginInformation />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default observer(NavBar);
