import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme
} from "@material-ui/core";
import { startOfTomorrow } from "date-fns";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { regex, translations } from "../../constants/constants";
import { formatDate } from "../../functions/date";
import { translateStatus } from "../../functions/translateStatus";
import { EEventStatus } from "../../models/IEventModel";
import { IOfficeModel } from "../../models/IOfficeModel";
import { IPositionModel } from "../../models/IPositionModel";
import { IProjectModel } from "../../models/IProjectModel";
import { IUserModel } from "../../models/IUserModel";
import EventService from "../../services/EventService";
import UserStore from "../../stores/UserStore";
import { IAddEventDialog } from "../DataGrid";
import DatePicker from "../DatePicker";
import PositionSelector, { ICallbackInfo } from "../PositionSelector";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    title: {
      textAlign: "center",
      boxSizing: "border-box"
    },
    red: {
      color: theme.palette.error.main
    },
    green: {
      color: theme.palette.success.main
    },
    fullWidth: {
      widht: "100%"
    },
    content: {
      width: "400px",
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2)
    },
    siblings: {
      display: "flex",
      flexDirection: "row",
      gap: theme.spacing(4)
    },
    requestButton: {
      width: "fit-content"
    }
  })
);

interface ISelections {
  Office?: IOfficeModel;
  Project?: IProjectModel;
  User?: IUserModel;
  status: EEventStatus;
  note?: string;
  location: string;
  groupNames: string[];
  date: string;
}

const AddEvent: FC<IAddEventDialog> = ({
  close,
  open,
  minGroups = 1,
  maxGroups = 2,
  users
}) => {
  const classes = useStyles();
  const userStore = useContext(UserStore);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [selections, setSelections] = useState<ISelections>({
    groupNames: ["Vormittag", "Nachmittag"],
    status: EEventStatus.created,
    date: formatDate(startOfTomorrow()),
    location: ""
  });

  const dialogClosed = () => {
    setSelections({
      ...selections,
      Office: undefined,
      Project: undefined,
      User: undefined
    });
    close();
  };

  const requirementsFullfilled = () => {
    return (
      selections.Office &&
      selections.Project &&
      selections.User &&
      selections.status &&
      selections.date &&
      selections.location &&
      selections.groupNames.length >= minGroups &&
      selections.groupNames.length <= maxGroups
    );
  };

  const updateEventRequest = async () => {
    if (!requirementsFullfilled()) {
      return;
    }
    const res = await EventService.updateEvent({
      id: "",
      ...selections
    });
    if (res.status_code === 1000) {
      history.go(0);
    } else {
      enqueueSnackbar(
        "Termin konnte nicht erstellt werden",
        getNotiOptions("error")
      );
    }
  };

  const onOfficeAndProjectCallback = (changes: {
    office?: ICallbackInfo<IOfficeModel> | undefined;
    project?: ICallbackInfo<IProjectModel> | undefined;
    position?: ICallbackInfo<IPositionModel> | undefined;
  }) => {
    setSelections({
      ...selections,
      Office:
        changes.office?.reset || changes.office?.object
          ? changes.office.object
          : selections.Office,
      Project:
        changes.project?.reset || changes.project?.object
          ? changes.project.object
          : selections.Project
    });
  };

  const onUserSelected = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const selected = users.find((user) => user.id === event.target.value);
    setSelections({
      ...selections,
      User: selected
    });
  };

  const onDateSelected = (date: Date) => {
    setSelections({
      ...selections,
      date: formatDate(date)
    });
  };

  const onGroupChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const isValidNumber = regex.wholeNumber.test(event.target.value);
    const num = +event.target.value;
    const oob = num < minGroups || num > maxGroups;
    if (oob) {
      event.target.value = `${num}`;
      enqueueSnackbar(
        `Gruppenanzahl muss zwischen ${minGroups} und ${maxGroups} liegen`,
        getNotiOptions("error")
      );
    }
    const numGroups = isValidNumber && !oob ? num : maxGroups;
    const currentLength = selections.groupNames
      ? selections.groupNames?.length
      : 0;
    let updatedGroups = selections.groupNames;
    if (numGroups < currentLength) {
      updatedGroups = updatedGroups?.slice(0, numGroups);
    } else if (numGroups > currentLength) {
      for (let i = currentLength; i < numGroups; i++) {
        updatedGroups?.push(`Gruppe ${i + 1}`);
      }
    }

    setSelections({
      ...selections,
      groupNames: updatedGroups
    });
  };

  const onLocationChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSelections({
      ...selections,
      location: event.target.value
    });
  };

  const onNoteChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSelections({
      ...selections,
      note: event.target.value
    });
  };

  const onGroupNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    group: number
  ) => {
    const updatedGroupNames = selections.groupNames;
    updatedGroupNames[group] = event.target.value;
    setSelections({
      ...selections,
      groupNames: updatedGroupNames
    });
  };

  const createGroupTextFields = () => {
    const fields: JSX.Element[] = [];
    if (selections.groupNames) {
      for (let i = 0; i < selections.groupNames.length; i++) {
        fields.push(
          <TextField
            key={i}
            label={`Gruppe ${i + 1}`}
            onChange={(event) => onGroupNameChange(event, i)}
            value={selections.groupNames[i]}
            fullWidth
          />
        );
      }
    }
    return fields;
  };

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={dialogClosed}
    >
      <DialogTitle className={classes.title}>Termin Erstellen</DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <PositionSelector
          positionUnselectable
          offices={userStore.user.Offices ? userStore.user.Offices : []}
          onChange={onOfficeAndProjectCallback}
        />
        <FormControl fullWidth>
          <InputLabel id="user-select-label">Moderator*in</InputLabel>
          <Select
            value={selections?.User?.id || ""}
            labelId="user-select-label"
            id="user-select"
            onChange={onUserSelected}
          >
            {users.map((user, i) => (
              <MenuItem key={i} value={user.id}>
                {user.firstName} {user.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={classes.siblings}>
          <DatePicker
            disablePast
            label="Datum *"
            minDate={startOfTomorrow()}
            initialDate={startOfTomorrow()}
            processDate={onDateSelected}
          />
          <TextField
            value={selections.groupNames?.length}
            onChange={onGroupChange}
            required
            type="number"
            // defaultValue={selections.groupNames}
            id="groups"
            label="Gruppen"
            inputMode="numeric"
          />
        </div>
        {createGroupTextFields()}
        <TextField
          required
          fullWidth
          id="location"
          label="Standort"
          onChange={onLocationChange}
        />
        <TextField
          fullWidth
          multiline
          id="note"
          label={translations["note"]}
          onChange={onNoteChange}
        />
        <FormControl fullWidth>
          <InputLabel id="status-select-label">Status *</InputLabel>
          <Select
            value={selections.status}
            labelId="status-select-label"
            id="status-select"
            onChange={(event) =>
              setSelections({
                ...selections,
                status: event.target.value as EEventStatus
              })
            }
          >
            {Object.values(EEventStatus).map((status, i) => (
              <MenuItem key={i} value={status}>
                {translateStatus("event", status)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          fullWidth
          size="small"
          variant="contained"
          color="secondary"
          type="submit"
          className={classes.requestButton}
          disabled={!requirementsFullfilled()}
          onClick={updateEventRequest}
        >
          Termin Erstellen
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default observer(AddEvent);
