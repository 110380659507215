import "dotenv/config";

declare global {
  interface Window {
    Cypress: never;
  }
}

export type CompetencyTypes = "OT" | "AC" | "PT";

export enum ResultType {
  Raw = "raw_orig",
  RawApplication = "raw_app",
  RawZ = "raw_z",
  PositionZ = "position_z",
  CompetencyZ = "competency_z",
  CompetencyAC = "competency_ac",
  PositionAC = "position_ac",
  PT = "competency_pt"
}

// VARIABLES
const serverUrl: string = process?.env?.REACT_APP_SERVER_URL || "";

const dateFormats = {
  default: "dd.MM.yyyy",
  variant: "d.M.yyyy"
};

const locale = "de-DE";
const localeOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
};
const localeOptionsTime: Intl.DateTimeFormatOptions = {
  ...localeOptions,
  hour: "2-digit",
  minute: "2-digit"
};
const regex = {
  birthday:
    /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/,
  date: /^(?:(?:[0-2]\d|[3][0-1]|\d)\.(?:[0][1-9]|[1][0-2]|\d)\.\d*|\d*-(?:[0][1-9]|[1][0-2]|\d)-(?:[0-2]\d|[3][0-1]|\d))$/,
  description: /^\S+$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  gender: /^.*(m\u00e4nnlich|weiblich|divers).*$/,
  hexCode: /^#([0-9A-F]{3}){1,2}$/i,
  name: /^[\u00C0-\u017F\sa-zA-Z,.'-]+$/i,
  nameWithNum: /^[\u00C0-\u017Fa-zA-Z0-9,.'-]+$/i,
  nameWithNumAndSpecial: /^[a-zA-Z0-9!@#~$%§^&*°()_+\-=[\]{};':"\\|,.<>/?]*$/,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&"§/()=])[A-Za-z\d@$!%*?&"§/()=]{8,}$/,
  phone: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
  sid: /^[0-9]*$/,
  wholeNumber: /^0*[1-9][0-9]*$/
};

const translations: { [string: string]: string } = {
  branding: "Branding",
  color: "Farbe",
  dataSharing: "Daten teilen",
  description: "Beschreibung",
  eulaSurvey: "EULA-Umfrage",
  exportType: "Export-Typ",
  longName: "Name",
  Offices: "Einheiten",
  reports: "Reports",
  revaluation: "Umwertung",
  shortName: "Abkürzung",
  showResults: "Ergebnisse",
  type: "Typ",
  grades: "Noten",
  points: "Punkte",
  percentiles: "Prozentränge",
  validFrom: "Gültig von",
  validUntil: "Gültig bis",
  status: "Status",
  location: "Standort",
  note: "Anmerkung"
};

const competencyTypes = {
  OT: "Leistung",
  AC: "Mündliches Verfahren",
  PT: "Persönlichkeit"
};

const colors = [
  "#F94144",
  "#d15bbd",
  "#F3722C",
  "#F8961E",
  "#F9844A",
  "#F9C74F",
  "#90BE6D",
  "#43AA8B",
  "#4D908E",
  "#577590",
  "#277DA1"
];

// FUNCTIONS
const setServerUrl = (path: string, mockPath: string): string => {
  return serverUrl === "mock" ? mockPath : `${serverUrl}${path}`;
};
const getPortalUrl = () => {
  const currentURL = window.location.href;
  return new URL(window.location.href).origin;
};

const validate = {
  email: (email: string) => {
    return regex.email.test(email);
  },
  name: (name: string) => {
    return regex.name.test(name);
  },
  gender: (gender: string) => {
    return regex.gender.test(gender);
  },
  birthday: (birthday: string) => {
    return regex.birthday.test(birthday);
  }
};

export {
  colors,
  dateFormats,
  getPortalUrl,
  locale,
  localeOptions,
  localeOptionsTime,
  regex,
  serverUrl,
  setServerUrl,
  translations,
  validate,
  competencyTypes
};
