import {
  Dialog,
  makeStyles,
  Theme,
  TextField,
  Button
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { CorrectAnswer, ISurveyModel } from "../../models/ISurveyModel";
import SurveyService from "../../services/SurveyService";
import { DataGridActionButtonProps } from "../DataGrid";
import DeleteButton from "../DeleteButton";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  title: {
    textAlign: "center",
    boxSizing: "border-box"
  },
  content: {
    width: "400px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2)
  },
  button: {
    marginTop: "15px"
  }
}));

interface EditCorrectAnswerProps {
  open: boolean;
  close: () => void;
  answer: CorrectAnswer;
}

export const EditCorrectAnswer: FC<EditCorrectAnswerProps> = ({
  open,
  close,
  answer
}) => {
  const classes = useStyles();
  const { editCorrectAnswer, deleteCorrectAnswer } = SurveyService;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [answerTitle, setAnswerTitle] = useState<string>(answer.title);
  const [answerSolution, setAnswerSolution] = useState<string>(answer.solution);

  const onClose = () => {
    close();
  };

  const onTitleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setAnswerTitle(event.target.value);
  };

  const onSolutionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setAnswerSolution(event.target.value);
  };

  const handleSubmit = async () => {
    const res = await editCorrectAnswer(answer.id, answerTitle, answerSolution);
    if (res.data) {
      history.go(0);
    } else {
      enqueueSnackbar(
        "Lösung konnte nicht gespeichert werden",
        getNotiOptions("error")
      );
    }
  };

  const handleDelete = async () => {
    const res = await deleteCorrectAnswer(answer.id);
    // TODO this doesn't work
    if (res) {
      history.go(0);
    } else {
      enqueueSnackbar(
        "Lösung konnte nicht gelöscht werden",
        getNotiOptions("error")
      );
    }
  };

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={onClose}
    >
      <DialogTitle className={classes.title}>
        Musterlösung Bearbeiten
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Geben Sie den Titel der Aufgabe und die Lösung an
        </Typography>
        <TextField
          required
          fullWidth
          value={answerTitle}
          key="title_field"
          label="Titel"
          onChange={onTitleChange}
        />
        <TextField
          value={answerSolution}
          required
          fullWidth
          key="solution_field"
          label="Lösung"
          onChange={onSolutionChange}
        />
        <Button
          className={classes.button}
          fullWidth
          size="small"
          variant="contained"
          color="secondary"
          type="submit"
          onClick={handleSubmit}
          disabled={!answerSolution.length || !answerTitle.length}
        >
          Speichern
        </Button>
        <div className={classes.button}>
          <DeleteButton
            fullWidth
            compareString="löschen"
            buttonName="Löschen"
            description={'Geben Sie "löschen" ein'}
            callback={handleDelete}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
