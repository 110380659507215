import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { regex } from "../../constants/constants";
import { emptySurvey, ISurveyModel } from "../../models/ISurveyModel";
import SurveyService from "../../services/SurveyService";
import { IAddDialog } from "../DataGrid";

interface IStrings {
  [key: string]: {
    [key: string]: string;
  };
}

enum ESteps {
  initial = 0,
  details,
  continue
}

const dialogStr: IStrings = {
  initial: {
    title: "Umfrage wählen",
    description: "Wählen Sie die Umfrage die Sie duplizieren möchten"
  },
  details: {
    title: "Details festlegen",
    description:
      "Bestimmen Sie eine Umfrage-ID, einen Umfragenamen und den Barrierestatus."
  },
  continue: {
    title: "Umfrage erstellen",
    description: "Sind Sie sicher, dass Sie die Umfrage erstellen wollen?"
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    form: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(1)
    },
    formControl: { width: "50%", minWidth: "200px", userSelect: "none" },
    actions: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    title: {
      textAlign: "center",
      boxSizing: "border-box"
    },
    description: {
      textAlign: "center",
      boxSizing: "border-box",
      padding: theme.spacing(2),
      margin: "0px"
    },
    surveyDetails: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(1),
      textAlign: "center",
      boxSizing: "border-box",
      margin: "0px"
    },
    content: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2)
    },
    table: {
      width: "80%"
    },
    row: {},
    rowName: {
      fontWeight: "bold"
    },
    cell: { width: "50%" },
    lastRow: {
      border: "none"
    },
    btn: {
      minWidth: "200px"
    },
    red: {
      color: theme.palette.error.main
    },
    yellow: {
      color: theme.palette.warning.main
    },
    lightYellow: {
      color: theme.palette.warning.light
    },
    green: {
      color: theme.palette.success.main
    }
  })
);

const AddSurvey: FC<IAddDialog> = ({ open, close, data }) => {
  const classes = useStyles();
  const history = useHistory();

  const surveys = data as ISurveyModel[];

  const { enqueueSnackbar } = useSnackbar();
  const { duplicateSurvey } = SurveyService;

  const [step, setStep] = useState<number>(ESteps.initial);
  const [selectedSurveyId, setSelectedSurveyId] = useState<string>("");
  const [title, setTitle] = useState<string>(
    dialogStr.initial.title.toUpperCase()
  );
  const [description, setDescription] = useState<string>(
    dialogStr.initial.description
  );
  const [surveyDetails, setSurveyDetails] = useState<ISurveyModel>(
    emptySurvey()
  );

  const handleClose = () => {
    close();
    setStep(0);
    setSelectedSurveyId("");
    setTitle(dialogStr.initial.title);
    setDescription(dialogStr.initial.description);
    setSurveyDetails(emptySurvey());
  };

  const handleCreateSurvey = async () => {
    const rsp = await duplicateSurvey(selectedSurveyId, surveyDetails);
    if (rsp.status_code === 1000) {
      enqueueSnackbar(
        `Umfrage erfolgreich erstellt`,
        getNotiOptions("success")
      );
      history.go(0);
    } else {
      enqueueSnackbar(
        `Umfrage konnte nicht erstellt werden`,
        getNotiOptions("error")
      );
      handleClose();
    }
  };

  const handlePropagation = (direction: "next" | "back") => {
    const newStep = direction === "next" ? step + 1 : step - 1;
    if (ESteps[newStep]) {
      setStep(newStep);
      setTitle(dialogStr[ESteps[newStep]].title);
      setDescription(dialogStr[ESteps[newStep]].description);
    }
  };

  const handleCheckbox = () => {
    if (surveyDetails.barrier) {
      setSurveyDetails({ ...surveyDetails, barrier: 0 });
    } else {
      setSurveyDetails({ ...surveyDetails, barrier: 1 });
    }
  };

  const handleSid = (value: string) => {
    const valid = regex.sid.test(value);
    if (valid && value.length <= 6 && !isNaN(Number(value))) {
      setSurveyDetails({ ...surveyDetails, sid: value });
    }
  };

  const handleDescription = (value: string) => {
    setSurveyDetails({ ...surveyDetails, description: value.replace(" ", "") });
  };

  const handleContinue = () => {
    if (
      surveyDetails.sid.length === 6 &&
      surveyDetails.description.length > 0 &&
      regex.sid.test(surveyDetails.sid) &&
      regex.description.test(surveyDetails.description)
    ) {
      if (
        !surveys
          ?.map((surv: ISurveyModel) => surv.sid)
          .includes(surveyDetails.sid)
      ) {
        handlePropagation("next");
      } else {
        enqueueSnackbar(
          "Die Umfrage-ID existiert schon",
          getNotiOptions("error")
        );
      }
    } else {
      enqueueSnackbar(
        "Die Umfrage-ID oder der Umfragename sind nicht gültig",
        getNotiOptions("error")
      );
    }
  };

  const detailsContent = () => {
    return (
      <>
        {selectedSurveyId && (
          <Table size="small" className={classes.table}>
            <TableRow className={classes.row}>
              <TableCell
                align="left"
                className={`${classes.rowName} ${classes.cell}`}
              >
                Vorlage:
              </TableCell>
              <TableCell align="right" className={classes.cell}>
                {
                  surveys?.filter(
                    (surv: ISurveyModel) => surv.id === selectedSurveyId
                  )[0].description
                }
              </TableCell>
            </TableRow>
            {step === 2 && surveyDetails.sid && (
              <TableRow className={classes.row}>
                <TableCell
                  align="left"
                  className={`${classes.rowName} ${classes.cell}`}
                >
                  Umfrage-ID:
                </TableCell>
                <TableCell align="right" className={classes.cell}>
                  {surveyDetails.sid}
                </TableCell>
              </TableRow>
            )}
            {step === 2 && surveyDetails.description && (
              <TableRow className={classes.row}>
                <TableCell
                  align="left"
                  className={`${classes.rowName} ${classes.cell}`}
                >
                  Umfragename:
                </TableCell>
                <TableCell align="right" className={classes.cell}>
                  {surveyDetails.description}
                </TableCell>
              </TableRow>
            )}
            {step === 2 && (
              <TableRow className={classes.row}>
                <TableCell
                  align="left"
                  className={`${classes.rowName} ${classes.lastRow} ${classes.cell}`}
                >
                  Barriereumfrage:
                </TableCell>
                <TableCell
                  align="right"
                  className={`${classes.lastRow} ${classes.cell}`}
                >
                  {surveyDetails.barrier === 0 ? "nein" : "ja"}
                </TableCell>
              </TableRow>
            )}
          </Table>
        )}
      </>
    );
  };

  const actionsContent = () => {
    const finalStep = Object.keys(ESteps).length / 2 - 1;
    return (
      <>
        {step > 0 && (
          <Button
            className={classes.yellow}
            onClick={() => handlePropagation("back")}
            color="default"
            variant="text"
            size="small"
          >
            Zurück
          </Button>
        )}
        {selectedSurveyId && step < finalStep && (
          <Button
            className={classes.green}
            onClick={() => {
              step === 0 ? handlePropagation("next") : handleContinue();
            }}
            color="default"
            variant="text"
            size="small"
          >
            Weiter
          </Button>
        )}
        <Button
          className={classes.red}
          onClick={handleClose}
          color="default"
          variant="text"
          size="small"
        >
          Abbrechen
        </Button>
      </>
    );
  };

  const surveySelectContent = () => {
    return (
      <FormGroup className={classes.form} row>
        <FormControl
          color="secondary"
          fullWidth
          className={classes.formControl}
        >
          <InputLabel id="surveys">Umfrage wählen</InputLabel>
          <Select
            autoWidth
            onChange={(e) => {
              setSelectedSurveyId(`${e.target.value}`);
            }}
          >
            {surveys
              ?.sort((a, b) => a.description.localeCompare(b.description))
              .map((survey: ISurveyModel, i: number) => {
                return (
                  <MenuItem key={i} value={survey.id}>
                    {survey.description}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </FormGroup>
    );
  };

  const detailsSelectContent = () => {
    return (
      <FormGroup className={classes.form} row>
        <FormControl className={classes.formControl}>
          <TextField
            color="secondary"
            type="number"
            label="Umfrage-ID"
            value={surveyDetails.sid}
            inputProps={{ min: 0, max: 999999 }}
            onChange={(e) => {
              handleSid(e.target.value);
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            color="secondary"
            label="Umfragename"
            value={surveyDetails.description}
            onChange={(e) => {
              handleDescription(e.target.value);
            }}
          />
        </FormControl>
        <FormControlLabel
          label="Barriereumfrage"
          labelPlacement="end"
          control={
            <Checkbox
              color="secondary"
              onChange={handleCheckbox}
              checked={surveyDetails.barrier === 1}
            />
          }
          className={classes.formControl}
        />
      </FormGroup>
    );
  };

  const summaryContent = () => {
    return (
      <Button
        className={classes.btn}
        onClick={() => {
          handleCreateSurvey();
        }}
        color="secondary"
        variant="contained"
        size="small"
        startIcon={<WarningIcon className={classes.lightYellow} />}
      >
        Bestätigen
      </Button>
    );
  };

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <Divider component="span" />
      <Typography className={classes.description}>{description}</Typography>
      <Divider component="span" />
      <DialogContent className={classes.content}>
        {detailsContent()}
        {step === 0 && surveySelectContent()}
        {step === 1 && detailsSelectContent()}
        {step === 2 && summaryContent()}
      </DialogContent>
      <Divider component="span" />
      <DialogActions className={classes.actions}>
        {actionsContent()}
      </DialogActions>
    </Dialog>
  );
};

export default observer(AddSurvey);
