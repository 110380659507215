import { ERunStatus } from "../models/IRunModel";
import { EEventStatus } from "../models/IEventModel";
import { EGroupStatus } from "../models/IGroupModel";
import { EApplicationStatus } from "../models/IApplicationModel";
import { TParticipantSupportstatus } from "../models/IParticipantDetailModel";

interface IStatusTranslations {
  run: { [status: string]: string };
  application: { [status: string]: string };
  event: { [status: string]: string };
  group: { [status: string]: string };
}

const translations: IStatusTranslations = {
  run: {
    created: "Erstellt",
    valid: "Aktiv",
    started: "Gestartet",
    complete: "Abgeschlossen",
    incomplete: "Nicht abgeschlossen",
    expired: "Abgelaufen"
  },
  application: {
    created: "Erstellt",
    pending: "Teilnahme ausstehend",
    complete: "Test abgeschlossen",
    incomplete: "Frist abgelaufen",
    abort: "Löschanfrage gestellt",
    support: "Supportfall in Bearbeitung"
  },
  event: {
    created: "Erstellt",
    pending: "Bestätigt",
    complete: "Durchgeführt",
    abort: "Storniert"
  },
  group: {
    valid: "Offen",
    complete: "Abgeschlossen",
    abort: "Storniert"
  }
};

type TStatusTranslation = "run" | "application" | "event" | "group";

const translateStatus = (
  type: TStatusTranslation,
  state: ERunStatus | EApplicationStatus | EEventStatus | EGroupStatus,
  supportStatus?: TParticipantSupportstatus
) => {
  let translation = "";
  switch (type) {
    case "run":
      translation = translations.run[state];
      break;
    case "application":
      translation = supportStatus
        ? translations.application.support
        : translations.application[state];
      break;
    case "event":
      translation = translations.event[state];
      break;
    case "group":
      translation = translations.group[state];
      break;

    default:
      break;
  }
  return translation;
};

export { translateStatus };
