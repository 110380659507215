import axios, { AxiosResponse } from "axios";
// import { ExportToCsv } from "export-to-csv";
import { IEditingRw } from "../components/details/ApplicationDetail";
import { setServerUrl } from "../constants/constants";
import { downloadCSV } from "../functions/csv";
import { formatDate } from "../functions/date";
import { ICommentModel, ICommentUpdateModel } from "../models/ICommentModel";
import { TParticipantSupportstatus } from "../models/IParticipantDetailModel";
import IParticipantModel from "../models/IParticipantModel";
import { APIResponse } from "./SurveyService";

export interface ProjectExportData {
  barrier: string;
  birthday: string;
  email: string;
  firstName: string;
  gender: string;
  lastName: string;
  positionLongName: string;
  positionShortName: string;
  position_z: string;
  project: string;
  status: string;
  supportStatus: string;
  validFrom: string;
  [positionId: string]: string;
}

const ParticipantService = {
  async addParticipants(formData: FormData): Promise<IParticipantModel[]> {
    const rsp: AxiosResponse<IParticipantModel[]> = await axios.post<
      IParticipantModel[]
    >(setServerUrl("/api/import", "/AddParticipants"), formData, {});
    return Promise.resolve(rsp.data);
  },
  async getParticpants() {
    const rsp = await axios.get(
      setServerUrl("/api/participant/all", "/api/participant/all")
    );
    return Promise.resolve(rsp.data.data);
  },
  async getParticpantById(id: string) {
    const rsp = await axios.get(
      setServerUrl(`/api/participant/${id}`, `/api/participant/${id}`)
    );
    if (rsp.data.status_code === 1000) {
      return Promise.resolve(rsp.data.data);
    } else {
      return Promise.reject();
    }
  },
  async updateParticipant(
    details: IParticipantModel
  ): Promise<APIResponse<"ok" | null>> {
    const rsp: AxiosResponse<any> = await axios.put(
      setServerUrl(`/api/participant`, `/api/participant`),
      { ...details }
    );
    return Promise.resolve(rsp.data);
  },
  async saveAcImportResult(participants: any[]): Promise<any> {
    const rsp: AxiosResponse = await axios.post(
      setServerUrl(
        `/api/participants/acimport/save`,
        `/api/participants/acimport/save`
      ),
      {
        participants
      }
    );
    return Promise.resolve(rsp);
  },
  async updateRawValue(
    applicationId: string,
    editingRw: IEditingRw
  ): Promise<any> {
    const rsp: AxiosResponse<any> = await axios.put(
      setServerUrl(`/api/application/rawvalue`, `/api/application/rawvalue`),
      { applicationId, resultId: editingRw.rwId, newValue: editingRw.value }
    );
    if (rsp.data.status_code === 1000) {
      return Promise.resolve(rsp.data.data);
    } else {
      return Promise.reject();
    }
  },
  async updateApplicationDates(
    participantId: string,
    applicationId: string,
    validFrom: Date,
    validUntil: Date
  ): Promise<any> {
    const rsp: AxiosResponse<any> = await axios.put(
      setServerUrl(`/api/application/dates`, `/api/application/dates`),
      { participantId, applicationId, validFrom, validUntil }
    );
    return Promise.resolve(rsp.data);
  },
  async archiveApplication(applicationId: string = "") {
    const rsp = await axios.get(
      setServerUrl(
        `/api/application/archive/${applicationId}`,
        `/api/application/archive/${applicationId}`
      )
    );
    return Promise.resolve(rsp.data);
  },
  async recalculateApplication(applicationId: string) {
    const rsp = await axios.put(
      setServerUrl(
        `/api/application/recalculate/${applicationId}`,
        `/api/application/recalculate/${applicationId}`
      )
    );
    return Promise.resolve(rsp.data);
  },
  async exportParticipants(
    projectId: string,
    participantIds: null | string[] = [],
    projectName: string = ""
  ): Promise<any> {
    const rsp: AxiosResponse<APIResponse<string[][]>> = await axios.put(
      setServerUrl(`/api/export/${projectId}`, `/api/export/${projectId}`),
      { participantIds: participantIds || [] }
    );
    if (rsp && rsp.data.data) {
      if (rsp.data.status_code === 4000) {
        return Promise.resolve(rsp.data.status_code);
      }

      downloadCSV(
        rsp.data.data,
        `${formatDate(new Date())}${
          projectName ? `_${projectName}` : ""
        }_kitest_export.csv`
      );
      return Promise.resolve(rsp.data);
    }
    return Promise.reject(5000);
  },
  async getReport(
    applicationId: string,
    firstName: string,
    lastName: string
  ): Promise<any> {
    const rsp: AxiosResponse<any> = await axios.get<any>(
      setServerUrl(
        `/api/export/report/${applicationId}`,
        `/api/export/report/${applicationId}`
      )
    );
    const linkSource = `data:application/pdf;base64,${rsp.data.data}`;
    const downloadLink = document.createElement("a");
    const fileName = `Report-${lastName}_${firstName}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink.remove();
    return Promise.resolve(rsp.data);
  },
  async getActivities(participantId: string): Promise<any> {
    const rsp: AxiosResponse<any> = await axios.get(
      setServerUrl(
        `/api/activity/${participantId}`,
        `/api/activity/${participantId}`
      )
    );
    return Promise.resolve(rsp.data.data);
  },
  async addComment(participantId: string, data: ICommentModel): Promise<any> {
    const rsp: AxiosResponse<any> = await axios.put(
      setServerUrl(
        `/api/activity/comment/add/${participantId}`,
        `/api/activity/comment/add/${participantId}`
      ),
      {
        data: { ...data }
      }
    );
    return Promise.resolve(rsp.data.data);
  },
  async updateComment(
    participantId: string,
    commentId: string,
    data: ICommentUpdateModel
  ): Promise<any> {
    const rsp: AxiosResponse<any> = await axios.put(
      setServerUrl(
        `/api/activity/comment/update/${participantId}/${commentId}`,
        `/api/activity/comment/update/${participantId}/${commentId}`
      ),
      {
        data: { ...data }
      }
    );
    if (rsp.data.status_code === 1000) {
      return Promise.resolve(rsp.data.data);
    } else {
      return Promise.reject();
    }
  },
  async updateSupportStatus(
    id: string,
    severity: TParticipantSupportstatus,
    level: "run" | "participant"
  ) {
    const rsp = await axios.put(
      setServerUrl(`/api/support/update`, `/api/support/update`),
      { id, severity, level }
    );
    return Promise.resolve(rsp.data);
  },
  async invalidateRun(participantId: string, runId: string) {
    const rsp: AxiosResponse<any> = await axios.put(
      setServerUrl(
        `/api/participant/invalidate/run`,
        `/api/participant/invalidate/run`
      ),
      { participantId, runId }
    );
    return Promise.resolve(rsp.data);
  },
  async replaceRun(
    participantId: string,
    runId: string,
    surveyId: string,
    validUntil: string
  ) {
    const rsp: AxiosResponse<any> = await axios.put(
      setServerUrl(
        `/api/participant/replace/run`,
        `/api/participant/replace/run`
      ),
      { participantId, runId, surveyId, validUntil }
    );
    return Promise.resolve(rsp.data);
  },
  async getAlternativeSurveys(participantId: string, runId: string) {
    const rsp: AxiosResponse<any> = await axios.get(
      setServerUrl(
        `/api/participant/alt/run/${participantId}/${runId}`,
        `/api/participant/alt/run/${participantId}/${runId}`
      )
    );
    if (rsp.data.status_code === 1000) {
      return Promise.resolve(rsp.data);
    } else {
      return Promise.reject();
    }
  },
  async saveCheckSum(applicationId: string, checksum: number): Promise<any> {
    const rsp: AxiosResponse<any> = await axios.post(
      setServerUrl(
        `/api/applications/checksum/save`,
        `/api/applications/checksum/save`
      ),
      { data: { checksum, applicationId } }
    );
    return Promise.resolve(rsp);
  },
  async getCheckSum(applicationId: string) {
    const rsp: AxiosResponse<any> = await axios.get<any>(
      setServerUrl(
        `/api/applications/checksum/${applicationId}`,
        `/api/applications/checksum/${applicationId}`
      )
    );
    return Promise.resolve(rsp.data);
  }
};

export default ParticipantService;
