import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  Theme,
  Typography
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { isBefore, isEqual, sub } from "date-fns";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { getNotiOptions } from "../../constants/configs";
import { formatDate } from "../../functions/date";
import {
  emptyApplication,
  IApplicationModel
} from "../../models/IApplicationModel";
import ParticipantService from "../../services/ParticipantService";
import DatePicker from "../DatePicker";

interface IStrings {
  [key: string]: {
    [key: string]: string;
  };
}

enum ESteps {
  initial = 0,
  continue
}

const dialogStr: IStrings = {
  initial: {
    title: "Gültigkeitszeitraum wählen",
    description:
      "Wählen Sie den neuen Gültigkeitszeitraum für die gewählte Bewerbung"
  },
  continue: {
    title: "Gültigkeitszeitraum ändern",
    description:
      "Sind Sie sicher, dass Sie den Gültigkeitszeitraum der Bewerbung ändern wollen?"
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    title: {
      textAlign: "center",
      boxSizing: "border-box"
    },
    description: {
      textAlign: "center",
      boxSizing: "border-box",
      padding: theme.spacing(2),
      margin: "0px"
    },
    content: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2)
    },
    table: {
      width: "80%"
    },
    row: {},
    cell: { minWidth: "50%" },
    lastRow: {
      border: "none"
    },
    btn: {
      minWidth: "200px"
    },
    italic: {
      fontStyle: "italic",
      fontSize: "0.9rem",
      color: theme.palette.grey[500]
    },
    bold: {
      fontWeight: "bold"
    },
    crossed: {
      textDecoration: "line-through"
    },
    red: {
      color: theme.palette.error.main
    },
    yellow: {
      color: theme.palette.warning.main
    },
    lightYellow: {
      color: theme.palette.warning.light
    },
    green: {
      color: theme.palette.success.main
    }
  })
);

interface IEditApplication {
  participantId: string;
  application: IApplicationModel;
  open: boolean;
  toggleDialog: () => void;
}

interface IDates {
  validFrom: Date;
  validUntil: Date;
}

const EditApplication: FC<IEditApplication> = ({
  participantId,
  application = emptyApplication(),
  open,
  toggleDialog
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { updateApplicationDates } = ParticipantService;

  const initialDates: IDates = {
    validFrom: new Date(application.validFrom),
    validUntil: new Date(application.validUntil)
  };

  const [step, setStep] = useState<number>(ESteps.initial);
  const [dates, setDates] = useState<IDates>(initialDates);
  const [title, setTitle] = useState<string>(
    dialogStr.initial.title.toUpperCase()
  );
  const [description, setDescription] = useState<string>(
    dialogStr.initial.description
  );

  const handleClose = () => {
    toggleDialog();
    setStep(0);
    setDates(initialDates);
    setTitle(dialogStr.initial.title);
    setDescription(dialogStr.initial.description);
  };

  const handleDate = (identifier: "validFrom" | "validUntil", date: Date) => {
    const validFrom = identifier === "validFrom" ? date : dates.validFrom;
    const validUntil = identifier === "validUntil" ? date : dates.validUntil;
    if (isBefore(validFrom, validUntil)) {
      setDates({ ...dates, [identifier]: date });
    } else {
      enqueueSnackbar(
        `Das Enddatum muss muss nach dem Startdatum liegen`,
        getNotiOptions("error")
      );
    }
  };

  const handlePropagation = (direction: "next" | "back") => {
    const newStep = direction === "next" ? step + 1 : step - 1;
    if (ESteps[newStep]) {
      setStep(newStep);
      setTitle(dialogStr[ESteps[newStep]].title);
      setDescription(dialogStr[ESteps[newStep]].description);
    }
  };

  const handleUpdateDates = async () => {
    const rsp = await updateApplicationDates(
      participantId,
      application.id,
      dates.validFrom,
      dates.validUntil
    );
    if (rsp.status_code === 1000) {
      enqueueSnackbar(
        `Gültigkeitszeitraum erfolgreich geändert`,
        getNotiOptions("success")
      );
      window.location.href = `/participant/${participantId}`;
    } else {
      enqueueSnackbar(
        `Gültigkeitszeitraum konnte nicht gändert werden`,
        getNotiOptions("error")
      );
      handleClose();
    }
  };

  const detailsContent = () => {
    return (
      <>
        <Table size="small" className={classes.table}>
          {dates.validFrom && (
            <TableRow className={classes.row}>
              <TableCell
                align="left"
                className={`${classes.bold} ${classes.cell}`}
              >
                Gültig von:
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                <span className={`${classes.bold} ${classes.red}`}>Alt: </span>
                <span className={classes.crossed}>
                  {formatDate(initialDates.validFrom)}
                </span>
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                <span className={`${classes.bold} ${classes.green}`}>
                  Neu:{" "}
                </span>
                {formatDate(dates.validFrom)}
              </TableCell>
            </TableRow>
          )}
          {dates.validUntil && (
            <TableRow className={classes.row}>
              <TableCell
                align="left"
                className={`${classes.bold} ${classes.cell}`}
              >
                Gültig bis:
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                <span className={`${classes.bold} ${classes.red}`}>Alt: </span>
                <span className={classes.crossed}>
                  {formatDate(initialDates.validUntil)}
                </span>
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                <span className={`${classes.bold} ${classes.green}`}>
                  Neu:{" "}
                </span>
                {formatDate(dates.validUntil)}
              </TableCell>
            </TableRow>
          )}
        </Table>
      </>
    );
  };

  const actionsContent = () => {
    const finalStep = Object.keys(ESteps).length / 2 - 1;
    return (
      <>
        {step > 0 && (
          <Button
            className={classes.yellow}
            onClick={() => handlePropagation("back")}
            color="default"
            variant="text"
            size="small"
          >
            Zurück
          </Button>
        )}
        {(!isEqual(dates.validFrom, initialDates.validFrom) ||
          !isEqual(dates.validUntil, initialDates.validUntil)) &&
          step < finalStep && (
            <Button
              className={classes.green}
              onClick={() => handlePropagation("next")}
              color="default"
              variant="text"
              size="small"
            >
              Weiter
            </Button>
          )}
        <Button
          className={classes.red}
          onClick={handleClose}
          color="default"
          variant="text"
          size="small"
        >
          Abbrechen
        </Button>
      </>
    );
  };

  const dateSelectContent = () => {
    const whiteList = {
      validFrom: ["created", "incomplete"],
      validUntil: ["created", "incomplete", "pending"]
    };
    return (
      <>
        {whiteList.validFrom.includes(application.status) ? (
          <DatePicker
            label="Von"
            initialDate={initialDates.validFrom}
            processDate={(d) => {
              handleDate("validFrom", d);
            }}
            disablePast={true}
            minDate={new Date()}
            maxDate={sub(dates.validUntil, { days: 1 })}
          />
        ) : (
          <Typography className={classes.italic}>
            - Startdatum nicht wählbar -
          </Typography>
        )}
        {whiteList.validUntil.includes(application.status) ? (
          <DatePicker
            label="Bis"
            initialDate={initialDates.validUntil}
            processDate={(d) => {
              handleDate("validUntil", d);
            }}
            disablePast={true}
            minDate={initialDates.validUntil}
          />
        ) : (
          <Typography className={classes.italic}>
            - Enddatum nicht wählbar -
          </Typography>
        )}
      </>
    );
  };

  const summaryContent = () => {
    return (
      <Button
        className={classes.btn}
        onClick={() => {
          handleUpdateDates();
        }}
        color="secondary"
        variant="contained"
        size="small"
        startIcon={<WarningIcon className={classes.lightYellow} />}
      >
        Bestätigen
      </Button>
    );
  };

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <Divider component="span" />
      <Typography className={classes.description}>{description}</Typography>
      <Divider component="span" />
      <DialogContent className={classes.content}>
        {step === 1 && detailsContent()}
        {step === 0 && dateSelectContent()}
        {step === 1 && summaryContent()}
      </DialogContent>
      <Divider component="span" />
      <DialogActions className={classes.actions}>
        {actionsContent()}
      </DialogActions>
    </Dialog>
  );
};

export default observer(EditApplication);
