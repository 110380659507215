import { PermContactCalendar } from "@material-ui/icons";
import AssessmentIcon from "@material-ui/icons/Assessment";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { observer } from "mobx-react-lite";
import { FC, MouseEvent, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ICustomerModel } from "../models/ICustomerModel";
import CustomerStore from "../stores/CustomerStore";
import ColorDrop from "./ColorDrop";
import DataGrid, { IDataGridProps } from "./DataGrid";
import Heading from "./Heading";
import IconDrop from "./IconDrop";
import Wrapper from "./containers/Wrapper";

const Customer: FC = () => {
  const history = useHistory();
  const customerStore = useContext(CustomerStore);
  const { fetchedCustomers, getCustomers } = customerStore;

  const handleRowClick = (
    _e: MouseEvent<HTMLButtonElement>,
    row: ICustomerModel
  ) => {
    history.push(`/customer/${row.id}`);
  };

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  const customerDataprops: IDataGridProps = {
    addCustomerButton: true,
    searchFilter: true,
    onRowClick: handleRowClick,
    columns: [
      { title: "Name", field: "longName", width: "50%" },
      { title: "Abkürzung", field: "shortName", width: "50%" },
      {
        title: "Farbe",
        field: "color",
        render: (row: ICustomerModel) => <ColorDrop color={row.color} />
      },
      {
        title: "Ergebnis",
        field: "showResults",
        type: "boolean",
        render: (row: ICustomerModel) => (
          <IconDrop
            dense
            title={"showResults"}
            enabled={row.showResults}
            icon={<FormatListBulletedIcon />}
          />
        )
      },
      {
        title: "Umrechnung",
        field: "revaluation",
        type: "boolean",
        render: (row: ICustomerModel) => (
          <IconDrop
            dense
            title={"revaluation"}
            enabled={row.revaluation}
            icon={<PostAddIcon />}
          />
        )
      },
      {
        title: "Bericht",
        field: "reports",
        type: "boolean",
        render: (row: ICustomerModel) => (
          <IconDrop
            dense
            title={"reports"}
            enabled={row.reports}
            icon={<AssessmentIcon />}
          />
        )
      }
    ],
    data: JSON.parse(JSON.stringify(fetchedCustomers))
  };

  return (
    <Wrapper>
      <Heading heading="Kunden">
        <PermContactCalendar />
      </Heading>
      <DataGrid dense {...customerDataprops} />
    </Wrapper>
  );
};

export default observer(Customer);
