import {
  Dialog,
  makeStyles,
  Theme,
  TextField,
  Button,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { ISurveyModel } from "../../models/ISurveyModel";
import { RvTypeService } from "../../services/RvTypeService";
import SurveyService from "../../services/SurveyService";
import { DataGridActionButtonProps } from "../DataGrid";
import { RawValue } from "../RawValue";
import { isValidSid } from "./EditSurvey";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  title: {
    textAlign: "center",
    boxSizing: "border-box"
  },
  content: {
    width: "400px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2)
  },
  button: {
    marginTop: "15px"
  }
}));

export const CreateRvType: FC<DataGridActionButtonProps> = ({
  open,
  close
}) => {
  const classes = useStyles();
  const { createRvType } = RvTypeService;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [longName, setLongName] = useState<string>();
  const [shortName, setShortName] = useState<string>();
  const [description, setDescription] = useState<string>();

  const onLongNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLongName(event.target.value);
  };

  const onShortNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setShortName(event.target.value);
  };

  const onDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const onSubmit = async () => {
    if (!isValidSubmission()) {
      return;
    }

    // types have already been checkd in isValidSubmission, but the compiler does not realize this
    const res = await createRvType(
      longName as string,
      shortName as string,
      description as string
    );

    if (res.status_code === 1000 && res.data?.id) {
      console.log(res);
      history.push(`/rvtype/${res.data?.id}`);
    } else {
      enqueueSnackbar(
        "Aufgabengruppe konnte nicht gespeichert werden",
        getNotiOptions("error")
      );
    }
  };

  const onClose = () => {
    close();
  };

  const isValidSubmission = () => {
    return longName?.length && shortName?.length && description?.length;
  };

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={onClose}
    >
      <DialogTitle className={classes.title}>
        Aufgabengruppe Erstellen
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          required
          fullWidth
          value={longName}
          label="Name"
          onChange={onLongNameChange}
        />
        <TextField
          required
          fullWidth
          value={shortName}
          label="Abkürzung"
          onChange={onShortNameChange}
        />
        <TextField
          required
          fullWidth
          value={description}
          label="Beschreibung"
          onChange={onDescriptionChange}
        />
        <Button
          className={classes.button}
          fullWidth
          size="small"
          variant="contained"
          color="secondary"
          type="submit"
          disabled={!isValidSubmission()}
          onClick={onSubmit}
        >
          Speichern
        </Button>
      </DialogContent>
    </Dialog>
  );
};
