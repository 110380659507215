import axios from "axios";
import { setServerUrl } from "../constants/constants";
import { IRawValueModel } from "../models/IRawValueModel";
import { APIResponse } from "./SurveyService";

export const RawValueService = {
  async getAllRawValues(): Promise<APIResponse<IRawValueModel[]>> {
    const rsp = await axios.get(setServerUrl("/api/rv/get", "rv/get"));
    return Promise.resolve(rsp.data);
  },
  async getRawValue(id: string): Promise<APIResponse<IRawValueModel>> {
    const rsp = await axios.get(setServerUrl(`/api/rv/${id}`, `rv/${id}`));
    return Promise.resolve(rsp.data);
  },
  async createOrUpdateRawValue(
    rv: Partial<IRawValueModel>,
    rvTypeId: string
  ): Promise<APIResponse<void>> {
    const rsp = await axios.put(setServerUrl(`/api/rv/update`, "rv/update"), {
      ...rv,
      rvTypeId
    });
    return Promise.resolve(rsp.data);
  },
  async deleteRawValue(id: string): Promise<APIResponse<void>> {
    const rsp = await axios.delete(
      setServerUrl(`/api/rv/delete/${id}`, `rv/delete/${id}`)
    );
    return Promise.resolve(rsp.data);
  }
};
