import { Chip, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { formatDistanceToNow } from "date-fns";
import deDE from "date-fns/locale/de";
import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import { locale } from "../constants/constants";
import { parseAndFormat } from "../functions/date";
import { searchUser } from "../functions/filter";
import { IActivityModel } from "../models/IActivityModel";
import { IOfficeModel } from "../models/IOfficeModel";
import ParticipantsStore from "../stores/ParticipantsStore";
import UserStore from "../stores/UserStore";
import CommentBox from "./CommentBox";
import Wrapper from "./containers/Wrapper";
import DataGrid, { IDataGridProps } from "./DataGrid";
import CommentDetailPanel from "./details/CommentDetail";

const useStyles = makeStyles((_theme: Theme) => ({
  chip: {
    fontSize: "0.7rem",
    margin: "1px",
    boxSizing: "border-box"
  }
}));

const styles: any = {
  cell: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "200px"
  },
  chip: {
    system: {
      backgroundColor: "DarkKhaki",
      color: "white"
    },
    internal: {
      backgroundColor: "CadetBlue",
      color: "white"
    }
  }
};

const ActivityStreamNew: FC = () => {
  const classes = useStyles();
  const { isAdminSupport } = useContext(UserStore);
  const { fetchedActivities } = useContext(ParticipantsStore);

  const getOverflowChip = (items: string[], type: "office" | "customer") => (
    <Tooltip title={items.join(" | ")}>
      <Chip
        className={classes.chip}
        style={styles.chip[type]}
        key="overflow"
        label={`+ ${items.length}`}
        size="small"
        {...(type === "office" && { variant: "outlined" })}
        {...(type === "customer" && { color: "secondary" })}
      />
    </Tooltip>
  );

  const renderCustomer = (row: IActivityModel) => {
    const offices = row.Offices;
    const customers = offices.map((office: IOfficeModel) => office.Customer);
    const uniqueCustomers = customers?.filter(
      (v: any, i: any, a: any) => a.findIndex((t: any) => t.id === v.id) === i
    );
    const shownCustomers = uniqueCustomers.slice(0, 3);
    const overflow = uniqueCustomers.slice(3);

    return (
      <>
        {shownCustomers?.map((customer: any) => (
          <Tooltip title={customer.longName}>
            <Chip
              className={classes.chip}
              key={customer.id}
              label={customer.shortName}
              size="small"
              color="secondary"
            />
          </Tooltip>
        ))}
        {overflow?.length > 0 &&
          getOverflowChip(
            overflow.map((cust) => cust?.shortName!),
            "customer"
          )}
      </>
    );
  };

  const renderOffice = (row: IActivityModel) => {
    const overflow: IOfficeModel[] = row.Offices.slice(3);
    return (
      <>
        {row.Offices.slice(0, 3).map((office: IOfficeModel) => (
          <Tooltip title={office.shortName}>
            <Chip
              className={classes.chip}
              key={office.id}
              label={office.shortName}
              size="small"
              variant="outlined"
            />
          </Tooltip>
        ))}
        {overflow.length > 0 &&
          getOverflowChip(
            overflow.map((office) => office?.shortName!),
            "office"
          )}
      </>
    );
  };

  const renderCreatedAt = (row: IActivityModel) => (
    <Tooltip title={parseAndFormat(row.createdAt, true)}>
      <span>
        {formatDistanceToNow(new Date(row.createdAt), { locale: deDE })}
      </span>
    </Tooltip>
  );

  const renderInternal = (row: IActivityModel) => (
    <Chip
      key={row.id}
      label={row.internal ? "Intern" : "Öffentlich"}
      size="small"
      className={classes.chip}
      style={row.internal ? {} : styles.chip.internal}
    />
  );

  const renderSystem = (row: IActivityModel) => (
    <Chip
      key={row.id}
      label={row.system ? "System" : "Kommentar"}
      size="small"
      className={classes.chip}
      style={row.system ? {} : styles.chip.system}
    />
  );

  const activityDataProps: IDataGridProps = {
    headerColor: "white",
    headerFontColor: "#008dcf",
    fontSize: "0.9rem",
    fontColor: "DimGray",
    hideContainer: true,
    columnFilter: true,
    pagingFilter: true,
    detailPanel: (row: IActivityModel) => <CommentDetailPanel comment={row} />,
    dense: true,
    columns: [
      {
        title: "Benutzer*in",
        field: "User",
        width: "70%",
        type: "string",
        cellStyle: styles.cell,
        customFilterAndSearch: (input: string, row: IActivityModel) =>
          searchUser.byFullName(input, row?.User),
        render: (row: IActivityModel) => (
          <span>
            {Number(row.system) === 1
              ? "System"
              : `${
                  row?.User
                    ? `${row.User.firstName} ${row.User.lastName}`
                    : "- Benutzer*in gelöscht -"
                }`}
          </span>
        )
      },
      {
        title: "Erstellt",
        field: "createdAt",
        width: "10%",
        type: "date",
        dateSetting: { locale },
        sorting: true,
        defaultSort: "desc",
        render: (row: IActivityModel) => renderCreatedAt(row),
        cellStyle: styles.cell
      },
      ...(isAdminSupport
        ? [
            {
              title: "Einrichtung",
              field: "Offices",
              width: "10%",
              customFilterAndSearch: (term: string, rowData: IActivityModel) =>
                rowData.Offices.map(
                  (office) =>
                    office.longName.toLowerCase().includes(term) ||
                    office.shortName.toLowerCase().includes(term)
                ).some((x) => x === true),
              render: (row: IActivityModel) => renderOffice(row),
              cellStyle: styles.cell
            },
            {
              title: "Kunde",
              field: "Offices",
              width: "10%",
              customFilterAndSearch: (term: string, rowData: IActivityModel) =>
                rowData.Offices.map(
                  (office) =>
                    office?.Customer?.longName
                      .toLowerCase()
                      .includes(term.toLowerCase()) ||
                    office?.Customer?.shortName
                      .toLowerCase()
                      .includes(term.toLowerCase())
                ).some((x) => x === true),
              render: (row: IActivityModel) => renderCustomer(row),
              cellStyle: styles.cell
            },
            {
              title: "Intern",
              field: "internal",
              width: "5%",
              lookup: { 0: "Öffentlich", 1: "Intern" },
              render: (row: IActivityModel) => renderInternal(row)
            },
            {
              title: "Typ",
              field: "system",
              width: "5%",
              lookup: { 0: "Kommentar", 1: "System" },
              render: (row: IActivityModel) => renderSystem(row)
            }
          ]
        : [])
    ],
    data: fetchedActivities ? JSON.parse(JSON.stringify(fetchedActivities)) : []
  };

  return (
    <Wrapper>
      <DataGrid {...activityDataProps} />
      <CommentBox />
    </Wrapper>
  );
};

export default observer(ActivityStreamNew);
