import { EExportType, IProjectModel } from "./IProjectModel";
import { IOfficeModel } from "./IOfficeModel";

export type TCustomerItem = "customer" | "office" | "project" | "event";

export interface IEditItem {
  type:
  | "id"
  | "name"
  | "default"
  | "boolean"
  | "color"
  | "multiselect"
  | "select"
  | "date";
  options?: ISelectOptions | ITextfieldOptions;
  value: string | boolean | IOfficeModel[] | IProjectModel | Date | EExportType[];
  name: string;
}

export interface ICustomerItemModel {
  customerId?: string;
  items: IEditItem[];
  type: TCustomerItem;
  title: string;
}

interface IDependency {
  states: string[],
  handler: (self: IEditItem, dependencies: any[]) => any[]
}

export interface IGenericOptions {
  unvalidated?: boolean,
  defaultState?: any,
  dependencies?: IDependency,
}

export interface ISelectOptions extends IGenericOptions {
  items: any[],
  format: string,
  comparisonKey: string,
  title?: string,
}

export interface ITextfieldOptions extends IGenericOptions {
  multiline?: boolean,
  fullWidth?: boolean,
}

/**
 * Checks if all required properties exist in the object to qualify as ISelectOptions
 * 
 * Required properties:
 * 
 * * comparisonKey
 * * format
 * * items
 * 
 * Optional properties:
 * 
 * * title
 * 
 * @param object 
 * @returns ``boolean``
 */
export const isISelectOptions = (object: any): object is ISelectOptions => {
  return object && "comparisonKey" in object && "format" in object && "items" in object
}

/**
 * Checks if all required properties exist in the object to qualify as ITextfieldOptions
 * 
 * Required properties:
 * 
 * *none*
 * 
 * Optional properties:
 * 
 * * multiline
 * * fullWidth
 * 
 * @param object 
 * @returns ``boolean``
 */
export const isITextfieldOptions = (object: any): object is ITextfieldOptions => {
  return object
}