/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import WarningIcon from "@material-ui/icons/Warning";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiOptions } from "../../constants/configs";
import { formatDate } from "../../functions/date";
import { emptyRun, IRunModel } from "../../models/IRunModel";
import { ISurveyModel } from "../../models/ISurveyModel";
import ParticipantService from "../../services/ParticipantService";
import DatePicker from "../DatePicker";

interface IStrings {
  [key: string]: {
    [key: string]: string;
  };
}

type TStrSelector = "initial" | "replace" | "invalidate" | "continue" | "date";
type TDiaStrType = "title" | "description";
type TToolStrType = "confirm";

const dialogStr: IStrings = {
  initial: {
    title: "Was wollen Sie tun?",
    description: "Wählen Sein eine Aktion für den Gewählten Test"
  },
  invalidate: {
    title: "Test entwerten",
    description: "Sind Sie sicher dass Sie den Test entwerten wollen?"
  },
  replace: {
    title: "Test wählen",
    description:
      "Wählen Sie einen alternativen Test, um den aktuellen Test zu ersetzen"
  },
  continue: {
    title: "Test ersetzen",
    description:
      "Sind Sie sicher, dass Sie den aktuellen Test durch den gewählten Test ersetzen wollen?"
  }
};

const toolStr: IStrings = {
  invalidate: {
    confirm:
      "Der bisherige Test und alle dazu gehörigen Ergebnisse werden entwertet"
  },
  replace: {
    confirm:
      "Der bisherige Test sowie dessen Ergebnisse werden entwertet und durch den neue Test ersetzt"
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    title: {
      textAlign: "center",
      boxSizing: "border-box"
    },
    description: {
      textAlign: "center",
      boxSizing: "border-box",
      padding: theme.spacing(2),
      margin: "0px"
    },
    content: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2)
    },
    container: {
      display: "flex",
      justifyContent: "space-around"
    },
    form: {
      textAlign: "left",
      minWidth: "200px"
    },
    table: {
      maxWidth: "80%"
    },
    row: {},
    rowName: {
      fontWeight: "bold"
    },
    btn: {
      minWidth: "200px"
    },
    red: {
      color: theme.palette.error.main
    },
    yellow: {
      color: theme.palette.warning.main
    },
    lightYellow: {
      color: theme.palette.warning.light
    },
    green: {
      color: theme.palette.success.main
    },
    grey: {
      color: "grey"
    }
  })
);

interface IEditRun {
  participantId: string;
  run: IRunModel | undefined;
  open: boolean;
  toggleDialog: () => void;
}

const EditRun: FC<IEditRun> = ({
  participantId,
  run = emptyRun(),
  open,
  toggleDialog
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { getAlternativeSurveys, invalidateRun, replaceRun } =
    ParticipantService;
  const { enqueueSnackbar } = useSnackbar();

  const [title, setTitle] = useState<string>(
    dialogStr.initial.title.toUpperCase()
  );
  const [description, setDescription] = useState<string>(
    dialogStr.initial.description
  );
  const [date, setDate] = useState(new Date());
  const [dateSelected, setDateSelected] = useState<boolean>(false);
  const [isContinue, setIsContinue] = useState<boolean>(false);
  const [isInvalidate, setIsInvalidate] = useState<boolean>(false);
  const [isReplacement, setIsReplacement] = useState<boolean>(false);
  const [selectedSurveyId, setSelectedSurveyId] = useState<string>("");
  const [surveys, setSurveys] = useState<Array<ISurveyModel>>([]);

  const getAltSurveys = async () => {
    const { data } = await getAlternativeSurveys(participantId, run.id);
    if (data) {
      setSurveys(data);
    }
  };

  const setInfoStr = (selector: TStrSelector, type: TDiaStrType) => {
    switch (type) {
      case "title":
        setTitle(dialogStr[selector].title.toUpperCase());
        break;
      case "description":
        setDescription(dialogStr[selector].description);
        break;
      default:
        break;
    }
  };

  const getToolStr = (selector: TStrSelector, type: TToolStrType) => {
    return toolStr[selector][type];
  };

  const setSurvey = (selSurvId: string) => {
    setSelectedSurveyId(selSurvId);
  };

  const handleClose = () => {
    toggleDialog();
    setInfoStr("initial", "title");
    setInfoStr("initial", "description");
    setIsReplacement(false);
    setIsInvalidate(false);
    setIsContinue(false);
    setDateSelected(false);
  };

  const continueReplacement = () => {
    setIsContinue(true);
    setInfoStr("continue", "title");
    setInfoStr("continue", "description");
  };

  const toggleReplacement = () => {
    setInfoStr("replace", "title");
    setInfoStr("replace", "description");
    setIsReplacement(true);
  };

  const toggleInvalidation = () => {
    setInfoStr("invalidate", "title");
    setInfoStr("invalidate", "description");
    setIsInvalidate(true);
  };

  const handleRunExclusion = async () => {
    if (run.id) {
      const rsp = await invalidateRun(participantId, run.id);
      if (rsp.status_code === 1000) {
        enqueueSnackbar(
          `Run (${run.Survey?.description}) entwertet`,
          getNotiOptions("success")
        );
      } else {
        enqueueSnackbar(`Fehler bei der Entwertung`, getNotiOptions("error"));
      }
      history.go(0);
    }
  };

  const handleRunReplacement = async () => {
    if (run.id) {
      const rsp = await replaceRun(
        participantId,
        run.id,
        selectedSurveyId,
        format(date, "yyyy-MM-dd")
      );
      if (rsp.status_code === 1000) {
        enqueueSnackbar(
          `Run (${run.Survey?.description}) ersetzt`,
          getNotiOptions("success")
        );
      } else {
        enqueueSnackbar(
          `Aktualisierung fehlgeschlagen`,
          getNotiOptions("error")
        );
      }
      history.go(0);
    }
  };

  // USE-EFFECT
  useEffect(() => {
    getAltSurveys();
  }, []);

  // CONTENT
  const initialContent = () => {
    return (
      <>
        <Button
          className={classes.btn}
          onClick={toggleInvalidation}
          color="secondary"
          variant="contained"
          size="small"
          startIcon={<ConfirmationNumberIcon />}
        >
          Test entwerten
        </Button>
        <Button
          className={classes.btn}
          onClick={toggleReplacement}
          color="secondary"
          variant="contained"
          size="small"
          startIcon={<SwapHorizIcon />}
        >
          Test ersetzen
        </Button>
      </>
    );
  };

  const invalidateContent = () => {
    return (
      <Tooltip title={getToolStr("invalidate", "confirm")}>
        <Button
          className={classes.btn}
          onClick={() => handleRunExclusion()}
          color="secondary"
          variant="contained"
          size="small"
          startIcon={<WarningIcon className={classes.lightYellow} />}
        >
          Bestätigen
        </Button>
      </Tooltip>
    );
  };

  const replacementContent = () => {
    return (
      <>
        {isReplacement && isContinue ? (
          <Button
            className={classes.btn}
            onClick={handleRunReplacement}
            color="secondary"
            variant="contained"
            size="small"
            startIcon={<WarningIcon className={classes.lightYellow} />}
          >
            Bestätigen
          </Button>
        ) : (
          <>
            <FormControl className={classes.form}>
              <InputLabel id="surveys">Umfrage wählen</InputLabel>
              <Select
                labelId="surveys"
                label="Umfrage Wählen"
                onChange={(e) => setSurvey(`${e.target.value}`)}
              >
                {surveys.map((surv: ISurveyModel, i) => (
                  <MenuItem key={i} value={surv.id}>
                    {surv.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedSurveyId.length > 0 && (
              <DatePicker
                label="Gültigkeit"
                processDate={(d) => {
                  setDateSelected(true);
                  setDate(d);
                }}
                disablePast={true}
                minDate={new Date()}
              />
            )}
          </>
        )}
      </>
    );
  };

  const actionsContent = () => {
    return (
      <>
        {isReplacement && !isInvalidate && !isContinue && dateSelected && (
          <Button
            className={classes.green}
            onClick={continueReplacement}
            color="default"
            variant="text"
            size="small"
          >
            Weiter
          </Button>
        )}
        <Button
          className={classes.red}
          onClick={handleClose}
          color="default"
          variant="text"
          size="small"
        >
          Abbrechen
        </Button>
      </>
    );
  };

  const detailsContent = () => {
    return (
      <Table size="small" className={classes.table}>
        {run?.Survey && (
          <TableRow className={classes.row}>
            <TableCell align="left" className={classes.rowName}>
              Aktueller Test:
            </TableCell>
            <TableCell align="right">
              {`${run.Survey.description} | ${run.Survey.sid}`}
            </TableCell>
          </TableRow>
        )}
        {selectedSurveyId && (
          <TableRow className={classes.row}>
            <TableCell align="left" className={classes.rowName}>
              Ersetzen durch:
            </TableCell>
            <TableCell align="right">{`${
              surveys?.filter((surv) => surv.id === selectedSurveyId)[0]
                .description
            } | ${
              surveys?.filter((surv) => surv.id === selectedSurveyId)[0].sid
            }`}</TableCell>
          </TableRow>
        )}
        {dateSelected && (
          <TableRow className={classes.row}>
            <TableCell align="left" className={classes.rowName}>
              Gültigkeit:
            </TableCell>
            <TableCell align="right">
              {`${formatDate(new Date())} - ${formatDate(date)}`}
            </TableCell>
          </TableRow>
        )}
      </Table>
    );
  };

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <Divider component="span" />
      <Typography className={classes.description}>{description}</Typography>
      <Divider component="span" />
      <DialogContent className={classes.content}>
        {detailsContent()}
        {isInvalidate && !isReplacement && invalidateContent()}
        {isReplacement && !isInvalidate && replacementContent()}
        {!isReplacement && !isInvalidate && initialContent()}
      </DialogContent>
      <Divider component="span" />
      <DialogActions className={classes.actions}>
        {actionsContent()}
      </DialogActions>
    </Dialog>
  );
};

export default observer(EditRun);
