import { ICustomerItemModel } from "./ICustomerItemModel";
import { IOfficeModel } from "./IOfficeModel";
import { IPositionModel } from "./IPositionModel";
import { IProjectModel } from "./IProjectModel";
export interface ICustomerModel {
  id: string;
  longName: string;
  shortName: string;
  color: string;
  showResults: boolean;
  revaluation: boolean;
  reports: boolean;
  Offices?: IOfficeModel[];
  Projects?: IProjectModel[];
  Positions?: IPositionModel[];
}

export function emptyCustomer(): ICustomerModel {
  return {
    id: "",
    longName: "",
    shortName: "",
    color: "",
    showResults: false,
    revaluation: false,
    reports: false,
    Offices: [] as IOfficeModel[],
    Projects: [] as IProjectModel[],
    Positions: [] as IPositionModel[]
  };
}

export function newCustomerItem(): ICustomerItemModel {
  return {
    items: [
      {
        type: "id",
        name: "id",
        value: ""
      },
      {
        type: "name",
        name: "longName",
        value: ""
      },
      {
        type: "default",
        name: "shortName",
        value: ""
      },
      {
        type: "color",
        name: "color",
        value: ""
      },
      {
        type: "boolean",
        name: "showResults",
        value: false
      },
      {
        type: "boolean",
        name: "revaluation",
        value: false
      },
      {
        type: "boolean",
        name: "reports",
        value: false
      }
    ],
    type: "customer",
    title: "Kunde erstellen"
  };
}
