export interface ICompetencyModel {
  id: string;
  longName: string;
  shortName: string;
  description: string;
  type: string;
}

export function emptyCompetency(): ICompetencyModel {
  return {
    id: "",
    longName: "",
    shortName: "",
    description: "",
    type: "OT" // detfault until multiple types are implemented
  };
}
