import axios, { AxiosResponse } from "axios";
import { setServerUrl } from "../constants/constants";
import {
  CorrectAnswer,
  ISurveyModel,
  ISurveyModel_RawValues,
  SurveyType
} from "../models/ISurveyModel";

// TODO: refactor api to use this
export interface APIResponse<T> {
  status_code: number;
  data?: T;
}

export interface LinksUpdate {
  handledLinks: number;
  newLinks: number;
  deletedLinks: number;
}

const SurveyService = {
  async getAllSurveys(): Promise<any> {
    const rsp: AxiosResponse = await axios.request({
      url: setServerUrl(`/api/survey`, `/survey`),
      method: "GET"
    });
    if (rsp.data.status_code === 1000) {
      return Promise.resolve(rsp.data);
    } else {
      return Promise.reject("error");
    }
  },

  async duplicateSurvey(surveyId: string, details: ISurveyModel) {
    const rsp: AxiosResponse<any> = await axios.put(
      setServerUrl(`/api/survey/add`, `/api/survey/add`),
      { surveyId, details }
    );
    return Promise.resolve(rsp.data);
  },

  async createSurvey(
    description: string,
    sid: string,
    barrier: boolean,
    rawValueIds: string[],
    type: SurveyType
  ): Promise<APIResponse<ISurveyModel>> {
    const rsp = await axios.post(
      setServerUrl("/api/survey/new", "/api/survey/new"),
      {
        description,
        sid,
        barrier: barrier ? 1 : 0,
        rawValueIds,
        type
      }
    );
    return Promise.resolve(rsp.data);
  },

  // fetch survey by id with sample solutions
  async getSurvey(
    id: string
  ): Promise<Readonly<APIResponse<ISurveyModel_RawValues>>> {
    const res: AxiosResponse = await axios.get(
      setServerUrl(`/api/survey/${id}`, `/survey/${id}`)
    );
    return Promise.resolve(res.data);
  },

  // update survey details
  async updateSurvey(
    id: string,
    details: ISurveyModel
  ): Promise<APIResponse<ISurveyModel>> {
    const res = await axios.put(
      setServerUrl("/api/survey/edit", "/survey/edit"),
      {
        surveyId: id,
        details
      }
    );

    return Promise.resolve(res.data);
  },

  async deleteSurvey(id: string): Promise<APIResponse<void>> {
    const res = await axios.delete(
      setServerUrl(`/api/survey/delete/${id}`, `/survey/delete/${id}`)
    );
    return Promise.resolve(res.data);
  },

  // TODO: Create database relation between surveys and solutions, then include this in getSurvey
  async getCorrectAnswers(sid: string): Promise<APIResponse<CorrectAnswer[]>> {
    const res: AxiosResponse = await axios.get(
      setServerUrl(`/api/survey/sid/${sid}`, `/survey/sid/${sid}`)
    );
    return Promise.resolve(res.data);
  },

  async createCorrectAnswer(
    sid: string,
    answerTitle: string,
    answerSolution: string
  ): Promise<APIResponse<CorrectAnswer>> {
    const res: AxiosResponse = await axios.post(
      setServerUrl("/api/survey/createAnswer", "/survey/createAnswer"),
      {
        sid,
        answerTitle,
        answerSolution
      }
    );
    return Promise.resolve(res.data);
  },

  async editCorrectAnswer(
    id: string,
    title: string,
    solution: string
  ): Promise<APIResponse<number | undefined>> {
    const res = await axios.put(
      setServerUrl("/api/survey/editAnswer", "/survey/editAnswer"),
      {
        id,
        title,
        solution
      }
    );
    return Promise.resolve(res.data);
  },

  async deleteCorrectAnswer(id: string): Promise<boolean> {
    const res = await axios.delete(
      setServerUrl(
        `/api/survey/deleteAnswer/${id}`,
        `/survey/deleteAnswer/${id}`
      )
    );
    return Promise.resolve(true);
  },

  // async createSurveyLink(
  //   rawValueId: string,
  //   surveyId: string
  // ): Promise<APIResponse<void>> {
  //   const rsp = await axios.post(
  //     setServerUrl(`/api/survey/link`, `/survey/link`),
  //     {
  //       rawValueId,
  //       surveyId
  //     }
  //   );
  //   return Promise.resolve(rsp.data);
  // },

  async removeSurveyLink(
    rawValueId: string,
    surveyId: string
  ): Promise<APIResponse<void>> {
    const rsp = await axios.delete(
      setServerUrl(
        `/api/survey/unlink/${rawValueId}/${surveyId}`,
        `/survey/unlink/${rawValueId}/${surveyId}`
      )
    );
    return Promise.resolve(rsp.data);
  },

  async createSurveyLinks(
    surveyId: string,
    rawValueIds: string[],
    deleteRawValueIds: string[]
  ): Promise<APIResponse<LinksUpdate>> {
    const rsp = await axios.post(
      setServerUrl(`/api/survey/link`, "/survey/link"),
      {
        surveyId,
        rawValueIds,
        deleteRawValueIds
      }
    );
    return Promise.resolve(rsp.data);
  }
};

export default SurveyService;
