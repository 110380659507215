import { IEventModel } from "../models/IEventModel";
import { IUserModel } from "../models/IUserModel";
import { translateStatus } from "./translateStatus";

export const sort = {
  alphabetically(strA: string | undefined, strB: string | undefined) {
    const a = strA || "";
    const b = strB || "";
    return a.length - b.length;
  }
};

export const searchUser = {
  byFullName(input: string, user?: IUserModel) {
    return input
      .split(" ")
      .map(
        (input) =>
          user?.firstName.toLowerCase().includes(input.toLowerCase()) ||
          user?.lastName.toLowerCase().includes(input.toLowerCase())
      )
      .every((x) => x);
  }
};

export const filterStatus = {
  application(input: string, event: IEventModel) {
    const translatedStatus = translateStatus("event", event.status);
    return translatedStatus.toLowerCase().includes(input.toLowerCase());
  }
};

export function filterDuplicatesByKey<T>(arr: T[], key: keyof T): T[] {
  return arr.filter(
    (a, index) => arr.findIndex((b) => a[key] === b[key]) === index
  );
}
