import { makeStyles, Theme } from "@material-ui/core";
import Chip from "@material-ui/core/Chip/Chip";
import { PeopleAlt } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import { FC, MouseEvent, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { parseAndFormat } from "../functions/date";
import { transform } from "../functions/transform";
import {
  IParticipantDetailModel,
  IReplaceParticipantDetailModel
} from "../models/IParticipantDetailModel";
import ParticipantsStore from "../stores/ParticipantsStore";
import Wrapper from "./containers/Wrapper";
import DataGrid, { IDataGridProps } from "./DataGrid";
import ExportProject from "./dialogs/ExportProject";
import Heading from "./Heading";
import LoadingAnimation from "./LoadingAnimation";

const useStyles = makeStyles((theme: Theme) => ({
  errorChip: {
    backgroundColor: theme.palette.error.main,
    color: "white"
  },
  warningChip: {
    backgroundColor: theme.palette.warning.main,
    color: "white"
  },
  whiteChip: {
    backgroundColor: "white",
    color: "black"
  }
}));

const Participants: FC = () => {
  const participantsStore = useContext(ParticipantsStore);
  const history = useHistory();
  const classes = useStyles();

  const { fetchedParticipants, isfetchingData, getParticipants } =
    participantsStore;

  const handleRowClick = (
    _e: MouseEvent<HTMLButtonElement>,
    row: IParticipantDetailModel
  ) => {
    history.push(`/participant/${row.id}`);
  };

  const renderSupportStatus = (
    row: IReplaceParticipantDetailModel
  ): JSX.Element => {
    if (!row.supportStatus) {
      return <Chip label="OK" size="small" className={classes.whiteChip} />;
    }

    // debugger;
    return (
      <Chip
        label={row.supportStatus}
        size="small"
        className={
          row.supportStatus === "major"
            ? classes.errorChip
            : classes.warningChip
        }
      />
    );
  };

  useEffect(() => {
    getParticipants();
  }, [getParticipants]);

  const participantsDataprops: IDataGridProps = {
    // selectable: true,
    columnFilter: true,
    searchFilter: true,
    pagingFilter: true,
    tableButtons: [
      {
        tooltip: "Teilnehmende exportieren",
        icon: "save_alt",
        popup: ExportProject,
        isFreeAction: true
      }
    ],
    onRowClick: handleRowClick,
    columns: [
      { title: "Vorname", field: "firstName", width: "25%" },
      { title: "Nachname", field: "lastName", width: "25%" },
      {
        title: "E-Mail",
        field: "email",
        width: "40%",
        searchable: true,
        render: (row) => <span>{transform.noBreak(row.email)}</span>
      },
      {
        title: "Geburtstag",
        field: "birthday",
        type: "string",
        customFilterAndSearch: (input, row) =>
          parseAndFormat(row.birthday).includes(input),
        render: (row) => <span>{parseAndFormat(row.birthday)}</span>,
        width: "10%"
      },
      {
        title: "Supportfall",
        field: "supportStatus",
        lookup: {
          minor: "Minor Support",
          major: "Major Support"
        },
        render: renderSupportStatus
      }
    ],
    data: JSON.parse(JSON.stringify(fetchedParticipants))
  };

  return (
    <Wrapper>
      <Heading heading="Teilnehmer*innen">
        <PeopleAlt />
      </Heading>
      {isfetchingData ? (
        <LoadingAnimation />
      ) : (
        <DataGrid {...participantsDataprops} />
      )}
    </Wrapper>
  );
};

export default observer(Participants);
