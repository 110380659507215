import { observable } from "mobx";
import { createContext } from "react";
export interface IImportState {
  responseData: {
    [index: number]: {
      participant: {
        id: string;
        firstName: string;
        lastName: string;
        occurence: string;
      };
      application: {
        [index: number]: {
          positionId: string;
          officeId: string;
          occurence: string;
          status: string;
          action: string;
        };
      };
    };
  };
  fetched: boolean;
  status: string;
}

interface IMappingState {
  complete: boolean;
  duplicates: boolean;
}

interface IValidationState {
  processing: boolean;
  complete: boolean;
  count: number;
}

interface IConflictState {
  fetching: boolean;
  processing: boolean;
  complete: boolean;
  count: number;
}

interface ISelectState {
  fetched: boolean;
  officeSelected: boolean;
  projectSelected: boolean;
}

class StepperStore {
  @observable public activeStep: number;
  @observable public conflictState: IConflictState;
  @observable public importState: IImportState;
  @observable public mappingState: IMappingState;
  @observable public selectState: ISelectState;
  @observable public validationState: IValidationState;

  constructor() {
    this.activeStep = 0;
    this.mappingState = {
      complete: false,
      duplicates: false
    };
    this.validationState = {
      processing: false,
      complete: false,
      count: 0
    };
    this.conflictState = {
      fetching: false,
      processing: false,
      complete: false,
      count: 0
    };
    this.selectState = {
      fetched: false,
      officeSelected: false,
      projectSelected: false
    };
    this.importState = {
      responseData: [
        {
          participant: {
            id: "",
            firstName: "",
            lastName: "",
            occurence: ""
          },
          application: [
            {
              positionId: "",
              officeId: "",
              occurence: "",
              status: "",
              action: ""
            }
          ]
        }
      ],
      fetched: false,
      status: ""
    };
  }

  public setActiveStep = (activeStep: number) => {
    this.activeStep = activeStep;
  };

  public setMappingState = (mappingState: IMappingState) => {
    this.mappingState = {
      complete: mappingState.complete,
      duplicates: mappingState.duplicates
    };
  };

  public setValidationState = (validationState: IValidationState) => {
    this.validationState = {
      processing: validationState.processing,
      complete: validationState.complete,
      count: validationState.count
    };
  };

  public setConflictState = (conflictState: IConflictState) => {
    this.conflictState = {
      fetching: conflictState.fetching,
      processing: conflictState.processing,
      complete: conflictState.complete,
      count: conflictState.count
    };
  };

  public setSelectState = (selectState: ISelectState) => {
    this.selectState = {
      fetched: selectState.fetched,
      officeSelected: selectState.officeSelected,
      projectSelected: selectState.projectSelected
    };
  };

  public setImportState = (importState: IImportState) => {
    this.importState = {
      responseData: importState.responseData,
      fetched: importState.fetched,
      status: importState.status
    };
  };
}

export default createContext(new StepperStore());
