import {
  Box,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { IApplicationModel } from "../models/IApplicationModel";
import { IOfficeModel } from "../models/IOfficeModel";
import { IPositionModel } from "../models/IPositionModel";
import { IProjectModel } from "../models/IProjectModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selector: {
      width: "100%",
      minWidth: "220px"
    },
    hint: {
      textAlign: "center",
      color: theme.palette.warning.main
    }
  })
);

interface ISelections {
  office: IOfficeModel;
  project: IProjectModel;
  position: IPositionModel;
}

export interface ICallbackInfo<T> {
  object?: T;
  reset?: boolean;
}

export interface IChanges {
  office?: ICallbackInfo<IOfficeModel>;
  project?: ICallbackInfo<IProjectModel>;
  position?: ICallbackInfo<IPositionModel>;
}

export interface IPositionSelector {
  offices: IOfficeModel[];
  invisibleProjects?: IProjectModel[];
  existingApplications?: IApplicationModel[];
  onChange?: (changes: IChanges) => void;
  projectUnselectable?: boolean;
  positionUnselectable?: boolean;
}

const initialSelectionState = {
  office: {} as IOfficeModel,
  project: {} as IProjectModel,
  position: {} as IPositionModel
};

const PositionSelector: FC<IPositionSelector> = ({
  offices,
  invisibleProjects,
  existingApplications,
  onChange,
  projectUnselectable,
  positionUnselectable
}) => {
  const classes = useStyles();
  const [selections, setSelections] = useState<ISelections>(
    initialSelectionState
  );

  const updateSelectedOffice = (office: IOfficeModel) => {
    const availableProjects = getAvailableProjects(office?.Projects);
    let newSelectedProject = {} as IProjectModel;
    let newSelectedPosition = {} as IPositionModel;
    if (availableProjects?.length === 1) {
      newSelectedProject = availableProjects[0];
      const availablePositions = getAvailablePositions(
        office,
        newSelectedProject
      );
      if (availablePositions?.length === 1) {
        newSelectedPosition = availablePositions[0];
      }
    }

    setSelections({
      office,
      project: newSelectedProject,
      position: newSelectedPosition
    });
    if (onChange)
      onChange({
        office: { object: office },
        project: { object: newSelectedProject, reset: !newSelectedProject },
        position: { object: newSelectedPosition, reset: !newSelectedPosition }
      });
  };

  const updateSelectedProject = (project: IProjectModel) => {
    setSelections({
      ...selections,
      project,
      position: {} as IPositionModel
    });

    if (onChange)
      onChange({ project: { object: project }, position: { reset: true } });
  };

  const updateSelectedPosition = (position: IPositionModel) => {
    setSelections({
      ...selections,
      position
    });
    if (onChange) onChange({ position: { object: position } });
  };

  const onOfficeSelected = (selectedOffice: IOfficeModel | null) => {
    if (!selectedOffice) return;
    const office = offices?.find(
      (office: IOfficeModel) => office.id === selectedOffice.id
    );
    if (office) {
      updateSelectedOffice(office);
    }
  };

  const onProjectSelected = (event: any) => {
    const project = selections?.office?.Projects?.find(
      (project: IProjectModel) => project.id === event.target.value
    );
    if (selections?.office?.id && project) {
      updateSelectedProject(project);
    }
  };

  const onPositionSelected = (event: any) => {
    const position = selections?.project?.Positions?.find(
      (position: IPositionModel) => position.id === event.target.value
    );
    if (selections?.office?.id && selections?.project?.id && position) {
      updateSelectedPosition(position);
    }
  };

  const getAvailableProjects = (allProjects: IProjectModel[] | undefined) => {
    return allProjects
      ? allProjects.filter(
          (project) =>
            !invisibleProjects
              ?.map((invProject) => invProject.id)
              .includes(project.id)
        )
      : undefined;
  };

  const getAvailableProjectsPicker = (
    allProjects: IProjectModel[] | undefined
  ) => {
    if (!selections?.office) return;
    const availableProjects = getAvailableProjects(allProjects);
    if (!availableProjects?.length)
      return (
        <p className={classes.hint}>
          Für diese Einheit sind keine Projekte verfügbar
        </p>
      );
    if (!selections.project.id && availableProjects.length === 1) {
      updateSelectedProject(availableProjects[0]);
    }
    return (
      <FormControl fullWidth>
        <InputLabel id="project-select-label">Projekt</InputLabel>
        <Select
          value={selections.project.id || ""}
          labelId="project-select-label"
          id="project-select"
          onChange={onProjectSelected}
        >
          {availableProjects?.map((project: IProjectModel, i) => (
            <MenuItem key={i} value={project.id}>
              {project.longName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const getAvailablePositions = (
    office: IOfficeModel,
    project: IProjectModel
  ) => {
    return project?.Positions?.filter((position) => {
      return !existingApplications?.find((application) => {
        return (
          application.Office.id === office.id &&
          application.Position.id === position.id
        );
      });
    });
  };

  const getAvailablePositionsPicker = () => {
    if (!selections?.project)
      // don't show the picker if no project is selected
      return;
    const availablePositions = getAvailablePositions(
      selections.office,
      selections.project
    );
    if (!availablePositions?.length)
      return (
        <p className={classes.hint}>
          Für dieses Projekt sind keine Positionen verfügbar
        </p>
      );
    if (!selections.position.id && availablePositions.length === 1) {
      updateSelectedPosition(availablePositions[0]);
    }
    return (
      <FormControl fullWidth>
        <InputLabel id="position-select-label">Position</InputLabel>
        <Select
          value={selections.position.id || ""}
          labelId="position-select-label"
          id="position-select"
          onChange={onPositionSelected}
        >
          {availablePositions.map((position, i) => (
            <MenuItem key={i} value={position.id}>
              {position.longName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const getAvailableOfficesPicker = (
    allOffices: IOfficeModel[] | undefined
  ) => {
    if (!allOffices?.length)
      return <p className={classes.hint}>Keine Einheiten verfügbar</p>;
    if (!selections.office.id && allOffices.length === 1) {
      updateSelectedOffice(allOffices[0]);
    }
    return (
      <FormControl fullWidth>
        <Autocomplete
          fullWidth
          id="office-select"
          options={allOffices}
          getOptionLabel={(office) => office?.longName || ""}
          value={selections.office}
          onChange={(e, value) => {
            onOfficeSelected(value);
          }}
          noOptionsText="Keine Einheit"
          size="small"
          renderInput={(params) => (
            <TextField {...params} color="secondary" label="Einheit" />
          )}
          groupBy={(office) => office.Customer?.longName || ""}
        />
      </FormControl>
    );
  };

  return (
    <Box className={classes.selector}>
      {getAvailableOfficesPicker(offices)}
      {selections?.office?.id &&
        !projectUnselectable &&
        getAvailableProjectsPicker(selections?.office?.Projects)}
      {selections?.project?.id &&
        !positionUnselectable &&
        getAvailablePositionsPicker()}
    </Box>
  );
};

export default observer(PositionSelector);
