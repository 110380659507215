import React, { FC, useContext, useState } from "react";

import {
  Button,
  ButtonGroup,
  makeStyles,
  TextField,
  Theme
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { getNotiOptions } from "../../constants/configs";
import { IActivityModel } from "../../models/IActivityModel";
import ParticipantsStore from "../../stores/ParticipantsStore";
import UserStore from "../../stores/UserStore";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .MuiInputBase-root.Mui-disabled": {
      color: theme.palette.secondary.dark
    }
  },
  messageButtonBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(1),
    width: "100%",
    boxSizing: "border-box"
  },
  edit: {
    fontSize: "0.7rem",
    textTransform: "none",
    padding: "0 3px",
    color: theme.palette.success.main
  },
  delete: {
    fontSize: "0.7rem",
    textTransform: "none",
    padding: "0 3px",
    color: theme.palette.error.main
  },
  message: {
    width: "100%",
    padding: theme.spacing(2),
    boxSizing: "border-box"
  },
  textField: {
    margin: theme.spacing(1),
    caretColor: "black",
    backgroundColor: "white"
  }
}));

export interface ICommentDetailPanelProps {
  comment: IActivityModel;
}

const CommentDetailPanel: FC<ICommentDetailPanelProps> = ({ comment }) => {
  const classes = useStyles();
  const { user } = useContext(UserStore);
  const { enqueueSnackbar } = useSnackbar();
  const { fetchedParticipant, updateComment } = useContext(ParticipantsStore);

  const [edit, setEdit] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(comment.message);

  return (
    <div key={comment.id} className={classes.root}>
      <TextField
        multiline={true}
        variant="outlined"
        value={message}
        disabled={!edit}
        inputRef={(input) => input && edit && input.focus()}
        className={classes.textField}
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => setMessage(e.currentTarget.value)}
      />
      {comment?.User?.id === user.id && !comment.system ? (
        <ButtonGroup
          size="small"
          variant="text"
          className={classes.messageButtonBox}
        >
          <Button
            variant="text"
            className={classes.edit}
            onClick={() => {
              if (edit) {
                if (message.length > 0) {
                  updateComment(fetchedParticipant.id, comment.id, {
                    message,
                    delete: false
                  });
                  setEdit(false);
                } else {
                  enqueueSnackbar(
                    `Der Kommentar muss mindestens ein zeichen enthalten`,
                    getNotiOptions("info")
                  );
                }
              } else {
                setEdit(true);
              }
            }}
          >
            {!edit ? "Bearbeiten" : "Speichern"}
          </Button>
          <Button
            variant="text"
            className={classes.delete}
            onClick={() =>
              edit
                ? setEdit(false)
                : updateComment(fetchedParticipant.id, comment.id, {
                    message,
                    delete: true
                  })
            }
          >
            {!edit ? "Löschen" : "Abbrechen"}
          </Button>
        </ButtonGroup>
      ) : (
        <></>
      )}
    </div>
  );
};

export default observer(CommentDetailPanel);
