import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import { Dispatch, FC, SetStateAction } from "react";
import { IUserModel } from "../models/IUserModel";

interface IAvatar {
  user: IUserModel;
  toggle: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.light,
      borderRadius: "50%",
      fontSize: "17px",
      cursor: "pointer",
      height: "50px",
      width: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  })
);

const stringToHslColor = (str: string, s: number, l: number) => {
  let hash = 0;
  for (const char of str) {
    hash = str.charCodeAt(str.indexOf(char)) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
};

const Avatar: FC<IAvatar> = ({ user, toggle }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      style={{
        backgroundColor:
          user?.firstName && user?.lastName
            ? stringToHslColor(user?.firstName + user.lastName, 30, 50)
            : "gray"
      }}
      onClick={() => toggle((prev) => !prev)}
      onMouseEnter={() => toggle(true)}
    >
      <Typography variant="h6">
        {user.firstName.substring(0, 1)}
        {user.lastName.substring(0, 1)}
      </Typography>
    </Box>
  );
};

export default Avatar;
