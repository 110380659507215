import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  makeStyles,
  TextField,
  Theme
} from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect, useState } from "react";
import { ICustomerModel } from "../models/ICustomerModel";
import { IOfficeModel } from "../models/IOfficeModel";
import ParticipantsStore from "../stores/ParticipantsStore";
import UserStore from "../stores/UserStore";
import Wrapper from "./containers/Wrapper";

const useStyles = makeStyles((theme: Theme) => ({
  comment: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1),
    textAlign: "left",
    width: "100%"
  },
  submitBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box"
  },
  submitFilter: {
    display: "flex",
    flexDirection: "row",
    overFlow: "hidden"
  },
  checkInternal: {
    width: "6rem"
  },
  officeSelect: {
    width: "14rem"
  },
  button: {},
  autocomplete: {
    width: "100%"
  }
}));

const CommentBox: FC = () => {
  const classes = useStyles();
  const { user, isAdminSupport } = useContext(UserStore);
  const { getActivities, fetchedParticipant, addComment } =
    useContext(ParticipantsStore);

  const [internal, setInternal] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);

  const [userOffices, setUserOffices] = useState<Array<IOfficeModel>>([]);
  const [selectedOffices, setSelectedOffices] = useState<Array<IOfficeModel>>(
    []
  );
  const [userCustomers, setUserCustomers] = useState<Array<ICustomerModel>>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<
    Array<ICustomerModel>
  >([]);

  const handleComment = () => {
    const response = addComment(fetchedParticipant.id, {
      message,
      offices: selectedOffices,
      customers: selectedCustomers,
      internal
    });
    setMessage("");
    if (response !== null) {
      getActivities(fetchedParticipant.id);
    }
  };

  useEffect(() => {
    getActivities(fetchedParticipant.id);
  }, [getActivities, fetchedParticipant]);

  useEffect(() => {
    user.Offices && setUserOffices(user.Offices);
  }, [user]);

  useEffect(() => {
    user.Customers && setUserCustomers(user.Customers);
  }, [user]);

  useEffect(() => {
    if (
      (message.length > 0 && selectedOffices.length > 0) ||
      internal ||
      selectedCustomers.length > 0
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [
    setIsValid,
    isValid,
    message.length,
    selectedOffices.length,
    internal,
    selectedCustomers.length
  ]);

  return (
    <Wrapper>
      <TextField
        multiline={true}
        placeholder="Kommentar eingeben..."
        className={classes.comment}
        rows={5}
        variant="outlined"
        fullWidth
        color="secondary"
        margin="normal"
        value={message}
        inputProps={{
          maxLength: 1000
        }}
        helperText={`${message.length}/${1000}`}
        data-cy="info-comment-textfield"
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => setMessage(e.currentTarget.value)}
      />
      <div className={classes.submitBox}>
        <form className={classes.submitFilter}>
          {isAdminSupport && (
            <FormControlLabel
              className={classes.checkInternal}
              checked={internal}
              data-cy="info-comment-intern"
              onChange={() => setInternal(!internal)}
              control={<Checkbox name="checkedC" />}
              label="intern"
            />
          )}
          {!internal && (
            <FormControl
              variant="outlined"
              color="secondary"
              data-cy="info-comment-office"
              className={classes.officeSelect}
              id="office"
              fullWidth
            >
              <Autocomplete
                size="small"
                className={classes.autocomplete}
                disabled={selectedCustomers.length > 0}
                filterSelectedOptions
                getOptionLabel={(option) => option?.shortName || ""}
                groupBy={(option) =>
                  option?.Customer?.longName ? option.Customer.longName : ""
                }
                id="customer-select"
                multiple
                noOptionsText="Keine Einheit"
                options={userOffices}
                value={selectedOffices}
                onChange={(e, value) => {
                  setSelectedOffices(value);
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      size="small"
                      {...getTagProps({ index })}
                      label={option.longName}
                      color="secondary"
                      icon={<BusinessIcon />}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    required={true}
                    {...params}
                    variant="outlined"
                    color="secondary"
                    label="Einheiten"
                  />
                )}
              />
            </FormControl>
          )}
          {!internal && isAdminSupport && (
            <FormControl
              variant="outlined"
              color="secondary"
              data-cy="info-comment-customer"
              className={classes.officeSelect}
              id="customer"
              fullWidth
            >
              <Autocomplete
                size="small"
                disabled={selectedOffices.length > 0}
                multiple
                className={classes.autocomplete}
                id="tags-outlined"
                options={userCustomers}
                value={selectedCustomers}
                getOptionLabel={(option) => option?.shortName || ""}
                filterSelectedOptions
                noOptionsText="Kein Kunde"
                onChange={(e, value) => {
                  setSelectedCustomers(value);
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      size="small"
                      {...getTagProps({ index })}
                      label={option.longName}
                      color="secondary"
                      icon={<PermContactCalendar />}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    required={true}
                    {...params}
                    variant="outlined"
                    color="secondary"
                    label="Kunde"
                  />
                )}
              />
            </FormControl>
          )}
        </form>
        <Button
          disabled={!isValid}
          color="secondary"
          variant="contained"
          component="span"
          data-cy="info-comment-btn"
          className={classes.button}
          onClick={handleComment}
        >
          Kommentieren
        </Button>
      </div>
    </Wrapper>
  );
};

export default observer(CommentBox);
