import { Button, Dialog, makeStyles, Theme } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { APICodes, getNotiOptions } from "../../constants/configs";
import { INormModel } from "../../models/INormModel";
import { NormService } from "../../services/NormService";
import { DataGridActionButtonProps } from "../DataGrid";
import DeleteButton from "../DeleteButton";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  title: {
    textAlign: "center",
    boxSizing: "border-box"
  },
  content: {
    width: "400px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2)
  },
  button: {
    marginTop: "15px"
  }
}));

export const EditOrCreateNorm: FC<DataGridActionButtonProps> = ({
  open,
  close,
  data
}) => {
  const norm = data as INormModel | undefined;

  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { createNorm, deleteNorm, updateNorm } = NormService;

  const [longName, setLongName] = useState(norm?.longName);
  const [shortName, setShortName] = useState(norm?.shortName);
  const [description, setDescription] = useState(norm?.description);

  const onLongNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLongName(event.target.value);
  };

  const onShortNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setShortName(event.target.value);
  };

  const onDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const handleDelete = async () => {
    if (!norm) return;
    const res = await deleteNorm(norm.id);
    if (res.status_code === APICodes.SUCCESS) {
      history.push("/norm");
    } else {
      enqueueSnackbar(
        "Norm konnte nicht gelöscht werden",
        getNotiOptions("error")
      );
    }
  };

  const handleSubmit = async () => {
    if (!isValidSubmission()) return;

    // let res: APIResponse<INormModel>;

    if (norm) {
      const res = await updateNorm(
        norm.id,
        longName as string,
        shortName as string,
        description as string
      );

      if (res.status_code === APICodes.SUCCESS) {
        history.go(0);
      } else {
        enqueueSnackbar(
          "Norm konnte nicht aktualisiert werden",
          getNotiOptions("error")
        );
      }
    } else {
      const res = await createNorm(
        longName as string,
        shortName as string,
        description as string
      );

      if (res.status_code === APICodes.SUCCESS && res.data) {
        history.push(`/norm/${res.data.id}`);
      } else {
        enqueueSnackbar(
          "Norm konnte nicht gespeichert werden",
          getNotiOptions("error")
        );
      }
    }
  };

  const isValidSubmission = () =>
    longName &&
    longName.length > 0 &&
    shortName &&
    shortName.length > 0 &&
    description &&
    description.length > 0;

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={close}
    >
      <DialogTitle>Norm {norm ? "Bearbeiten" : "Erstellen"}</DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          required
          fullWidth
          label="Name"
          value={longName}
          onChange={onLongNameChange}
        />
        <TextField
          required
          fullWidth
          label="Abkürzung"
          value={shortName}
          onChange={onShortNameChange}
        />
        <TextField
          required
          fullWidth
          label="Beschreibung"
          value={description}
          onChange={onDescriptionChange}
        />
        <Button
          className={classes.button}
          fullWidth
          size="small"
          variant="contained"
          color="secondary"
          type="submit"
          onClick={handleSubmit}
          disabled={!isValidSubmission()}
        >
          Speichern
        </Button>
        {norm && (
          <DeleteButton
            buttonName="Löschen"
            callback={handleDelete}
            compareString="löschen"
            description="Geben Sie 'Löschen' ein"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
