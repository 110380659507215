import {
  Box,
  Button,
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  Theme,
  Typography
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";
import { IGroupModel } from "../../models/IGroupModel";
import { IPositionModel } from "../../models/IPositionModel";
import FileInput from "../FileInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      margin: theme.spacing(2),
      gap: theme.spacing(3)
    },
    modal: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      position: "absolute",
      top: "40%",
      left: "65%",
      transform: "translate(-50%, -50%)",
      width: "fit-content",
      backgroundColor: "white",
      padding: theme.spacing(2),
      borderRadius: "5px"
    },
    red: {
      color: theme.palette.error.main
    },
    green: {
      color: theme.palette.success.main
    },
    warning: {
      textAlign: "center",
      width: "100%",
      color: theme.palette.warning.main
    },
    filename: {
      marginBottom: theme.spacing(2),
      color: theme.palette.warning.main
    },
    group: {
      color: theme.palette.info.main
    },
    btnBox: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between"
    }
  })
);

export interface IUploadAcResults {
  open: boolean;
  position: IPositionModel;
  positions: IPositionModel[] | undefined;
  group: IGroupModel;
  close: () => void;
  handlePosition: (position: IPositionModel) => void;
  upload: (file: File) => void;
}

const UploadAcResults: FC<IUploadAcResults> = ({
  open,
  position = {},
  positions = [],
  group = {},
  close,
  handlePosition,
  upload
}) => {
  const classes = useStyles();
  const [file, setFile] = useState<File>({} as File);

  const handleReset = () => {
    close();
    setFile({} as File);
  };

  const handleFileSelect = (file: File) => {
    setFile(file);
  };

  const handleUpload = () => {
    upload(file);
    handleReset();
  };

  return (
    <Modal open={open} onClose={close} onBackdropClick={close}>
      <Box className={classes.modal}>
        <Typography variant="h6" className={classes.group}>
          {group?.longName}
        </Typography>
        <Typography variant="body1">
          Bitte Wählen Sie eine Position und eine Datei
        </Typography>
        <FormControl className={classes.formControl}>
          {positions.length ? (
            <>
              <Select
                fullWidth
                value={position.id || ""}
                onChange={(e) =>
                  handlePosition(
                    positions.find((pos) => pos.id === e.target.value) ||
                      ({} as IPositionModel)
                  )
                }
              >
                {positions.map((pos, i) => (
                  <MenuItem key={i} value={pos.id}>
                    {pos.longName}
                  </MenuItem>
                ))}
              </Select>
              <FileInput
                buttonText="Datei wählen"
                filetypes=".csv, .xlsx, .xlsm, .xls"
                callback={(file: File): void => {
                  handleFileSelect(file);
                }}
              />
            </>
          ) : (
            <p className={classes.warning}>
              Keine Positionen für den Import verfügbar
            </p>
          )}
        </FormControl>
        <Typography variant="body2" className={classes.filename}>
          {file?.name}
        </Typography>
        <Box className={classes.btnBox}>
          <Button
            onClick={handleReset}
            variant="outlined"
            size="small"
            className={classes.red}
          >
            Abbrechen
          </Button>
          <Button
            disabled={!position.id || !file?.name}
            onClick={handleUpload}
            variant="outlined"
            size="small"
            className={classes.green}
          >
            Ergebnisse importieren
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default observer(UploadAcResults);
